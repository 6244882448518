import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, Spinner, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ArrowLeft, ChevronLeft, ChevronRight } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useParams } from 'react-router-dom'
import LoaderDefault from '../LoaderDefault';
import Status from '../Status';
import Swal from 'sweetalert2';
import NodataFound from '../NodataFound';
import { useForm } from 'react-hook-form';
import { changeIntoDate, errorHandling } from '../../utlis';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { baseString } from '../../config';
import { toast } from 'react-toastify';


const PickupPoints = (props) => {
  const [pickupList, setPickupList] = useState({});
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState('');
  const dropFilterCount = 20
  const { merchantId } = useParams();

  const merchantPickupPointListing = () => {
    setLoadertable(true);
    setFilterInput('')
    setCurrentCount(1)
    setPage(1)
    let data = {}
    //console.log("merchantId",JSON.stringify(merchantId));

    if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
      data = { "merchant_id": props.merchantId }
    } else {
      data = { "merchant_id": merchantId }
    }
    setLoader(false)
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_PICKUPPOINT,
      data: data,
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        setLoader(false)
        if (response.data !== true) {
          setLoadertable(false)
          setPickupList(response.data)
        }
      })
      .catch(function (response) {
        setLoader(false);
        setLoadertable(false);
        errorHandling(response, "reload");
      });
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(pickupList.data) && checkUndeNullBlank(pickupList.totalPage)) {
      // &&checkUndeNullBlank(pickupList.totalPage).length>page.length
      if (pickupList.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(pickupList.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(pickupList.data)
      if (currentCount > Object.entries(pickupList.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  useEffect(() => {
    merchantPickupPointListing()
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [merchantId])
  const { register, handleSubmit } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onSubmit = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.brand_name)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage);
    } else {
      toast.info('Select Your Filtering Preference');
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
    let mydata = {}
    if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
      mydata = { "merchant_id": props.user[0].id }
    } else {
      mydata = { "merchant_id": props.merchantId }
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_PICKUPPOINT}?contact_name=${checkUndeNullBlank(data.brand_name) ? data.brand_name : ''}&page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: mydata,
      headers: { "token": window.btoa(baseString) }
    }).then(function (response) {
      setLoader(false);
      if (response.data.fatal !== true) {
        setPickupList(response.data)
      }
    }).catch(function (response) {
      setLoader(false);
      errorHandling(response, "");
    });
  };
  const markas = (data) => {
    setLoader(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_PICKUPPOINT_DEFAULT,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', });
        merchantPickupPointListing();
      }
    })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "")
      });
  };
  return (
    <div   className={props.inherit ? "app-content overflow-hidden" : "app-content content overflow-hidden"}>
    <Card  className="shadow border-0 mb-4 p-4">
      {loader && <div className="formLoader"><Spinner /></div>} 
      <Row>
        <Col sm={12}>
        <div className="p-0 allShipment content-wrapper border-0"> 
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                {/* {props.merchantId} */}

                
              </CardHeader>
              <Row className="px-3 mb-4">
                <Col sm={12}>
                  <Form onSubmit={handleSubmit(onSubmit)} className="cardFilter">
                    <CardTitle className="page-title">Manage Warehouse</CardTitle>
                    <Row className="mx-0 mb-3 mb-sm-0 justify-content-start justify-content-sm-center">
                      <Col sm={6} md={4} lg={3} xl={3}>
                        <FormGroup>
                          <input {...register("brand_name")} className="form-control-sm form-control" placeholder="Enter Warehouse Name" />
                        </FormGroup>
                      </Col>
                      <Col sm={3} className="btn-container">
                        <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                        <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline onClick={merchantPickupPointListing}>Reset</Button>
                      </Col>
                      <Col sm={6}>
                        <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                          {checkData(props.user) && checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === "Super" &&
                            <>
                              <Link to='/merchants' className="btn-text btn-light mx-1"><span className="icon"><ArrowLeft /></span><span>Back</span></Link>
                            </>
                          }

                          <Link to={`/add-pickuppoint/${checkData(props.user) && checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === "Merchant" ? props.user[0].id : merchantId}`} className="btn-text btn-light mx-1"><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Add Warehouse</span></Link>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(pickupList) && checkData(pickupList.data) && checkUndeNullBlank(pickupList.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(pickupList.data).length)} of {pickupList.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Warehouse ID</th>
                      <th className="text-nowrap">Warehouse Name</th>
                      <th className="text-nowrap">Mobile</th>
                      <th className="text-nowrap">Created</th>
                      {/* <th className="text-nowrap">Updated</th> */}
                      <th className="text-nowrap">Status</th>
                      {/* <th className="text-nowrap">Default</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(pickupList.data) ? pickupList.data.map((item, index) => (
                      <tr>
                        <td>{item.id}</td>
                        <td className="text-nowrap"><Link to={`/edit-pickuppoint/${item.id}`}><span>{item.contact_name}</span></Link></td>
                        <td>{item.pickup_phone}</td>
                        <td className="text-nowrap">{changeIntoDate(item.created_at)}</td>
                        {/* <td className="text-nowrap">{changeIntoDate(item.updated_at)}</td> */}
                        <td><span><Status statusUrl={process.env.REACT_APP_MERCHANT_PICKUPPOINT} listId={item.id} valStatus={item.status} reListing={merchantPickupPointListing} data={{ "pickup_point_id": item.id, "status": item.status === 1 ? 0 : 1 }} /></span></td>
                        {/* <td><span><Button outline color={`${item.is_default === 1 ? "success" : "danger"}`} className="ctm-btn btn-sm btn-active text-nowrap" onClick={(data) => markas(data = { "pickup_point_id": item.id, "merchant_id": item.merchant_id, "is_default": item.is_default === 1 ? 0 : 1 })} > {item.is_default === 1 ? 'Default' : 'Set Default'} </Button></span></td> */}
                      </tr>
                    ))
                      : loadertable ? <tr><td colSpan="6"><LoaderDefault /></td></tr> : <tr><td colSpan="6"><NodataFound /></td></tr>}
                  </tbody>
                </Table>
                {checkData(pickupList) && checkData(pickupList.data) && checkUndeNullBlank(pickupList.TotalRows) ?
                  <>
                    {pickupList.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
       
          </div>
        </Col>

      </Row>
      </Card>
    </div>
  );
}

export default PickupPoints;