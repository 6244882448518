import React from 'react';
import { toast } from 'react-toastify';

import { Row, Col, Card, CardBody,Button, CardTitle, Label, Input,FormGroup,Form, CardHeader} from 'reactstrap';
const Setting = (props) => {
  return (
    <div className="app-content content overflow-hidden">
      <Row>
       <Col sm={12}>
        <Card className="p-0 py-3 mt-3 manageOrder shadow">
          <Card className="mb-3 border-0">
            <CardHeader className="d-flex justify-content-between border-bottom">
              <CardTitle className="mb-0">MANAGE PASSWORD</CardTitle>
              </CardHeader>
                <CardBody>
                <Row className="justify-content-center">
            <Col sm={8}>
            <Card className="pt-3 border-0">
           <CardBody>
                <Form>
                  <FormGroup>
                    <Label for="name">Old Password*</Label>
                    <Input type="password" name="name" id="name" placeholder="Old Password" />
                  </FormGroup>
                    <FormGroup>
                        <Label for="code">New Password*</Label>
                        <Input type="password" name="code" id="code" placeholder="New Password" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="code">Confirm Password*</Label>
                        <Input type="text" name="key" id="key" placeholder="Confirm Password" />
                    </FormGroup>
                    <Col sm={12} className="text-center">
                   <Button className="col-6 ctm-btn m-auto">Submit</Button>
                   </Col>
                    </Form>
                </CardBody>
                </Card>
            </Col>
           </Row>
                </CardBody>
            </Card>
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}

export default Setting;