import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Spinner, FormGroup, Form, CardHeader, Input, Label, Button } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2'
import { StateList } from '../manageMasters/StateList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { checkData } from '../../ObjectExist';
import { errorHandling } from '../../utlis';
import { baseString } from '../../config';


const AddPickupPoint = (props) => {
    const { register: addPickupPoint, handleSubmit: pickupPointSubmit, formState: { errors }, setValue, getValues, reset } = useForm();
    const [returnchecked, setreturnChecked] = useState(true);
    const [pickupchecked, setpickupChecked] = useState(true);
    const [sameAsPickupdtl, setSameAsPickupdtl] = useState(false);
    const [loader, setLoader] = useState(false);
    const { merchantId } = useParams();
    const onAddPickupPoint = (data) => {
        setLoader(true);
        if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
            data.merchant_id = props.user[0].id
        } else {
            data.merchant_id = merchantId
        }
        let formData = new FormData();
        formData.append("merchant_id", data.merchant_id);
        formData.append("contact_name", data.contact_name);
        formData.append("pickup_address_1", data.pickup_address_1);
        formData.append("pickup_address_2", data.pickup_address_2);
        formData.append("pickup_city_name", data.pickup_city_name);
        formData.append("pickup_state_id", data.pickup_state_id);
        formData.append("pickup_pincode", data.pickup_pincode);
        formData.append("pickup_phone", data.pickup_phone);
        formData.append("pickup_details_for_label", data.pickup_details_for_label !== undefined ? data.pickup_details_for_label : "");
        formData.append("return_address_1", data.pickup_address_1);
        formData.append("return_address_2", data.pickup_address_2);
        formData.append("return_city_name", data.pickup_city_name);
        formData.append("return_state_id", data.pickup_state_id);
        formData.append("return_pincode", data.pickup_pincode);
        formData.append("return_details_for_label", data.return_details_for_label !== undefined ? data.return_details_for_label : '');
        formData.append("return_phone", data.pickup_phone);
        formData.append("address_label_bottom_line", data.address_label_bottom_line);
        formData.append("status", "1");

        axios({
            method: "post",
            url: process.env.REACT_APP_MERCHANT_PICKUPPOINT_CREATE,
            data: formData,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false)
            if (res.data.error === false) {
                reset();
                Swal.fire({
                    title: 'Success',
                    text: res.data.message,
                    icon: 'success',
                }).then(okay => {
                    if (okay) {
                        if (checkData(props.user[0]) && props.user[0].user_type === "Super") {
                            history.push(`/merchants-pickuppoint/${merchantId}`)
                        } else {
                            history.push('my-pickuppoint')
                        }
                    }
                });
            }
        })
            .catch(function (response) {
                setLoader(false);
                errorHandling(response, "");
            });
    };

    // ------same as pickup details
    const sameAsPickupDetails = (event) => {
        setSameAsPickupdtl(!sameAsPickupdtl);
        if (!sameAsPickupdtl) {
            const { pickup_address_1, pickup_address_2, pickup_pincode, pickup_city_name, pickup_state_id, pickup_phone, pickup_details_for_label } = getValues();
            [{ name: 'return_address_1', value: pickup_address_1 }, { name: 'return_address_2', value: pickup_address_2 },
            { name: 'return_pincode', value: pickup_pincode }, { name: 'return_city_name', value: pickup_city_name },
            { name: 'return_state_id', value: pickup_state_id }, { name: 'return_phone', value: pickup_phone },
            { name: 'return_details_for_label', value: pickup_details_for_label }
            ].forEach(({ name, value }) => setValue(name, value))
        } else {
            [{ name: 'return_address_1', value: "" }, { name: 'return_address_2', value: "" }, { name: 'return_pincode', value: "" },
            { name: 'return_city_name', value: "" }, { name: 'return_state_id', value: "" }, { name: 'return_phone', value: "" },
            { name: 'return_details_for_label', value: "" }
            ].forEach(({ name, value }) => setValue(name, value))
        }
    }

    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }

    return (
        <div className="app-content content overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}
            <Row>
                <Col sm={12}>
                    <div className="p-0 my-3 addPickupPoints content-wrapper border-0">
                        <Form onSubmit={pickupPointSubmit(onAddPickupPoint)}>
                            <Card className="shadow border-0">
                                <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                                    <CardTitle className="page-title">Add New Warehouse Address</CardTitle>
                                    <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                                        <div onClick={goToPreviousPath} className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back to Warehouse List</span></div>
                                    </div>
                                </CardHeader>
                                <CardBody className="forms">
                                    <CardHeader className="px-0"><CardTitle>Sender Name</CardTitle></CardHeader>
                                    <FormGroup>
                                        <Label for="contactName">Enter Full Name<span className="text-danger">*</span></Label>
                                        <input {...addPickupPoint("contact_name", {
                                            required: 'Required Full Name',
                                            pattern: { value: /^[a-z ,.'-]+$/i, message: 'Required Valid Full Name' }
                                        })} className="form-control-sm form-control" placeholder="Full Name" id="contactName" />
                                        {errors.contact_name && <span className="text-danger d-block error">{errors.contact_name.message}</span>}
                                    </FormGroup>
                                </CardBody>
                                <Row >
                                    <Col md="12">
                                    <CardHeader className="d-flex justify-content-between"><CardTitle>Pickup Details</CardTitle><div></div></CardHeader>
                                    <CardBody className="pt-0">
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="address1_pickup">Enter Address Line 1<span className="text-danger">*</span></Label>
                                                    <input {...addPickupPoint("pickup_address_1", { required: 'Required Address Line 1' })} className="form-control-sm form-control" placeholder="Address Line 1" id="address1_pickup" />
                                                    {errors.pickup_address_1 && <span className="text-danger d-block error">{errors.pickup_address_1.message}</span>}
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="address2_pickup">Enter Address Line 2</Label>
                                                    <input {...addPickupPoint("pickup_address_2")} className="form-control-sm form-control" placeholder="Address Line 2" id="address2_pickup" />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="pincode_pickup">Enter Pincode<span className="text-danger">*</span></Label>
                                                    <input {...addPickupPoint("pickup_pincode", {
                                                        required: 'Required Pincode',
                                                        minLength: { value: 6, message: "at least 6 digits" },
                                                        maxLength: { value: 6, message: "maximum 6 digits" },
                                                        pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Required Valid Pincode' }
                                                    })} type="text" className="form-control-sm form-control" placeholder="Pincode" id="pincode_pickup" />
                                                    {errors.pickup_pincode && <span className="text-danger d-block error">{errors.pickup_pincode.message}</span>}
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="city_pickup">Enter City Name<span className="text-danger">*</span></Label>
                                                    <input {...addPickupPoint("pickup_city_name", {
                                                        required: 'Required City Name',
                                                        pattern: { value: /^[a-z ,.'-]+$/i, message: 'Required Valid City' }
                                                    })} className="form-control-sm form-control" placeholder="City Name" id="city_pickup" />
                                                    {errors.pickup_city_name && <span className="text-danger d-block error">{errors.pickup_city_name.message}</span>}
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="state_pickup">Select State<span className="text-danger">*</span></Label>
                                                    <div className="position-relative">
                                                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                                        <select {...addPickupPoint("pickup_state_id", { required: 'Please Select State' })} className="form-control-sm form-control" id="state_pickup">
                                                            <option value="">Select State</option>
                                                            {StateList.map((item, index) => (
                                                                <option value={item.state_id}>{item.state_name}</option>
                                                            ))
                                                            }
                                                        </select>
                                                        {errors.pickup_state_id && <span className="text-danger d-block error">{errors.pickup_state_id.message}</span>}
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="contact_pickup">Enter Mobile Number<span className="text-danger">*</span></Label>
                                                    <input {...addPickupPoint("pickup_phone", {
                                                        required: 'Required Mobile Number',
                                                        minLength: { value: 10, message: "Required 10 Digit Mobile Number" },
                                                        maxLength: { value: 10, message: "Required 10 Digit Mobile Number" },
                                                        pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Required Valid Mobile Number' }
                                                    })} type="text" className="form-control-sm form-control" placeholder="Mobile" id="contact_pickup" />
                                                    {errors.pickup_phone && <span className="text-danger d-block error">{errors.pickup_phone.message}</span>}
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup check className="mb-3 d-none">
                                                    <Input {...addPickupPoint("samelabelPickup")} type="checkbox" name="check" id="exampleCheck" checked={pickupchecked} onChange={e => setpickupChecked(e.target.checked)} />
                                                    <Label for="exampleCheck" check>Same as in address label <small>(Paste on label)</small></Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className='d-none'>
                                                    <Label for="pickupDetails_pickup">Pickup Details</Label>
                                                    <textarea {...addPickupPoint("pickup_details_for_label")} disabled={pickupchecked} type="textarea" placeholder="Pickup Details For address label" id="pickupDetails_pickup" className="form-control-sm form-control" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    {/* <Col className='d-none' md={6}>
                                        <Card className="shadow border-0">
                                            <CardHeader className="d-flex justify-content-between"><CardTitle>return Details</CardTitle><div>
                                                <FormGroup check>
                                                    <Input {...addPickupPoint("sameasPickup")} onChange={sameAsPickupDetails} type="checkbox" name="check" id="sameasPickup" checked={sameAsPickupdtl} />
                                                    <Label for="sameasPickup" check>Same as Pickup Details</Label>
                                                </FormGroup></div>
                                            </CardHeader>
                                            <CardBody className="pt-0">
                                                <FormGroup>
                                                    <Label for="address1_return">Enter Address Line 1<span className="text-danger">*</span></Label>
                                                    <input {...addPickupPoint("return_address_1", { required: 'Required Address Line 1' })} className="form-control-sm form-control" placeholder="Address Line 1" id="address1_return" />
                                                    {errors.return_address_1 && <span className="text-danger d-block error">{errors.return_address_1.message}</span>}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="address2_return">Enter Address Line 2</Label>
                                                    <input {...addPickupPoint("return_address_2")} className="form-control-sm form-control" placeholder="Address Line 2" id="address2_return" />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="pincode_return">Enter Pincode<span className="text-danger">*</span></Label>
                                                    <input {...addPickupPoint("return_pincode", {
                                                        required: 'Required Pincode',
                                                        minLength: { value: 6, message: "at least 6 digits" },
                                                        maxLength: { value: 6, message: "maximum 6 digits" },
                                                        pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Required Valid Pincode' }
                                                    })} type="text" className="form-control-sm form-control" placeholder="Pincode" id="pincode_return" />
                                                    {errors.return_pincode && <span className="text-danger d-block error">{errors.return_pincode.message}</span>}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="city_return">Enter City Name<span className="text-danger">*</span></Label>
                                                    <input {...addPickupPoint("return_city_name", {
                                                        required: 'Required City Name',
                                                        pattern: { value: /^[a-z ,.'-]+$/i, message: 'Required Valid City' }
                                                    })} className="form-control-sm form-control" placeholder="City Name" id="city_return" />
                                                    {errors.return_city_name && <span className="text-danger d-block error">{errors.return_city_name.message}</span>}
                                                </FormGroup>
                                                <FormGroup className="position-relative">
                                                    <Label for="state_return">Select State<span className="text-danger">*</span></Label>
                                                    <div className="position-relative">
                                                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                                        <select {...addPickupPoint("return_state_id", { required: 'Required City Name' })} className="form-control-sm form-control" id="state_return">
                                                            <option value="">Select State</option>
                                                            {StateList.map((item, index) => (
                                                                <option value={item.state_id}>{item.state_name}</option>
                                                            ))
                                                            }
                                                        </select>
                                                        {errors.return_state_id && <span className="text-danger d-block error">{errors.return_state_id.message}</span>}
                                                    </div>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="contact_return">Enter Mobile Number<span className="text-danger">*</span></Label>
                                                    <input {...addPickupPoint("return_phone", {
                                                        required: 'Required Mobile Number',
                                                        minLength: { value: 10, message: "Required 10 Digit Mobile Number" },
                                                        maxLength: { value: 10, message: "Required 10 Digit Mobile Number" },
                                                        pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Required Valid Mobile Number' }
                                                    })} type="text" className="form-control-sm form-control" placeholder="Mobile" id="contact_return" />
                                                    {errors.return_phone && <span className="text-danger d-block error">{errors.return_phone.message}</span>}
                                                </FormGroup>
                                                <FormGroup check className="mb-3">
                                                    <Input {...addPickupPoint("sameAddressReturn")} type="checkbox" name="check" id="exampleCheck2" checked={returnchecked} onChange={e => setreturnChecked(e.target.checked)} />
                                                    <Label for="exampleCheck2" check>Same as in address label <small>(Paste on label)</small></Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="pickupDetails_return">Pickup Details</Label>
                                                    <textarea {...addPickupPoint("return_details_for_label")} disabled={returnchecked} type="textarea" placeholder="Pickup Details For address label" className="form-control-sm form-control" />
                                                </FormGroup>
                                            </CardBody>
                                        </Card>
                                    </Col> */}
                                    </Col>
                                </Row>
                            </Card>

                            {/* <Card className="shadow border-0 d-none">
                                <CardHeader><CardTitle className="mb-0">Address Labels Bottom Line</CardTitle><small>(e.g note)</small></CardHeader>
                                <CardBody className="pt-0">
                                    <FormGroup>
                                        <input {...addPickupPoint("address_label_bottom_line")} type="text" className="form-control-sm form-control" placeholder="Address Lables Bottom Line" />
                                    </FormGroup>
                                </CardBody>
                            </Card> */}
                            <Row>
                                <Col sm={12} className="text-right ml-auto btn-container">
                                    <Card><Button className="ctm-btn btn-sm" color="primary">Save</Button></Card>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>

            </Row>
        </div>
    );
}

export default AddPickupPoint;