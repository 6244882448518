import Cards from './Cards';
import React, { useEffect, useState } from 'react';
import CityWisePerformance from './CityWisePerformance';
import StateWisePerformance from './StateWisePerformance';
import {Row, Col} from 'reactstrap';

const Overview = (props) => {

    const [stateTrigger, setStateTrigger] = useState('');


    const callBackChangeCityData = (stateName) => {
       console.log("calling calback",stateName);
       setStateTrigger(stateName); 
      }

      

    return (
        <div className="overview">
              <div className="mb-4"><StateWisePerformance callBackChangeCityData={callBackChangeCityData} user={props.user} oneCalendar={props.oneCalendar} /></div>

             <div className="mb-4"><CityWisePerformance stateTrigger={stateTrigger}  user={props.user} oneCalendar={props.oneCalendar} /></div>
            
            
        </div>
    );
}

export default Overview;