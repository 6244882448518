import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Form, CardHeader, Table, Modal, ModalBody, ModalHeader, Label, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUpload, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { changeIntoDate, remExtraSpace, errorHandling } from '../../utlis';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { baseString, download_delay, yes_no } from '../../config';
import { useHistory } from 'react-router';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';


const Serviceability = (props) => {
  const [excelmodal, setExcelmodal] = useState(false);
  const [serviceabilityList, setServiceabilityList] = useState({});
  // const [serviceabilityFilter, setServiceabilityFilter] = useState(0);
  const [excelFile, setExcelFile] = useState();
  const [courierId, setCourierId] = useState();
  const [loader, setLoader] = useState(false);
  const [loaderTable, setLoaderTable] = useState(false);
  const [buttonDisabled, setButtonDisables] = useState(false);

  const history = useHistory();

  const notify = (courier_id, type) => {
    setButtonDisables(true)
    let data = {}
    if (type === 'unique') {
      data = { "is_unique": 1 }
    } else {
      data = { "courier_id": courier_id }
    }
    setLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVICEABLITY_DOWNLOAD}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setButtonDisables(false)
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          setLoader(false);
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          if (res.data.data !== '') {
            setTimeout(function () {
              setLoader(false);
              history.push("/my-downloads");
            }, download_delay);
          } else {
            setLoader(false);
            Swal.fire({ icon: 'warning', text: res.data.msg, title: 'Serviceability' })
          }
        }
      }
    }).catch((err) => {
      setLoader(false);
      errorHandling(err, "");
    });
    toast.info("Process to download!", {
      pauseOnHover: false,
      autoClose: 5000,
      draggable: false,
    })
  };

  const toggleExcelmodal = (courier_id) => {
    setCourierId(courier_id)
    setExcelmodal(!excelmodal);
  }
  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0])
  }
  const serviceAbilityFetch = () => {
    if (checkData(props.user) && props.user[0].user_type === "Super") {

      // setServiceabilityFilter(0);
      setLoaderTable(true);
      reset();

      axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVICEABLITY_LIST}`,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        setLoaderTable(false)
        if (res.data !== undefined && res.data.fatal !== true) {
          if (parseInt(res.data.errno) > 0) {
            if (res.data.sqlMessage !== undefined) {
                          toast.error(res.data.sqlMessage,{position:'top-right'})
            }
          } else {
            setServiceabilityList(res.data);
          }
        }
      }).catch((err) => {
        errorHandling(err, "");
        setLoaderTable(false)
      });
    }
  }
  useEffect(() => {
    if (checkData(props.user) && props.user[0].user_type === "Super") {
      serviceAbilityFetch();
    }
    // setServiceabilityFilter(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { register: uploadServiceablityPin, handleSubmit: ServiceablityPinSubmit, formState: { errors: errors3 } } = useForm();
  const onUploadServiceablityPin = (data) => {
    setLoader(true)
    let formData = new FormData();
    formData.append("file", excelFile);
    formData.append("courier_id", courierId);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVICEABLITY_ADD}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false)
      if (res.data.error === false) {
        setExcelmodal(false);
        Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', }).then(okay => {
          if (okay) {
            serviceAbilityFetch();
          }
        });
      }
    })
      .catch((err) => {
        setLoader(false)
        errorHandling(err, "");
      });
  };

  // filter Serviceability
  const { register: filter, handleSubmit: filterServiceabilitySubmit, formState: { errors }, reset } = useForm();
  const onFilterServiceability = data => {
    let byData = {}
    setLoader(true);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }
    byData = { "source_pincode": checkUndeNullBlank(data.source_pincode) ? remExtraSpace(data.source_pincode) : '', "destination_pincode": checkUndeNullBlank(data.destination_pincode) ? remExtraSpace(data.destination_pincode) : '' }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVICEABLITY_SEARCH_LIST}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setServiceabilityList(res.data);
            // setServiceabilityFilter(res.data.filter)
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  };


  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 serviceability shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                <div className="d-none card-header-right d-flex flex-wrap align-items-center ml-auto">
                  <Button download className="btn-text btn-light" onClick={() => notify('1', 'unique')}><span className="icon mr-2"><FontAwesomeIcon icon={faDownload} /></span><span>All Unique Pincodes</span></Button>
                </div>
              </CardHeader>
              <Row className="px-3 mb-4">
                <Col sm={12}>
                  <Form onSubmit={filterServiceabilitySubmit(onFilterServiceability)} className="cardFilter">
                    <CardTitle className="page-title">Manage Serviceability</CardTitle>
                    <Row className="mx-0 justify-content-start justify-content-md-center mb-3 mb-md-0">
                      <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                          <input {...filter("source_pincode", {
                            required: 'Please Enter Pincode.',
                            minLength: { value: 6, message: "at least 6 digits" },
                            maxLength: { value: 6, message: "maximum 6 digits" },
                            pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Pincode' }
                          })} type="text" className="form-control-sm form-control" placeholder="Enter Pickup Pincode" />
                          {errors.source_pincode && <span className="text-danger d-block error">{errors.source_pincode.message}</span>}
                        </FormGroup>

                      </Col>
                      <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                          <input {...filter("destination_pincode", {
                            required: 'Please Enter Pincode.',
                            minLength: { value: 6, message: "at least 6 digits" },
                            maxLength: { value: 6, message: "maximum 6 digits" },
                            pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Pincode' }
                          })} type="text" className="form-control-sm form-control" placeholder="Enter Destination Pincode" />
                          {errors.destination_pincode && <span className="text-danger d-block error">{errors.destination_pincode.message}</span>}
                        </FormGroup>
                      </Col>
                      {/* {checkData(serviceabilityList.data) && */}
                      <Col sm={2} className="btn-container">
                        <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                        <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={serviceAbilityFetch}>Reset</Button>
                      </Col>
                      {/* } */}
                    </Row>
                  </Form>
                </Col>
              </Row>

              <Modal isOpen={excelmodal} toggle={toggleExcelmodal} scrollable={true} >
                <ModalHeader toggle={toggleExcelmodal}>Upload Serviceability</ModalHeader>
                <ModalBody>
                  <Form onSubmit={ServiceablityPinSubmit(onUploadServiceablityPin)}>
                    <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Pincode via XLSX</p>
                    <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                    <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                    <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                    <Row className="m-0 pb-1">
                      <Col sm={12} className="p-0">
                        <p className="mb-2">
                          <span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/serviceability.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download </Link>
                          <span className="ml-2">Serviceability Format File</span>
                        </p>
                      </Col>
                      <Col sm={12} className="m-auto pt-1 p-0">
                        <FormGroup>
                          <input {...uploadServiceablityPin("excelUpload", {
                            required: 'Please Upload XLSX File',
                            validate: {
                              lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                              acceptedFormats: files =>
                                ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                  files[0]?.type
                                ) || 'Only .xlsx file allowed',
                            },
                          })}
                            type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                          <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                          {errors3.excelUpload && <span className="text-danger d-block error">{errors3.excelUpload.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col sm={12} className="text-right">
                        <Button className="btn ctm-btn btn-sm" color="primary">Upload</Button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
              <CardBody className="p-0">
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Carrier Code</th>
                      <th className="text-nowrap w-some">Carrier</th>
                      <th className="text-nowrap">COD</th>
                      <th className="text-nowrap">Prepaid</th>
                      <th className="text-nowrap">Pickup</th>
                      {checkUndeNullBlank(serviceabilityList.filter) && serviceabilityList.filter !== 1 &&
                        <>
                          <th className="text-nowrap">Last Updated On</th>
                          <th className="text-nowrap">Last Updated By</th>
                          <th className="text-nowrap">Download</th>
                          <th className="text-nowrap">Upload</th>
                        </>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(serviceabilityList.data) ? serviceabilityList.data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.courier_id}</td>
                        <td>{item.courierName}</td>
                        <td>{item.totalCOD || yes_no[item.cod]}	</td>
                        <td>{item.totalPrepaid || yes_no[item.prepaid]}</td>
                        <td>{item.totalPickup || yes_no[item.pickup]}</td>
                        {checkUndeNullBlank(serviceabilityList.filter) && serviceabilityList.filter !== 1 &&
                          <>
                            <td>{changeIntoDate(item.updated_at)}</td>
                            <td>-----</td>
                            <td>{item.totalCOD || item.totalPrepaid || item.totalPickup ? <Button outline color="primary" className="text-nowrap" download disabled={buttonDisabled} onClick={() => notify(item.courier_id, 'courier')}><span className="icon mr-2"><FontAwesomeIcon icon={faDownload} /></span><span>Export Pincode</span></Button> : ''}</td>
                            <td><div onClick={() => toggleExcelmodal(item.courier_id)} className="cursor-pointer text-nowrap"><span className="icon mr-2"><FontAwesomeIcon icon={faUpload} /></span><span>Upload Pincode</span></div></td>
                          </>
                        }
                      </tr>
                    )) : loaderTable ? <tr><td colSpan="9" className="text-center"><LoaderDefault /></td></tr> : <tr><td colSpan="9" className="text-center"><NodataFound /></td></tr>}
                  </tbody>
                </Table>
                <ToastContainer />
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

export default Serviceability;