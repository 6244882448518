import React, {useState} from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Spinner} from 'reactstrap';
import { connect } from 'react-redux';
import TrackingCode from '../Merchants/Setting/TrackingCode';
import { toast } from 'react-toastify';

const TrackingCodePage = (props) => {
  const [loader, setLoader] = useState(false);

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 ndr shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex justify-content-between align-items-start flex-wrap">
              <CardTitle className="page-title">Tracking Code</CardTitle>
            </CardHeader>            
              <CardBody className="pt-0">
                <TrackingCode user={props.user} setLoader={setLoader} header={"no"} />
              </CardBody>
            </Card>
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}

function mapDispatchToProps(state){
  return {
    state:state.DataReducer
  }
}

export default connect(mapDispatchToProps)(TrackingCodePage);