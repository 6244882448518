import React, {useEffect, useState} from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import LoaderDefault from '../../../LoaderDefault';
import {connect, useDispatch} from 'react-redux'
import { dashRemittanceOverView } from '../../../../actions';
import {errorHandling } from '../../../../utlis';

const RemittanceOverview = (props) => {
    const [remittanceView, setRemittanceView] = useState({});
    const [loadertable, setLoadertable] = useState(false);
    const dispatch = useDispatch();
    
    const listingSummary = () => {
    setLoadertable(true);
    let tatperiod = checkUndeNullBlank(props.user[0].remittance_tat_period) ? props.user[0].remittance_tat_period : '';
    let mypost = {"merchant_id":props.user[0].id,"remittance_tat_period":tatperiod,}
    let myget = {}
    dispatch(dashRemittanceOverView(mypost,myget)).then((response) => {
        if(response.error === false){
            setRemittanceView(response.data);
        }
        setLoadertable(false)
      }).catch(function (response) {
        setLoadertable(false);
        errorHandling(response,"reload");
      });
    }
    useEffect(() => {
        if(checkData(props.state) && checkData(props.state.viewAllRemittanceOver)&& checkData(props.state.viewAllRemittanceOver.data)){
            setRemittanceView(props.state.viewAllRemittanceOver.data);
          }else{
            listingSummary();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="d-none remittanceOverview d-lg-flex w-100">
            <Card className="shadow border-0 w-100">
                <div className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <div className="customTitle"><span>Remittance Overview</span></div>
                </div>
                <CardBody className="p-0">
                    <Row className="mx-0">
                        {!loadertable ? 
                        <>
                        <Col md={8}>
                            <Row className="remittance-overview-list mb-md-2">
                                <Col sm={6} md={6} className="border-sort-right">
                                    <div className='media'>
                                        <div className='media-body my-auto py-2'>
                                            <h4 className="card-val text-primary"><span>{checkData(remittanceView) ? checkUndeNullBlank(remittanceView.total_remittance) ? Math.round(remittanceView.total_remittance) : 0 : 0}</span></h4>
                                            <p className="text-muted font-weight-500 title-card">Total COD</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} md={6} className="">
                                    <div className='media'>
                                        <div className='media-body my-auto py-2'>
                                            <h4 className="card-val text-primary"><span>{checkData(remittanceView) ? checkUndeNullBlank(remittanceView.total_remittance_paid) ? Math.round(remittanceView.total_remittance_paid) : 0 : 0}</span></h4>
                                            <p className="text-muted font-weight-500 title-card">COD Paid</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} md={6} className="border-sort-top border-sort-right-2">
                                    <div className='media'>
                                        <div className='media-body my-auto py-2'>
                                            <h4 className="card-val text-primary"><span>{checkData(remittanceView) ? checkUndeNullBlank(remittanceView.total_remittance_adjusted) ? Math.round(remittanceView.total_remittance_adjusted) : 0 : 0}</span></h4>
                                            <p className="text-muted font-weight-500 title-card">COD Adjusted</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} md={6} className="border-sort-top">
                                    <div className='media'>
                                        <div className='media-body my-auto py-2'>
                                            <h4 className="card-val text-primary"><span>{checkData(remittanceView) ? checkUndeNullBlank(remittanceView.total_remittance_due) ? Math.round(remittanceView.total_remittance_due) : 0 : 0}</span></h4>
                                            <p className="text-muted font-weight-500 title-card">Unpaid COD</p>
                                        </div>
                                    </div>
                                </Col>                        
                            </Row>
                        </Col>
                        <Col md={4} className="d-flex align-items-center px-0 mb-2">
                            <Row className="w-100 mx-0">
                            <Col sm={12} className="">
                                <div className='media'>
                                    <div className='media-body my-auto py-2'>
                                        <h4 className="card-val card-val2 text-success"><span>{checkData(remittanceView) ? checkUndeNullBlank(remittanceView.total_remittance_due_as_per_tat) ? Math.round(remittanceView.total_remittance_due_as_per_tat) : 0 : 0}</span></h4>
                                        <p className="text-muted font-weight-500 title-card title-card2 position-relative">Expected Remittance Due <small className="text-nowrap aspertat">(as per TAT)</small></p>
                                    </div>
                                </div>
                            </Col>
                            </Row>
                        </Col>
                        </>
                        : <div className="py-2"><LoaderDefault /></div>}
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}

function mapDispatchToProps (state) {
    return{
      state:state.DataReducer
    }
  }
  export default connect(mapDispatchToProps)(RemittanceOverview);