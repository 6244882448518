import './App.css';
import React, { Suspense,useState, useEffect, useCallback } from "react";
import DashboardMerchant from './components/Merchants/Dashboard/Dashboard';

import Login from './components/Login';
import Register from './components/Register';
import ForgetPassword from './components/ForgetPassword';
import AdminHeader from './components/AdminHeader';
import AdminSideBar from './components/AdminSideBar';
import { Switch, Route, useHistory } from "react-router-dom";
import Orders from './components/Orders/Orders';
import CreateOrder from './components/Orders/CreateOrder';
import OrderDetail from './components/Orders/OrderDetail';
import PriceCalculator from './components/UtilityTool/PriceCalculator';
import Stock from './components/UtilityTool/Stock';
import Shipments from './components/Orders/Shipment';
import ReportShipment from './components/Orders/Reportshipment';
import LiveNdr from './components/ndr/LiveNdr';
import AllNdr from './components/ndr/AllNdr';
import RemittanceTransactions from './components/Remittance/RemittanceTransactions';
import CourierRemittance from './components/Remittance/CourierRemittance';
import InitiatedRemittance from './components/Remittance/InitiatedRemittance';
import MerchantRemittance from './components/Remittance/MerchantRemittance';
import RemittancePaid from './components/Remittance/RemittancePaid';
import RateCard from './components/Merchants/RateCard';
import RemittanceAdjusted from './components/Remittance/RemittanceAdjusted';
import MerchantInvoices from './components/Invoices/MerchantInvoices';
import InvoiceDatail from './components/Invoices/InvoiceDetail';
import Rto from './components/Orders/Rto';
import UpdateLost from './components/Orders/UpdateLost';
import MerchantCreditNote from './components/Merchants/CreditNote';
import MerchantCreditNoteDetail from './components/Merchants/MerchantCreditNoteDetail';
import MerchantDebitNoteDetail from './components/Merchants/MerchantDebitNoteDetail';
import MerchantDebitNote from './components/Merchants/DebitNote';
import WeightMismatch from './components/WeightMismatch/WeightMismatch';
import MultiplePickPoint from './components/Profile/MultiplePickPoint';
import ManageBrands from './components/Profile/ManageBrands';
import Setting from './components/Setting';
import City from './components/manageMasters/City';
import State from './components/manageMasters/State';
import MerchantFreight from './components/Merchants/MerchantFreight';
import Courier from './components/Couriers/Courier';
import CourierLedger from './components/Ledger/CourierLedger';
import MerchantLedger from './components/Ledger/MerchantLedger';
import Merchants from './components/Merchants/Merchants';
import AccountSetting from './components/Admin/AccountSetting/AccountSetting';
import MerchantSetting from './components/Merchants/Setting/Setting';
import CourierPricing from './components/Couriers/CourierPricing';
import CourierDetails from './components/Couriers/CourierDetails';
import Zoning from './components/Couriers/Zoning';
import CourierCreditNote from './components/Couriers/CreditNote';
import CourierDebitNote from './components/Couriers/DebitNote'
import MerchantDetails from './components/Merchants/MerchantDetails';
import PickupPoints from './components/Merchants/PickupPoints';
import MerchantBrands from './components/Merchants/MerchantBrands';
import Serviceability from './components/Serviceability/Serviceability';
import AddPickupPoint from './components/Merchants/AddPickupPoint';
import MerchantPricing from './components/Merchants/MerchantPricing';
import MerchantPassbook from './components/Merchants/passbook/Passbook';
import DragTab from './components/Merchants/DragTab';
import EditPickupPoint from './components/Merchants/EditPickupPoint';
import AdminDownloads from './components/Admin/AdminDownloads';
import MerchantDownloads from './components/Merchants/MerchantDownloads';
import UtilityTool from './components/UtilityTool/UtilityTool';
import ChannelIntegration from './components/ChannelIntegration/ChannelIntegration';
import ChannelOrder from './components/ChannelIntegration/ChannelOrder';
import SmsLog from './components/ChannelIntegration/SmsLog';
import ChannelOrderDetail from './components/ChannelIntegration/ChannelOrderDetail';
import TrackingCodePage from './components/TrackingCode/TrackingCodePage';
import EmailTemplate from './components/Cms/EmailTemplate';
import PaymentMsg from './components/PaymentMsg/PaymentMsg';
import ThankYouMsg from './components/ThankYouMsg';
import { useLocation } from 'react-router';
import AdminLogin from './components/Admin/AdminLogin';
import MerchantSideBar from './components/MerchantSideBar';
// import Notfound from './components/Notfound';
import { checkData, checkUndeNullBlank } from './ObjectExist';
import { errorHandling } from './utlis';
import { encryptStorageLogin } from './EncStorage';
import { connect, useDispatch } from 'react-redux';
import { AllShipmentMerchantCourier, TotalWalletBalance } from './actions';
import Tour from 'reactour'
import SignupMerchants from './components/Merchants/SignupMerchants';
import 'rsuite/dist/rsuite.css';
import { toast, ToastContainer } from 'react-toastify';
import io from "socket.io-client";
import WeightReconcilation from './components/WeightMismatch/WeightReconcilation';
import TawkTo from 'tawkto-react'

//import DashboardAdmin from './components/Admin/Dashboard/Dashboard';
const DashboardAdmin = React.lazy(() => import("./components/Admin/Dashboard/Dashboard"));

//import Pincode from './components/manageMasters/Pincode';
const Pincode = React.lazy(() => import('./components/manageMasters/Pincode'));

//const socket = io.connect(process.env.REACT_APP_BASE_URL, { path: "/server/socket.io", secure: true, query: "id=1&mobile=" });

function App(props) {
  const toastId = React.useRef(null);
  const downloadNotification = (msg, redirectUrl) => {
    if (redirectUrl !== undefined && redirectUrl !== '') {
      toastId.current = toast.info(msg, {
        onClick: e => {
          history.push(redirectUrl);
        }, preventDuplicates: true, theme: 'dark', 'type': 'spinner', autoClose: false
      });

    } else {
      toastId.current = toast.info(msg, { preventDuplicates: true, theme: 'dark', 'type': 'spinner', autoClose: false });

    }
  }

  // const data = useEventSource(`${process.env.REACT_APP_BASE_URL}/stream-random-numbers`);
  const userdec = encryptStorageLogin.getItemFromPattern('userenc');
  const user = userdec !== undefined && userdec.userenc !== undefined && checkData(userdec) && checkUndeNullBlank(userdec.userenc.data) ? userdec.userenc.data : userdec !== undefined && userdec.userenc !== undefined && checkData(userdec) && userdec.userenc;
  const [sideEpand, setSideExpand] = useState(true);
  const [userBalance, setUserBalance] = useState('');
  const [userBalanceOther, setUserBalanceOther] = useState('');
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch();
  const history = useHistory();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const steps = [{ selector: ".menu-light", content: "This is my First step", }, { selector: ".table", content: "This is my second step", },];
  const allShipmentCourier = () => {
    let mypost = {}
    let myget = {}
    if (checkData(user)) {
      if (user[0].user_type === "Super") {
        mypost = { "admin_id": user[0].id }
      } else {
        mypost = { "merchant_id": user[0].id, }
      }
    }
    dispatch(AllShipmentMerchantCourier(mypost, myget)).then((response) => {
    }).catch(function (response) {
      errorHandling(response, "")
    });
  }
  const walletBalanceUpdate = () => {
    setLoader(true);
    let mypost = {}
    let myget = {}
    if (checkData(user)) {
      if (user[0].user_type === "Super") {
        mypost = { "admin_id": user[0].id }
      } else {
        mypost = { "merchant_id": user[0].id, "wallet_balance": user[0].wallet_balance, "remittance_tat_period": user[0].remittance_tat_period }
      }
    }
    dispatch(TotalWalletBalance(mypost, myget)).then((response) => {
      setLoader(false);
      if (response.error === false) {
        setUserBalance(response.balance);
        setUserBalanceOther(response.other_details);
        if (response.balance < 0) {
          history.push("/");
        }
      }
    }).catch(function (response) {
      setLoader(false);
      errorHandling(response, "")
    });
  }
  const location = useLocation();
  const [oneCalendar, setOneCalendar] = useState(false);

  const handleKeyPress = useCallback((event) => {
   // console.log(`Key pressed: ${event.key}`);
  }, []);

  useEffect(() => {
    // socket.on("connect", () => {

    //   const userdec = encryptStorageLogin.getItemFromPattern('userenc');
    //   const user = userdec !== undefined && userdec.userenc !== undefined && checkData(userdec) && checkUndeNullBlank(userdec.userenc.data) ? userdec.userenc.data : userdec !== undefined && userdec.userenc !== undefined && checkData(userdec) && userdec.userenc;

    //   let obj = { 'socket': socket.id, 'mid': user[0].id, 'name': user[0].brand_name };
    //   socket.emit("join_room", obj);


    // });
    // socket.on("disconnect", () => { console.log('d', socket.id); });
    //On receive from server
    // socket.on("downloadEvent", (param) => {
    //   let error = param.error !== undefined ? param.error : true;
    //   let msg = param.msg !== undefined ? param.msg : '';
    //   if (msg !== '') {
    //     if (error === true || error === 'true') {
    //       toast.update(toastId.current, { render: msg, theme: 'dark', type: 'danger', autoClose: 5000 })
    //     } else if (error === false || error === 'false') {
    //       toast.update(toastId.current, { render: msg, theme: 'dark', type: 'success', autoClose: 5000 })
    //     }
    //   }
    // });
    if (user === false && location.pathname === "/rapid/admin/login") {
      history.push("/rapid/admin/login");
    } else if (user === false && location.pathname === "/register") {
      history.push("/register");
    } else if (user === false && location.pathname === "/ship2Bh") {
      history.push("/ship2Bh");
    } else if (user === false && location.pathname === "/signin") {
      history.push("/signin");
    } else if (user === false) {
      history.push("/");
    }

    if (location.pathname === "/" || location.pathname === "/shipments" || location.pathname === "/credit-note" || location.pathname === "/upload-orders" || location.pathname === "/lost" || location.pathname === "/courier-ledger" || location.pathname === "/merchant-ledger"
      || location.pathname === "/orders" || location.pathname === "/rto" || location.pathname === "/sms-log" || location.pathname === "/ndr" || location.pathname === "/old-ndr" || location.pathname === "/weight-mismatch" || location.pathname === "/weight-recon"
      || location.pathname === "/my-remittance" || location.pathname === "/merchant-freight" || location.pathname === "/freight" || location.pathname === "/merchant-invoices" || location.pathname === "/merchant-remittance" || location.pathname === "/remittance-transactions" || location.pathname === "/adjusted-remittance"
      || location.pathname === "/debit-note") {
      if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier)) {
      } else {
        if (checkData(user)) {
          allShipmentCourier();
        }
      }
    }

    if (window.innerWidth < 992) {
      setOneCalendar(true);
    } else {
      setOneCalendar(false);
    }
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, handleKeyPress, user]);

  const sideBarToggle = (e) => {
    setSideExpand(!sideEpand)
  }

  const closeSideBar = () => {
    if (window.innerWidth < 992) {
      setSideExpand(true);
    }
  }

  window.addEventListener('resize', function (event) {
    if (window.innerWidth < 992) {
      setOneCalendar(true);
    } else {
      setOneCalendar(false);
    }
  });
  return (
    <div className={`App ${sideEpand ? 'menu-collapsed' : ''}`}>
      <React.Fragment>
        <Tour
          steps={steps}
          isOpen={isTourOpen}
          onRequestClose={() => setIsTourOpen(false)}
        />
        <ToastContainer />
        {user !== undefined && user !== null && Object.entries(user).length > 0 &&
          <>
            {location.pathname !== "/login" && location.pathname !== "/rapid/admin/login" && location.pathname !== "/register" && location.pathname !== "/ship2" && location.pathname !== "/forgot-password" && location.pathname !== "/payment-response" && location.pathname !== "/thankyou" ?
              <>
                {user !== undefined && user !== null && Object.entries(user).length > 0 && user[0].user_type === "Super" ?
                  <AdminSideBar sideEpand={sideEpand} sideBarToggle={sideBarToggle} closeSideBar={closeSideBar} user={user} />
                  :
                  userBalance >= 0 &&
                  <>
                    <MerchantSideBar sideEpand={sideEpand} sideBarToggle={sideBarToggle} closeSideBar={closeSideBar} user={user} />
                  </>
                }
                <AdminHeader user={user !== undefined && user !== null ? user : ''} sideBarToggle={sideBarToggle} walletBalanceUpdate={walletBalanceUpdate} userBalance={userBalance} userBalanceOther={userBalanceOther} loader={loader} />
              </>
              : ''}
          </>
        }

        <Switch>
          <Route path="/login"><Login user={user} /></Route>
          <Route path="/rapid/admin/login"><AdminLogin user={user} /></Route>
          <Route path="/register"><Register user={user} /></Route>
          <Route path="/ship2"><Register user={user} /></Route>
          <Route path="/forgot-password"> <ForgetPassword /></Route>

          {user !== undefined && user !== null && Object.entries(user).length > 0 ?
            <>

              {user !== undefined && user !== null && Object.entries(user).length > 0 && user[0].user_type === "Super" ?
                <>
                  <Route exact path="/">
                    <Suspense fallback={<div>Loading</div>}>
                     <DashboardAdmin user={user} oneCalendar={oneCalendar} />
                    </Suspense> 
                  </Route>
                  <Route path="/orders">
                    <Suspense fallback={<div>Loading</div>}>
                     <Orders user={user} oneCalendar={oneCalendar} />
                    </Suspense> 
                  </Route>
                  <Route path="/city"><City /></Route>
                  <Route path="/state"><State /></Route>
                  <Route path="/pincode"><Pincode /></Route>
                  <Route path="/merchant-remittance"><MerchantRemittance user={user} /></Route>
                  <Route path="/adjusted-remittance"><RemittanceAdjusted user={user} /></Route>
                  <Route path="/courier-remittance"><CourierRemittance user={user} /></Route>
                  <Route path="/merchant-freight"><MerchantFreight user={user} /></Route>
                  <Route path="/merchants"><Merchants user={user} /></Route>
                  <Route path="/merchant-invoices"><MerchantInvoices user={user} /></Route>
                  <Route path="/signup-merchants"><SignupMerchants user={user} /></Route>
                  <Route path="/merchants-passbook/:merchantId"><MerchantPassbook user={user} /></Route>
                  <Route path="/settings"><AccountSetting user={user} /></Route>
                  <Route path="/merchants-pickuppoint/:merchantId"><PickupPoints user={user} /></Route>
                  <Route path="/my-downloads"><AdminDownloads user={user} /></Route>
                  <Route path="/merchants-brand/:merchantId"><MerchantBrands user={user} /></Route>
                  <Route path="/merchant-pricing/:merchantId"><MerchantPricing user={user} /></Route>
                  <Route path="/merchants-details/:merchantId/:tab"><MerchantDetails user={user} /></Route>
                  <Route path="/courier-pricing/:courierId"><CourierPricing user={user} /></Route>
                  <Route path="/courier"><Courier user={user} /></Route>
                  <Route path="/courier-details/:courierId"><CourierDetails /></Route>
                  <Route path="/courier-ledger"><CourierLedger user={user} oneCalendar={oneCalendar} /></Route>
                  <Route path="/merchant-ledger"><MerchantLedger user={user} oneCalendar={oneCalendar} /></Route>
                  <Route path="/zoning/:courierId"><Zoning /></Route>
                  <Route path="/setting"><Setting user={user} /></Route>
                  <Route path="/report-shipment"><ReportShipment user={user} /></Route>
                  <Route path="/lost"><UpdateLost user={user} oneCalendar={oneCalendar} /></Route>
                  <Route path="/weight-recon"><WeightReconcilation user={user} oneCalendar={oneCalendar} /></Route>
                  <Route path="/create-order"><CreateOrder user={user} /></Route>
                </>
                :
                <>
                  <Route exact path="/">{userBalance >= 0 && <DashboardMerchant user={user} oneCalendar={oneCalendar} />}</Route>
                  <Route path="/orders"><Orders user={user} oneCalendar={oneCalendar} /></Route>
                  <Route path="/create-order"><CreateOrder user={user} /></Route>
                  <Route path="/my-remittance"><MerchantRemittance user={user} oneCalendar={oneCalendar} /></Route>
                  <Route path="/remittance-paid"><RemittancePaid user={user} oneCalendar={oneCalendar} /></Route>
                  <Route path="/rate-card"><RateCard user={user} /></Route>
                  <Route path="/passbook"><MerchantPassbook user={user} /></Route>
                  <Route path="/settings/:settingsTab"><MerchantSetting user={user} /></Route>
                  <Route path="/my-pickuppoint"><PickupPoints user={user} /></Route>
                  <Route path="/my-downloads"><MerchantDownloads user={user} /></Route>
                  <Route path="/my-brands"><MerchantBrands user={user} /></Route>
                  <Route path="/freight"><MerchantFreight user={user} oneCalendar={oneCalendar} /></Route>
                  <Route path="/invoices"><MerchantInvoices user={user} /></Route>
                  <Route path="/channel-integration"><ChannelIntegration user={user} /></Route>
                  <Route path="/tracking"><TrackingCodePage user={user} /></Route>
                  <Route path="/stock"><Stock user={user} downloadNotification={downloadNotification} /></Route>
                </>
              }

              <Route path="/channel-order-detail/:orderId"><ChannelOrderDetail user={user} /></Route>
              <Route path="/channel-orders"><ChannelOrder user={user} oneCalendar={oneCalendar} /></Route>
              <Route path="/sms-log"><SmsLog user={user} oneCalendar={oneCalendar} /></Route>
              <Route path="/price-calculator"><PriceCalculator user={user} /></Route>
              <Route path="/order-detail/:orderId"><OrderDetail user={user} /></Route>
              <Route path="/shipments"><Shipments user={user} oneCalendar={oneCalendar} downloadNotification={downloadNotification} /></Route>
              <Route path="/ndr"><LiveNdr user={user} /></Route>
              <Route path="/old-ndr"><AllNdr user={user} /></Route>
              <Route path="/weight-mismatch"><WeightMismatch user={user} oneCalendar={oneCalendar} /></Route>
              <Route path="/rto"><Rto user={user} oneCalendar={oneCalendar} /></Route>
              <Route path="/invoice-detail/:invoiceId"><InvoiceDatail user={user} /></Route>
              <Route path="/remittance-transactions"><RemittanceTransactions user={user} /></Route>
              <Route path="/initiated-remittance"><InitiatedRemittance user={user} /></Route>
              <Route path="/email-content"><EmailTemplate /></Route>
              <Route path="/credit-note"><MerchantCreditNote user={user} oneCalendar={oneCalendar} /></Route>
              <Route path="/credit-note-detail/:creditNoteId"><MerchantCreditNoteDetail user={user} /></Route>
              <Route path="/debit-note-detail/:debitNoteId"><MerchantDebitNoteDetail user={user} /></Route>
              <Route path="/courier-credit-note"><CourierCreditNote user={user} /></Route>
              <Route path="/debit-note"><MerchantDebitNote user={user} oneCalendar={oneCalendar} /></Route>
              <Route path="/courier-debit-note"><CourierDebitNote /></Route>
              <Route path="/multiple-pickPoint"><MultiplePickPoint /></Route>
              <Route path="/manage-brands"><ManageBrands /></Route>
              <Route path="/add-pickuppoint/:merchantId"><AddPickupPoint user={user} /></Route>
              <Route path="/edit-pickuppoint/:merchantId"><EditPickupPoint user={user} /></Route>
              <Route path="/serviceability"><Serviceability user={user} /></Route>
              <Route path="/utility-tool"><UtilityTool user={user} /></Route>
              <Route path="/sort"><DragTab /></Route>
              <Route path="/payment-response"><PaymentMsg /></Route>
              <Route path="/thankyou"><ThankYouMsg /></Route>
              {/* <Route path='/404' component={Notfound} />
              <Redirect from='*' to='/404' />            */}
            </>
            :
            <>
              <Route exact path="/"><Login user={user} /></Route>
            </>
          }
          {/* <Route path="*" exact={true} component={Notfound} /> */}

        </Switch>
      </React.Fragment>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapStateToProps)(App);
