import React,{useRef} from 'react';
import { Row, Col, Label, Button,Form, FormGroup, CardTitle} from 'reactstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseString } from '../../../config';
import { toast } from 'react-toastify';

const ChangePassword = (props) => {
    
    const { register:changepswdInput, handleSubmit:changePasswordSubmit,watch, reset, formState: { errors }} = useForm();
    const onMerchantChangePassword = (data) => {
        if(props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant"){
            data.merchant_id=props.user[0].id
        }else{
            data.admin_id=props.user[0].id
        } 
    // axios.post(process.env.REACT_APP_ADMIN_CHANGE_PASSWORD, data)
      axios({
        method: "post",
        url: process.env.REACT_APP_ADMIN_CHANGE_PASSWORD,
        data: data,
        headers: { "token": window.btoa(baseString) }
        }).then((res) => {
        if(res.data.error === false){
            reset();
            Swal.fire({title: 'Success',text: res.data.msg,icon: 'success',})
        }
        if(res.data.error === true){
            Swal.fire({title: 'Error',text: res.data.msg,icon: 'error',})
        }
      })
      .catch((err) => Swal.fire({title:'Error', text:'Something went wrong', icpon:'error'}));
    };
    const new_password = useRef({});
    new_password.current = watch("new_password", "");
    
    return (
        <Form onSubmit={changePasswordSubmit(onMerchantChangePassword)}>
            <div className="sub-page-header">
                <CardTitle tag="h4" className="sub-page-title">Change Password</CardTitle>
            </div>
            <Row>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="old-password">Old Password<span className="text-danger">*</span></Label>
                        <input {...changepswdInput("old_password",{ required: 'Please Enter Old Password'})}  type="password" className="form-control" placeholder="Old Password" id="old-password" />
                        {errors.old_password && <span className="text-danger d-block error">{errors.old_password.message}</span>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>     
                <Col sm={6}>
                    <FormGroup>
                        <Label for="new-password">New Password<span className="text-danger">*</span></Label>
                        <input {...changepswdInput("new_password",{ required: 'Please Enter New Password',
                        minLength: { value: 6, message: "Password must be at least 6 characters"}})}  type="password" className="form-control" placeholder="New Password" id="new-password" />
                        {errors.new_password && <span className="text-danger d-block error">{errors.new_password.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="retype-password">Retype New Password<span className="text-danger">*</span></Label>
                        <input {...changepswdInput("confirm_password",{ required: 'Please Enter Confirm Password', validate: value => value === new_password.current || "The passwords do not match"})}  type="password" className="form-control" placeholder="Retype New Password" id="retype-password" />
                        {errors.confirm_password && <span className="text-danger d-block error">{errors.confirm_password.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={12} className="mt-2 text-right">                    
                    <Button className="ctm-btn btn-sm" color="primary"> Save changes</Button>
                </Col>            
            </Row>
        </Form>
    );
}

export default ChangePassword;