import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, Input, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Spinner, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Label, InputGroup, InputGroupAddon, InputGroupText, CardSubtitle, CardText, Alert } from 'reactstrap';
import { DateRangePicker } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faArrowDown, faArrowUp, faChevronRight, faDownload, faFileDownload, faCalculator } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ChevronLeft, ChevronRight, Copy } from 'react-feather';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { changeIntoDate, errorHandling, remExtraSpace, copyToClipBoard, firstDayOftheMonth, changeIntoDateTime } from '../../utlis';
import { useHistory } from 'react-router-dom';
import { baseString, download_delay, creditNote_head_id, zone_category_id } from '../../config';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import { MultiSelect, } from "react-multi-select-component";
import { Link } from 'react-router-dom';

const RemittanceTransactions = (props) => {

  const [merchants, setMerchants] = useState([]);
  const [transactions, setTransactions] = useState({});
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [filterDate, setFilterDate] = useState([]);
  const [filterDate2, setFilterDate2] = useState([]);
  const [filterDate3, setFilterDate3] = useState([]);
  const [filterDate4, setFilterDate4] = useState([]);
  const [selectperpage, setSelectperpage] = useState(20);
  const [page, setPage] = useState(1);
  const [page2, setPage2] = useState(1);
  const [currentCount, setCurrentCount] = useState(1);
  const [currentCount2, setCurrentCount2] = useState(1);
  const [filterInput, setFilterInput] = useState('');
  const [activeTab, setActiveTab] = useState('shipping-charges');
  const dropFilterCount = 20
  const history = useHistory();

  const [merchantInvoiceList, setMerchantInvoiceList] = useState({});
  const [defSelYear, setDefSelYear] = useState('');
  const [defSelMonth, setDefSelMonth] = useState('');

  let Year = moment().format('YYYY');
  let prevYear = moment().add(-1, 'year').format('YYYY');

  const [excelmodal, setExcelmodal] = useState(false);
  const [excelFile, setExcelFile] = useState();
  const [creditNote, setCreditNote] = useState({});
  const [headId, setHeadId] = useState([]);


  const httpClient = axios.create()
  httpClient.defaults.timeout = 300000;

  const [allCourier, setAllCourier] = useState([]);
  const [zoneCategory, setZoneCategory] = useState([]);
  const [zoneCorrmodal, setZoneCorrmodal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchValue2, setSearchValue2] = useState('');
  const [searchValue3, setSearchValue3] = useState('');
  const [merchantFreight, setMerchantFreight] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  const [overallchecked, setOverallChecked] = useState(false);
  const [checkboxOrderId, setCheckboxOrderId] = useState([]);
  const [checked, setChecked] = useState(false);


  let dateRangeRef = dateRange => dateRangeRef = dateRange;
  let dateRangeRef2 = dateRange => dateRangeRef2 = dateRange;
  let dateRangeRef4 = dateRange => dateRangeRef4 = dateRange;
  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  const onDateChange2 = (e) => {
    setFilterDate2(e.text);
  };
  
  const onDateChange4 = (e) => {
    setFilterDate4(e.text);
  };

  var opt_merchants = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {}
      if (merchantName && merchantId) {
        tempObj = { label: merchantName, value: merchantId }
        opt_merchants.push(tempObj)
      }
      return true;
    });
  }
  const toggleCheck = (utilityCheckbox, event, Id) => {
    setOverallChecked(false);
    var opt_order_id = checkboxOrderId;
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[utilityCheckbox] = !prevState[utilityCheckbox];
      return newState;
    });
    if (event.target.checked) {
      opt_order_id.push(Id)
      setCheckboxOrderId(opt_order_id)
    } else {
      function arrayRemove(arr, value) {
        return arr.filter(function (ele) {
          return ele !== value;
        });
      }
      var result = arrayRemove(checkboxOrderId, Id);
      setCheckboxOrderId(result)
    };
  };
  const selectOverAll = () => {
    setOverallChecked(true);
    // setCheckboxOrderId([]);
  }
  const selectAll = (value) => {
    setOverallChecked(false);
    let abc = checkData(merchantFreight.data) && merchantFreight.data.map((key) => (
      key === false
    ));
    if (value) {
      var opt_order_id = [];
      checkData(merchantFreight.data) && Object.keys(merchantFreight.data).map(function (key) {
        let tempObj = merchantFreight.data[key].order_id
        opt_order_id.push(tempObj)
        return setCheckboxOrderId(opt_order_id)
      });
      // ));
    } else {
      setCheckboxOrderId([]);
    }
    setChecked(abc);
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const utilityCheckbox in newState) {
        newState[utilityCheckbox] = value;
      }
      return newState;
    });
  };
  const handleFileInput = (e) => { setExcelFile(e.target.files[0]); }
  const transactiosLIsting = () => {
    setMerchants([]);
    setFilterDate([]);
    resetFilter();

    let data = {}

    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setLoadertable(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_REMITTANCE_TRANSACTIONS}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false)
      if (res.data.error === false) {
        setTransactions(res.data)
      }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err, "reload");
    });
  }

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
    // history.push(`/shipments?s=${tab}`)
  }
  const opt_head_id = [];
  for (const property in creditNote_head_id) {
    let tempObj = { label: `${creditNote_head_id[property]}`, value: property };
    opt_head_id.push(tempObj);
  }
  const merchantsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }
  

  var opt_zoneCategory = [];
  Object.keys(zone_category_id).map(function (key) {
    let tempObj = { label: zone_category_id[key], value: parseInt(key) }
    return opt_zoneCategory.push(tempObj);
  });

  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    if (!excelmodal) {
      setExcelFile("");
    }
  }
  useEffect(() => {
    transactiosLIsting();
    InvoiceListing();
    creditNoteListing();
    merchantFreightListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { register: filter2, handleSubmit: filterMerchantFreight, reset:resetFreight } = useForm();
  const { register: uploadFreight, handleSubmit: freightUploadSubmit, formState: { errors3 }, reset: resetfreightFile } = useForm();
  const { register: uploadZone, handleSubmit: zoneUploadSubmit, formState: { errors: errors2 }, reset: resetzoneFile } = useForm();
  const { handleSubmit: filterCreditNoteSubmit } = useForm();
  const { handleSubmit: filterInvoiceListSubmit, reset } = useForm();
  const { register: uploadCreditNote, handleSubmit: creditNoteSubmit, formState: { errors } } = useForm();

  const onCreditNote = (data, pagePerpage) => {
    // setIsExport(true);
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.credit_note_no) || checkData(merchants) || checkData(headId) || checkData(filterDate)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
     // toast.info('Select Your Filtering Preference');
     toast.info('Select Your Filtering Preference');

    }
  };

  const zoneUpload = () => {
    setZoneCorrmodal(!zoneCorrmodal);
    resetzoneFile();
    if (!zoneCorrmodal) { setExcelFile(); }
  }
  var opt_allCourier = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)) {
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function (key) {
      let tempObj = { label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }
      return opt_allCourier.push(tempObj)
    });
  }
  const onMerchantFreight = (data, pagePerpage) => {
    pagePerpage.page = 1
    console.log("onMerchantFreight",JSON.stringify(data));
    if ((checkUndeNullBlank(data.shipping_search_field) && checkUndeNullBlank(data.shipping_search_value)) || checkData(allCourier) || checkData(merchants) || checkData(zoneCategory) || checkUndeNullBlank(data.movement_type) || checkUndeNullBlank(filterDate2)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter2(data, pagePerpage)
     } else {
     // toast.info('Select Your Filtering Preference');
      toast.info('Select Your Filtering Preference');

    }
  };

  const onFreightSubmit = (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("file", excelFile);
    if (props.user[0].user_type === "Super") {
      formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');
    } else {
      formData.append("merchant_id", checkData(props.user) ? props.user[0].id : '');
      formData.append("admin_id", process.env.REACT_APP_ADMIN_ID);
    }
    // axios
    httpClient.post(`${process.env.REACT_APP_FREIGHT_CORRECTION}`, formData)
      // axios({
      //   method: "post",
      //   url: `${process.env.REACT_APP_ZONE_CORRECTION}`,
      //   data: formData,
      // })
      .then((res) => {
        let errorFile = '';
        if (res.data.errorfile !== undefined) {
          errorFile = checkUndeNullBlank(res.data.errorfile) ? process.env.REACT_APP_BASE_URL + res.data.errorfile : '';
        }
        setLoader(false);
        setExcelFile();
        setExcelmodal(false);
        if (res.data.error === false) {
          Swal.fire({
            title: 'Success', text: res.data.msg, icon: 'success', allowOutsideClick: false, allowEscapeKey: false,
            html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>` : ''}</tbody></Table>`,
          }).then(okay => {
            if (okay) {
              merchantFreightListing();
            }
          });
        } else {
          Swal.fire({
            title: 'Error', text: res.data.msg, icon: 'error', allowOutsideClick: false, allowEscapeKey: false,
            html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
          }).then(okay => {
            if (okay) {
              merchantFreightListing();
            }
          });
        }
      }).catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      });
  }

  const onZoneSubmit = (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("file", excelFile);
    if (props.user[0].user_type === "Super") {
      formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');
    } else {
      formData.append("merchant_id", checkData(props.user) ? props.user[0].id : '');
      formData.append("admin_id", process.env.REACT_APP_ADMIN_ID);
    }
    // axios
    // httpClient.post(`${process.env.REACT_APP_ZONE_CORRECTION}`, formData)
    // axios({
    //   method: "post",
    //   url: `${process.env.REACT_APP_ZONE_CORRECTION}`,
    //   data: formData,
    // })
    axios({
      method: "post",
      url: process.env.REACT_APP_ZONE_CORRECTION,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      let errorFile = '';
      if (res.data.errorfile !== undefined) {
        errorFile = checkUndeNullBlank(res.data.errorfile) ? process.env.REACT_APP_BASE_URL + res.data.errorfile : '';
      }
      setLoader(false);
      setExcelFile();
      setZoneCorrmodal(false);
      if (res.data.error === false) {
        Swal.fire({
          title: 'Success', text: res.data.msg, icon: 'success', allowOutsideClick: false, allowEscapeKey: false,
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>` : ''}</tbody></Table>`,
        }).then(okay => {
          if (okay) {
            merchantFreightListing();
          }
        });
      } else {
        Swal.fire({
          title: 'Error', text: res.data.msg, icon: 'error', allowOutsideClick: false, allowEscapeKey: false,
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
        }).then(okay => {
          if (okay) {
            merchantFreightListing();
          }
        });
      }
    }).catch((err) => {
      setLoader(false);
      errorHandling(err, "");
    });
  }
  const merchantFreightListing = ( pagePerpage) => {
    setFilterInput({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), })
    setLoadertable(true);
    setChecked(false);
    setOverallChecked(false);
    setCheckedAll(false);
    setCheckboxOrderId([]);
    reset(); setFilterDate2([firstDayOftheMonth(), new Date()]); setMerchants([]); setAllCourier([]); setSearchValue2(' ');
 
    let data = {"start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), "end_date": moment().format('YYYY-MM-DD') }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    httpClient.post(`${process.env.REACT_APP_MERCHANT_FREIGHT_LIST}?page=${page2}&per_page=${selectperpage}`, data)
      .then((res) => {
        setLoadertable(false);
        if (res.data !== undefined && res.data.fatal !== true) {
          if (parseInt(res.data.errno) > 0) {
            if (res.data.sqlMessage !== undefined) {
              toast.error(res.data.sqlMessage, { position: 'top-right' })
            }
          } else {
            setMerchantFreight(res.data);
          }
        }
      }).catch((err) => {
        setLoadertable(false);
        errorHandling(err, "reload")
      });
  }

  const exportShipment = () => {
    if (checkData(filterInput)) {
      let data = filterInput
      if (props.user[0].user_type === "Super") {
        data.admin_id = props.user[0].id
      } else {
        data.merchant_id = props.user[0].id
      }
      data.is_export = 1
      setLoader(true);
      axios({
        method: "post",
        url: process.env.REACT_APP_MERCHANT_FREIGHT_LIST,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
          if (parseInt(res.data.errno) > 0) {
            setLoader(false)
            if (res.data.sqlMessage !== undefined) {
              toast.error(res.data.sqlMessage, { position: 'top-right' })
            }
          } else {
            if (res.data.is_export === 1) {
              setTimeout(function () {
                setLoader(false);
                history.push("/my-downloads");
              }, download_delay);
            }
          }
        }
      }).catch((err) => {
        setLoader(false)
        errorHandling(err, "")
      });
    } else {
      //Swal.fire({ icon: 'error', title: 'Error', text: 'Select Your Filtering Preference' })
      toast.error('Select Your Filtering Preference');

    }
  }

  // cancel shipment
  const reCalculate = () => {
    if (checkboxOrderId.length > 0) {
      setLoader(true);
      let data = {}
      if (props.user[0].user_type === "Super") {
        data = { "order_ids": checkboxOrderId }
      }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_FREIGHT_RECALC}`,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        setLoader(false);
        setChecked(false);
        setOverallChecked(false);
        setCheckedAll(false);
        setCheckboxOrderId([]);
        if (res.data.error === false) {
          Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success' })
        }
      }).catch((err) => {
        errorHandling(err, "");
        setLoader(false);
      });
    } else {
      toast.error("Select Order", { position: 'top-right' })

    }
  }


  const exportCreditNote = (credit_note_id) => {
    let data = {}
    data.credit_note_id = checkUndeNullBlank(credit_note_id) ? credit_note_id : '';

    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setLoader(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_CREDIT_NOTE_DOWNLOAD,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
        if (parseInt(res.data.errno) > 0) {
          setLoader(false);
          if (res.data.sqlMessage !== undefined) {
            toast.error(res.data.sqlMessage, { position: 'top-right' })
          }
        } else {
          if (res.data.error === false) {
            setTimeout(function () {
              setLoader(false);
              history.push("/my-downloads");
            }, download_delay);
          }
        }
      }
    }).catch((err) => {
      setLoader(false);
      errorHandling(err, "")
    });

  }
  const creditNoteListing = () => {
    setLoadertable(true);
    setMerchants([]);
    setHeadId([]);
    reset();
    setFilterDate3([firstDayOftheMonth(), new Date()]);
    let data = { "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), "end_date": moment().format('YYYY-MM-DD') }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_CREDIT_NOTE_LIST}?page=${page}&per_page=${selectperpage}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            toast.error(res.data.sqlMessage, { position: 'top-right' })
          }
        } else {
          setCreditNote(res.data);
        }
      }
    }).catch((err) => {
      setLoadertable(false);
      errorHandling(err, "reload");
    });
  }


  const onUploadCreditNote = () => {
    setLoader(true);
    let formData = new FormData();
    formData.append("file", excelFile);
    formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');

    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_CREDIT_NOTE_UPLOAD}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      let errorFile = '';
      if (res.data.errorfile !== undefined) {
        errorFile = checkUndeNullBlank(res.data.errorfile) ? process.env.REACT_APP_BASE_URL + res.data.errorfile : '';
      }
      setExcelFile();
      setExcelmodal(false);
      if (res.data.error === false) {
        Swal.fire({
          title: 'Success',
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>` : ''}</tbody></Table>`,
          text: res.data.msg,
          icon: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(okay => {
          if (okay) {
            creditNoteListing();
          }
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: res.data.msg,
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(okay => {
          if (okay) {
            setExcelFile(false);
            creditNoteListing();
          }
        });
      }
    })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      });
  }


  const onpaginationFilter2 = (data, pagePerpage) => {
    onCommonFilter2(data, pagePerpage)
  };

  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };

  const onFilterInvoiceList = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.invoice_no) || checkUndeNullBlank(data.bill_month) || checkData(data.bill_year) || checkData(merchants)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      //toast.info('Select Your Filtering Preference');
      toast.info('Select Your Filtering Preference');

    }
  };

  const InvoiceListing = () => {
    reset();
    setMerchants([]);

    if (props.user[0].user_type === "Super") {
      setDefSelYear(moment().format('YYYY'));
      setDefSelMonth(moment().add(-1, 'month').format('MM'));
    } else {
      setDefSelYear('');
      setDefSelMonth('');
    }

    let data = {};

    if (props.user[0].user_type === "Super") {
      data.bill_month = moment().add(-1, 'month').format('MM');
      data.bill_year = moment().format('YYYY');
    }

    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setLoadertable(true)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_INVOICE}?page=${page}&per_page=${selectperpage}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false)
      if (res.data.error === false) {
        setMerchantInvoiceList(res.data);
      } else {
        Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
      }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err, "reload");
    });
  }


  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(merchantInvoiceList.data)
      if (currentCount > Object.entries(merchantInvoiceList.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }

  function nextPageData() {
    if (checkData(merchantInvoiceList.data) && checkUndeNullBlank(merchantInvoiceList.totalPage)) {
      if (merchantInvoiceList.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(merchantInvoiceList.data).length)
        setPage(page + 1)
        setLoadertable(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }

  function previousPageData2() {
    if (page2 > 1) {
      setPage2(page2 - 1)
      checkData(merchantFreight.data)
      if (currentCount2 > Object.entries(merchantFreight.data).length) {
        setCurrentCount2(currentCount2 - selectperpage)
      } else {
        setCurrentCount2(1)
      }
      let pagePerpage = { "page": page2 > 1 && page2 - 1, "per_page": selectperpage };
      onpaginationFilter2(filterInput, pagePerpage);
    }
  }

  function nextPageData2() { 
    if (checkData(merchantFreight.data) & checkUndeNullBlank(merchantFreight.totalPage)) {
      //console.log('totalPage', merchantFreight)
      if (merchantFreight.totalPage > page2) {
      //  console.log('totalPage', merchantFreight.totalPage > page2)
        setCurrentCount2(currentCount2 + Object.entries(merchantFreight.data).length)
        setPage2(page2 + 1)
        setLoader(true)
        let pagePerpage = { "page": page2 + 1, "per_page": selectperpage };
        onpaginationFilter2(filterInput, pagePerpage);
      }
    }
    // console.log('nextPageData2', checkData(merchantFreight.data)) 
    ///console.log('nextPageData2', page2)
  }

  // copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }

  // filter remittance transactions
  const { register: filter, handleSubmit, reset: resetFilter } = useForm();

  const onSubmit = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkData(filterDate) || checkData(merchants) || checkUndeNullBlank(data.transaction_id)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      //toast.info('Select Your Filtering Preference');
      toast('Select Your Filtering Preference');
    }

  };

  const onCommonFilter2 = (data, pagePerpage) => {
   console.log("onCommonFilter2",JSON.stringify(data));
    setChecked(false);
    setOverallChecked(false);
    setCheckedAll(false);
    setCheckboxOrderId([]);
    let byData = {}
    let courierId = [];
    let merchantsId = [];
    let zoneCategoryId = [];

    if (checkData(allCourier)) {
      allCourier.map((item) => (
        courierId.push(item.value)
      ));
    }
    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }
    if (checkData(zoneCategory)) {
      zoneCategory.map((item) => (
        zoneCategoryId.push(item.value)
      ));
    }

    if (checkUndeNullBlank(courierId)) {
      data.courier_id = courierId
    }
    setLoader(true)
    let sDate = checkData(filterDate2) ? moment(filterDate2[0]).format('YYYY-MM-DD') : '';
    let eDate = checkData(filterDate2) ? moment(filterDate2[1]).format('YYYY-MM-DD') : '';
    byData = { "search_field": checkUndeNullBlank(data.shipping_search_field) ? data.shipping_search_field : '', "search_value": checkUndeNullBlank(data.shipping_search_value) ? remExtraSpace(data.shipping_search_value) : '', "courier_id": checkUndeNullBlank(courierId) ? courierId : '', "merchant_ids": checkUndeNullBlank(merchantsId) ? merchantsId : '', "zone_category_id": checkUndeNullBlank(zoneCategoryId) ? zoneCategoryId : '', "start_date": sDate, "end_date": eDate }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_FREIGHT_LIST}?page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setMerchantFreight(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  };

  const onCommonFilter = (data, pagePerpage) => {
    setLoader(true)
    let byData = {}
    let merchantsId = [];

    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }

    byData = { "ref_no": checkUndeNullBlank(data.transaction_id) ? remExtraSpace(data.transaction_id) : '', "merchant_ids": checkUndeNullBlank(merchantsId) ? merchantsId : '', "start_date": checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '', "end_date": checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '' }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = 1
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_REMITTANCE_TRANSACTIONS}?page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            toast.error(res.data.sqlMessage, { position: 'top-right' })
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setTransactions(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  };


  const exportRemmitance = (date, tran_id) => {
    var dates = date.split("-");
    var dates2 = dates[2] + "-" + dates[1] + "-" + dates[0];

    let data = { "pay_status": 1, "trans_date": checkUndeNullBlank(date) ? dates2 : '' }

    data.ref_no = checkUndeNullBlank(tran_id) ? tran_id : '';


    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }

    data.is_export = 1
    setLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_REMITTANCE_LIST}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
        if (parseInt(res.data.errno) > 0) {
          setLoader(false);
          if (res.data.sqlMessage !== undefined) {
            toast.error(res.data.sqlMessage, { position: 'top-right' })
          }
        } else {
          if (res.data.is_export === 1) {
            setTimeout(function () {
              setLoader(false);
              history.push("/my-downloads");
            }, download_delay);
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "")
    });

  }

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Card className="shadow border-0 mb-4 p-4">
        <div className="content-header">
          <ul className="channels-tabs">
            <li
              className={`channels-tab ${activeTab === 'remittance' ? 'active' : ''}`}
              onClick={() => { toggleTab('remittance'); }}>
              COD Remittance
            </li>
            <li
              className={`channels-tab ${activeTab === 'shipping-charges' ? 'active' : ''}`}
              onClick={() => { toggleTab('shipping-charges'); }}>
              Shipping Charges
            </li>
            <li
              className={`channels-tab ${activeTab === 'invoice' ? 'active' : ''}`}
              onClick={() => { toggleTab('invoice'); }}>
              Invoice
            </li>
            <li
              className={`channels-tab ${activeTab === 'credit-receipt' ? 'active' : ''}`}
              onClick={() => { toggleTab('credit-receipt'); }}>
              Credit Receipt
            </li>
          </ul>
        </div>
        <Row>
          {activeTab === 'remittance' ?
            <Col sm={12}>
              <div className="p-0 my-3 CourierRemittance content-wrapper border-0">
                <Card className="border-0">
                  {/* <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="page-title card-title">Remittance Paid</CardTitle>
              </CardHeader> */}
                  <Form onSubmit={handleSubmit(onSubmit)} className="cardFilter">
                    <CardTitle className="page-title card-title">Remittance Paid</CardTitle>
                    <Row className="mx-0 mb-3 mb-sm-0 justify-content-start">
                      <Col sm={6} md={4} lg={3} xl={3}>
                        <FormGroup>
                          <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} format="dd-MM-yyyy" showOneCalendar={props.oneCalendar} placement="bottomStart" ref={dateRangeRef} change={onDateChange} placeholder="Choose Tran. Date Range" />
                        </FormGroup>
                      </Col>
                      <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                          <input {...filter("transaction_id")} type="text" className="form-control-sm form-control" placeholder="Enter Transaction ID" />
                        </FormGroup>
                      </Col>
                      <Col sm={3} className="btn-container">
                        <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                        <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={transactiosLIsting}>Reset</Button>
                      </Col>
                    </Row>
                  </Form>
                  <CardBody className="p-0">
                    <div className="showing-count">
                      {checkData(transactions) && checkData(transactions.data) && checkUndeNullBlank(transactions.TotalRows) ?
                        <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(transactions.data).length)} of {transactions.TotalRows}</span> : ''}
                    </div>
                    <Table responsive className="text-center custom-table">
                      <thead>
                        <tr>
                          <th className="text-nowrap">Transaction ID</th>
                          <th className="text-nowrap">Transaction Date</th>
                          <th className="text-nowrap">Remittance Amount</th>
                          <th className="text-nowrap">Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkData(transactions.data) ? transactions.data.map((item, index) => (
                          <tr key={index}>
                            <td>{item.ref_no}</td>
                            <td>{changeIntoDate(item.trans_date)}</td>
                            <td>{item.credit_amt}</td>
                            <td><Button className="btn btn-sm text-nowrap" color="info" outline onClick={() => { exportRemmitance(changeIntoDate(item.trans_date), item.ref_no) }}>Download Excel</Button></td>
                          </tr>
                        )) :
                          loadertable ? <tr><td colSpan="8" className="text-center"><LoaderDefault /></td></tr>
                            : <tr><td colSpan="8" className="text-center"><NodataFound /></td></tr>}
                      </tbody>
                    </Table>
                    {checkData(transactions) && checkData(transactions.data) && checkUndeNullBlank(transactions.TotalRows) ?
                      <>
                        {transactions.TotalRows > dropFilterCount ?
                          <Row className="m-0 table-footer">
                            <Col xs="6">
                              <Pagination>
                                <PaginationItem className="prev">
                                  <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                                </PaginationItem>
                                <PaginationItem className="next ml-3">
                                  <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                                </PaginationItem>
                              </Pagination>
                            </Col>
                            <Col xs="6">
                              <Form>
                                <FormGroup>
                                  <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                  <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} className="form-control-sm">
                                    <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </Input>
                                </FormGroup>
                              </Form>
                            </Col>
                          </Row> : ''}
                      </>
                      : ''}
                  </CardBody>
                </Card>
              </div>
            </Col>
            : ''}
          {activeTab === 'shipping-charges' ?
            <Col sm={12}>
              <Card className="p-0 my-3 merchantFreight content-wrapper border-0">

                <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                  <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                    <ModalHeader toggle={excelUpload}>Upload Freight Correction</ModalHeader>
                    <ModalBody>
                      <Form onSubmit={freightUploadSubmit(onFreightSubmit)}>
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Upload Freight Correction via XLSX</p>
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                        <Row className="m-0 pb-1">
                          <Col sm={12} className="p-0">
                            <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/freight_correction.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download
                            </Link>
                              <span className="ml-2">Update Freight Correction Format File</span></p>
                          </Col>
                          <Col sm={12} className="m-auto pt-1 p-0">
                            <FormGroup>
                              <input {...uploadFreight("excelUpload", {
                                required: 'Please Upload XLSX File',
                                validate: {
                                  lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                  acceptedFormats: files =>
                                    ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                      files[0]?.type
                                    ) || 'Only .xslx file allowed',
                                },
                              })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" multiple onChange={handleFileInput} />
                              <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                              {errors.excelUpload && <span className="text-danger d-block error">{errors.excelUpload.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm={12} className="text-right">
                            <Button className="ctm-btn btn-sm" color="primary">Upload</Button>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                  <Modal isOpen={zoneCorrmodal} toggle={zoneCorrmodal} scrollable={true} >
                    <ModalHeader toggle={zoneUpload}>Upload Zone Correction</ModalHeader>
                    <ModalBody>
                      <Form onSubmit={zoneUploadSubmit(onZoneSubmit)}>
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Upload Zone Correction via XLSX</p>
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                        <Row className="m-0 pb-1">
                          <Col sm={12} className="p-0">
                            <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/zone_correction.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download
                            </Link>
                              <span className="ml-2">Update Zone Correction Format File</span></p>
                          </Col>
                          <Col sm={12} className="m-auto pt-1 p-0">
                            <FormGroup>
                              <input {...uploadZone("excelUpload", {
                                required: 'Please Upload XLSX File',
                                validate: {
                                  lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                  acceptedFormats: files =>
                                    ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                      files[0]?.type
                                    ) || 'Only .xslx file allowed',
                                },
                              })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                              <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                              {errors2.excelUpload && <span className="text-danger d-block error">{errors2.excelUpload.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm={12} className="text-right">
                            <Button className="ctm-btn btn-sm" color="primary">Upload</Button>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardHeader>
                <Form onSubmit={filterMerchantFreight(onMerchantFreight)} className="cardFilter">
                  <CardTitle className="page-title card-title">Shipping Charges</CardTitle>
                  <Row className="mx-0 justify-content-start">
                    <Col sm={6} md={5} lg={4} xl={3}>
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="position-relative p-0">
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select {...filter2("shipping_search_field")} className="form-control form-control-sm" onChange={(e) => { if (e.target.selectedOptions[0].text !== "Search By") { setSearchValue2("Enter " + e.target.selectedOptions[0].text) } else { setSearchValue2('') } }}>
                                <option value="">Search By</option>
                                <option value="awb_number">AWB Number</option>
                                <option value="order_no">Order Number</option>
                                <option value="system_order_no">Reference No.</option>
                              </select>
                            </InputGroupText>
                          </InputGroupAddon>
                          <input {...filter2("shipping_search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue2} />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    

                    <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                        <DateRangePicker value={filterDate2} onChange={date => setFilterDate2(date)} placeholder="Choose Charge Date" format="dd-MM-yyyy" placement="bottomEnd" showOneCalendar={props.oneCalendar} ref={dateRangeRef2} change={onDateChange2} />
                      </FormGroup>
                    </Col>
                    <Col sm={3} className="btn-container">
                      <Button className="ctm-btn btn-sm" color="primary">Search Charges</Button>
                      <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline onClick={merchantFreightListing}>Reset</Button>
                    </Col>
                    <Col sm={12}>
                      <div className="card-header-right d-flex flex-wrap align-items-center mr-auto">
                        <div className="btn-text btn-light mx-2" onClick={exportShipment}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Export Freight</span></div>
                        {props.user[0].user_type === "Super" &&
                          <>
                            <div className="btn-text btn-light" onClick={reCalculate} ><span className="icon mr-2"><FontAwesomeIcon icon={faCalculator} /></span><span>Freight Recalculate</span></div>
                            <div className="btn-text btn-light" onClick={excelUpload} ><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Freight Correction</span></div>
                            <div className="btn-text btn-light" onClick={zoneUpload} ><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Zone Correction</span></div>
                          </>
                        }
                      </div>
                    </Col>
                  </Row>
                </Form>
                <Row className="mx-0 my-3 justify-content-center">
                  <Col sm={6} md={2}>
                  {/* <div className='smallCard'> 
                    <div className='smallCardTitleWrap'>
                        <p className='smallCardTitleTitle'>Freight (Inc% tax)</p>
                        <h3 className='smallCardTitleCount'>{checkData(merchantFreight.TotalData) && checkUndeNullBlank(merchantFreight.TotalData[0].totalFreight) ? "₹ " + merchantFreight.TotalData[0].totalFreight : "NA"}</h3>
                    </div>
                  </div> */}
                    {/* <Card className="border shadow-sm">
                      <CardBody className="d-flex justify-content-between align-items-center">
                        <span className="text-white d-flex align-items-center justify-content-center bg-primary" style={{ width: "50px", height: "50px", fontSize: "25px" }}><FontAwesomeIcon icon={faMoneyBillAlt} /></span>
                        <div>
                          <CardSubtitle tag="h5" className="mb-2">{checkData(merchantFreight.TotalData) && checkUndeNullBlank(merchantFreight.TotalData[0].totalFreight) ? "₹ " + merchantFreight.TotalData[0].totalFreight : "NA"}</CardSubtitle>
                          <CardText>Total Freight (Inc% tax)</CardText>
                        </div>
                      </CardBody>
                    </Card> */}
                  </Col>
                  <Col sm={6} md={2}>
                    {/* <div className='smallCard'> 
                      <div className='smallCardTitleWrap'>
                          <p className='smallCardTitleTitle'>Shipments Count</p>
                          <h3 className='smallCardTitleCount'>{checkData(merchantFreight.TotalData) && checkUndeNullBlank(merchantFreight.TotalData[0].totalShipments) ? merchantFreight.TotalData[0].totalShipments : "NA"}</h3>
                      </div>
                    </div> */}
                    {/* <Card className="border shadow-sm">
                      <CardBody className="d-flex justify-content-between align-items-center">
                        <span className="text-white bg-primary d-flex justify-content-center align-items-center" style={{ width: "50px", height: "50px", fontSize: "25px" }}><Layers style={{ width: "30px", height: "30px" }} /></span>
                        <div>
                          <CardSubtitle tag="h5" className="mb-2">{checkData(merchantFreight.TotalData) && checkUndeNullBlank(merchantFreight.TotalData[0].totalShipments) ? merchantFreight.TotalData[0].totalShipments : "NA"}</CardSubtitle>
                          <CardText>Shipments Count</CardText>
                        </div>
                      </CardBody>
                    </Card> */}
                  </Col>
                </Row>
                <CardBody className="p-0">
                  <div className="">
                    <div className="showing-count">
                      {checkData(merchantFreight) && checkData(merchantFreight.data) && checkUndeNullBlank(merchantFreight.TotalRows) ?
                        <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(merchantFreight.data).length)} of {merchantFreight.TotalRows}</span> : ''}
                    </div>
                    {checkboxOrderId.length > 0 && !overallchecked &&
                      <Alert color="secondary" className='small total-select' fade={false}>
                        <span className='mx-1'>Total {checkboxOrderId.length} items on this page are selected.</span>{checkData(merchantFreight.data) && checkUndeNullBlank(merchantFreight.TotalRows) && merchantFreight.TotalRows > selectperpage && checkedAll && <span onClick={selectOverAll} className='cursor-pointer text-primary d-none'>Select all <span className='font-weight-bold'>{checkData(merchantFreight.data) && checkUndeNullBlank(merchantFreight.TotalRows) && merchantFreight.TotalRows}</span> items across all pages</span>}
                      </Alert>
                    }
                    {overallchecked &&
                      <Alert color="secondary" className='small total-select' fade={false}>
                        <span className='mx-1'>Total {checkData(merchantFreight.data) && checkUndeNullBlank(merchantFreight.TotalRows) && merchantFreight.TotalRows} items are Selected.</span>
                      </Alert>
                    }
                    <Table responsive className="text-center custom-table">
                      <thead>
                        <tr>
                          {props.user[0].user_type === "Super" &&
                            <th><div className="form-check2"><input name="cust-type form-check-input" type="checkbox" id="select_all" checked={checkedAll} onChange={(event) => selectAll(event.target.checked)} value="checkedall" /><label className="form-check-label" for="select_all"></label></div></th>}
                          {props.user[0].user_type === "Super" &&
                            <>
                              <th className="text-nowrap">Merchant</th>
                            </>
                          }
                          <th className="text-nowrap">Created</th>

                          <th className="text-nowrap">Carrier</th>
                          <th className="text-nowrap">Tracking ID</th>
                          <th className="text-nowrap">Applied Weight <small>(in kgs)</small></th>
                          <th className="text-nowrap">Forward</th>
                          <th className="text-nowrap">RTO</th>
                          <th className="text-nowrap">COD</th>
                          <th className="text-nowrap">Tax Charges</th>
                          <th className="text-nowrap">Total</th>
                          <th className="text-nowrap">Zone</th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkData(merchantFreight.data) ? merchantFreight.data.map((item, index) => (
                          <tr>
                            {props.user[0].user_type === "Super" &&
                              <td><div className="form-check2"><input checked={checked !== undefined && checked[index]} name={'check' + index} type="checkbox" id={item.order_id} onChange={(event) => toggleCheck(index, event, item.order_id)} disabled={overallchecked} /><label className="form-check-label" for={item.order_id}></label></div></td>}
                            {props.user[0].user_type === "Super" &&
                              <>
                                <td>{item.MerchantName}</td>
                              </>
                            }
                           <td className="text-nowrap">{changeIntoDate(item.created_at)}</td>
                           <td className="text-nowrap">{ item.CourierName }</td>
                           <td className="text-nowrap"><Link to={`/order-detail/${item.order_id}`}>{item.awb_no ? item.awb_no : "NA"}</Link></td>
                            <td>{item.charged_weight ? item.charged_weight : 'NA'}</td>
                            <td className='text-nowrap'>{item.forward_charge ? item.forward_charge : '0'}</td>
                            <td className='text-nowrap'>{item.rto_charge ? item.rto_charge : '0'}</td>
                            <td className='text-nowrap'>{item.cod_charge > 0 ? item.cod_charge : 0}</td>
                            {/* <td className='text-left'>
                        <div>
                          Forward - {item.forward_charge ? item.forward_charge : '0' }
                        </div>
                        <div>
                          RTO - {item.rto_charge ? item.rto_charge : '0' }
                        </div>
                        {item.cod_charge > 0 &&
                          <div>
                            COD - {item.cod_charge}
                          </div>
                        }
                      </td> */}
                            <td>{item.tax_amt}</td>
                            <td>{item.total_amt}</td>
                            <td>{zone_category_id[item.zone_category_id]}</td>
                          </tr>
                        ))
                          : loadertable ? <tr> <td colSpan="7"><LoaderDefault /></td> </tr> :
                            <tr> <td colSpan="6"><NodataFound /></td> </tr>}
                      </tbody>
                    </Table>

                  </div>

                  {checkData(merchantFreight) && checkData(merchantFreight.data) && checkUndeNullBlank(merchantFreight.TotalRows) ?
                    <>
                      {merchantFreight.TotalRows > dropFilterCount ?
                        <Row className="m-0 table-footer">
                          <Col xs="6">
                            <Pagination aria-label="Page navigation example align-items-center">
                              <PaginationItem className="prev">
                                <PaginationLink previous to="#" onClick={previousPageData2} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft />1</PaginationLink>
                              </PaginationItem>
                              <PaginationItem className="next ml-3">
                                <PaginationLink next to="#" onClick={nextPageData2} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight />2</PaginationLink>
                              </PaginationItem>
                            </Pagination>
                          </Col>
                          <Col xs="6">
                            <Form>
                              <FormGroup>
                                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                  <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                  <option value="250">250</option>
                                  <option value="500">500</option>
                                </select>
                              </FormGroup>
                            </Form>
                          </Col>
                        </Row> : ''}
                    </>
                    : ''}
                </CardBody>

              </Card>
            </Col>
            : ''}
          {activeTab === 'invoice' ?
            <Col sm={12}>
              <div className="p-0 my-3 merchantInvoice content-wrapper border-0">
                <p>You are requested to cross check invoicing details like; Name, GST Number, GST State, Address etc. and in case of any mismatch get it updated with our support team within 10 days from the date when original invoice was issued.</p>
                <Form onSubmit={filterInvoiceListSubmit(onFilterInvoiceList)} className="cardFilter">
                  <CardTitle className="page-title card-title">Manage {props.user[0].user_type === "Super" && "Merchant"} Invoices</CardTitle>
                  <Row className="mx-0 mb-3 mb-sm-0 justify-content-start justify-content-md-center">
                    {props.user[0].user_type === "Super" &&
                      <Col sm={6} md={5} lg={4} xl={3}>
                        <FormGroup>
                          <MultiSelect
                            options={opt_merchants}
                            value={merchants}
                            onChange={(e) => { setMerchants(e); }}
                            labelledBy="Select Merchant"
                            overrideStrings={merchantsMultiSelectSettings}
                            className={checkData(merchants) && "active"}
                          />
                        </FormGroup>
                      </Col>}

                    <Col sm={6} md={4} lg={3} xl={2}>
                      <FormGroup>
                        <input  {...filter("invoice_no")} type="text" className="form-control-sm form-control" placeholder="Enter Invoice No." />
                      </FormGroup>
                    </Col>
                    
                    <Col sm={3} className="btn-container">
                      <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" onClick={InvoiceListing} outline>Reset</Button>
                    </Col>
                  </Row>
                </Form>
                <CardBody className="p-0">
                  <div className="showing-count">
                    {checkData(merchantInvoiceList) && checkData(merchantInvoiceList.data) && checkUndeNullBlank(merchantInvoiceList.TotalRows) ?
                      <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(merchantInvoiceList.data).length)} of {merchantInvoiceList.TotalRows}</span> : ''}
                  </div>
                  <Table responsive className="text-center custom-table">
                    <thead>
                      <tr>
                        {props.user[0].user_type === "Super" &&
                          <th className="text-nowrap">Merchant</th>}
                        <th className="text-nowrap">INVOICE NO. #</th>
                        <th className="text-nowrap">SERVICE TYPE</th>
                        <th className="text-nowrap">INVOICE DATE</th>
                        <th className="text-nowrap">INVOICE PERIOD</th>
                        <th className="text-nowrap">INVOICE AMOUNT</th>
                        <th className="text-nowrap">ACTION</th>
                        {/* <th className="text-nowrap">Balance</th>
                        <th className="text-nowrap">Pay Status</th>
                        <th className="text-nowrap">View</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {checkData(merchantInvoiceList.data) ? merchantInvoiceList.data.map((item, index) => {
                        let totalAmount = checkUndeNullBlank(item.net_amt) ? item.net_amt : 0
                        let totalAdjusted = checkUndeNullBlank(item.totalAdjust) ? item.totalAdjust : 0
                        let totalBalnce = (totalAmount - totalAdjusted).toFixed(2)
                        return (
                          <tr>
                            {props.user[0].user_type === "Super" &&
                              <td>{item.merchantName}</td>}
                            <td><Link target="_blank" to={`/invoice-detail/${item.id}`}>{item.invoice_no}</Link>
                              <span onClick={() => copied(item.invoice_no)} className="ml-1 copy-btn" id={`copyInvNo${item.id}`}>
                                <Copy />
                                <UncontrolledTooltip placement="bottom" target={`copyInvNo${item.id}`}>
                                  Copy
                                </UncontrolledTooltip>
                              </span>
                            </td>
                            <td className='text-nowrap'>{changeIntoDate(item.invoice_date)}</td>
                            <td className='text-nowrap'>{changeIntoDate(item.due_date)}</td>
                            <td>{item.total_gst}</td>
                            <td>{totalAmount}</td>
                            <td>{totalAdjusted}</td>
                            <td>{totalBalnce}</td>
                            <td>
                              {item.pay_status === 0 && totalBalnce > 0 ?
                                <span className="text-danger">Unpaid</span> :
                                <span className="text-success">Paid</span>
                              }
                            </td>
                            <td>
                              <div className="text-nowrap">
                                <Link to={`/invoice-detail/${item.id}`}>Invoice</Link>
                              </div>
                            </td>
                          </tr>
                        )
                      }) :
                        loadertable ? <tr><td colSpan="10" className="text-center"><LoaderDefault /></td></tr>
                          : <tr><td colSpan="10" className="text-center"><NodataFound /></td></tr>}
                    </tbody>
                  </Table>


                  {checkData(merchantInvoiceList) && checkData(merchantInvoiceList.data) && checkUndeNullBlank(merchantInvoiceList.TotalRows) ?
                    <>
                      {merchantInvoiceList.TotalRows > dropFilterCount ?
                        <Row className="m-0 table-footer">
                          <Col xs="6">
                            <Pagination aria-label="Page navigation example align-items-center">
                              <PaginationItem className="prev">
                                <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                              </PaginationItem>
                              <PaginationItem className="next ml-3">
                                <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                              </PaginationItem>
                            </Pagination>
                          </Col>
                          <Col xs="6">
                            <Form>
                              <FormGroup>
                                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                  <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                              </FormGroup>
                            </Form>
                          </Col>
                        </Row> : ''}
                    </>
                    : ''}
                </CardBody>
              </div>
            </Col>
            : ''}
          {activeTab === 'credit-receipt' ?
            <Col sm={12}>
              <div className="p-0 my-3 MerchantCreditNote content-wrapper border-0">
                  <CardHeader className="page-header d-flex justify-content-between flex-wrap p-0">
                    <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                      <div className="btn-text btn-light mx-2 d-none"><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Export Credit Note</span></div>
                      {props.user[0].user_type === "Super" &&
                        <div className="btn-text btn-light" onClick={excelUpload} ><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Upload Credit Note</span></div>}
                    </div>
                    <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                      <ModalHeader toggle={excelUpload}>Upload Credit Note</ModalHeader>
                      <ModalBody>
                        <Form onSubmit={creditNoteSubmit(onUploadCreditNote)}>
                          <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Credit Note via XLSX</p>
                          <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please Export below given template</p>
                          <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                          <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                          <Row className="m-0 pb-1">
                            <Col sm={12} className="p-0">
                              <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/credit_note.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download
                              </Link>
                                <span className="ml-2">Credit Format File</span></p>
                            </Col>
                            <Col sm={12} className="m-auto pt-1 p-0">
                              <FormGroup>
                                <input {...uploadCreditNote("excelUpload", {
                                  required: 'Please Upload XLSX File',
                                  validate: {
                                    lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                    acceptedFormats: files =>
                                      ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                        files[0]?.type
                                      ) || 'Only .xslx file allowed',
                                  },
                                })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                                <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                                {errors.excelUpload && <span className="text-danger d-block error">{errors.excelUpload.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} className="text-right">
                              <Button className="ctm-btn btn-sm" color="primary">Upload</Button>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  </CardHeader>
                  <Form onSubmit={filterCreditNoteSubmit(onCreditNote)} className="cardFilter">
                    <CardTitle className="page-title card-title">Manage Credit Note</CardTitle>
                    <Row className="mx-0 justify-content-start justify-content-md-center mb-xl-3">
                      <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                          <input {...filter("credit_note_no")} type="text" placeholder="Enter Credit Note No" className="form-control-sm form-control" />
                        </FormGroup>
                      </Col>
                      {props.user[0].user_type === "Super" &&
                        <Col sm={6} md={5} lg={4} xl={3}>
                          <FormGroup>
                            <MultiSelect
                              options={opt_merchants}
                              value={merchants}
                              onChange={(e) => { setMerchants(e); }}
                              labelledBy="Select Merchant"
                              overrideStrings={merchantsMultiSelectSettings}
                              className={checkData(merchants) && "active"}
                            />
                          </FormGroup>
                        </Col>}
                     
                      <Col sm={6} md={4} lg={3} xl={3}>
                        <FormGroup>
                          <DateRangePicker value={filterDate4} onChange={date => setFilterDate4(date)} placeholder="Choose Tra. Date Range" placement="bottomEnd" format="dd-MM-yyyy" showOneCalendar={props.oneCalendar} ref={dateRangeRef4} change={onDateChange4} />
                        </FormGroup>
                      </Col>
                      <Col sm={3} className="btn-container">
                        <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                        <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline onClick={creditNoteListing}>Reset</Button>
                      </Col>
                    </Row>
                  </Form>
                  <CardBody className="p-0">
                    <div className="showing-count">
                      {checkData(creditNote) && checkData(creditNote.data) && checkUndeNullBlank(creditNote.TotalRows) ?
                        <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(creditNote.data).length)} of {creditNote.TotalRows}</span> : ''}
                    </div>
                    <Table responsive className="text-center custom-table">
                      <thead>
                        <tr>
                          {props.user[0].user_type === "Super" &&
                            <th className="text-nowrap">Merchant</th>}
                          <th className="text-nowrap w-some">CN NO. #</th>
                          <th className="text-nowrap">CN SERVICE TYPE</th>
                          <th className="text-nowrap">CN DATE</th>
                          <th className="text-nowrap">CN PERIOD</th>
                          <th className="text-nowrap w-some">CN AMOUNT</th>
                          <th className="text-nowrap w-some">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkData(creditNote.data) ? creditNote.data.map((item, index) => (
                          <tr>
                            {props.user[0].user_type === "Super" &&
                              <td>{item.MerchantName}</td>
                            }
                            <td>{creditNote_head_id[item.head_id]}</td>
                            <td>
                              <div className="text-nowrap">
                                <Link target="_blank" to={`/credit-note-detail/${item.id}`}>CN/{item.credit_note_no}</Link>
                                <span onClick={() => copied(item.credit_note_no)} className="ml-1 copy-btn" id={`copyOdr${item.id}`}>
                                  <Copy />
                                  <UncontrolledTooltip placement="bottom" target={`copyOdr${item.id}`}>
                                    Copy
                                  </UncontrolledTooltip>
                                </span>
                              </div>
                            </td>
                            <td className="text-nowrap">{changeIntoDateTime(item.created_at)}</td>
                            <td>{item.amount}</td>
                            <td>{item.note_detail}</td>
                            <td>
                              <span>
                                <Button className="btn ctm-btn btn-sm text-nowrap" color="info" outline onClick={() => { exportCreditNote(item.id) }}>Download Excel</Button>
                              </span>
                              {props.user[0].user_type === "Super" && checkUndeNullBlank(item.uploaded_file_name) &&
                                <span className="mx-2">
                                  <a href={`${process.env.REACT_APP_BASE_URL + '/download/' + item.uploaded_file_name + '?pathName=' + creditNote.path}`} download target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFileDownload} /></a>
                                </span>
                              }
                            </td>
                          </tr>
                        ))
                          : loadertable ? <tr> <td colSpan="7"><LoaderDefault /></td> </tr> :
                            <tr> <td colSpan="7"><NodataFound /></td> </tr>}
                      </tbody>
                    </Table>
                    <ToastContainer style={{ width: "200px" }} />
                    {checkData(creditNote) && checkData(creditNote.data) && checkUndeNullBlank(creditNote.TotalRows) ?
                      <>
                        {creditNote.TotalRows > dropFilterCount ?
                          <Row className="m-0 table-footer">
                            <Col xs="6">
                              <Pagination aria-label="Page navigation example align-items-center">
                                <PaginationItem className="prev">
                                  <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                                </PaginationItem>
                                <PaginationItem className="next ml-3">
                                  <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                                </PaginationItem>
                              </Pagination>
                            </Col>
                            <Col xs="6">
                              <Form>
                                <FormGroup>
                                  <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                  <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                    <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="250">250</option>
                                  </select>
                                </FormGroup>
                              </Form>
                            </Col>
                          </Row> : ''}
                      </>
                      : ''}
                  </CardBody>
              </div>
            </Col>
            : ''}
        </Row>
      </Card>
    </div>
  );
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}

export default connect(mapDispatchToProps)(RemittanceTransactions);