import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, Label, Spinner, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { ArrowLeft, ChevronLeft, ChevronRight } from 'react-feather';
import axios from 'axios';
import LoaderDefault from '../LoaderDefault';
import { changeIntoDate, errorHandling, remExtraSpace } from '../../utlis';
import Status from '../Status';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import NodataFound from '../NodataFound';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { baseString } from '../../config';
import { toast } from 'react-toastify';


const MerchantBrands = (props) => {
  const [addmodal, setAddmodal] = useState(false);
  const [brandList, setBrandList] = useState({});
  const { merchantId } = useParams();
  const [logo, setLogo] = useState('');
  const [editmodal, setEditmodal] = useState(false);
  const [editMerchantBrands, setEditMerchantBrands] = useState({});
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState('');
  const dropFilterCount = 20

  const handleFileInput = (e) => {
    setLogo(e.target.files[0])
  }

  const toggleAdd = () => setAddmodal(!addmodal);
  const toggleEditClose = () => setEditmodal(false);
  const toggleEdit = (i) => {
    setEditmodal(!editmodal);
    setLoader(true);
    fetch(`${process.env.REACT_APP_MERCHANT_BRANDS}/view/${i}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
        "token": window.btoa(baseString)
      },
    }).then((resp) => {
      resp.json().then((result) => {
        setLoader(false);
        let myResponse = result[0];
        myResponse.status = String(myResponse.status)
        reset2(myResponse);
        setEditMerchantBrands(result[0])
      });
    });
  }
  const brandPointListing = () => {
    setLoadertable(true);
    setFilterInput('')
    setCurrentCount(1)
    setPage(1)
    let data = {}
    if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
      data = { "merchant_id": props.user[0].id }
    } else {
      data = { "merchant_id": merchantId }
    }
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_BRANDS,
      data: data,
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        setLoadertable(false);
        if (response.data.fatal !== true) {
          setBrandList(response.data)
        }
      })
      .catch(function (response) {
        setLoadertable(false);
        errorHandling(response, "reload")
      });
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(brandList.data) && checkUndeNullBlank(brandList.totalPage)) {
      // &&checkUndeNullBlank(brandList.totalPage).length>page.length
      if (brandList.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(brandList.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(brandList.data)
      if (currentCount > Object.entries(brandList.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  useEffect(() => {
    brandPointListing()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantId])
  const { register: addMerchantBrands, handleSubmit: MerchantBrandSubmit, formState: { errors }, reset } = useForm();
  const onAddMerchantBrandsSubmit = (data) => {
    setLoader(true);
    if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
      data.merchant_id = props.user[0].id
    } else {
      data.merchant_id = merchantId
    }
    let formData = new FormData();
    formData.append("merchant_id", data.merchant_id);
    formData.append("brand_name", data.brand_name);
    formData.append("brand_website", data.brand_website);
    formData.append("status", data.status);
    formData.append("logo", logo);

    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_BRANDS_CREATE,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data.error === false) {
        setAddmodal(false);
        setLoader(false)
        reset();
        brandPointListing();
        Swal.fire({
          title: 'Success',
          text: res.data.msg,
          icon: 'success',
        })
      }
    })
      .catch(function (response) {
        setLoader(false);
        errorHandling(response, "")
      });
  };

  const { register: editMerchantBranddata, handleSubmit: merchantBrandsEditSubmit, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const onEditMerchantBrandsSubmit = (data) => {
    setLoader(true);
    if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
      data.merchant_id = props.user[0].id
    } else {
      data.merchant_id = merchantId
    }
    let formData = new FormData();
    formData.append("merchant_id", data.merchant_id);
    formData.append("brand_name", data.brand_name);
    formData.append("brand_website", data.brand_website);
    formData.append("status", data.status);
    formData.append("logo", logo !== '' ? logo : editMerchantBrands !== undefined && editMerchantBrands.logo !== undefined && editMerchantBrands.logo !== "" && editMerchantBrands.logo !== null ? editMerchantBrands.logo : 'NA');

    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_BRANDS_UPDATE}/${editMerchantBrands.id}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data.error === false) {
        setEditmodal(false);
        setLoader(false);
        setLogo('');
        brandPointListing();
        Swal.fire({
          title: 'Success',
          text: res.data.msg,
          icon: 'success',
        })
      }
    })
      .catch(function (response) {
        setLoader(false);
        errorHandling(response, "")
      });
  };
  const { register, handleSubmit } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onSubmit = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.brand_name)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      toast.info('Select Your Filtering Preference');
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
    let mydata = {}
    if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
      mydata = { "merchant_id": props.user[0].id }
    } else {
      mydata = { "merchant_id": merchantId }
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_BRANDS}?brand_name=${checkUndeNullBlank(data.brand_name) ? remExtraSpace(data.brand_name) : ''}&page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: mydata,
      headers: { "token": window.btoa(baseString) }
    }).then(function (response) {
      setLoader(false);
      if (response.data.fatal !== true) {
        setBrandList(response.data);
      }
    }).catch(function (response) {
      setLoader(false);
      errorHandling(response, "")
    });
  };

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 merchantBrands shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">

                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  {checkUndeNullBlank(props.user[0]) && props.user[0].user_type === "Super" &&
                    <Link to='/merchants' className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back to Merchant List</span></Link>}
                  <div className="btn-text btn-light" onClick={toggleAdd}><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Add Merchant Store</span></div>
                </div>
                <Modal isOpen={addmodal} toggle={addmodal} scrollable={true}>
                  <ModalHeader toggle={toggleAdd}>Add Store</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={MerchantBrandSubmit(onAddMerchantBrandsSubmit)}>
                      <Row className="mx-0 justify-content-center addModal">
                        <Col sm={6}>
                          <FormGroup>
                            <Label for="brandName_add">Store Name<span className="text-danger">*</span></Label>
                            <input {...addMerchantBrands("brand_name", { required: 'Please Enter Store Name', pattern: { value: /^[-a-zA-Z ]+$/, message: 'Please Enter Valid Store Name' } })} className="form-control-sm form-control" placeholder="Store Name" id="brandName_add" />
                            {errors.brand_name && <span className="text-danger d-block error">{errors.brand_name.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <Label for="website_add">Store URL<span className="text-danger">*</span></Label>
                            <input {...addMerchantBrands("brand_website", { required: 'Please Enter Store Website', pattern: { value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/, message: 'Please Enter Valid Website URL' } })} className="form-control-sm form-control" placeholder="Store Website" id="website_add" />
                            {errors.brand_website && <span className="text-danger d-block error">{errors.brand_website.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} className='d-none'>
                          <FormGroup>
                            <Label for="brandLogo_add">Logo</Label>
                            <input {...addMerchantBrands("logo")} type="file" onChange={handleFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="brandLogo_add" />
                            {errors.logo && <span className="text-danger d-block error">{errors.logo.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <FormGroup className="mb-0">
                            <Label>Status<span className="text-danger">*</span></Label>
                            <div>
                              <span className="mr-2">
                                <input {...addMerchantBrands("status", { required: 'Please Add Status' })} type="radio" value="1" id="active_add" defaultChecked />
                                <Label className="mx-2" for="active_add">Enable</Label>
                              </span>
                              <span>
                                <input {...addMerchantBrands("status", { required: 'Please Add Status' })} type="radio" value="0" id="inactive_add" />
                                <Label className="mx-2" for="inactive_add">Disabled</Label>
                              </span>
                            </div>
                            {errors.status && <span className="text-danger d-block error">{errors.status.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
                <Modal isOpen={editmodal} toggle={editmodal} scrollable={true} >
                  <ModalHeader toggle={toggleEditClose}>Edit Merchant Store</ModalHeader>
                  <ModalBody>
                    {checkData(editMerchantBrands) &&
                      <Form onSubmit={merchantBrandsEditSubmit(onEditMerchantBrandsSubmit)}>
                        <Row className="mx-0 justify-content-center addModal">
                          <Col sm={12}>
                            <FormGroup>
                              <Label for="brandName_edit">Enter Store Name<span className="text-danger">*</span></Label>
                              <input {...editMerchantBranddata("brand_name", { required: 'Please Enter Store Name', pattern: { value: /^[-a-zA-Z ]+$/, message: 'Please Enter Valid Store Name' } })} className="form-control-sm form-control" placeholder="Store Name" id="brandName_edit" />
                              {errors2.brand_name && <span className="text-danger d-block error">{errors2.brand_name.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm={12}>
                            <FormGroup>
                              <Label for="websiteUrl_edit">Enter Website URL<span className="text-danger">*</span></Label>
                              <input {...editMerchantBranddata("brand_website", {
                                required: 'Please Enter Store Website',
                                pattern: { value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/, message: 'Please Enter Valid Website URL' }
                              })} className="form-control-sm form-control" placeholder="Store Website" id="websiteUrl_edit" />
                              {errors2.brand_website && <span className="text-danger d-block error">{errors2.brand_website.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col className='d-none' sm={12}>
                            <FormGroup>
                              <Label for="uploadLogo_edit">Upload Logo</Label>
                              <input {...editMerchantBranddata("brandLogo")} type="file" onChange={handleFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadLogo_edit" />
                              {editMerchantBrands.logo !== '' && editMerchantBrands.logo !== undefined && editMerchantBrands.logo !== null && <div className="edit-brand-logo mt-2"><img src={process.env.REACT_APP_IMAGE_MERCHANT + editMerchantBrands.logo} alt="logo" style={{ width: "150px" }} /></div>
                              }
                              {errors2.brandLogo && <span className="text-danger d-block error">{errors2.brandLogo.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm={12}>
                            <Col sm={12}>
                              <FormGroup className="mb-0">
                                <Label>Status<span className="text-danger">*</span></Label>
                                <div>
                                  <span className="mr-2">
                                    <input {...editMerchantBranddata("status", { required: 'Please Add Status' })} type="radio" value="1" id="active_add" />
                                    <Label className="mx-2" for="active_add">Enable</Label>
                                  </span>
                                  <span>
                                    <input {...editMerchantBranddata("status", { required: 'Please Add Status' })} type="radio" value="0" id="inactive_add" />
                                    <Label className="mx-2" for="inactive_add">Disabled</Label>
                                  </span>
                                </div>
                                {errors.status && <span className="text-danger d-block error">{errors.status.message}</span>}
                              </FormGroup>
                            </Col>
                          </Col>
                          <Col sm={12} className="text-right">
                            <Button className="ctm-btn btn-sm search" color="primary">Save</Button>
                          </Col>
                        </Row>
                      </Form>
                    }
                  </ModalBody>
                </Modal>
              </CardHeader>
              <Row className="px-3 mb-4">
                <Col sm={12}>
                  <Form onSubmit={handleSubmit(onSubmit)} className="cardFilter">
                    <CardTitle className="page-title">
                      {checkUndeNullBlank(props.user[0]) && props.user[0].user_type === "Super" ? "Manage Merchant Stores" : "Manage Stores"}
                    </CardTitle>
                    <Row className="mx-0 mb-3 mb-sm-0 justify-content-start justify-content-sm-center">
                      <Col sm={6} md={4} lg={3} xl={3}>
                        <FormGroup>
                          <input {...register("brand_name")} type="text" className="form-control-sm form-control" placeholder="Store Name" />
                        </FormGroup>
                      </Col>
                      <Col sm={2} className="btn-container">
                        <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                        <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline onClick={brandPointListing}>Reset</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(brandList) && checkData(brandList.data) && checkUndeNullBlank(brandList.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(brandList.data).length)} of {brandList.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Store Code</th>
                      <th className="text-nowrap">Store Name</th>
                      {/* <th className="text-nowrap">Store Website</th>
                      <th>Logo</th> */}
                      <th>Added</th>
                      {/* <th>Updated</th> */}
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(brandList.data) ? brandList.data.map((item, index) => (
                      <tr>
                        <td>{item.id}</td>
                        <td>{item.brand_name}</td>
                        {/* <td><div className="text-truncate"><Link to={{ pathname: item.brand_website }} target="_blank" >{item.brand_website}</Link></div></td> */}
                        {/* <td><img src={process.env.REACT_APP_IMAGE_MERCHANT + item.logo} alt="blue logo" className="courier-logo" /></td> */}
                        {/* <td className="text-nowrap">{(item.updated_at !== undefined && item.updated_at !== null ? changeIntoDate(item.updated_at) : 'NA')}</td> */}
                        <td className="text-nowrap">{(item.created_at !== undefined && item.created_at !== null ? changeIntoDate(item.created_at) : 'NA')}</td>
                        <td className="text-nowrap"><span className="mr-2"><Status statusUrl={process.env.REACT_APP_MERCHANT_BRANDS} listId={item.id} valStatus={item.status} reListing={brandPointListing} data={{ "brand_id": item.id, "status": item.status === 1 ? 0 : 1 }} /></span>
                          <span><Button outline className="ctm-btn btn-sm ml-2" color="info" onClick={() => toggleEdit(item.id)}>Edit</Button></span>
                        </td>
                      </tr>
                    ))
                      : loadertable ? <tr><td colSpan="7"><LoaderDefault /></td> </tr> :
                        <tr><td colSpan="7"><NodataFound /></td> </tr>}
                  </tbody>
                </Table>
                {checkData(brandList) && checkData(brandList.data) && checkUndeNullBlank(brandList.TotalRows) ?
                  <>
                    {brandList.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

export default MerchantBrands;