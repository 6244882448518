import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Table } from 'reactstrap';
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import axios from 'axios';
import { baseString, data_type_id } from '../../config';
import { changeIntoDateTime, errorHandling } from '../../utlis';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { toast } from 'react-toastify';

const MerchantDownloads = (props) => {
  const [myDownload, setMyDownload] = useState({})
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    setLoader(true);
    myDownloadListing()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const myDownloadListing = () => {
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANTDOWNLOAD_LIST,
      data: { "merchant_id": props.user[0].id },
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setMyDownload(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "reload")
      setLoader(false);
    });
  }

  let history = useHistory();
  const goToPreviousPath = () => {
    if (checkUndeNullBlank(history.location.key)) {
      history.goBack();
    }
  }

  return (
    <div className="app-content content overflow-hidden">
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 merchantDownload shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="mb-0 pb-2 font-weight-bold border-short">Activity Logs</CardTitle>
                {/* <div className='card-header-right d-flex flex-wrap align-items-center ml-auto'>
                  <div onClick={goToPreviousPath} className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back</span></div>
                </div> */}
              </CardHeader>
              <CardBody className="p-0">
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      {/* <th>Sno.</th> */}
                      <th>Activity</th>
                      {/* <th>description</th> */}
                      <th>Start date & time</th>
                      {props.user[0].user_type === "Super" && <th>User</th>}
                      {/* <th>Filter</th> */}
                      <th>Report</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(myDownload.data) ? myDownload.data.map((item, index) => (
                      <tr>
                        {/* <td>{index + 1}</td> */}
                        <td>{data_type_id[item.data_type_id]}</td>
                        {/* <td>{item.description}</td> */}
                        <td>{changeIntoDateTime(item.created_at)}</td>
                        {props.user[0].user_type === "Super" && <td>{item.UserName}</td>}
                        {/* <td>{item.filter}</td> */}
                        <td><a href={process.env.REACT_APP_BASE_URL + item.file_name} download target="_blank" rel="noreferrer">Download</a></td>
                      </tr>
                    ))
                      : loader ? <td colSpan="7"><LoaderDefault /></td> : <td colSpan="6" className="text-center"><NodataFound /></td>}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

export default MerchantDownloads;