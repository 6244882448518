import React, { useState,useEffect } from 'react';
import { Row, Col, Label, Form, FormGroup, CardSubtitle, CardTitle, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from 'axios';
import { errorHandling } from '../../../utlis';
 import { useHistory } from 'react-router-dom';
import { baseString } from '../../../config';
 
const AgrementDetail = (props) => {
    const [Agrementdetail, setAgrementdetail] = useState({});

     
     useEffect(() => {
        let data = { "merchant_id": props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id : '', }
       
        axios({
          method: "post",
          url: `${process.env.REACT_APP_MERCHANT_AGREMENT_SETTINGS_VIEW}`,
          data: data,
          headers: { "token": window.btoa(baseString) }
        }).then((res) => {
          if (res.data !== undefined && res.data.fatal !== true) {
            if (parseInt(res.data.errno) > 0) {
              if (res.data.sqlMessage !== undefined) {
                            toast.error(res.data.sqlMessage,{position:'top-right'})
              }
            } else {
              if (res.data.error === false) {
                console.log(res.data.data[0]);

                if(res.data!=undefined && res.data.data!=undefined && res.data.data[0]!=undefined){
                    setAgrementdetail(res.data.data[0]);
                }
                
              }
            }
          }
        }).catch((err) => {
          errorHandling(err, "reload");
        });

 
      },[])
   
    
    return (
        <div>
          
            <Row className="sub-page-header px-3 border-bottom py-3">
                <Col sm={6}>
                    <CardTitle tag="h4" className=" pb-0 mb-0 card-title h5 text-dark">Agrement Status</CardTitle>
                </Col>
                <Col sm={6} className="text-right">
                    <Button className="btn-md ctm-btn rounded-pill" color="primary">Accept Agrement</Button>
                </Col>
            </Row>
            <Row className="p-4">
                <Col sm={6}>
                    <FormGroup>
                        <CardSubtitle tag="h6" className="mb-2">Agrement Status</CardSubtitle>
                        <Label for="full_name">{(Agrementdetail!=undefined &&  Agrementdetail.status!=undefined && Agrementdetail.status == 1)?'Accepted':'Not Accept' }</Label>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <CardSubtitle tag="h6" className="mb-2">Accepted On</CardSubtitle>
                        <Label for="full_name">{props.user[0].email || 'NAN'}</Label>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <CardSubtitle tag="h6" className="mb-2">Agrement Link</CardSubtitle>
                        <Label for="full_name">{props.user[0].mobile || 'NAN'}</Label>
                    </FormGroup>
                </Col>
                
                {/* <Col sm={12} className="mt-2">
                    <Button className="btn-sm ctm-btn" color="primary">Accept Agrement</Button>
                </Col> */}
            </Row>

        </div>
    );
}

export default AgrementDetail;