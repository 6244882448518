import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Spinner, FormGroup, Form, CardHeader, Label, Button, Table, Collapse, UncontrolledTooltip } from 'reactstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { baseString, order_status } from '../../config';
import { errorHandling, changeIntoDateTime, moreText } from '../../utlis';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { toast } from 'react-toastify';


const Stock = (props) => {
    const [isInward, setIsInward] = useState(true);
    const [isOutward, setIsOutward] = useState(false);
    const [loader, setLoader] = useState(false);
    const [loadertable, setLoadertable] = useState(false);
    const [AWBStock, setAWBStock] = useState([]);
    const [AWBNoList, setAWBNoList] = useState([]);
    const [stockInOutTotal, setStockInOutTotal] = useState({});
    const [pendingTotal, setPendingTotal] = useState({});

    useEffect(() => {
        totalStatus();
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleBeforeUnload = (e) => {
        e.preventDefault();
        const message =
            "Are you sure you want to leave? All provided data will be lost.";
        e.returnValue = message;
        return message;
    };

    const toggleInward = () => {
        setIsInward(true);
        if (isOutward) {
            setIsOutward(false);
        }
    };
    const toggleOutward = () => {
        setIsOutward(true);
        if (isInward) {
            setIsInward(false);
        }
    };

    const totalStatus = () => {
        setLoader(true);
        let data = {};
        if (props.user[0].user_type === "Merchant") {
            data.merchant_id = props.user[0].id
        }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_FOR_MANIFEST_SCAN_COUNT}`,
            data: data,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false);
            if (checkData(res.data.stockInAndStockOut)) {
                setStockInOutTotal(res.data.stockInAndStockOut[0]);
            }
            if (checkData(res.data.pendingOrder)) {
                setPendingTotal(res.data.pendingOrder[0]);
            }
        }).catch((err) => {
            setLoader(false);
            errorHandling(err, "");
        });
    }

    const { register: stockRegister, handleSubmit: stockHandleSubmit, formState: { errors }, reset, setValue } = useForm();
    const onStockSubmit = (data) => {
    }

    const sendAwbNo = (event) => {
        if (event.keyCode === 13) {
            let awb = event.target.value.trim();
            DuplicateAwbNo(awb);
            setValue('awbNumber_inward', '');
            setValue('awbNumber_outward', '');
        }
    }

    const DuplicateAwbNo = (current_awb) => {
        let matchValue = AWBStock.find(o => o.awb_no === current_awb);
        if (matchValue) { } else { getStockList(current_awb) }
    }

    const OneCourierAccept = (courier_name) => {
        if (checkData(AWBStock)) {
            let matchValue = AWBStock.find(o => o.CourierName === courier_name);
            if (matchValue) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    let getStockList = (awbNo) => {


        let data = {}
        if (isInward) {
            data.scan_type = 'inward'
        } else {
            data.scan_type = 'outward'
        }
        let list = awbNo.split(/\r?\n/);
        let awbArray = list.filter((el) => {
            return checkUndeNullBlank(el);
        });

        if (props.user[0].user_type === "Super") {
            data.admin_id = props.user[0].id
        } else {
            data.merchant_id = props.user[0].id
        }

        data.awb_no = awbArray;

        if (awbArray.length > 0) {
            setLoadertable(true);
            axios({
                method: "post",
                url: `${process.env.REACT_APP_FOR_INWARD}`,
                data: data,
                headers: { "token": window.btoa(baseString) }
            }).then((res) => {
                if (res.data.error === false) {
                    // totalStatus();
                    console.log('res.data', res.data)
                    if (OneCourierAccept(res.data.data.orderDetail[0].CourierName)) {
                        setAWBStock((oldArray) => [res.data.data.orderDetail[0], ...oldArray]);
                        setAWBNoList((oldArray) => [awbNo, ...oldArray]);
                    } else {
                        Swal.fire({ icon: 'error', text: "Invalid Courier", title: 'Error' });
                    }
                } else {
                    Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
                }
                setLoadertable(false);
            }).catch((err) => {
                errorHandling(err, "");
                setLoadertable(false);
            });
        }
    }

    const RemoveAWBStockItem = (index) => {
        AWBNoList.splice(index, 1);
        let remove = async (AWBStock) => {
            await AWBStock.splice(index, 1);
            return true;
        }

        let list = remove(AWBStock);
        list.then(function (res) {
            if (res) {
                let tempData = AWBStock;
                let tempNoData = AWBNoList;
                setAWBStock([]);
                setAWBStock(tempData);
                setAWBNoList([]);
                setAWBNoList(tempNoData);
            }
        })
    }

    let getAgeing = (created_at) => {
        let diff = 0;
        let toDayDate = new Date();
        let createDate = new Date(created_at);
        while (createDate <= toDayDate) {
            diff += 1;
            createDate.setDate(createDate.getDate() + 1);
        }
        return diff;
    }

    let orderIds = [];
    checkData(AWBStock) && AWBStock.map((item, index) => {
        orderIds.push(item.id);
        return true;
    });

    const manifestScan = (formData) => {
        let data = {};

        data.scan_type = formData.scan_type;

        if (props.user[0].user_type === "Super") {
            data.admin_id = props.user[0].id
        } else {
            data.merchant_id = props.user[0].id
        }

        data.order_ids = orderIds;
        if (orderIds.length > 0) {
            setLoader(true);
            axios({
                method: "post",
                url: `${process.env.REACT_APP_FOR_MANIFEST_SCAN}`,
                data: data,
                headers: { "token": window.btoa(baseString) }
            }).then((res) => {
                setLoader(false);
                totalStatus();
                if (res.data.error !== true) {
                    Swal.fire({
                        icon: 'success',
                        text: res.data.msg,
                        showCancelButton: true,
                        cancelButtonText: 'Ok',
                        cancelButtonColor: '#7066E0',
                        confirmButtonText: 'Generate Custom Manifest',
                        title: 'Success',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            manifestDownload();
                        }
                    })
                    reset();
                    setAWBStock([]);
                    setAWBNoList([]);
                }
            }).catch((err) => {
                errorHandling(err, "");
                setLoader(false);
            })
        } else {
            Swal.fire({ icon: 'info', text: 'Please First Scan or Enter AWB Number', title: 'Info' });
        }
    }

    const manifestDownload = () => {
        if (checkData(AWBNoList)) {
            manifestDownloadCommon();
        }
    }

    const manifestDownloadCommon = () => {
        setLoader(true);

        let data = {};
        if (props.user[0].user_type === "Super") {
            data.admin_id = props.user[0].id
        } else {
            data.merchant_id = props.user[0].id
        }

        data.awb_nos = AWBNoList;

        axios({
            method: "post",
            url: `${process.env.REACT_APP_DOWNLOAD_MANIFEST}`,
            data: data,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            if (res.data.is_generate === 1) {
                setLoader(false);
                props.downloadNotification('Generating Manifest...', '/my-downloads');
            } else {
                setLoader(false);
                Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
            }
        }).catch((err) => {
            errorHandling(err, "")
            setLoader(false);
        });
    }

    let pendingStock = checkUndeNullBlank(pendingTotal.totalPending) ? pendingTotal.totalPending : 0;
    let totalStockIn = checkUndeNullBlank(stockInOutTotal.TotalIn) ? stockInOutTotal.TotalIn : 0;
    let totalStockOut = checkUndeNullBlank(stockInOutTotal.totalOut) ? stockInOutTotal.totalOut : 0;
    let totalStockInOut = parseInt(totalStockIn) + parseInt(totalStockOut);


    return (
        <div className="app-content content overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}
            <Row>
                <Col sm={12}>
                    <Card className="p-0 my-3 Stock shadow content-wrapper border-0">
                        <Card className="border-0 mb-0">
                            <CardHeader className="page-header d-flex justify-content-between">
                                <CardTitle className="mb-0 page-title">Stock</CardTitle>
                            </CardHeader>
                            <CardBody className="p-0 max-height-stock">
                                <Row className="card-stock-list justify-content-center m-0">
                                    <Col xs={6} sm={3} md={2} className="border-right">
                                        <div className="media">
                                            <div className="media-body">
                                                <h4 className="card-val">{pendingStock}</h4>
                                                <p className="text-muted text-nowrap font-weight-500 title-card">Pending Inward</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} sm={3} md={2} className="border-right">
                                        <div className="media">
                                            <div className="media-body">
                                                <h4 className="card-val">{totalStockIn}</h4>
                                                <p className="text-muted text-nowrap font-weight-500 title-card">Total Inward</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} sm={3} md={2} className="border-right">
                                        <div className="media">
                                            <div className="media-body">
                                                <h4 className="card-val">{totalStockOut}</h4>
                                                <p className="text-muted text-nowrap font-weight-500 title-card">Total Outward</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} sm={3} md={2} className="border-right">
                                        <div className="media">
                                            <div className="media-body">
                                                <h4 className="card-val">{totalStockInOut}</h4>
                                                <p className="text-muted text-nowrap font-weight-500 title-card">Total Stock</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Form onSubmit={stockHandleSubmit(onStockSubmit)}>
                                    <div className="d-flex justify-content-center mt-4 mb-3">
                                        <span className="form-check2 mr-4 border py-1 px-2">
                                            <input type="radio" {...stockRegister("scan_type", { required: 'Please Select Stock Type' })} value="inward" className="mx-2" id="inward" onClick={toggleInward} defaultChecked />
                                            <Label for="inward" className="mb-0">Inward (RTO)</Label>
                                        </span>
                                        <span className="form-check2 border py-1 px-2">
                                            <input type="radio" {...stockRegister("scan_type", { required: 'Please Select Stock Type' })} value="outward" className="mx-2" id="outward" onClick={toggleOutward} />
                                            <Label for="outward" className="mb-0">Outward (Fresh Pickup)</Label>
                                        </span>
                                    </div>
                                    {errors.scan_type && <span className="text-danger d-block error">{errors.scan_type.message}</span>}
                                    <Collapse isOpen={isInward}>
                                        {isInward &&
                                            <Row className="mx-0 mb-3">
                                                <Col xs={8} sm={9} md={9} lg={10}>
                                                    <FormGroup className="mb-0">
                                                        <Label for="awbNumber_inward">Scan or Enter AWB Number</Label>
                                                        <input {...stockRegister("awbNumber_inward", { required: false })} onKeyDown={(e) => sendAwbNo(e)} className="form-control-sm form-control" id="awbNumber_inward" />
                                                        {errors.awbNumber_inward && <span className="text-danger d-block error">{errors.awbNumber_inward.message}</span>}
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={4} sm={3} md={3} lg={2} className="d-flex align-items-end">
                                                    <div className="media">
                                                        <div className="media-body d-sm-flex align-items-center">
                                                            <h4 className="card-val mb-0 mr-2">{AWBStock.length}</h4>
                                                            <p className="text-muted text-nowrap font-weight-500 title-card mb-0">Inward Count</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>}
                                    </Collapse>
                                    <Collapse isOpen={isOutward}>
                                        {isOutward &&
                                            <Row className="mx-0 mb-3">
                                                <Col xs={8} sm={9} md={9} lg={10}>
                                                    <FormGroup className="mb-0">
                                                        <Label for="awbNumber_outward">Scan or Enter AWB Number</Label>
                                                        <input {...stockRegister("awbNumber_outward", { required: false })} onKeyDown={(e) => sendAwbNo(e)} className="form-control-sm form-control" id="awbNumber_outward" />
                                                        {errors.awbNumber_outward && <span className="text-danger d-block error">{errors.awbNumber_outward.message}</span>}
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={4} sm={3} md={3} lg={2} className="d-flex align-items-end">
                                                    <div className="media">
                                                        <div className="media-body d-sm-flex align-items-center">
                                                            <h4 className="card-val mb-0 mr-2">{AWBStock.length}</h4>
                                                            <p className="text-muted text-nowrap font-weight-500 title-card mb-0">Outward Count</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>}
                                    </Collapse>
                                    {checkData(AWBNoList) &&
                                        <CardBody className="d-none">
                                            {AWBNoList.join(", ")}
                                        </CardBody>
                                    }
                                    <Table responsive className="text-center custom-table">
                                        <thead>
                                            <tr>
                                                <th className="text-nowrap">Courier</th>
                                                <th className="text-nowrap">Booking Date</th>
                                                <th className="text-nowrap">AWB Number</th>
                                                <th className="text-nowrap">Status</th>
                                                <th className="text-nowrap">Ageing</th>
                                                <th className="text-nowrap">Product</th>
                                                <th className="text-nowrap">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {checkData(AWBStock) ? AWBStock.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-nowrap">{item.CourierName}</td>
                                                    <td className="text-nowrap">{changeIntoDateTime(item.created_at)}</td>
                                                    <td className="text-nowrap">{item.awb_no}</td>
                                                    <td className="text-nowrap">{order_status[item.order_status_id]}</td>
                                                    <td>{getAgeing(item.created_at)}</td>
                                                    <td>
                                                        <div>
                                                            <div className="w-some" id={`MoreProduct${index}`}>{moreText(item.product_name)}</div>
                                                            <UncontrolledTooltip placement="top" target={`MoreProduct${index}`}>
                                                                {item.product_name}
                                                            </UncontrolledTooltip>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span><Button outline className="ctm-btn btn-sm light ml-2" color="secondary" onClick={() => RemoveAWBStockItem(index)}>Remove </Button></span>
                                                    </td>
                                                </tr>
                                            ))
                                                : loadertable ? <tr><td colSpan="7" className="text-center"><LoaderDefault /></td></tr>
                                                    : <tr><td colSpan="7" className="text-center"><NodataFound /></td></tr>}
                                        </tbody>
                                    </Table>
                                    <CardBody className="pt-0 text-right">
                                        <Button className="ctm-btn btn-sm" color="primary" onClick={stockHandleSubmit(manifestScan)}>Save</Button>
                                    </CardBody>
                                </Form>
                            </CardBody>
                        </Card>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Stock;