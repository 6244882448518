import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Form, CardHeader, Table, Pagination, Spinner, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label, UncontrolledTooltip, Alert, Nav, NavItem, NavLink, Badge, DropdownToggle, DropdownMenu, UncontrolledDropdown, ListGroup, ListGroupItem } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faTimes, faChevronDown, faPlus, faChevronRight, faDownload, faCartPlus, faArrowsAltH, faUser, faTruck, faBox, faLocationArrow, faAngleDown, faCheck, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { DateRangePicker } from 'rsuite';
import classnames from 'classnames';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import axios from 'axios';
import CreateOrder from './CreateOrder';
import PickupPoints from '../Merchants/PickupPoints';
import { MultiSelect, } from "react-multi-select-component";
import StarRatings from 'react-star-ratings';
import { changeIntoDateTime, arrayValueSum } from '../../utlis';
import { channel_order_status, block_merchant_id, service } from '../../config';
import { changeIntoDate, firstDayOftheMonth, copyToClipBoard, moreText, errorHandling, remExtraSpace } from '../../utlis';
import LoaderDefault from '../LoaderDefault';
import { baseString, delivery_type_id, download_delay } from '../../config';
import EditBulkOrders from './EditBulkOrders';
import NodataFound from '../NodataFound';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { toast } from 'react-toastify';
import { ChevronLeft, ChevronRight, Copy } from 'react-feather';
import moment from 'moment';
import EditOrder from '../ChannelIntegration/EditOrder';
import { AllChannelBrand, mergeAccountList } from '../../actions';
import ProcessingOrder from './ProcessingOrder';
import FailedOrders from './FailedOrders';
import ChannelOrder from '../ChannelIntegration/ChannelOrder';
import { getFirstLetter } from '../../utlis';
import TrackOrder from "../Orders/TrackOrder";
import { connect, useDispatch } from 'react-redux';

const UploadOrders = (props) => {

  const [deliveryType, setDeliveryType] = useState([]);
  const [excelFile, setExcelFile] = useState();
  const [excelmodal, setExcelmodal] = useState(false);
  const [allOrder, setAllOrder] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editmodal, setEditmodal] = useState(false);
  const [cancelmodal, setCancelmodal] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [filterDate, setFilterDate] = useState([firstDayOftheMonth(), new Date()]);
  const [searchValue, setSearchValue] = useState(' ');
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), });
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkboxOrderId, setCheckboxOrderId] = useState([]);
  const [checked, setChecked] = useState(false);
  const [merchantId, setMerchantId] = useState('');
  const [overallchecked, setOverallChecked] = useState(false);
  const [changeCourierModal, setChangeCourierModal] = useState(false);
  const [courierList, setCourierList] = useState({});
  const [activeTab, setActiveTab] = useState('singleOrder');

  let [bulkCount, setBulkCount] = useState(0);
  let [processCount, setProcessCount] = useState(0);
  let [failedCount, setFailedCount] = useState(0);

  let ShipmentStartDate = (new URLSearchParams(window.location.search)).get("start_date");
  let ShipmentEndDate = (new URLSearchParams(window.location.search)).get("end_date");

  // //////////////////////////

  const [tableLoader, setTableLoader] = useState(false);
  const [filterDateOverview, setFilterDateOverview] = useState([new Date(), new Date()]);
  const [channelOrder, setChannelOrder] = useState({});
  const [channelSummary, setChannelSummary] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [dataList, setDataList] = useState([]);
  const [allTagsChecked, setAllTagsChecked] = useState(false);
  const [tagData, setTagData] = useState([]);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [shipOrdersModal, setShipOrdersModal] = useState(false);
  const [wghtDmnModal, setWghtDmnModal] = useState(false);
  const [anotherActModal, setAnotherActModal] = useState(false);
  const [linkedMerchant, setLinkedMerchant] = useState({});
  const [overviewModal, setOverviewModal] = useState(false);
  const [pickupLocation, setPickupLocation] = useState({});
  const [blockfeatures, setBlockfeatures] = useState(false);
  const [sideAnim, setSideAnim] = useState(false);
  const [orderTracking, setOrderTracking] = useState({});
  const [lastOrderStatus, setlastOrderStatus] = useState();
  const [statusRecheckOrderId, setStatusRecheckOrderId] = useState([]);
  const [tempClass, setTempClass] = useState(null);

  // /////////////////////////

  const closeTracker = (data) => {
    setSideAnim(!sideAnim)
    setOrderTracking({});
    setTempClass(null);
  }




  const handleTabClick = (tabName) => {
    if(tabName === "channelOrder"){
      channelOrderListing('all');
    }
    setActiveTab(tabName);
  };



  const dropFilterCount = 20
  const history = useHistory();

  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  const changeCourierToggle = () => {
    if (checkboxOrderId.length > 0) {
      setChangeCourierModal(!changeCourierModal);
    } else {
                              toast.error("Select Order",{position:'top-right'})

    }
  }
  const { register: changeCourier, handleSubmit: changeCourierSubmit, formState: { errors: errors5 }, reset: resetChangeCourier } = useForm();
  const onChangeCourierSubmit = data => {
    setLoader(true);
    data.order_ids = checkboxOrderId
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    // axios.post(`${process.env.REACT_APP_CHANGE_COURIER}`, data)
    axios({
      method: "post",
      url: process.env.REACT_APP_CHANGE_COURIER,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.fatal !== true && res.data.error !== true) {
        Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success' }).then(okay => {
          if (okay) {
            // bulkOrderListing();
            onCommonFilter();
            setChangeCourierModal(false);
            resetChangeCourier();
          }
        });
      }
      else {
        Swal.fire({ title: 'Error', text: res.data.msg, icon: 'error', })
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  }
  const selectAll = (value) => {
    setOverallChecked(false);

    let abc = checkData(allOrder.data) && allOrder.data.map((key) => (
      key === false
    ));
    if (value) {
      // checkData(allOrder.data)&&allOrder.data.map((item) => (
      // setCheckboxOrderId(item)
      var opt_order_id = [];
      checkData(allOrder.data) && Object.keys(allOrder.data).map(function (key) {
        let tempObj = allOrder.data[key].id
        opt_order_id.push(tempObj)
        return setCheckboxOrderId(opt_order_id)
      });
      // ));
    } else {
      setCheckboxOrderId([])
    }

    setChecked(abc);
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const utilityCheckbox in newState) {
        newState[utilityCheckbox] = value;
      }
      return newState;
    });
  };

  const selectOverAll = () => {
    setOverallChecked(true);
    // setCheckboxOrderId([]);
  }

  const toggleCheck = (utilityCheckbox, event, Id) => {
    setOverallChecked(false);
    var opt_order_id = checkboxOrderId;

    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[utilityCheckbox] = !prevState[utilityCheckbox];
      return newState;
    });
    if (event.target.checked) {
      opt_order_id.push(Id)
      setCheckboxOrderId(opt_order_id)
    } else {
      function arrayRemove(arr, value) {
        return arr.filter(function (ele) {
          return ele !== value;
        });
      }
      var result = arrayRemove(checkboxOrderId, Id);
      setCheckboxOrderId(result)
    };
  }


  let dateRangeRef = dateRange => dateRangeRef = dateRange;

  const toggleEditClose = () => setEditmodal(false);
  const toggleEdit = (productId, merchantId) => {
    setMerchantId(merchantId)
    let data = {}
    data = { "order_id": productId, "merchant_id": merchantId, "is_edit": 1 }
    setEditmodal(!editmodal);
    // axios.post(process.env.REACT_APP_ORDER_DETAILS, data)
    axios({
      method: "post",
      url: process.env.REACT_APP_ORDER_DETAILS,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false)
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          setOrderDetail(res.data);
        }
      }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err, "");
    })
  }

  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    orderReset();
    if (!excelmodal) { setExcelFile(""); }
  }


  const cancelmodalToggle = () => {
    if (checkboxOrderId.length > 0) {
      setCancelmodal(!cancelmodal);
    } else {
                              toast.error("Select Order",{position:'top-right'})

    }
  }

  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0])
    //orders count listing

  }
  const orderCountListing = () => {
    let data = {}
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ORDER_COUNT}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data.error === false) {
        let myResponse = res.data.data
        if (checkData(myResponse)) {
          myResponse.map((item) => {
            let uploadCount = (checkUndeNullBlank(item.order_status_id) && (parseInt(item.order_status_id) === 1)) ? parseInt(item.total) : 0
            let processingCount = (checkUndeNullBlank(item.order_status_id) && (parseInt(item.order_status_id) === 25)) ? item.total : 0
            let failedCount = checkUndeNullBlank(item.order_status_id) && parseInt(item.order_status_id) === 3 ? item.total : 0
            if (parseInt(uploadCount) > 0) {
              setBulkCount(uploadCount);
            }
            if (parseInt(processingCount) > 0) {
              setProcessCount(processingCount);
            }
            if (parseInt(failedCount) > 0) {
              setFailedCount(failedCount);
            }
            return true
          });
        }
      } else {
        Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
      }
    }).catch((err) => {
      errorHandling(err, "reload");
    })
  }
  useEffect(() => {
    setLoadertable(true)
    bulkOrderListing();
    couurierListing();
    orderCountListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //listing bulk Order
  const bulkOrderListing = () => {
    setFilterDate([firstDayOftheMonth(), new Date()]);
    let data = { "order_status_id": 1, "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), "end_date": moment().format('YYYY-MM-DD') }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setFilterInput({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), })
    reset(); setDeliveryType([]); setSearchValue(' ');
    setOverallChecked(false);
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);

    // axios.post(process.env.REACT_APP_ALL_ORDER, data)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_ORDER}?page=${page}&per_page=${selectperpage}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false)
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          setAllOrder(res.data);
        }
      }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err, "reload");
    })
  }

  const { register: uploadOrderdata, handleSubmit: orderUploadSubmit, formState: { errors: errors3 }, reset: orderReset } = useForm();
  const onUploadOrderSubmit = (data) => {
     
    setLoader(true);
    let formData = new FormData();
    formData.append("file", excelFile);
    formData.append("merchant_id", checkData(props.user) ? props.user[0].id : '');
    formData.append("admin_id", process.env.REACT_APP_ADMIN_ID);
    // axios.post(`${process.env.REACT_APP_UPLOAD_ORDER}`, formData)
    var posturl = process.env.REACT_APP_UPLOAD_ORDER;

    if (props.user[0].user_type === "Super") {
      posturl = process.env.REACT_APP_ADMIN_UPLOAD_ORDER;

    }
    console.log('posturl',posturl,'type',props.user[0].user_type);

    axios({
      method: "post",
      url: posturl,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      let errorFile = '';
      if (res.data.errorfile !== undefined) {
        errorFile = checkUndeNullBlank(res.data.errorfile) ? process.env.REACT_APP_BASE_URL + res.data.errorfile : '';
      }
      setExcelFile(); orderReset();
      setExcelmodal(false);
      if (res.data.error === false) {

        toast.success(<div dangerouslySetInnerHTML={{ __html: ` UPLOADED !! <br/><Table className="table w-100 text-left"><tbody><tr><th>Total</th><td>${res.data.torder}</td></tr><tr><th>Uploaded</th><td>${res.data.sorder}</td></tr><tr><th>Error</th><td>${res.data.forder}</td></tr>${errorFile !== '' ? `<tr><th>Failed File</th><td><a href=${errorFile} target="_blank" rel="noopener noreferrer">Download File</a></td></tr>` : ''}</tbody></Table></span>`}} />, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          closeButton: true,
          style: { width: "400px" } // Set the width as needed

        });
  
      } else {

        toast.error(<div dangerouslySetInnerHTML={{ __html: ` ERROR !! <Table className="table w-100 text-left"><tbody><tr><th>Uploaded</th><td>${res.data.torder}</td></tr><tr><th>Success</th><td>${res.data.sorder}</td></tr><tr><th>Error</th><td>${res.data.forder}</td></tr>${errorFile !== '' ? `<tr><th>File  </th><td><a href=${errorFile} target="_blank" rel="noopener noreferrer">Download</a></td></tr>` : ''}</tbody></Table></span>`}} />, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 15000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          closeButton: true,
          style: { width: "400px" } // Set the width as needed

        });

        // Swal.fire({
        //   title: 'Error',
        //   text: res.data.msg,
        //   html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
        //   icon: 'error',
        //   allowOutsideClick: false,
        //   allowEscapeKey: false
        // }).then(okay => {
        //   if (okay) {
        //     bulkOrderListing();
        //   }
        // });

      }
    })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      });
  };
  const placeBulkOrder = () => {
    let data = { "order_ids": checkboxOrderId }
    var posturl = process.env.REACT_APP_PLACE_BULK_ORDER;
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id

    } else {
      data.merchant_id = props.user[0].id
      data.merchant_wallet_balance = props.user[0].wallet_balance

    }
    if (checkboxOrderId.length > 0) {
      setLoader(true);

      // axios.post(process.env.REACT_APP_PLACE_BULK_ORDER, data)
      axios({
        method: "post",
        url: posturl,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        setLoader(false);
        if (res.data.error !== true && res.data.fatal !== true) {
          Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success', allowOutsideClick: false, allowEscapeKey: false }).then(okay => {
            if (okay) {
              history.push("/orders?o=processing");
            }
          });
        } else {
          Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
        }
      }).catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      })
    } else {
                              toast.error("Select Order",{position:'top-right'})

    }
  }
  const exportShipment = () => {
    if (checkData(filterInput)) {
      let data = filterInput
      if (props.user[0].user_type === "Super") {
        data.admin_id = props.user[0].id
      } else {
        data.merchant_id = props.user[0].id
      }
      data.order_status_id = 1
      data.is_export = 1
      setLoader(true);
      // axios.post(process.env.REACT_APP_ALL_ORDER, data)
      axios({
        method: "post",
        url: process.env.REACT_APP_ALL_ORDER,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
          if (parseInt(res.data.errno) > 0) {
            setLoader(false);
            if (res.data.sqlMessage !== undefined) {
                          toast.error(res.data.sqlMessage,{position:'top-right'})
            }
          } else {
            if (res.data.is_export === 1) {
              setTimeout(function () {
                setLoader(false);
                history.push("/my-downloads");
              }, download_delay);
            }
            // setAllShipment(res.data);
          }
        }
      }).catch((err) => {
        setLoader(false)
        errorHandling(err, "");
      });
    } else {
      Swal.fire({ icon: 'error', title: 'Error', text: 'Select Your Filtering Preference' })
    }
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(allOrder.data) && checkUndeNullBlank(allOrder.totalPage)) {
      // &&checkUndeNullBlank(allOrder.totalPage).length>page.length
      if (allOrder.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(allOrder.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(allOrder.data)
      if (currentCount > Object.entries(allOrder.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { register: filter, handleSubmit: filterOrderSubmit, watch: filterWatch, reset } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onFilterOrder = (data, pagePerpage) => {
    pagePerpage.page = 1;
    if ((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(data.delivery_type_id) || checkData(filterDate)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      toast.info('Select Your Filtering Preference');
    }
  };

  const callOnFilterOrder = () => {
    onFilterOrder(filterInput, { "page": "" });
  }

  const couurierListing = () => {
    if (checkData(props.user) && props.user[0].user_type === "Merchant") {
      setLoader(true)
      axios({
        method: "post",
        url: process.env.REACT_APP_MERCHANT_COURIER_LIST,
        data: { "merchant_id": checkData(props.user) && props.user[0].id },
        headers: { "token": window.btoa(baseString) }
      }).then(function (response) {
        setLoader(false)
        if (response.data.error === false) {
          setCourierList(response.data.data.courierList)
        } else {
          Swal.fire({ title: 'Error', text: response.data.msg ? response.data.msg : 'Something went wrong', icon: 'error' });
        }

      }).catch(function (err) {
        setLoader(false);
        errorHandling(err, "reload")
      })
    }
  }

  const onCommonFilter = (data, pagePerpage) => {
    setOverallChecked(false);
    setCheckedAll(false);
    setChecked(false);
    setCheckboxOrderId([]);
    let byData = {}
    let tmpDeliveryType = []


    deliveryType.map((item) => (
      tmpDeliveryType.push(item.value)
    ));

    setLoader(true)
    if (data === undefined) {
      data = filterInput
    }
    byData = { "order_status_id": 1, "search_field": checkUndeNullBlank(data.search_field) ? data.search_field : '', "search_value": checkUndeNullBlank(data.search_value) ? remExtraSpace(data.search_value) : '', "delivery_type_id": checkUndeNullBlank(data.delivery_type_id) ? data.delivery_type_id : '', "start_date": checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '', "end_date": checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '' }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_ORDER}?page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setAllOrder(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  };


  //cancel Order
  const { register: cancelOrder, handleSubmit: cancelOrderSubmit, formState: { errors: errors4 }, reset: resetCancelled } = useForm();
  const onCancelOrderSubmit = data => {
    setLoader(true);
    data.order_ids = checkboxOrderId
    // axios.post(process.env.REACT_APP_CANCEL_ORDER, data)
    axios({
      method: "post",
      url: process.env.REACT_APP_CANCEL_ORDER,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false)
      if (res.data.sqlMessage !== undefined) {
                    toast.error(res.data.sqlMessage,{position:'top-right'})
      }
      if (res.data.error === false) {
        Swal.fire({ icon: 'success', title: 'Success', text: res.data.msg })
        setCancelmodal(false);
        resetCancelled();
        setCheckboxOrderId([]);
        // bulkOrderListing();
        onCommonFilter();
        setCheckedAll(false);
        setChecked(false);
      } else {
        Swal.fire({ icon: 'error', title: 'Error', text: res.data.msg })
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err, "");
    });
  }

  // copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }


  // //////////////////////////


  const orderTrack = (awbNo, lastStatus) => {
    setLoader(true);
    setlastOrderStatus(lastStatus)
    setStatusRecheckOrderId(awbNo)
    let data = { "awb_no": awbNo }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_TRACK_ORDER}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setOrderTracking(res.data.data)
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "")
      setLoader(false);
    });
  }

  const orderData = [
    { id: 1, value: "Consignee Name", isChecked: false },
    { id: 2, value: "Store Name", isChecked: false },
    { id: 3, value: "Warehouse", isChecked: false },
    { id: 4, value: "Upload Date", isChecked: false }
  ];
  const [tagChecked, setTagChecked] = useState(orderData.map((index, key) => (
    key === false
  )));

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
    if (props.user[0].user_type === "Merchant") {
     
      //channelOrderListing(tab);   
      history.push(`/channel-orders?o=${tab}`)
    }
  }

  const clearAll = (value) => {
    setAllTagsChecked(false);
    setTagChecked(false);
  };
  const ApplyTagFIlter = () => {
    // console.log('checked',checked)
  }



  const toggleTagsCheck = (event, utilityCheckbox, dataId, dataValue) => {
    var opt_tag_id = tagData;
    setTagChecked((prevState) => {
      const newState = { ...prevState };
      newState[utilityCheckbox] = !prevState[utilityCheckbox];
      return newState;
    });
    if (event.target.checked) {
      opt_tag_id.push(dataId)
      setTagData(opt_tag_id)
    } else {
      function arrayRemove(arr, dataId) {
        return arr.filter(function (ele) {
          return ele !== dataId;
        });
      }
      var result = arrayRemove(tagData, dataId);
      setTagData(result)
    };
  };
  const tagToggle = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const resetForm = () => {
    channelOrderListing(activeTab);
  }
  const [allBrand, setAllBrand] = useState([]);
  const allBrandMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Store"
  }
  const dispatch = useDispatch();

  var opt_allBrand = [];
  if (checkData(props.state) && checkData(props.state.channelBrandData) && checkData(props.state.channelBrandData.brands)) {
    Object.keys(props.state.channelBrandData.brands).map(function (key) {
      let tempObj = { label: props.state.channelBrandData.brands[key].brandName, value: props.state.channelBrandData.brands[key].brand_id }
      return opt_allBrand.push(tempObj)
    });
  }
  const [leadStatus, setLeadStatus] = useState([]);
  const allLeadStatusMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Lead Status"
  }
  var opt_allLeadStatus = [];

  for (const property in channel_order_status) {
    let tempObj = { label: `${channel_order_status[property]}`, value: property };
    opt_allLeadStatus.push(tempObj);
  }

  const [allTags, setAllTags] = useState([]);
  const allTagsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "System Tags"
  }
  var opt_allTags = [
    { label: 'Duplicate Mobile', value: 'Duplicate Mobile' },
    { label: 'Short Address', value: 'Short Address' },
    { label: 'Duplicate Email', value: 'Duplicate Email' },
    { label: 'Duplicate IP', value: 'Duplicate IP' },
    { label: 'Cancellation Requested', value: 'Cancellation Requested' },
    { label: 'Invalid Mobile', value: 'Invalid Mobile' },
    { label: 'Multiple Order', value: 'Multiple Order' },
    { label: 'Address Corrected', value: 'Address Corrected' },
    { label: 'Address Updated', value: 'Address Updated' },
  ];

  const [custRating, setCustRating] = useState([]);
  const custRatingMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Customer Rating"
  }
  var opt_custRaing = [
    { label: '1 ★', value: 1 },
    { label: '2 ★', value: 2 },
    { label: '3 ★', value: 3 },
    { label: '4 ★', value: 4 },
    { label: '5 ★', value: 5 },
  ];

  const channelOrderSummary = (type) => {
    setLoader(true);
    // let data = {}
    let data = { "start_date": checkData(filterDateOverview) ? moment(filterDateOverview[0]).format('YYYY-MM-DD') : '', "end_date": checkData(filterDateOverview) ? moment(filterDateOverview[1]).format('YYYY-MM-DD') : '' }
    if (type === 'reset') {
      data.start_date = moment().format('YYYY-MM-DD');
      data.end_date = moment().format('YYYY-MM-DD');
      setFilterDateOverview([new Date(), new Date()]);
    }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_ORDER_SUMMARY}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false)
      if (res.data.error === false) {
        setChannelSummary(res.data);
      } else {
        Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err, "reload");
    })
  }
  const channelOrderListing = (order_status_id) => {
    setFilterInput({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment().format('YYYY-MM-DD'), });
    let odr_status_id = "";

    if (order_status_id === "in-process") {
      odr_status_id = "1";
    } else if (order_status_id === "cancelled") {
      odr_status_id = "2";
    } else if (order_status_id === "shipped") {
      odr_status_id = "3";
    } else if (order_status_id === "all") {
      odr_status_id = "";
    }
    resetFilter();
    setAllBrand([]);
    setAllTags([]);
    setCustRating([]);

    if (checkUndeNullBlank(ShipmentStartDate) && checkUndeNullBlank(ShipmentEndDate)) {
      setFilterDate([ShipmentStartDate, ShipmentEndDate]);
    } else {
      setFilterDate([new Date(), new Date()]);
    }
    setOverallChecked(false);
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    setCurrentCount(1);
    setPage(1)
    setTableLoader(true);
    // let data = {"order_status_id": order_status_id !== undefined && order_status_id !== '4' ? order_status_id :  order_status_id !== undefined && order_status_id === '4' ? '' : '1',"start_date":changeIntoDateYmd(firstDayOftheMonth()),"end_date":changeIntoDateYmd(new Date())}
    // let data = {"order_status_id": order_status_id !== undefined && order_status_id !== '4' ? order_status_id :  order_status_id !== undefined && order_status_id === '4' ? '' : '1'}
    let data = { "order_status_id": order_status_id !== undefined && odr_status_id }

    if (checkUndeNullBlank(ShipmentStartDate) && checkUndeNullBlank(ShipmentEndDate)) {
      data.start_date = ShipmentStartDate;
      data.end_date = ShipmentEndDate;
    } else {
      data.start_date = moment().format('YYYY-MM-DD');
      data.end_date = moment().format('YYYY-MM-DD');
    }

    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_ORDER}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setTableLoader(false)
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          setChannelOrder(res.data);
        }
      }
    }).catch((err) => {
      setTableLoader(false)
      errorHandling(err, "reload");
    })
  }
  const allChannelFilter = () => {
    let mypost = {}
    let myget = {}
    if (checkData(props.user)) {
      if (props.user[0].user_type === "Super") {
        mypost = { "admin_id": props.user[0].id }
      } else {
        mypost = { "merchant_id": props.user[0].id, }
      }
    }
    dispatch(AllChannelBrand(mypost, myget)).then((response) => {
    }).catch(function (response) {
      errorHandling(response, "");
    });
  }
  function checkMerchant(mid) {
    if (mid === parseInt(props.user[0].id)) {
      setBlockfeatures(true)
    }
    return
  }

  useEffect(() => {
    block_merchant_id.filter(checkMerchant)
    // if (orderTab === 'all') {
    //   setActiveTab('all')
    // }
    couurierListing();

    setDataList(orderData);
    let allChecked = true;
    for (const utilityCheckbox in tagChecked) {
      if (tagChecked[utilityCheckbox] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setAllTagsChecked(true);
    } else {
      setAllTagsChecked(false);
    }
    if (checkData(props.state) && checkData(props.state.channelBrandData)) {
    } else {
      if (checkData(props.user)) {
        allChannelFilter();
      }
    }
    setSelectperpage(20);
    setLoader(false);
    if (checkData(props.user) && props.user[0].user_type === "Merchant") {
      pickupLocationList();
      // if (orderTab !== null) {
      //   history.push(`/channel-orders?o=${orderTab}`)
      //   channelOrderListing(orderTab);
      //   setActiveTab(orderTab)
      // } else {
      //   history.push(`/channel-orders?o=${activeTab}`)
      //   channelOrderListing(activeTab);
      // }
    } else {
      setActiveTab('all');
    }

    if (checkData(props.user) && props.user[0].user_type === "Merchant") {
      LinkedMerchantL();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagChecked])

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setTableLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(channelOrder.data) && checkUndeNullBlank(channelOrder.totalPage)) {
      // &&checkUndeNullBlank(channelOrder.totalPage).length>page.length
      if (channelOrder.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(channelOrder.data).length)
        setPage(page + 1)
        setTableLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(channelOrder.data)
      if (currentCount > Object.entries(channelOrder.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { handleSubmit: filterChannelOrderSubmit, reset: resetFilter } = useForm();

  const onFilterchannelOrder = (data, pagePerpage) => {
    // setIsExport(true);
    pagePerpage.page = 1
    if ((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkUndeNullBlank(data.weight) || checkData(data.is_verify) || checkData(data.is_user_tag) || checkData(data.delivery_type_id) || checkData(leadStatus) || checkData(data.merchant_tags) || checkData(allBrand) || checkData(allTags) || checkData(filterDate) || checkData(custRating)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      toast.info('Select Your Filtering Preference');
    }
  };

  const exportOrders = () => {
    if (checkData(filterInput)) {
      let data = filterInput
      if (props.user[0].user_type === "Super") {
        data.admin_id = props.user[0].id
      } else {
        data.merchant_id = props.user[0].id
      }
      data.is_export = 1
      setLoader(true);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_CHANNEL_ORDER}`,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
          if (parseInt(res.data.errno) > 0) {
            setLoader(false);
            if (res.data.sqlMessage !== undefined) {
                          toast.error(res.data.sqlMessage,{position:'top-right'})
            }
          } else {
            if (res.data.is_export === 1) {
              setTimeout(function () {
                setLoader(false);
                history.push("/my-downloads");
              }, download_delay);
            }
          }
        }
      }).catch((err) => {
        setLoader(false)
        errorHandling(err, "");
      });
    } else {
      Swal.fire({ icon: 'error', title: 'Error', text: 'Select Your Filtering Preference' })
    }
  }
  const toggleChangeStatus = () => {
    if (checkboxOrderId.length > 0) {
      setChangeStatusModal(!changeStatusModal);
    } else {
                              toast.error("Select Order",{position:'top-right'})

    }
  }
  const { register: changeStatus, handleSubmit: changeStatusSubmit, reset: reset2 } = useForm();
  const onChangeStatus = data => {
    data.order_ids = checkboxOrderId
    data.merchant_name = checkData(props.user) && props.user[0].name
    data.merchant_id = checkData(props.user) && props.user[0].id
    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_ORDER_UPDATE_STATUS}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success' }).then(okay => {
          if (okay) {
            reset2();
            setChangeStatusModal(false);
            setCheckboxOrderId([]);
            setCheckedAll(false);
            setChecked(false);
            if (checkData(filterInput)) {
              onCommonFilter();
            } else {
              channelOrderListing();
            }
          }
        });

      } else {
        Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  }
  const closeChangeStatus = (orderId) => {
    setChangeStatusModal(false);
    reset2();
  }


  const pickupLocationList = () => {
    let mydata = {}
    if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
      mydata = { "merchant_id": props.user[0].id }
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_ACTIVE_PICKUPPOINT}`,
      data: mydata,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          if (res.data.error === false) {
            setPickupLocation(res.data.data);
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "");
    });
  }
  const { register: shipOrders, handleSubmit: shipOrdersSubmit, formState: { errors: errors1 }, setValue } = useForm();

  const toggleShipOrdersClose = () => {
    setShipOrdersModal(false);
  }
  const toggleShipOrders = () => {
    [
      { name: 'service', value: 'Surface' }
    ].forEach(({ name, value }) => setValue(name, value))

    if (checkboxOrderId.length > 0) {
      setShipOrdersModal(!shipOrdersModal);
    } else {
                              toast.error("Select Order",{position:'top-right'})

    }
  }

  const onShipOrders = data => {
    // let data = {"order_ids":checkboxOrderId}
    data.order_ids = checkboxOrderId
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
      data.merchant_wallet_balance = props.user[0].wallet_balance
    }
    if (checkboxOrderId.length > 0) {
      setLoader(true);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_CHANNEL_ORDER_SAVE_SHIP}`,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        setLoader(false);
        if (res.data.error === false) {
          Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success', allowOutsideClick: false, allowEscapeKey: false }).then(okay => {
            if (okay) {
              setShipOrdersModal(false);
              if (checkData(filterInput)) {
                onCommonFilter();
              } else {
                channelOrderListing();
              }
              // if(checkData(failedOrder.data)){
              //   channelOrderListing();
              // }else{
              // history.push("/shipments");
              // }
            }
          });
        } else {
          Swal.fire({
            icon: 'error',
            html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th style="padding: 0.25rem 0;">Order No</th><th style="padding: 0.25rem 0;">Response</th></tr>
              ${res.data.data.map((item, index) => '<tr style="font-size:0.8rem;"><td>' + item.order_no + '</td><td>' + item.msg.map(data => { return data; }) + '</td></tr>')}
              </tbody></Table>`,
            title: 'Error'
          });
        }
      }).catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      })
    } else {
                              toast.error("Select Order",{position:'top-right'})

    }
  }
  const toggleWghtDmn = () => {
    if (checkboxOrderId.length > 0) {
      setWghtDmnModal(!wghtDmnModal);
    } else {
                              toast.error("Select Order",{position:'top-right'})

    }
  }
  const toggleMoveOrder = () => {
    if (checkboxOrderId.length > 0) {
      setAnotherActModal(!anotherActModal);
    } else {
                              toast.error("Select Order",{position:'top-right'})

    }
  }

  const LinkedMerchantL = () => {
    setLoader(true)
    let mypost = {}
    let myget = {}
    mypost.admin_id = process.env.REACT_APP_ADMIN_ID
    mypost.login_merchant_id = props.user[0].id

    dispatch(mergeAccountList(mypost, myget)).then((response) => {
      setLoader(false)
      if (response.error === false) {
        setLinkedMerchant(response.data);
      }
    }).catch(function (response) {
      setLoader(false)
      errorHandling(response, "reload");
    });
  };

  const loginAccount = (id, comp_name, email) => {
    let data = { "merchant_id": id }
    data.admin_id = process.env.REACT_APP_ADMIN_ID
    axios
      .post('', data)
      .then((res) => {
        setLoader(false);
        if (res.data.error === false) {
        }
      })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      });
  };



  const { register: wghtDmn, handleSubmit: WghtDmnSubmit, formState: { errors: errors2 }, reset: resetWeight } = useForm();
  const onWghtDmn = data => {
    console.log(data);
    setLoader(true);
    data.order_ids = checkboxOrderId
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_ORDER_WEIGHT_DIM_UPDATE}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success' });
        resetWeight(); setWghtDmnModal(false); setCheckboxOrderId([]); setCheckedAll(false); setChecked(false); setOverallChecked(false);
      } else {
        Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
      }
    }).catch((err) => {
      setLoader(false);
      console.error(err);
    });
  }

  const toggleOverview = () => {
    setOverviewModal(!overviewModal);
  }





  let totalCod = 0;
  let totalPrepaid = 0;
  let totalCodPrepaid = 0;

  let brandorderSummary = checkData(channelSummary) && checkData(channelSummary.data) && Object.values(channelSummary.data.reduce((osi, item) => {
    let brandId = item.brand_id
    if (!osi[brandId])
      osi[brandId] = { brand_id: brandId, email: item.email, brand_name: item.brand_name, cod: [], prepaid: [] };

    let codcount = checkUndeNullBlank(item.delivery_type_id) && parseInt(item.delivery_type_id) === 1 ? item.total : 0;
    let prepaidcount = checkUndeNullBlank(item.delivery_type_id) && parseInt(item.delivery_type_id) === 3 ? item.total : 0;
    osi[brandId].cod.push(codcount);
    osi[brandId].prepaid.push(prepaidcount);

    totalCod += codcount;
    totalPrepaid += prepaidcount;
    totalCodPrepaid = totalCod + totalPrepaid
    return osi;
  }, {}));

  const getRatingColor = (val) => {
    if (val < 3) return "red";
    else if (val < 4) return "#fd7e14";
    else if (val <= 5) return "#198754";
  }



  const merchantTagDel = (cnl_id, tag, tagType) => {
    Swal.fire({
      html: '<h5 class="mb-0">Do you want to delete this tag?</h5>',
      showCancelButton: true,
      confirmButtonText: 'Save',
      customClass: 'confirm-swal-fortag-con'
    }).then((result) => {
      if (result.isConfirmed) {
        setLoader(true);
        let data = { "channel_order_id": cnl_id, "tag": tag, "tag_type": tagType }
        if (props.user[0].user_type === "Merchant") {
          data.merchant_id = props.user[0].id;

        }
        axios({
          method: "post",
          url: `${process.env.REACT_APP_CHANNEL_REMOVE_TAG}`,
          data: data,
          headers: { "token": window.btoa(baseString) }
        }).then((res) => {
          setLoader(false);
          onCommonFilter(filterInput, { "page": "1" })

        }).catch((err) => {
          setLoader(false);
          errorHandling(err, "");

        })
      }
    })
  }






  /////////////////////////////






  return (
    // <div className="app-content content overflow-hidden">
    <div>
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="shadow border-0 mb-4 p-4">
            <div>
              <ul className="channels-tabs">
                <li
                  className={`channels-tab ${activeTab === 'singleOrder' ? 'active' : ''}`}
                  onClick={() => handleTabClick('singleOrder')}>
                  Book Order
                </li>
                <li
                  className={`channels-tab ${activeTab === 'bulkOrder' ? 'active' : ''}`}
                  onClick={() => handleTabClick('bulkOrder')}>
                  Bulk Order
                </li>
                <li
                  className={`channels-tab ${activeTab === 'allOrder' ? 'active' : ''}`}
                  onClick={() => handleTabClick('allOrder')}>
                  All Orders
                </li>
                <li
                  className={`channels-tab ${activeTab === 'processing' ? 'active' : ''}`}
                  onClick={() => handleTabClick('processing')}>
                  Processing
                </li>
                <li
                  className={`channels-tab ${activeTab === 'failed' ? 'active' : ''}`}
                  onClick={() => handleTabClick('failed')}>
                  Failed
                </li>
                <li
                  className={`channels-tab ${activeTab === 'channelOrder' ? 'active' : ''}`}
                  onClick={() => handleTabClick('channelOrder')}>
                  Channel Order
                </li>
              </ul>
              <div className="channels-tab-content">

                {activeTab === 'channelOrder' &&
                  <>
                     
                      {loader && <div className="formLoader"><Spinner /></div>}
                      {checkData(orderTracking) && <div className="overlay"></div>}
                      <Row>
                        <Col sm={12}>
                          <div className={`${blockfeatures ? 'overlay-0' : ''}`}></div>
                          <Card className="p-0 my-3 channelOrder content-wrapper border-0">
                            <Card className="border-0">
                              
                              <Modal isOpen={changeCourierModal} toggle={changeCourierModal} >
                                <ModalHeader toggle={changeCourierToggle}>Change Carrier</ModalHeader>
                                <ModalBody>
                                  <Form onSubmit={changeCourierSubmit(onChangeCourierSubmit)}>
                                    <Row className="m-0 pb-1">
                                      <Col sm={12}>
                                        <FormGroup className='position-relative'>
                                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                          <select {...changeCourier("courier_id", { required: 'Please Choose Carrier' })} className="form-control-sm form-control border-right-0">
                                            <option value="">Select Carrier</option>
                                            {/* {checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers) && props.state.shipmentMerchantCourier.couriers.map((item) => ( */}
                                            {checkData(courierList) && courierList.map((item) => (
                                              item.status !== 0 &&
                                              <option value={item.courier_id}>{item.courierName}</option>
                                            ))}
                                          </select>
                                          {errors4.courier_id && <span className="text-danger d-block error">{errors4.courier_id.message}</span>}
                                        </FormGroup>
                                      </Col>
                                      <Col sm={12} className="text-right">
                                        <Button className="btn ctm-btn btn-sm" color="primary">Submit</Button>
                                      </Col>
                                    </Row>
                                  </Form>
                                </ModalBody>
                              </Modal>
                              <div className="mx-3 d-none">
                                <Nav tabs className="mb-4 pt-2 border-bottom text-center flex-nowrap">
                                  {props.user[0].user_type === "Merchant" &&
                                    <>
                                      {/* <NavItem className="">
                        <NavLink className={`warning-ctm ${classnames({ active: activeTab === 'in-process' })}`} onClick={() => { toggle('in-process'); }}>
                          <div className="">
                            <span className="tab-icon"><FontAwesomeIcon icon={faSpinner} /></span>
                            <span className="tab-text text-nowrap">In Process</span>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem className="">
                        <NavLink className={`danger-ctm ${classnames({ active: activeTab === 'cancelled' })}`} onClick={() => { toggle('cancelled'); }}>
                          <div>
                            <span className="tab-icon"><FontAwesomeIcon icon={faTimes} /></span>
                            <span className="tab-text text-nowrap">Cancelled</span>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem className="">
                        <NavLink className={`success-ctm ${classnames({ active: activeTab === 'shipped' })}`} onClick={() => { toggle('shipped'); }}>
                          <div>
                            <span className="tab-icon"><FontAwesomeIcon icon={faTruck} /></span>
                            <span className="tab-text text-nowrap">Shipped</span>
                          </div>
                        </NavLink>
                      </NavItem> */}
                                    </>
                                  }
                                  <NavItem>
                                    <NavLink className={classnames({ active: activeTab === 'all' })} onClick={() => { toggle('all'); }}>
                                      <div>
                                        <span className="tab-icon"></span>
                                        <span className="tab-text text-nowrap">All Orders</span>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                              </div>
                              <Row className="px-3 mb-4">
                                <Col sm={12}>
                                  <Form onSubmit={filterChannelOrderSubmit(onFilterchannelOrder)} className="cardFilter">
                                    <CardTitle className="page-title">Channel Orders</CardTitle>
                                    <Row className="mx-0 justify-content-start justify-content-lg-center mb-3 mb-lg-0">
                                      <Col sm={6} md={5} lg={5} xl={4}>
                                        <FormGroup>
                                          <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText className="position-relative p-0">
                                                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                                <select className="form-control-sm form-control rounded-right-0" {...filter("search_field")} onChange={(e) => { if (e.target.selectedOptions[0].text !== "Search By") { setSearchValue("Enter " + e.target.selectedOptions[0].text); } else { setSearchValue('') } }}>
                                                  <option value="customer_name">Consignee Name</option>
                                                  <option value="customer_name">Consignee Pincode</option>
                                                  <option value="customer_mobile">Consignee Mobile</option>
                                                  <option value="order_no">Channel Order No</option>
                                                </select>
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <input type="text" {...filter("search_value")} className="form-control-sm form-control" placeholder={searchValue} />
                                          </InputGroup>
                                        </FormGroup>
                                      </Col>
                                      <Col sm={6} md={3} lg={3} xl={2}>
                                        <FormGroup>
                                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                          <select {...filter("delivery_type_id")} className={`form-control-sm form-control ${checkData(filterWatch("delivery_type_id")) ? 'valid' : ''}`}>
                                            <option value="">Payment Type</option>
                                            {Object.entries(delivery_type_id).map(([key, value]) => (
                                              <option value={key}>{value}</option>
                                            ))}
                                          </select>
                                        </FormGroup>
                                      </Col>
                                      <Col sm={6} md={3} lg={3} xl={2} className='d-none'>
                                        <FormGroup>
                                          <MultiSelect
                                            options={opt_allLeadStatus}
                                            value={leadStatus}
                                            onChange={(e) => setLeadStatus(e)}
                                            labelledBy="All Lead Status"
                                            overrideStrings={allLeadStatusMultiSelectSettings}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col sm={6} md={3} lg={3} xl={2}>
                                        <FormGroup>
                                          <MultiSelect
                                            options={opt_allBrand}
                                            value={allBrand}
                                            onChange={(e) => setAllBrand(e)}
                                            labelledBy="All Store"
                                            overrideStrings={allBrandMultiSelectSettings}
                                            className={checkData(allBrand) && "active"}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col className='d-none' sm={6} md={3} lg={3} xl={2}>
                                        <FormGroup>
                                          <MultiSelect
                                            options={opt_allTags}
                                            value={allTags}
                                            onChange={(e) => setAllTags(e)}
                                            labelledBy="System Tags"
                                            overrideStrings={allTagsMultiSelectSettings}
                                            className={checkData(allTags) && "active"}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col className='d-none' sm={6} md={3} lg={3} xl={2}>
                                        <FormGroup>
                                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                          <select {...filter("is_user_tag")} className={`form-control-sm form-control ${checkData(filterWatch("is_user_tag")) ? 'valid' : ''}`}>
                                            <option value="">User Tag</option>
                                            <option value='1'>Yes</option>
                                            <option value='0'>No</option>
                                          </select>
                                        </FormGroup>
                                      </Col>
                                      <Col className='d-none' sm={6} md={3} lg={3} xl={2}>
                                        <FormGroup>
                                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                          <select {...filter("is_verify")} className={`form-control-sm form-control ${checkData(filterWatch("is_verify")) ? 'valid' : ''}`}>
                                            <option value="">Mobile Verify</option>
                                            <option value='1'>Is Verified</option>
                                            <option value='0'>Not Verified</option>
                                          </select>
                                        </FormGroup>
                                      </Col>
                                      <Col className='d-none' sm={6} md={3} lg={3} xl={2}>
                                        <FormGroup>
                                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                          <select {...filter("weight")} className={`form-control-sm form-control ${checkData(filterWatch("weight")) ? 'valid' : ''}`}>
                                            <option value="">Weight</option>
                                            <option value='0,0.5'>Below 0.5Kg</option>
                                            <option value='0.5,1'>0.5 Kg to 1Kg</option>
                                            <option value='1,2'>1Kg to 2Kg</option>
                                            <option value='2,5'>2Kg to 5Kg</option>
                                            <option value='5,15'>Above 5Kg</option>
                                          </select>
                                        </FormGroup>
                                      </Col>
                                      <Col className='d-none' sm={6} md={3} lg={3} xl={2}>
                                        <FormGroup>
                                          <MultiSelect
                                            options={opt_custRaing}
                                            value={custRating}
                                            onChange={(e) => setCustRating(e)}
                                            labelledBy="Customer Rating"
                                            overrideStrings={custRatingMultiSelectSettings}
                                            className={checkData(custRating) && "active"}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col sm={6} md={4} lg={3} xl={3} className='d-none'>
                                        <FormGroup>
                                          <input {...filter("merchant_tags")} type="text" className="form-control-sm form-control" placeholder="Filter your own tags comma seperated" />
                                        </FormGroup>
                                      </Col>
                                      <Col sm={6} md={4} lg={3} xl={3}>
                                        <FormGroup>
                                          <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} format="dd-MM-yyyy" showOneCalendar={props.oneCalendar} placement="bottomStart" ref={dateRangeRef} change={onDateChange} />
                                        </FormGroup>
                                      </Col>
                                      <Col sm={3} className="btn-container">
                                        <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                                        <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline onClick={resetForm}>Reset</Button>
                                      </Col>
                                      <Col sm={6}>
                                        <div className='card-header-right d-flex flex-wrap align-items-center ml-auto'>
                                          <div className="btn-text btn-light d-none" onClick={() => { toggleOverview(); channelOrderSummary(); }}><span className="icon mr-2"><FontAwesomeIcon icon={faChartBar} /></span><span>Overview</span></div>
                                          {/* <Link to="/sms-log" className="btn-text btn-light"><span className="icon mr-2"><FontAwesomeIcon icon={faComment} /></span><span>SMS Log</span></Link> */}
                                          {!blockfeatures && <div className="btn-text btn-light" onClick={exportOrders}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Export</span></div>}
                                          <UncontrolledDropdown className="d-inline-block">
                                            <DropdownToggle caret className="btn-text btn-light">
                                              Choose Action<span className="ml-1"><FontAwesomeIcon icon={faAngleDown} /></span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                              {/* <div className="dropdown-item" onClick={toggleChangeStatus}>Change Status</div> */}
                                              <div className="dropdown-item" onClick={changeCourierToggle}>Move Carrier</div>
                                              <div className="dropdown-item" onClick={toggleShipOrders}>Ship Orders</div>
                                              {/* <div className="dropdown-item" onClick={toggleWghtDmn}>Upload Weight/Dimension</div> */}
                                              {/* <div className="dropdown-item" onClick={toggleMoveOrder}>Move to Another Account</div> */}
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                          {/* <div className="btn-text btn-light" onClick={toggleChangeStatus}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Choose Action</span></div> */}
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </Col>
                              </Row>
                              <CardBody className="p-0">
                                <div className="showing-count">
                                  {checkData(channelOrder) && checkData(channelOrder.data) && checkUndeNullBlank(channelOrder.TotalRows) ?
                                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(channelOrder.data).length)} of {channelOrder.TotalRows}</span> : ''}
                                </div>
                                <Table responsive className="text-left custom-table channel-table">
                                  <thead>
                                    <tr>
                                      <th><div className="form-check2"><input name="cust-type form-check-input" type="checkbox" id="select_all" checked={checkedAll} onChange={(event) => selectAll(event.target.checked)} value="checkedall" /><label className="form-check-label" for="select_all"></label></div></th>
                                      <th className="text-nowrap">CONSIGNEE  </th>
                                      <th className="text-nowrap">CONSIGNEE Address </th>

                                      <th className="text-nowrap">ORDER </th>
                                      <th className="text-nowrap">PRODUCT</th>
                                       
                                      <th className="text-nowrap">Added</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {checkData(channelOrder.data) ? channelOrder.data.map((item, index) => {
                                      if (item.system_tags[0] === ',') {
                                        item.system_tags = item.system_tags.substring(1);
                                      }
                                      return (
                                        <tr key={index} className={`${tempClass === item.id && "showTop"}`}>
                                          <td><div className="form-check2"><input checked={checked !== undefined && checked[index]} name={'check' + index} type="checkbox" id={item.id} onChange={(event) => toggleCheck(index, event, item.id)} disabled={overallchecked} /><label className="form-check-label" for={item.id}></label></div></td>
                                          <td className="align-middle">
                                            <span className={`${blockfeatures ? 'filter-blr-3' : ''}`}>
                                              {item.customer_name}</span><br />
                                             <span className={`${blockfeatures ? 'filter-blr-3' : ''}`}>{item.customer_mobile} </span>
                                             
                                                 
                                           </td>
                                           <td className='text-left'>
       
                                          <span>{item.customer_address_1}</span>,{"\n"}
                                                    <span>{item.customer_address_2}</span>,{"\n"}
                                                    <span>{item.customer_city}</span>,{"\n"}<br></br>
                                                    <span>{item.customer_state}</span>,{"\n"}
                                                    <span>{item.customer_pincode}</span>,{"\n"}
                                         
                                            </td>
                                          <td className='text-left'>
                                            <div className={`text-nowrap ${blockfeatures ? 'filter-blr-3' : ''}`}><span className="font-weight-500 fsc-4">Channel Order NO. - <br /></span>{item.order_no || 'NA'}</div>
                                            <span className="font-weight-500 fsc-4">Store - <br /></span><span className='text-uppercase text-nowrap'>{item.merchantBrandName || 'NA'}</span><br />
                                            <span className="font-weight-500 fsc-4">Warehouse - <br /></span><span className='text-uppercase text-nowrap'>{item.MerchantPickupPointName || 'NA'}</span>
                                          </td>
                                          <td>
                                            <div className="w-product" id={`productTooltip${item.id}`}>{moreText(item.product_name)}</div>
                                            <UncontrolledTooltip placement="top" target={`productTooltip${item.id}`}>
                                              {item.product_name} * {item.product_qty}
                                            </UncontrolledTooltip>
                                            <span className="font-weight-500 fsc-2">Payment Type -</span> {delivery_type_id[item.delivery_type_id]}{item.product_cod_value > 0 && ' ( ' + item.product_cod_value + ' ) '}<br />
                                            <span className="font-weight-500 fsc-2">DIM | WEIGHT -</span> {item.length_in_cms}*{item.breadth_in_cms}*{item.height_in_cms} | {item.product_weight_in_kgs}
                                          </td>
                                         
                                          <td>
                                            <span className="text-nowrap mr-2">{changeIntoDateTime(item.created_at)}</span>
                                            <hr className='p-0 m-0'></hr>

                                            {/* {checkData(props.user) && props.user[0].user_type === "Merchant" &&
                                              <span><Button className="ctm-btn btn-sm" color="primary" outline onClick={() => toggleEdit(item.id, item.merchant_id)}>Edit</Button></span>
                                            } */}
                                          </td>
                                        </tr>
                                      )
                                    }) :
                                      tableLoader ? <tr><td colSpan="6" className="text-center"><LoaderDefault /></td></tr>
                                        : <tr><td colSpan="6" className="text-center"><NodataFound /></td></tr>
                                    }

                                  </tbody>
                                </Table>

                                {checkData(channelOrder) && checkData(channelOrder.data) && checkUndeNullBlank(channelOrder.TotalRows) ?
                                  <>
                                    {channelOrder.TotalRows > dropFilterCount ?
                                      <Row className="m-0 table-footer">
                                        <Col xs="6">
                                          <Pagination aria-label="Page navigation example align-items-center">
                                            <PaginationItem className="prev">
                                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem className="next ml-3">
                                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                                            </PaginationItem>
                                          </Pagination>
                                        </Col>
                                        <Col xs="6">
                                          <Form>
                                            <FormGroup>
                                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                              </select>
                                            </FormGroup>
                                          </Form>
                                        </Col>
                                      </Row> : ''}
                                  </>
                                  : ''}
                              </CardBody>
                            </Card>
                          </Card>
                        </Col>

                      </Row>
                      {checkData(orderDetail) &&
                        <Modal isOpen={editmodal} toggle={editmodal} scrollable={true} size="xl">
                          <ModalHeader toggle={toggleEditClose}>Edit Details</ModalHeader>
                          <ModalBody>
                            <EditOrder orderDetails={orderDetail} merchantId={merchantId} setEditmodal={setEditmodal} user={props.user} channelOrderListing={channelOrderListing} />
                          </ModalBody>
                        </Modal>
                      }
                      <Modal isOpen={changeStatusModal} toggle={changeStatusModal} scrollable={true} size="md">
                        <ModalHeader toggle={closeChangeStatus}>Change Status</ModalHeader>
                        <ModalBody>
                          <Form onSubmit={changeStatusSubmit(onChangeStatus)} className="">
                            <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-start justify-content-lg-center">
                              <Col sm={12}>
                                <FormGroup>
                                  <Label>Select Status</Label>
                                  <div className="position-relative">
                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                    <select {...changeStatus("order_status_id")} className="form-control-sm form-control">
                                      <option value="">Select Status</option>
                                      {Object.entries(channel_order_status).map(([key, value]) => (
                                        <option value={key}>{value} {value === "Cancel" && "Order"}</option>
                                      ))}
                                    </select>
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col sm={12}>
                                <FormGroup>
                                  <Label>Remarks</Label>
                                  <textarea {...changeStatus("remarks")} className="form-control-sm form-control" placeholder="Enter Remarks" />
                                </FormGroup>
                              </Col>
                              <Col sm={12} className="text-right">
                                <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                              </Col>
                            </Row>
                          </Form>
                        </ModalBody>
                      </Modal>

                      <Modal isOpen={shipOrdersModal} toggle={toggleShipOrders} scrollable={true} size="md">
                        <ModalHeader toggle={toggleShipOrdersClose}>Select Courier/Warehouse</ModalHeader>
                        <ModalBody>
                          <Form onSubmit={shipOrdersSubmit(onShipOrders)} className="cardFilter">
                            <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-start justify-content-lg-center">
                              <Col sm={12}>
                                <FormGroup>
                                  {/* <Label>Select Service<span className="text-danger">*</span></Label> */}
                                  <div className="position-relative">
                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                    <Badge color='success' pill className={`badge-70-text selected-option-badge }`} ><span className="small-check-ctm"><FontAwesomeIcon icon={faCheck} /></span></Badge>
                                    <select {...shipOrders("service", { required: 'Please Select Service' })} className="form-control-sm form-control choose-option">
                                      <option value="">Select Service</option>
                                      {Object.entries(service).map(([key, value]) => (
                                        <option value={value}>{value}</option>
                                      ))}
                                    </select>
                                  </div>
                                  {errors1.service && <span className="text-danger d-block error">{errors1.service.message}</span>}
                                </FormGroup>
                              </Col>
                              <Col sm={6}>
                                <FormGroup>
                                  <Label>Select Carrier </Label>
                                  <div className="position-relative">
                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                    <select {...shipOrders("courier_id")} className="form-control-sm form-control">
                                      <option value="">Select Carrier</option>
                                      {checkData(courierList) && courierList.map((item) => (
                                        item.status !== 0 &&
                                        <option value={item.courier_id}>{item.courierName}</option>
                                      ))}
                                    </select>
                                  </div>
                                  {errors1.courier_id && <span className="text-danger d-block error">{errors1.courier_id.message}</span>}
                                </FormGroup>
                              </Col>
                              <Col sm={6}>
                                <FormGroup>
                                  <Label>Select Warehouse<span className="text-danger">*</span></Label>
                                  <div className="position-relative">
                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                    <Badge color='success' pill className={`badge-70-text selected-option-badge }`} ><span className="small-check-ctm"><FontAwesomeIcon icon={faCheck} /></span></Badge>
                                    <select {...shipOrders("pickup_point_id", { required: 'Please Select Warehouse' })} className="form-control-sm form-control choose-option">
                                      {/* <option value="">Select Warehouset</option> */}
                                      {pickupLocation !== undefined && pickupLocation !== null && Object.entries(pickupLocation).length > 0 ? pickupLocation.map((item, index) => {
                                        [
                                          { name: 'pickup_point_id', value: item.is_default === 1 && item.id }
                                        ].forEach(({ name, value }) => setValue(name, value))
                                        return (
                                          <option data-id={index} value={item.id}>{item.contact_name}</option>
                                        )
                                      }) : ''}
                                    </select>
                                  </div>
                                  {errors1.pickup_point_id && <span className="text-danger d-block error">{errors1.pickup_point_id.message}</span>}
                                </FormGroup>
                              </Col>
                              <Col sm={12} className="text-right">
                                <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                              </Col>
                            </Row>
                          </Form>
                        </ModalBody>
                      </Modal>

                      <Modal isOpen={wghtDmnModal} toggle={toggleWghtDmn} scrollable={true} size="lg">
                        <ModalHeader toggle={toggleWghtDmn}>Upload Weight and Dimenstion</ModalHeader>
                        <ModalBody>
                          <Form onSubmit={WghtDmnSubmit(onWghtDmn)} className="">
                            <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-start justify-content-lg-center">
                              <Col sm={4}>
                                <FormGroup>
                                  <Label>Enter Weight IN KGS.<span className="text-danger">*</span> <small>eg.0.5</small></Label>
                                  <input {...wghtDmn("weight_in_kgs", {
                                    required: 'Please Enter Weight',
                                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Weight' }
                                  })} className="form-control-sm form-control" />
                                  {errors2.weight_in_kgs && <span className="text-danger d-block error">{errors2.weight_in_kgs.message}</span>}
                                </FormGroup>
                              </Col>
                              <Col sm={8}>
                                <FormGroup>
                                  <Label>Dimension<span className="text-danger">*</span></Label>
                                  <div className="d-flex">
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <div className="mx-2"><Label>L</Label></div>
                                        <div><input {...wghtDmn("length_in_cms", {
                                          required: 'Please Enter Length',
                                          pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Length' }
                                        })} className="form-control-sm form-control" /></div>
                                      </div>
                                      {errors2.length_in_cms && <span className="text-danger d-block error">{errors2.length_in_cms.message}</span>}
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <div className="mx-2"><Label>B</Label></div>
                                        <div><input {...wghtDmn("breadth_in_cms", {
                                          required: 'Please Enter Breadth ',
                                          pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Breadth ' }
                                        })} className="form-control-sm form-control" /></div>
                                      </div>
                                      {errors2.breadth_in_cms && <span className="text-danger d-block error">{errors2.breadth_in_cms.message}</span>}
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <div className="mx-2"><Label>H</Label></div>
                                        <div><input {...wghtDmn("height_in_cms", {
                                          required: 'Please Enter Height',
                                          pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Height ' }
                                        })} className="form-control-sm form-control" /></div>
                                      </div>
                                      {errors2.height_in_cms && <span className="text-danger d-block error">{errors2.height_in_cms.message}</span>}
                                    </div>
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col sm={12} className="text-right">
                                <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                              </Col>
                            </Row>
                          </Form>
                        </ModalBody>
                      </Modal>

                      <Modal isOpen={anotherActModal} toggle={toggleMoveOrder} scrollable={true}>
                        <ModalHeader toggle={toggleMoveOrder}>Move To Another Account</ModalHeader>
                        <ModalBody>
                          {checkData(linkedMerchant) ? linkedMerchant.map((item, index) => (
                            <ListGroup className="another-act-list">
                              <ListGroupItem>
                                <div className="d-flex align-items-center">
                                  <div className="avatar d-flex justify-content-center align-items-center position-relative">
                                    <span className="rounded-circle avatar-name">{getFirstLetter(item.company_name)}</span>
                                    <span className={`avatar-status-online ${item.status !== 0 ? "bg-success" : "bg-danger"}`}></span>
                                  </div>
                                  <div style={{ width: "calc(100% - 40px)" }} className="list-item pl-2" onClick={() => { if (item.status === 1) { loginAccount(item.id, item.company_name, item.email); } }}>
                                    <span>{item.company_name}</span><small> ({item.id})</small> <br />
                                    <span>({item.email})</span>
                                  </div>
                                </div>
                              </ListGroupItem>
                            </ListGroup>
                          )) : tableLoader ? <div><LoaderDefault /></div>
                            : <div><NodataFound /></div>
                          }
                        </ModalBody>
                      </Modal>

                      <Modal isOpen={overviewModal} toggle={toggleOverview} scrollable={true} size="lg">
                        <ModalHeader toggle={toggleOverview}>Channel Order Overview</ModalHeader>
                        <ModalBody>
                          <Form className="cardFilter">
                            <Row className="justify-content-start mb-3">
                              <Col sm={6} md={4} lg={3} xl={4}>
                                <FormGroup className='mb-sm-0'>
                                  <DateRangePicker value={filterDateOverview} onChange={date => setFilterDateOverview(date)} placement="bottomStart" showOneCalendar={props.oneCalendar} placeholder="Choose Date Range" format="dd-MM-yyyy" />
                                </FormGroup>
                              </Col>
                              <Col sm={3} className="btn-container">
                                <Button className="btn btn-sm" color="primary" onClick={() => channelOrderSummary()}>Search</Button>
                                <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={() => channelOrderSummary('reset')}>Reset</Button>
                              </Col>
                            </Row>
                          </Form>
                          <div>
                            <Table responsive className="text-center custom-table mb-0">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>COD</th>
                                  <th>Prepaid</th>
                                  <th>Total</th>
                                </tr>
                                <tr>
                                  <th>Total</th>
                                  <th className="text-nowrap">{totalCod}</th>
                                  <th className="text-nowrap">{totalPrepaid}</th>
                                  <th className="text-nowrap">{totalCodPrepaid} </th>
                                </tr>
                              </thead>
                              <tbody>
                                {checkData(brandorderSummary) ? brandorderSummary.map((item, index) => {
                                  let codCount = checkUndeNullBlank(item.cod) ? arrayValueSum(item.cod) : 0;
                                  let prepaidCount = checkUndeNullBlank(item.prepaid) ? arrayValueSum(item.prepaid) : 0;
                                  let total = codCount + prepaidCount
                                  return (
                                    <tr>
                                      <td><strong>{item.brand_name}</strong><br></br><small> {item.email}</small></td>
                                      <td className="text-nowrap">{codCount}</td>
                                      <td className="text-nowrap">{prepaidCount}</td>
                                      <td className="text-nowrap">{total}</td>
                                    </tr>)
                                }) : ''
                                }

                              </tbody>
                            </Table>
                          </div>
                        </ModalBody>
                      </Modal>
                      {checkData(orderTracking) && <TrackOrder orderTracking={orderTracking} toggleTrack={sideAnim ? "show" : "hide"} closeTracker={(e) => { closeTracker(e) }} position="fixed" lastStatus={lastOrderStatus} statusRecheckOrderId={statusRecheckOrderId} user={props.user} />}
                  
                  </>
                }
                {activeTab === 'singleOrder' &&
                  <>
                    <CreateOrder merchantId={props.user[0].id} />
                  </>
                }
                {activeTab === 'processing' &&
                  <>
                    <ProcessingOrder user={props.user} oneCalendar={props.oneCalendar} orderCountListing={orderCountListing} />
                  </>
                }
                {activeTab === 'failed' &&
                  <>
                    <FailedOrders user={props.user} oneCalendar={props.oneCalendar} ShipmentStartDate={ShipmentStartDate} ShipmentEndDate={ShipmentEndDate} />
                  </>
                }

                {activeTab === 'bulkOrder' &&
                  <>
                    <Row>
                      <Col sm={6}>
                        <div className='p-3' >
                          <Form onSubmit={orderUploadSubmit(onUploadOrderSubmit)}>
                          <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Always use XLSX format</p>
                            <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Export below given template</p>
                            <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Check mandatory fields.</p>

                            <Row className="m-0 pb-1">
                              <Col sm={12} className="p-0">
                                <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>
                                  {props.user[0].user_type !== "Merchant" &&
                                    <Link target="_blank" download to="/format/order_for_admin.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Export</Link>
                                  }
                                  {props.user[0].user_type === "Merchant" &&
                                    <Link target="_blank" download to="/format/order.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Export</Link>
                                  }
                                  <span className="ml-2">Order Format File</span></p>
                              </Col>
                              <Col sm={12} className="m-auto pt-1 p-0">
                                <FormGroup>
                                  <input {...uploadOrderdata("excelUpload", {
                                    required: 'Please Upload XLSX File',
                                    validate: {
                                      lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                      acceptedFormats: files =>
                                        ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                          files[0]?.type
                                        ) || 'Only .xslx file allowed',
                                    },
                                  })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                                  <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                                  {errors3.excelUpload && <span className="text-danger d-block error">{errors3.excelUpload.message}</span>}
                                </FormGroup>
                              </Col>
                              <Col sm={12} className="text-right">
                                <Button className="btn ctm-btn btn-sm" color="primary">Upload</Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className='p-3'>
                          <h3 className='NewHeading'> Bulk Upload Instructions </h3>
                          <div className="block-content mt-3" >
                            <ul className="fa-ul mb-0 ml-0">
                              <li className="mb-2 d-flex"><b style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>Step 1:</b> Add warehouse by inputing the pickup details to generate warehouse ID</li>
                              <li className="mb-2 d-flex"><b style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>Step 2:</b> Star marked are mandatory field &amp; Non-Star marked are non mandatory field</li>
                              <li className="mb-0 d-flex"><b style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>Step 3:</b> Fill all the relavent fields reqiuired in the appropiate format and upload the file in csv format</li>
                            </ul>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                }
                {activeTab === "allOrder" &&
                  <>


                    <Row className=''>
                      <Col>
                        <Card className="border-0">
                          <CardHeader className="page-header d-flex justify-content-between flex-wrap d-none">
                            <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">

                              {props.user[0].user_type !== "Merchant" &&
                                <>
                                  <Link to='/create-order' className="btn-text btn-light mr-2"><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Add Single Order</span></Link>
                                  <div className="btn-text btn-light mr-2" onClick={excelUpload}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Upload Orders</span></div>

                                </>
                              }
                              {checkData(allOrder.data) &&
                                <>
                                  <div className="btn-text btn-light mr-2" onClick={cancelmodalToggle}><span className="icon mr-2"><FontAwesomeIcon icon={faTimes} /></span><span>Cancel Orders</span></div>
                                  <div className="btn-text btn-light " onClick={exportShipment}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Export</span></div>
                                </>
                              }
                            </div>
                            <Modal isOpen={changeCourierModal} toggle={changeCourierModal} >
                              <ModalHeader toggle={changeCourierToggle}>Change Carrier</ModalHeader>
                              <ModalBody>
                                <Form onSubmit={changeCourierSubmit(onChangeCourierSubmit)}>
                                  <Row className="m-0 pb-1">
                                    <Col sm={12}>
                                      <FormGroup className='position-relative'>
                                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                        <select {...changeCourier("courier_id", { required: 'Please Choose Carrier' })} className="form-control-sm form-control border-right-0">
                                          <option value="">Select Courier</option>
                                          {/* {checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers) && props.state.shipmentMerchantCourier.couriers.map((item) => (
                                <option value={item.courier_id}>{item.courierName}</option>
                              ))} */}
                                          {checkData(courierList) && courierList.map((item) => (
                                            item.status !== 0 &&
                                            <option value={item.courier_id}>{item.courierName}</option>
                                          ))}
                                        </select>
                                        {errors5.courier_id && <span className="text-danger d-block error">{errors5.courier_id.message}</span>}
                                      </FormGroup>
                                    </Col>
                                    <Col sm={12} className="text-right">
                                      <Button className="btn ctm-btn btn-sm" color="primary">Submit</Button>
                                    </Col>
                                  </Row>
                                </Form>
                              </ModalBody>
                            </Modal>
                            <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                              <ModalHeader toggle={excelUpload}>Upload Bulk Orders</ModalHeader>
                              <ModalBody>
                                <Form onSubmit={orderUploadSubmit(onUploadOrderSubmit)}>
                                <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Always use XLSX format</p>
                                <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Export below given template</p>
                                <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Check mandatory fields.</p>

                                  <Row className="m-0 pb-1">
                                    <Col sm={12} className="p-0">
                                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>
                                        {props.user[0].user_type !== "Merchant" &&
                                          <Link target="_blank" download to="/format/order_for_admin.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Export</Link>
                                        }
                                        {props.user[0].user_type === "Merchant" &&
                                          <Link target="_blank" download to="/format/order.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Export</Link>
                                        }
                                        <span className="ml-2">Order Format File</span></p>
                                    </Col>
                                    <Col sm={12} className="m-auto pt-1 p-0">
                                      <FormGroup>
                                        <input {...uploadOrderdata("excelUpload", {
                                          required: 'Please Upload XLSX File',
                                          validate: {
                                            lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                            acceptedFormats: files =>
                                              ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                                files[0]?.type
                                              ) || 'Only .xslx file allowed',
                                          },
                                        })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                                        <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                                        {errors3.excelUpload && <span className="text-danger d-block error">{errors3.excelUpload.message}</span>}
                                      </FormGroup>
                                    </Col>
                                    <Col sm={12} className="text-right">
                                      <Button className="btn ctm-btn btn-sm" color="primary">Upload</Button>
                                    </Col>
                                  </Row>
                                </Form>
                              </ModalBody>
                            </Modal>

                            <Modal isOpen={cancelmodal} toggle={cancelmodal} scrollable={true} >
                              <ModalHeader toggle={cancelmodalToggle}>Cancel Orders</ModalHeader>
                              <ModalBody>
                                <Form onSubmit={cancelOrderSubmit(onCancelOrderSubmit)}>
                                  <Row className="m-0 pb-1">
                                    <Col sm={12}>
                                      <FormGroup>
                                        <Label for="remaks">Remarks</Label>
                                        <textarea {...cancelOrder("remarks", { required: 'Please Enter Remaks' })} rows="4" className="form-control-sm form-control" placeholder="Remarks" id="remarks" />
                                        {errors4.remarks && <span className="text-danger d-block error">{errors4.remarks.message}</span>}
                                      </FormGroup>
                                    </Col>
                                    <Col sm={12} className="text-right">
                                      <Button className="btn ctm-btn btn-sm" color="primary">Submit</Button>
                                    </Col>
                                  </Row>
                                </Form>
                              </ModalBody>
                            </Modal>
                          </CardHeader>
                          {checkData(orderDetail) &&
                            <Modal isOpen={editmodal} toggle={editmodal} scrollable={true} size="xl">
                              <ModalHeader toggle={toggleEditClose}>Edit Order</ModalHeader>
                              <ModalBody>
                                <EditBulkOrders orderDetails={orderDetail} merchantId={merchantId} setEditmodal={setEditmodal} user={props.user} bulkOrderListing={bulkOrderListing} callOnFilterOrder={callOnFilterOrder} />
                              </ModalBody>
                            </Modal>
                          }
                          <Form onSubmit={filterOrderSubmit(onFilterOrder)} className="cardFilter">
                            <CardTitle className="page-title">All Orders</CardTitle>
                            <Row className="mx-0 justify-content-start justify-content-lg-center">
                              <Col sm={6} md={5} lg={5} xl={4}>
                                <FormGroup>
                                  <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText className="position-relative p-0">
                                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                        <select {...filter("search_field")} className="form-control-sm form-control rounded-right-0" onChange={(e) => { if (e.target.selectedOptions[0].text !== "Search By") { setSearchValue("Enter " + e.target.selectedOptions[0].text) } else { setSearchValue('') } }}>
                                          <option value="customer_name">Consignee Name</option>
                                          <option value="customer_name">Consignee Pincode</option>
                                          <option value="customer_mobile">Consignee Phone</option>
                                          <option value="order_no">Order No</option>
                                          <option value="product_name">Product Name</option>
                                        </select>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <input {...filter("search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue} />
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                              <Col sm={6} md={3} lg={3} xl={2}>
                                <FormGroup>
                                  <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                  <select {...filter("delivery_type_id")} className={`form-control-sm form-control ${checkData(filterWatch("delivery_type_id")) ? 'valid' : ''}`}>
                                    <option value="">Payment Type</option>
                                    {Object.entries(delivery_type_id).map(([key, value]) => (
                                      <option value={key}>{value}</option>
                                    ))}
                                  </select>
                                </FormGroup>
                              </Col>
                              <Col sm={6} md={4} lg={3} xl={3}>
                                <FormGroup>
                                  <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} format="dd-MM-yyyy" placement="bottomEnd" ref={dateRangeRef} change={onDateChange} />
                                </FormGroup>
                              </Col>
                              <Col sm={4} className="btn-container">
                                <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                                <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={bulkOrderListing}>Reset</Button>
                                {checkData(allOrder.data) && checkUndeNullBlank(props.user[0].ship_status) && parseInt(props.user[0].ship_status) === 1 &&
                                 <div className="btn-text btn-light mr-2 mt-2" onClick={placeBulkOrder}><span className="icon mr-2"><FontAwesomeIcon icon={faCartPlus} /></span><span>Place Bulk Orders</span></div>
                               }
                              </Col>
                              
                            </Row>
                          </Form>
                          <CardBody className="p-0">
                            <div className="showing-count">
                              {checkData(allOrder) && checkData(allOrder.data) && checkUndeNullBlank(allOrder.TotalRows) ?
                                <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(allOrder.data).length)} of {allOrder.TotalRows}</span> : ''}
                            </div>
                            {checkboxOrderId.length > 0 && !overallchecked &&
                              <Alert color="secondary" className='small total-select' fade={false}>
                                <span className='mx-1'>Total {checkboxOrderId.length} items on this page are selected.</span>{checkData(allOrder.data) && checkUndeNullBlank(allOrder.TotalRows) && allOrder.TotalRows > selectperpage && checkedAll && <span onClick={selectOverAll} className='cursor-pointer text-primary d-none'>Select all <span className='font-weight-bold'>{checkData(allOrder.data) && checkUndeNullBlank(allOrder.TotalRows) && allOrder.TotalRows}</span> items across all pages</span>}
                              </Alert>
                            }
                            {overallchecked &&
                              <Alert color="secondary" className='small total-select' fade={false}>
                                <span className='mx-1'>Total {checkData(allOrder.data) && checkUndeNullBlank(allOrder.TotalRows) && allOrder.TotalRows} items are Selected.</span>
                              </Alert>
                            }
                            <Table responsive className="text-center custom-table">
                              <thead>
                                <tr>
                                  <th><div className="form-check2"><input name="cust-type" type="checkbox" id="select_all" checked={checkedAll} onChange={(event) => selectAll(event.target.checked)} value="checkedall" /><label className="form-check-label" for="select_all"></label></div></th>
                                  {props.user[0].user_type === "Super" &&
                                    <th className="text-nowrap">Merchant Name</th>
                                  }
                                  <th className="text-nowrap">Warehouse </th>
                                  <th className="text-nowrap">Order No</th>
                                  <th className="text-nowrap">Delivery</th>
                                  <th className="text-nowrap">Consignee Details </th>
                                  <th className="col-2 text-nowrap">Product</th>
                                  <th className="text-nowrap">Added</th>
                                </tr>
                              </thead>
                              <tbody>
                                {checkData(allOrder.data) ? allOrder.data.map((item, index) => (
                                  <tr>
                                    <td><div className="form-check2"><input checked={checked !== undefined && checked[index]} name={'check' + index} type="checkbox" id={item.id} onChange={(event) => toggleCheck(index, event, item.id)} disabled={overallchecked} /><label className="form-check-label" for={item.id}></label></div></td>
                                    {props.user[0].user_type === "Super" &&
                                      <td>{item.merchantName}<br />{item.merchantBrandName}</td>
                                    }
                                    <td>{item.MerchantPickupPointName}</td>
                                    <td><div className="text-nowrap"><Link target="_blank" to={`/order-detail/${item.id}`} from={"a"}>{item.order_no}</Link></div></td>
                                    <td>{delivery_type_id[item.delivery_type_id]}</td>
                                    <td>{item.customer_name}<br />{item.customer_mobile}</td>
                                    <td><div id={`MoreProduct${item.id}`}>{moreText(item.product_name)}</div>
                                      <UncontrolledTooltip placement="top" target={`MoreProduct${item.id}`}>
                                        {item.product_name}
                                      </UncontrolledTooltip>
                                    </td>
                                    <td className="text-nowrap">{(item.created_at !== undefined && item.created_at !== null ? changeIntoDate(item.created_at) : 'NA')} <span><Button className="mx-2 btn-sm" color="primary" outline onClick={() => toggleEdit(item.id, item.merchant_id)}>Edit Detail</Button></span></td>
                                  </tr>
                                )) :
                                  loadertable ? <tr>
                                    <td colSpan="8"><LoaderDefault /></td>
                                  </tr>
                                    : <tr><td colSpan="8" className="text-center"><NodataFound /></td></tr>
                                }
                              </tbody>
                            </Table>

                            {checkData(allOrder) && checkData(allOrder.data) && checkUndeNullBlank(allOrder.TotalRows) ?
                              <>
                                {allOrder.TotalRows > dropFilterCount ?
                                  <Row className="m-0 table-footer">
                                    <Col xs="6">
                                      <Pagination aria-label="Page navigation example align-items-center">
                                        <PaginationItem className="prev">
                                          <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem className="next ml-3">
                                          <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                                        </PaginationItem>
                                      </Pagination>
                                    </Col>
                                    <Col xs="6">
                                      <Form>
                                        <FormGroup>
                                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                          <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="150">150</option>
                                            <option value="250">250</option>
                                            <option value="500">500</option>
                                          </select>
                                        </FormGroup>
                                      </Form>
                                    </Col>
                                  </Row> : ''}
                              </>
                              : ''}
                          </CardBody>

                        </Card>
                      </Col>
                    </Row >



                  </>
                }
              </div>
            </div>
          </Card>
        </Col>
      </Row>
     
         
          {/* {props.user[0].id} */}
          <PickupPoints user={props.user} inherit={'content'} merchantId={props.user[0].id} />
        

    </div >
  );
}
function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}

// export default connect(mapDispatchToProps)(ChannelOrder);
export default connect(mapDispatchToProps)(UploadOrders, ChannelOrder);
