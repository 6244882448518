import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Form, CardHeader, Table, CardSubtitle, CardText, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label, UncontrolledTooltip, Spinner, Alert } from 'reactstrap';
import { DateRangePicker } from 'rsuite';
import { Link, useHistory } from 'react-router-dom';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faChevronDown, faMoneyBillAlt, faArrowUp, faChevronRight, faDownload, faCalculator } from '@fortawesome/free-solid-svg-icons';
import { Copy, ChevronLeft, ChevronRight, Layers } from 'react-feather';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import axios from 'axios';
import { copyToClipBoard, changeIntoDateTime, firstDayOftheMonth, errorHandling, remExtraSpace } from '../../utlis';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { baseString, delivery_type_id, download_delay, zone_category_id } from '../../config';
import moment from 'moment';

const MerchantFreight = (props) => {
  const httpClient = axios.create()
  httpClient.defaults.timeout = 300000;

  const [merchants, setMerchants] = useState([]);
  const [allCourier, setAllCourier] = useState([]);
  const [zoneCategory, setZoneCategory] = useState([]);
  const [excelmodal, setExcelmodal] = useState(false);
  const [zoneCorrmodal, setZoneCorrmodal] = useState(false);
  const [excelFile, setExcelFile] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [merchantFreight, setMerchantFreight] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filterDate, setFilterDate] = useState('');
  const [page, setPage] = useState(1)
  const [currentCount, setCurrentCount] = useState(1);
  const [selectperpage, setSelectperpage] = useState('20');
  const [filterInput, setFilterInput] = useState({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), });
  const dropFilterCount = 20
  const [checkedAll, setCheckedAll] = useState(false);
  const [overallchecked, setOverallChecked] = useState(false);
  const [checkboxOrderId, setCheckboxOrderId] = useState([]);
  const [checked, setChecked] = useState(false);

  const history = useHistory();
  const selectAll = (value) => {
    setOverallChecked(false);
    let abc = checkData(merchantFreight.data) && merchantFreight.data.map((key) => (
      key === false
    ));
    if (value) {
      var opt_order_id = [];
      checkData(merchantFreight.data) && Object.keys(merchantFreight.data).map(function (key) {
        let tempObj = merchantFreight.data[key].order_id
        opt_order_id.push(tempObj)
        return setCheckboxOrderId(opt_order_id)
      });
      // ));
    } else {
      setCheckboxOrderId([]);
    }
    setChecked(abc);
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const utilityCheckbox in newState) {
        newState[utilityCheckbox] = value;
      }
      return newState;
    });
  };

  const selectOverAll = () => {
    setOverallChecked(true);
    // setCheckboxOrderId([]);
  }

  const toggleCheck = (utilityCheckbox, event, Id) => {
    setOverallChecked(false);
    var opt_order_id = checkboxOrderId;
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[utilityCheckbox] = !prevState[utilityCheckbox];
      return newState;
    });
    if (event.target.checked) {
      opt_order_id.push(Id)
      setCheckboxOrderId(opt_order_id)
    } else {
      function arrayRemove(arr, value) {
        return arr.filter(function (ele) {
          return ele !== value;
        });
      }
      var result = arrayRemove(checkboxOrderId, Id);
      setCheckboxOrderId(result)
    };
  };

  // cancel shipment
  const reCalculate = () => {
    if (checkboxOrderId.length > 0) {
      setLoader(true);
      let data = {}
      if (props.user[0].user_type === "Super") {
        data = { "order_ids": checkboxOrderId }
      }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_FREIGHT_RECALC}`,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        setLoader(false);
        setChecked(false);
        setOverallChecked(false);
        setCheckedAll(false);
        setCheckboxOrderId([]);
        if (res.data.error === false) {
          Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success' })
        }
      }).catch((err) => {
        errorHandling(err, "");
        setLoader(false);
      });
    } else {
                              toast.error("Select Order",{position:'top-right'})

    }
  }

  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    resetfreightFile();
    if (!excelmodal) { setExcelFile(); }
  }

  const zoneUpload = () => {
    setZoneCorrmodal(!zoneCorrmodal);
    resetzoneFile();
    if (!zoneCorrmodal) { setExcelFile(); }
  }

  const handleFileInput = (e) => {
    e.preventDefault();
    setExcelFile(e.target.files[0]);
  }
  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  let dateRangeRef = dateRange => dateRangeRef = dateRange;

  const { register: uploadFreight, handleSubmit: freightUploadSubmit, formState: { errors }, reset: resetfreightFile } = useForm();
  const { register: uploadZone, handleSubmit: zoneUploadSubmit, formState: { errors: errors2 }, reset: resetzoneFile } = useForm();



  var opt_merchants = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {}
      if (merchantName && merchantId) {
        tempObj = { label: merchantName, value: merchantId }
        opt_merchants.push(tempObj)
      }
      return true
    });
  }
  const merchantsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }

  const allCourierMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Couriers"
  }
  var opt_allCourier = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)) {
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function (key) {
      let tempObj = { label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }
      return opt_allCourier.push(tempObj)
    });
  }

  const allZoneCategorySettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Zone Category"
  }
  var opt_zoneCategory = [];
  Object.keys(zone_category_id).map(function (key) {
    let tempObj = { label: zone_category_id[key], value: parseInt(key) }
    return opt_zoneCategory.push(tempObj);
  });

  // copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }


  useEffect(() => {
    merchantFreightListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // merchant freight Listing 
  const merchantFreightListing = () => {
    setFilterInput({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), })
    setLoadertable(true);
    setChecked(false);
    setOverallChecked(false);
    setCheckedAll(false);
    setCheckboxOrderId([]);
    reset(); setFilterDate([firstDayOftheMonth(), new Date()]); setMerchants([]); setAllCourier([]); setSearchValue(' ');
    let data = { "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), "end_date": moment().format('YYYY-MM-DD') }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    httpClient.post(`${process.env.REACT_APP_MERCHANT_FREIGHT_LIST}?page=${page}&per_page=${selectperpage}`, data)
      .then((res) => {
        setLoadertable(false);
        if (res.data !== undefined && res.data.fatal !== true) {
          if (parseInt(res.data.errno) > 0) {
            if (res.data.sqlMessage !== undefined) {
                          toast.error(res.data.sqlMessage,{position:'top-right'})
            }
          } else {
            setMerchantFreight(res.data);
          }
        }
      }).catch((err) => {
        setLoadertable(false);
        errorHandling(err, "reload")
      });
  }
  const onFreightSubmit = (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("file", excelFile);
    if (props.user[0].user_type === "Super") {
      formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');
    } else {
      formData.append("merchant_id", checkData(props.user) ? props.user[0].id : '');
      formData.append("admin_id", process.env.REACT_APP_ADMIN_ID);
    }
    // axios
    httpClient.post(`${process.env.REACT_APP_FREIGHT_CORRECTION}`, formData)
      // axios({
      //   method: "post",
      //   url: `${process.env.REACT_APP_ZONE_CORRECTION}`,
      //   data: formData,
      // })
      .then((res) => {
        let errorFile = '';
        if (res.data.errorfile !== undefined) {
          errorFile = checkUndeNullBlank(res.data.errorfile) ? process.env.REACT_APP_BASE_URL + res.data.errorfile : '';
        }
        setLoader(false);
        setExcelFile();
        setExcelmodal(false);
        if (res.data.error === false) {
          Swal.fire({
            title: 'Success', text: res.data.msg, icon: 'success', allowOutsideClick: false, allowEscapeKey: false,
            html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>` : ''}</tbody></Table>`,
          }).then(okay => {
            if (okay) {
              merchantFreightListing();
            }
          });
        } else {
          Swal.fire({
            title: 'Error', text: res.data.msg, icon: 'error', allowOutsideClick: false, allowEscapeKey: false,
            html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
          }).then(okay => {
            if (okay) {
              merchantFreightListing();
            }
          });
        }
      }).catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      });
  }

  const onZoneSubmit = (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("file", excelFile);
    if (props.user[0].user_type === "Super") {
      formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');
    } else {
      formData.append("merchant_id", checkData(props.user) ? props.user[0].id : '');
      formData.append("admin_id", process.env.REACT_APP_ADMIN_ID);
    }
    // axios
    // httpClient.post(`${process.env.REACT_APP_ZONE_CORRECTION}`, formData)
    // axios({
    //   method: "post",
    //   url: `${process.env.REACT_APP_ZONE_CORRECTION}`,
    //   data: formData,
    // })
    axios({
      method: "post",
      url: process.env.REACT_APP_ZONE_CORRECTION,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      let errorFile = '';
      if (res.data.errorfile !== undefined) {
        errorFile = checkUndeNullBlank(res.data.errorfile) ? process.env.REACT_APP_BASE_URL + res.data.errorfile : '';
      }
      setLoader(false);
      setExcelFile();
      setZoneCorrmodal(false);
      if (res.data.error === false) {
        Swal.fire({
          title: 'Success', text: res.data.msg, icon: 'success', allowOutsideClick: false, allowEscapeKey: false,
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>` : ''}</tbody></Table>`,
        }).then(okay => {
          if (okay) {
            merchantFreightListing();
          }
        });
      } else {
        Swal.fire({
          title: 'Error', text: res.data.msg, icon: 'error', allowOutsideClick: false, allowEscapeKey: false,
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
        }).then(okay => {
          if (okay) {
            merchantFreightListing();
          }
        });
      }
    }).catch((err) => {
      setLoader(false);
      errorHandling(err, "");
    });
  }
  const exportShipment = () => {
    if (checkData(filterInput)) {
      let data = filterInput
      if (props.user[0].user_type === "Super") {
        data.admin_id = props.user[0].id
      } else {
        data.merchant_id = props.user[0].id
      }
      data.is_export = 1
      setLoader(true);
      axios({
        method: "post",
        url: process.env.REACT_APP_MERCHANT_FREIGHT_LIST,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
          if (parseInt(res.data.errno) > 0) {
            setLoader(false)
            if (res.data.sqlMessage !== undefined) {
                          toast.error(res.data.sqlMessage,{position:'top-right'})
            }
          } else {
            if (res.data.is_export === 1) {
              setTimeout(function () {
                setLoader(false);
                history.push("/my-downloads");
              }, download_delay);
            }
          }
        }
      }).catch((err) => {
        setLoader(false)
        errorHandling(err, "")
      });
    } else {
      Swal.fire({ icon: 'error', title: 'Error', text: 'Select Your Filtering Preference' })
    }
  }

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoadertable(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(merchantFreight.data) && checkUndeNullBlank(merchantFreight.totalPage)) {
      if (merchantFreight.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(merchantFreight.data).length)
        setPage(page + 1)
        setLoadertable(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(merchantFreight.data)
      if (currentCount > Object.entries(merchantFreight.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }

  const { register: filter, handleSubmit: filterMerchantFreight, reset } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onMerchantFreight = (data, pagePerpage) => {
    pagePerpage.page = 1
    if ((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(allCourier) || checkData(merchants) || checkData(zoneCategory) || checkUndeNullBlank(data.movement_type) || checkUndeNullBlank(filterDate)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      toast.info('Select Your Filtering Preference');
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    setChecked(false);
    setOverallChecked(false);
    setCheckedAll(false);
    setCheckboxOrderId([]);
    let byData = {}
    let courierId = [];
    let merchantsId = [];
    let zoneCategoryId = [];

    if (checkData(allCourier)) {
      allCourier.map((item) => (
        courierId.push(item.value)
      ));
    }
    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }
    if (checkData(zoneCategory)) {
      zoneCategory.map((item) => (
        zoneCategoryId.push(item.value)
      ));
    }

    if (checkUndeNullBlank(courierId)) {
      data.courier_id = courierId
    }
    setLoader(true)
    let sDate = checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '';
    let eDate = checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '';
    byData = { "search_field": checkUndeNullBlank(data.search_field) ? data.search_field : '', "search_value": checkUndeNullBlank(data.search_value) ? remExtraSpace(data.search_value) : '', "courier_id": checkUndeNullBlank(courierId) ? courierId : '', "merchant_ids": checkUndeNullBlank(merchantsId) ? merchantsId : '', "zone_category_id": checkUndeNullBlank(zoneCategoryId) ? zoneCategoryId : '', "start_date": sDate, "end_date": eDate }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_FREIGHT_LIST}?page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setMerchantFreight(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  };


  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 merchantFreight shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                <CardTitle className="page-title">Manage Freight</CardTitle>
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  <div className="btn-text btn-light mx-2" onClick={exportShipment}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Export Freight</span></div>
                  {props.user[0].user_type === "Super" &&
                    <>
                      <div className="btn-text btn-light" onClick={reCalculate} ><span className="icon mr-2"><FontAwesomeIcon icon={faCalculator} /></span><span>Freight Recalculate</span></div>
                      <div className="btn-text btn-light" onClick={excelUpload} ><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Freight Correction</span></div>
                      <div className="btn-text btn-light" onClick={zoneUpload} ><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Zone Correction</span></div>
                    </>
                  }
                </div>
                <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                  <ModalHeader toggle={excelUpload}>Upload Freight Correction</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={freightUploadSubmit(onFreightSubmit)}>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Upload Freight Correction via XLSX</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                      <Row className="m-0 pb-1">
                        <Col sm={12} className="p-0">
                          <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/freight_correction.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download
                          </Link>
                            <span className="ml-2">Update Freight Correction Format File</span></p>
                        </Col>
                        <Col sm={12} className="m-auto pt-1 p-0">
                          <FormGroup>
                            <input {...uploadFreight("excelUpload", {
                              required: 'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                  ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                    files[0]?.type
                                  ) || 'Only .xslx file allowed',
                              },
                            })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" multiple onChange={handleFileInput} />
                            <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                            {errors.excelUpload && <span className="text-danger d-block error">{errors.excelUpload.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="ctm-btn btn-sm" color="primary">Upload</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
                <Modal isOpen={zoneCorrmodal} toggle={zoneCorrmodal} scrollable={true} >
                  <ModalHeader toggle={zoneUpload}>Upload Zone Correction</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={zoneUploadSubmit(onZoneSubmit)}>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Upload Zone Correction via XLSX</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                      <Row className="m-0 pb-1">
                        <Col sm={12} className="p-0">
                          <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/zone_correction.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download
                          </Link>
                            <span className="ml-2">Update Zone Correction Format File</span></p>
                        </Col>
                        <Col sm={12} className="m-auto pt-1 p-0">
                          <FormGroup>
                            <input {...uploadZone("excelUpload", {
                              required: 'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                  ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                    files[0]?.type
                                  ) || 'Only .xslx file allowed',
                              },
                            })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                            <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                            {errors2.excelUpload && <span className="text-danger d-block error">{errors2.excelUpload.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="ctm-btn btn-sm" color="primary">Upload</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </CardHeader>
              <Form onSubmit={filterMerchantFreight(onMerchantFreight)} className="cardFilter">
                <Row className="mx-0 justify-content-start">
                  <Col sm={6} md={5} lg={4} xl={3}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control form-control-sm" onChange={(e) => { if (e.target.selectedOptions[0].text !== "Search By") { setSearchValue("Enter " + e.target.selectedOptions[0].text) } else { setSearchValue('') } }}>
                              <option value="">Search By</option>
                              <option value="awb_number">AWB Number</option>
                              <option value="order_no">Order Number</option>
                              <option value="system_order_no">Reference No.</option>
                            </select>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input {...filter("search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue} />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  {props.user[0].user_type === "Super" &&
                    <Col sm={6} md={4} lg={3} xl={2}>
                      <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect
                            options={opt_merchants}
                            value={merchants}
                            onChange={setMerchants}
                            labelledBy="Select Merchant"
                            overrideStrings={merchantsMultiSelectSettings}
                            className={checkData(merchants) && "active"}
                          />
                        </div>
                      </FormGroup>
                    </Col>}

                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <MultiSelect
                        options={opt_allCourier}
                        value={allCourier}
                        onChange={setAllCourier}
                        labelledBy="All Couriers"
                        overrideStrings={allCourierMultiSelectSettings}
                        className={checkData(allCourier) && "active"}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <MultiSelect
                        options={opt_zoneCategory}
                        value={zoneCategory}
                        onChange={setZoneCategory}
                        labelledBy="Zone Category"
                        overrideStrings={allZoneCategorySettings}
                        className={checkData(zoneCategory) && "active"}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Charge Date" format="dd-MM-yyyy" placement="bottomEnd" showOneCalendar={props.oneCalendar} ref={dateRangeRef} change={onDateChange} />
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline onClick={merchantFreightListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <Row className="mx-0 my-3 justify-content-center">
                <Col sm={6} md={3}>
                  <Card className="border shadow-sm">
                    <CardBody className="d-flex justify-content-between align-items-center">
                      <span className="text-white d-flex align-items-center justify-content-center bg-primary" style={{ width: "50px", height: "50px", fontSize: "25px" }}><FontAwesomeIcon icon={faMoneyBillAlt} /></span>
                      <div>
                        <CardSubtitle tag="h5" className="mb-2">{checkData(merchantFreight.TotalData) && checkUndeNullBlank(merchantFreight.TotalData[0].totalFreight) ? "₹ " + merchantFreight.TotalData[0].totalFreight : "NA"}</CardSubtitle>
                        <CardText>Total Freight (Inc% tax)</CardText>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={6} md={3}>
                  <Card className="border shadow-sm">
                    <CardBody className="d-flex justify-content-between align-items-center">
                      <span className="text-white bg-primary d-flex justify-content-center align-items-center" style={{ width: "50px", height: "50px", fontSize: "25px" }}><Layers style={{ width: "30px", height: "30px" }} /></span>
                      <div>
                        <CardSubtitle tag="h5" className="mb-2">{checkData(merchantFreight.TotalData) && checkUndeNullBlank(merchantFreight.TotalData[0].totalShipments) ? merchantFreight.TotalData[0].totalShipments : "NA"}</CardSubtitle>
                        <CardText>Shipments Count</CardText>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <CardBody className="p-0">
                <div className="">
                  <div className="showing-count">
                    {checkData(merchantFreight) && checkData(merchantFreight.data) && checkUndeNullBlank(merchantFreight.TotalRows) ?
                      <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(merchantFreight.data).length)} of {merchantFreight.TotalRows}</span> : ''}
                  </div>
                  {checkboxOrderId.length > 0 && !overallchecked &&
                    <Alert color="secondary" className='small total-select' fade={false}>
                      <span className='mx-1'>Total {checkboxOrderId.length} items on this page are selected.</span>{checkData(merchantFreight.data) && checkUndeNullBlank(merchantFreight.TotalRows) && merchantFreight.TotalRows > selectperpage && checkedAll && <span onClick={selectOverAll} className='cursor-pointer text-primary d-none'>Select all <span className='font-weight-bold'>{checkData(merchantFreight.data) && checkUndeNullBlank(merchantFreight.TotalRows) && merchantFreight.TotalRows}</span> items across all pages</span>}
                    </Alert>
                  }
                  {overallchecked &&
                    <Alert color="secondary" className='small total-select' fade={false}>
                      <span className='mx-1'>Total {checkData(merchantFreight.data) && checkUndeNullBlank(merchantFreight.TotalRows) && merchantFreight.TotalRows} items are Selected.</span>
                    </Alert>
                  }
                  <Table responsive className="text-center custom-table">
                    <thead>
                      <tr>
                        {props.user[0].user_type === "Super" &&
                          <th><div className="form-check2"><input name="cust-type form-check-input" type="checkbox" id="select_all" checked={checkedAll} onChange={(event) => selectAll(event.target.checked)} value="checkedall" /><label className="form-check-label" for="select_all"></label></div></th>}
                        {props.user[0].user_type === "Super" &&
                          <>
                            <th className="text-nowrap">Merchant</th>
                          </>
                        }
                        <th className="text-nowrap">Order</th>
                        <th className="text-nowrap">Weight <small>(in kgs)</small></th>
                        <th className="text-nowrap">Forword</th>
                        <th className="text-nowrap">RTO</th>
                        <th className="text-nowrap">COD</th>
                        <th className="text-nowrap">GST</th>
                        <th className="text-nowrap">Total</th>
                        <th className="text-nowrap">Zone</th>
                        <th className="text-nowrap">Type</th>
                        <th className="text-nowrap">Payment Type</th>
                        <th className="text-nowrap">Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkData(merchantFreight.data) ? merchantFreight.data.map((item, index) => (
                        <tr>
                          {props.user[0].user_type === "Super" &&
                            <td><div className="form-check2"><input checked={checked !== undefined && checked[index]} name={'check' + index} type="checkbox" id={item.order_id} onChange={(event) => toggleCheck(index, event, item.order_id)} disabled={overallchecked} /><label className="form-check-label" for={item.order_id}></label></div></td>}
                          {props.user[0].user_type === "Super" &&
                            <>
                              <td>{item.MerchantName}</td>
                            </>
                          }
                          <td className="align-middle text-left col-2">
                            <div className="list-group-item border-0 p-0 d-inline-flex">
                              <div className="list-icon">
                                <div className="avatar">
                                  {item.logo !== null && item.logo !== undefined ?
                                    <img className="img-fluid" src={process.env.REACT_APP_IMAGE_COURIER + item.logo} alt="logo" width="60" />
                                    : <span className="no-img d-flex align-items-center justify-content-center rounded-circle border font-weight-500">M</span>}
                                </div>
                              </div>
                              <div className="list-content text-left">
                                <div className="">
                                  <p className="mb-0">{item.CourierName}</p>
                                </div>
                                <div className="d-flex"><div className="mb-1 d-block text-left text-nowrap">AWB - {item.awb_no ? item.awb_no : "NA"}</div>
                                  <span onClick={() => copied(item.awb_no ? item.awb_no : "NA")} className="ml-1 copy-btn" id={`copy${item.order_id}`}>
                                    <Copy />
                                    <UncontrolledTooltip placement="bottom" target={`copy${item.order_id}`}>
                                      Copy
                                    </UncontrolledTooltip>
                                  </span>
                                </div>
                                <div class="text-nowrap">Order No - <Link target="_blank" to={`/order-detail/${item.order_id}`} className="text-nowrap">{item.order_no}</Link>
                                  <span onClick={() => copied(item.order_no)} className="ml-1 copy-btn" id={`copyOdr${item.id}`}>
                                    <Copy />
                                    <UncontrolledTooltip placement="bottom" target={`copyOdr${item.id}`}>
                                      Copy
                                    </UncontrolledTooltip>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>{item.charged_weight ? item.charged_weight : 'NA'}</td>
                          <td className='text-nowrap'>{item.forward_charge ? item.forward_charge : '0'}</td>
                          <td className='text-nowrap'>{item.rto_charge ? item.rto_charge : '0'}</td>
                          <td className='text-nowrap'>{item.cod_charge > 0 ? item.cod_charge : 0}</td>
                          {/* <td className='text-left'>
                        <div>
                          Forward - {item.forward_charge ? item.forward_charge : '0' }
                        </div>
                        <div>
                          RTO - {item.rto_charge ? item.rto_charge : '0' }
                        </div>
                        {item.cod_charge > 0 &&
                          <div>
                            COD - {item.cod_charge}
                          </div>
                        }
                      </td> */}
                          <td>{item.tax_amt}</td>
                          <td>{item.total_amt}</td>
                          <td>{zone_category_id[item.zone_category_id]}</td>
                          <td>{item.is_suplimentary === 1 ? "Excess Weight" : "Freight"}</td>
                          <td>{delivery_type_id[item.delivery_type_id]}</td>
                          <td className="text-nowrap">{changeIntoDateTime(item.created_at)}</td>
                        </tr>
                      ))
                        : loadertable ? <tr> <td colSpan="7"><LoaderDefault /></td> </tr> :
                          <tr> <td colSpan="6"><NodataFound /></td> </tr>}
                    </tbody>
                  </Table>

                </div>

                {checkData(merchantFreight) && checkData(merchantFreight.data) && checkUndeNullBlank(merchantFreight.TotalRows) ?
                  <>
                    {merchantFreight.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}

export default connect(mapDispatchToProps)(MerchantFreight);