import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Spinner, FormGroup, Form, CardHeader, Label, Button, Table, Collapse, Badge } from 'reactstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { baseString, zone_category_id } from '../../config';
import { errorHandling } from '../../utlis';
import { toast } from 'react-toastify';


const PriceCalculator = (props) => {
    const [isOpenCod, setIsOpenCod] = useState(false);
    const [prepaid, setPrepaid] = useState(true);
    const [loader, setLoader] = useState(false);
    const [loadertable, setLoadertable] = useState(false);
    const [courierwithPrice, setCourierwithPrice] = useState(false);

    const toggleCod = () => {
        setIsOpenCod(!isOpenCod);
        if (prepaid) {
            setPrepaid(!prepaid);
        }
    };
    const togglePrepaid = () => {
        setPrepaid(!prepaid);
        if (isOpenCod) {
            setIsOpenCod(!isOpenCod);
        }
    };

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
            data.merchant_id = props.user[0].id
        } else {
            data.admin_id = props.user[0].id
        }
        setLoader(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_PRICE_CALCULATOR}`,
            data: data,
            headers: { "token": window.btoa(baseString) }
        })
            .then(function (response) {
                setLoader(false);
                if (response.data.error === false) {
                    setLoadertable(true);
                    setCourierwithPrice(response.data.data)
                }
                if (response.data.error === true) {
                    Swal.fire({ title: 'Error', text: response.data.msg, icon: 'error', })
                }
            })
            .catch(function (response) {
                setLoader(false);
                errorHandling(response, "")
            });
    }

    return (
        <div className="app-content content overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}
            <Row>
                <Col sm={12}>
                    <Card className="p-0 my-3 CreateOrder shadow content-wrapper border-0">
                        <Card className="border-0 mb-0">
                            <CardHeader className="page-header d-flex justify-content-between">
                                <CardTitle>Price Calculator</CardTitle>
                            </CardHeader>
                            <CardBody className="forms">
                                <Form className="larg-form" onSubmit={handleSubmit(onSubmit)}>
                                    <Row className="mx-0">
                                        <Col sm={12}>
                                            <Card className="border-0">
                                                <CardBody className="p-0">
                                                    <Row>
                                                        <Col sm={12} md={8} lg={6} xl={5}>
                                                            <CardHeader className="px-0"><CardTitle >Choose Payment Type<span className="text-danger">*</span></CardTitle></CardHeader>
                                                            <FormGroup>
                                                                <div className="d-flex">
                                                                    <span className="form-check2 mr-2">
                                                                        <input {...register("delivery_type_id", { required: true })} type="radio" value="3" id="prepaid" defaultChecked onClick={togglePrepaid} />
                                                                        <Label className="mx-2" for="prepaid">Prepaid</Label>
                                                                    </span>
                                                                    <span className="form-check2 mr-2">
                                                                        <input {...register("delivery_type_id", { required: true })} type="radio" value="1" id="cod" onClick={toggleCod} />
                                                                        <Label className="mx-2" for="cod">COD</Label>
                                                                    </span>
                                                                    <Collapse isOpen={isOpenCod}>
                                                                        {isOpenCod &&
                                                                            <input {...register("cod_value", { required: 'Please Enter COD Amount' })} className="form-control-sm form-control" placeholder="Enter COD Amount*" id="cod_amount" style={{ width: "170px" }} />
                                                                        }
                                                                    </Collapse>
                                                                </div>
                                                                {errors.cod_value && <span className="text-danger d-block error">{errors.cod_value.message}</span>}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col sm={12}><CardHeader className="px-0"><CardTitle>Pincode Details</CardTitle></CardHeader></Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="source_pincode">Source Pincode<span className="text-danger">*</span></Label>
                                                                <input {...register("source_pincode", {
                                                                    required: 'Please Enter Source Pincode',
                                                                    minLength: { value: 6, message: "at least 6 digits" },
                                                                    maxLength: { value: 6, message: "maximum 6 digits" },
                                                                    pattern: { value: /^[0-9]+$/i, message: 'Only numbers allowed' }
                                                                })} id="source_pincode" className="form-control-sm form-control" placeholder="Enter Source Pincode" />
                                                                {errors.source_pincode && <span className="text-danger d-block error">{errors.source_pincode.message}</span>}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="dest_pincodes">Destination Pincode<span className="text-danger">*</span></Label>
                                                                <input {...register("destination_pincode", {
                                                                    required: 'Please Enter Destination Pincode',
                                                                    minLength: { value: 6, message: "at least 6 digits" },
                                                                    maxLength: { value: 6, message: "maximum 6 digits" },
                                                                    pattern: { value: /^[0-9]+$/i, message: 'Only numbers allowed' }
                                                                })} className="form-control-sm form-control" placeholder="Enter Destination Pincode" id="destination_pincode" />
                                                                {errors.destination_pincode && <span className="text-danger d-block error">{errors.destination_pincode.message}</span>}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="prod_actual">Weight In KGS.<span className="text-danger">*</span></Label>
                                                                <input {...register("weight_in_kgs", {
                                                                    required: 'Weight In KGS',
                                                                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Only decimal numbers allowed' }
                                                                })} className="form-control-sm form-control" id="product_actual" />
                                                                {errors.weight_in_kgs && <span className="text-danger d-block error">{errors.weight_in_kgs.message}</span>}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="product_dimension">Product Dimension<span className="text-danger">*</span></Label>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="mx-2">
                                                                        <div className="d-flex align-items-center">
                                                                            <Label for="prod_l" className="mx-2">L</Label>
                                                                            <input {...register("length_in_cms", {
                                                                                required: 'Please Enter Length',
                                                                                pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Only decimal numbers allowed' }
                                                                            })} className="form-control-sm form-control " id="length_in_cms" />
                                                                        </div>
                                                                        {errors.length_in_cms && <span className="text-danger d-block error">{errors.length_in_cms.message}</span>}
                                                                    </div>
                                                                    <div className="mx-2">
                                                                        <div className="d-flex align-items-center">
                                                                            <Label for="breadth_in_cms" className="mx-2">B</Label>
                                                                            <input {...register("breadth_in_cms", {
                                                                                required: 'Please Enter Breadth',
                                                                                pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Only decimal numbers allowed' }
                                                                            })} className="form-control-sm form-control " id="breadth_in_cms" />
                                                                        </div>
                                                                        {errors.breadth_in_cms && <span className="text-danger d-block error">{errors.breadth_in_cms.message}</span>}
                                                                    </div>
                                                                    <div className="mx-2">
                                                                        <div className="d-flex align-items-center">
                                                                            <Label for="prod_h" className="mx-2">H</Label>
                                                                            <input {...register("height_in_cms", {
                                                                                required: 'Please Enter Height',
                                                                                pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Only decimal numbers allowed' }
                                                                            })} className="form-control-sm form-control " id="height_in_cms" />
                                                                        </div>
                                                                        {errors.height_in_cms && <span className="text-danger d-block error">{errors.height_in_cms.message}</span>}
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="mx-0 mb-4">
                                        <Col sm={12} className="text-right">
                                            <Button className="btn ctm-btn btn-sm" color="primary">Submit</Button>
                                        </Col>
                                    </Row>
                                </Form>
                                {loadertable &&
                                    <>
                                        <Table responsive className="text-center custom-table">
                                            <thead>
                                                <tr>
                                                    <th className="text-nowrap col-3">Carrier</th>
                                                    <th className="text-nowrap col-3">Weight(KGS).</th>
                                                    <th className="text-nowrap col-2">Price</th>
                                                    <th className="text-nowrap col-2">Zone</th>
                                                    {/* <th className="text-nowrap col-2">TAT</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.entries(courierwithPrice).length > 0 && courierwithPrice !== undefined ? courierwithPrice.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className='text-left pl-md-4'>{item.courier_name}</td>
                                                        <td>{item.charged_weight_in_kgs + ' Kgs' || 'NA'}</td>
                                                        <td>{checkData(item.price) ? <> {'₹ ' + item.price.total.toFixed(2) || 'NA'}</> : ''}</td>
                                                        <td>{zone_category_id[item.zone_category_id] || 'NA'}</td>
                                                        {/* <td>{checkUndeNullBlank(item.tat_in_days) ? item.tat_in_days + ' Days' : 'NA'}</td> */}
                                                    </tr>
                                                ))
                                                    : loadertable ? <tr><td colSpan="6">Loading...</td></tr> : <tr><td colSpan="6">No Data Found</td></tr>}
                                            </tbody>
                                        </Table>
                                        {/* <Badge color='dark' pill className='w-100 mb-2 text-right'>All Price are inclusive tax</Badge> */}
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default PriceCalculator;