import React,{useState, useEffect} from 'react';
import Cards from './Cards';
import SmallCards from './SmallCards';
import OrderSummary from './OrderSummary';
import RemittanceOverview from './RemittanceOverview';
import PerformanceOverview from './PerformanceOverview';
import {Row, Col} from 'reactstrap';
import ShipmentData from './ShipmentData';
import CourierOverview from './CourierOverview';
import ShipmentStatus from './ShipmentStatus';
// import CodPrepaid from './CodPrepaid';
import FreightOverview from './FreightOverview';
import CityState from './CityState';
import StateMap from './StateMap';
import NdrCard from './NdrCard';
import NDRSummary from '../NDR/NDRSummary';
import { ndrCardOverView } from '../../../../actions';
import { errorHandling } from '../../../../utlis';
import { checkData } from '../../../../ObjectExist';
import {connect, useDispatch} from 'react-redux';



const Overview = (props) => {

    const [cardsOverView, setCardsOverView] = useState({});
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();

    
    const listingReport = () => {
        setLoader(true);
          let mypost = {"merchant_id":props.user[0].id}
          let myget = {}
          dispatch(ndrCardOverView(mypost,myget)).then((response) => {
            if(response.error === false){
              setCardsOverView(response.data)
            }
            //console.log("adasdasd",JSON.stringify(response.data));
            setLoader(false)
          }).catch(function (response) {
            setLoader(false);
            errorHandling(response,"reload");
          });
      }

      useEffect(() => {
        if(checkData(props.state) && checkData(props.state.viewNdrCardOver)&& checkData(props.state.viewNdrCardOver.data)){
          setCardsOverView(props.state.viewNdrCardOver.data);
        }else{
          listingReport();
        }
  
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      
    return (
        <div className="overview">
            <div className="mb-4"><Cards user={props.user} /></div> 
            {/* <div className="mb-4"><SmallCards user={props.user} /></div> */}
            <div className="mb-4"><NDRSummary  user={props.user} cardsOverView={cardsOverView} /></div>  


            <div className="mb-4"><OrderSummary user={props.user} oneCalendar={props.oneCalendar} /></div>
            <div className="mb-4 d-none"><ShipmentData user={props.user} /></div>
            <Row>
                {/* <Col md={12} lg={6} className="mb-4 d-lg-flex"><FreightOverview user={props.user} /></Col> */}
                {/* <Col md={12} lg={6} className="mb-4 d-lg-flex"><RemittanceOverview user={props.user} /></Col> */}
            </Row>
            {/* <div className="mb-4"><CourierOverview user={props.user} oneCalendar={props.oneCalendar} /></div> */}
            <div className="mb-4"><PerformanceOverview user={props.user} oneCalendar={props.oneCalendar} /></div>
            <Row>
                {/* <Col md={12} lg={6} className="mb-4"><ShipmentStatus user={props.user} oneCalendar={props.oneCalendar} /></Col> */}
                {/* <Col md={12} lg={6} className="mb-4 d-flex"><CityState user={props.user} /></Col> */}
            </Row>
            {/* <div className="mb-4 d-none"><StateMap user={props.user} oneCalendar={props.oneCalendar} /></div> */}
        </div>
    );
}

export default Overview;