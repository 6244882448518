import React, { useEffect } from 'react';
import { Row, Col, Label, Form, FormGroup, Button, CardTitle } from 'reactstrap';
import lableimg1 from '../../../assets/img/Address-Label1.png'
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import axios from 'axios';
import { errorHandling } from '../../../utlis';
import { baseString } from '../../../config';

const LabelSetting = (props) => {
    const { register: addresslabelChoose, handleSubmit: addressLabelSubmit, reset } = useForm();

    useEffect(() => {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_SETTING}/view`,
            data: { "setting_id": '1', "merchant_id": props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id : '', },
            headers: { "token": window.btoa(baseString) }
        })
            .then(function (response) {
                let myResponse = response.data[0];
                reset(myResponse);
            })
            .catch(function (err) {
                errorHandling(err, "")
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset])

    const onSelectAddressLabel = (data) => {
        props.setLoader(true);
        let formData = new FormData();
        formData.append("merchant_id", props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id : '');
        formData.append("setting_value", data.setting_value);
        formData.append("setting_id", '1');
        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_SETTING}`,
            data: formData,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            props.setLoader(false);
            if (res.data.error === false) {
                Swal.fire({
                    title: 'Success',
                    text: res.data.msg,
                    icon: 'success',
                })
            }
        })
            .catch((err) => {
                props.setLoader(false);
                Swal.fire({ icon: 'error', text: "Something went wrong", title: 'Error' })
            });
    };

    return (
        <Form onSubmit={addressLabelSubmit(onSelectAddressLabel)}>
            <Row className="sub-page-header px-3 border-bottom py-3">
                <Col sm={6}>
                    <CardTitle tag="h4" className=" pb-0 mb-0 card-title h5 text-dark">Label Setting</CardTitle>
                </Col>
                <Col sm={6} className="text-right">
                    <Button className="btn-md ctm-btn rounded-pill" color="primary">Save</Button>
                </Col>
            </Row>
            
            <Row className="p-4">
                <Col sm={4}>
                    <FormGroup>
                        <div className="form-check2">
                            <input type="radio" id="l1" className="mr-2" {...addresslabelChoose("setting_value", { required: 'Please Choose Label' })} value="1" />
                            <Label for="l1">
                                Label One
                            </Label>
                        </div>
                        <div>
                            <img src={lableimg1} className="img-fluid" alt="lable1" />
                        </div>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup>
                        <div className="form-check2">
                            <input type="radio" id="l2" className="mr-2" {...addresslabelChoose("setting_value", { required: 'Please Choose Label' })} value="2" />
                            <Label for="l2">
                                Label Two
                            </Label>
                        </div>
                        <div>
                            <img src={lableimg1} className="img-fluid" alt="lable1" />
                        </div>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup>
                        <div className="form-check2">
                            <input type="radio" id="l3" className="mr-2" {...addresslabelChoose("setting_value", { required: 'Please Choose Label' })} value="3" />
                            <Label for="l3">
                                Label Three
                            </Label>
                        </div>
                        <div>
                            <img src={lableimg1} className="img-fluid" alt="lable1" />
                        </div>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup>
                        <div className="form-check2">
                            <input type="radio" id="l4" className="mr-2" {...addresslabelChoose("setting_value", { required: 'Please Choose Label' })} value="4" />
                            <Label for="l4">
                                Label Four
                            </Label>
                        </div>
                        <div>
                            <img src={lableimg1} className="img-fluid" alt="lable1" />
                        </div>
                    </FormGroup>
                </Col>

                {/* <Col sm={12} className="mt-2 text-right">
                    <Button className="btn-sm ctm-btn" color="primary"> Save changes</Button>
                </Col> */}
            </Row>
        </Form>
    );
}

export default LabelSetting;