import React, { useState } from 'react';
import { Nav, NavItem, CardImg,Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillAlt, faWeightHanging,faPhoneSlash,faUsersCog,faPhoneAlt, faChevronDown, faChevronUp, faCube, faWallet, faTimes, faLocationArrow, faCode, faLink, faListAlt, faWeight, faMinus} from '@fortawesome/free-solid-svg-icons';
import LogoL from '../assets/img/logo-l.png';
// import LogoR from '../assets/img/logo-r.png';
import { Table, Archive, ShoppingCart, Upload, Package, RotateCw, List, Tool, ArrowRight, Layers} from 'react-feather';
import { useLocation } from 'react-router';
import { faWpforms } from '@fortawesome/free-brands-svg-icons';
import { toast } from 'react-toastify';

// import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

const MerchantSideBar = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isAccount, setIsAccount] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [isNDR, setIsNDR] = useState(false);
    const [isTool, setIsTool] = useState(false);
    const toggleTool = () => setIsTool(!isTool);
    const urlPath = useLocation();

    const toggle = () => setIsOpen(!isOpen);
    // const toggleShipment = () => setIsShipment(!isShipment);
    const toggleAccount = () => setIsAccount(!isAccount);
    const toggleNDR = () => setIsNDR(!isNDR);
  return (
        <div className={`main-menu menu-fixed menu-accordion menu-shadow menu-light ${!expanded ? "expanded": ""}`}  onMouseEnter={() => setExpanded(true)} onMouseLeave={() => setExpanded(false)}>
            <div className="navbar-header position-relative d-flex align-items-center">
            <Nav vertical>
                <NavItem>
                    <Link to="/" className="mx-auto d-flex align-items-center navbar-brand">
                        <CardImg top src={process.env.PUBLIC_URL + "/logo-r" + process.env.REACT_APP_ADMIN_ID +".png"} alt="Card image cap mx-auto" style={{height:'35px',width:"auto"}} className="brand-text" />
                    </Link>
                </NavItem>
            </Nav>
            <span className="sideCollapse cursor-pointer" onClick={() => props.sideBarToggle()}>
                <span className="for-open"><ArrowRight /></span>
                <span className="for-close"><FontAwesomeIcon icon={faTimes} /></span>
            </span>
            </div>
            <div className={`scrollbar-container main-menu-content ${props.user[0].document_status === 0 ? 'd-none':''}`}>
                <Nav vertical className="navigation navigation-main">
                <NavItem className={`${urlPath.pathname ==='/' && 'active'}`}>
                    <Link to='/' className="d-flex align-items-center nav-link font-weight-500" active onClick={() => props.closeSideBar()}>
                    <span className="mr-2 menu-icon"><Table /></span>
                    <span className="menu-title">Home</span>
                    </Link>
                </NavItem>
                <NavItem className={`${urlPath.pathname ==='/orders' && 'active'}`}>
                                <Link to='/orders?o=upload' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><Archive /></span>
                                <span className="menu-title">Orders</span>
                                </Link>
                </NavItem>
                {/* <NavItem className={`${urlPath.pathname ==='/channel-orders' && 'active'}`}>
                                <Link to='/channel-orders' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><FontAwesomeIcon icon={ faListAlt} /></span>
                                    <span className="menu-title">Channel Orders</span>
                                </Link>
                </NavItem> */}
                <NavItem className={`${urlPath.pathname ==='/shipments' && 'active'}`}>
                    <Link to='/shipments' className="d-flex align-items-center nav-link font-weight-500" onClick={() => props.closeSideBar()}>
                    <span className="mr-2"><List /></span>
                    <span className="menu-title">Shipments</span>
                    </Link>
                </NavItem>
               <NavItem className={"d-none"}>
                    <Link to='#' className="d-flex align-items-center nav-link font-weight-500" onClick={toggle}>
                        <span className="mr-2"><ShoppingCart /></span>
                        <span className="menu-title">Orders</span>
                        {!isOpen && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isOpen && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    <Collapse isOpen={isOpen} className="d-none collapse-css-transition">
                        <Nav vertical>
                             {/* <NavItem className={`${urlPath.pathname ==='/upload-orders' && 'active'}`}>
                                <Link to='/upload-orders' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><Upload /></span>
                                <span>Create Order</span>
                                </Link>
                            </NavItem> */}
                             <NavItem className={`${urlPath.pathname ==='/orders' && 'active'}`}>
                                <Link to='/orders?o=upload' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><Upload /></span>
                                <span className="menu-title">Create Order</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/channel-orders' && 'active'}`}>
                                <Link to='/channel-orders' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><FontAwesomeIcon icon={ faListAlt} /></span>
                                    <span className="menu-title">Channel Orders</span>
                                </Link>
                            </NavItem>
                            {/* <NavItem className={`${urlPath.pathname ==='/create-order' && 'active'}`}>
                                <Link to='/create-order' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><Package /></span>
                                <span>Single Order</span>
                                </Link>
                            </NavItem> */}
                            {/* <NavItem className={`${urlPath.pathname ==='/processing-orders' && 'active'}`}>
                                <Link to='/processing-orders' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><FileMinus /></span>
                                <span>Processing Order</span>
                                </Link>
                            </NavItem> */}
                            {/* <NavItem className={`${urlPath.pathname ==='/failedOrders' && 'active'}`}>
                                <Link to='/failed-orders' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><AlertTriangle /></span>
                                <span>Failed Orders</span>
                                </Link>
                            </NavItem>*/}
                        </Nav>
                    </Collapse>
                </NavItem> 
               {/* <NavItem>
                    <Link to='#' className="d-flex align-items-center nav-link font-weight-500" onClick={toggleShipment}>
                        <span className="mr-2"><ShoppingCart /></span>
                        <span>Shipments</span>
                        {!isShipment && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isShipment && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    <Collapse isOpen={isShipment}>
                        <Nav vertical>
                             <NavItem className={`${urlPath.pathname ==='/shipments' && 'active'}`}>
                                <Link to='/shipments' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><Package /></span>
                                <span>Shipment</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/pickup-pending' && 'active'}`}>
                                <Link to='/pickup-pending' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><Watch /></span>
                                <span>Pickup Pending</span>
                                </Link>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </NavItem>  */}
                <NavItem className={`${urlPath.pathname ==='/ndr' && 'active'}`}>
                    <Link to='/ndr' className="d-flex align-items-center nav-link font-weight-500" onClick={() => props.closeSideBar()}>
                    <span className="mr-2"><FontAwesomeIcon icon={faPhoneAlt} /></span>
                    <span className="menu-title">NDR Expections</span>
                    </Link>
                </NavItem> 
                <NavItem className={`${urlPath.pathname ==='/weight-mismatch' && 'active'}`}>
                    <Link to='/weight-mismatch' className="d-flex align-items-center nav-link font-weight-500" onClick={() => props.closeSideBar()}>
                        <span className="mr-2"><FontAwesomeIcon icon={faWeightHanging} /></span>
                        <span className="menu-title">Weight Correction</span>
                    </Link>
                </NavItem> 
                <NavItem className={`${urlPath.pathname ==='/remittance-paid' && 'active'}`}>
                    <Link to='/remittance-paid' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                        <span className="mr-2"><FontAwesomeIcon icon={faWallet} /></span>
                        <span className="menu-title">Billing</span>
                    </Link>
                </NavItem>
                
                {/* <NavItem className={`${urlPath.pathname ==='/rate-card' && 'active'}`}>
                    <Link to='/rate-card' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                        <span className="mr-2"><FontAwesomeIcon icon={faCube} /></span>
                        <span className="menu-title">Rate Card</span>
                    </Link>
                </NavItem> */}
                {/* <NavItem className={isNDR && "collapse-con"}>
                    <Link to='#' className="d-flex align-items-center nav-link font-weight-500" onClick={toggleNDR}>
                        <span className="mr-2"><FontAwesomeIcon icon={faPhoneAlt} /></span>
                        <span className="menu-title">Delivery Failed</span>
                        {!isNDR && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isNDR && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    <Collapse isOpen={isNDR} className="collapse-css-transition">
                        <Nav vertical>
                            <NavItem className={`${urlPath.pathname ==='/ndr' && 'active'}`}>
                                <Link to='/ndr' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"></span>
                                <span className="text-nowrap menu-title">Ongoing <br></br> Failed</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/old-ndr' && 'active'}`}>
                                <Link to='/old-ndr' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"></span>
                                <span className="text-nowrap menu-title">All <br></br> Failed</span>
                                </Link>
                            </NavItem>
                           </Nav>
                    </Collapse>
                </NavItem>                    */}
               
                <NavItem className='d-none'>
                    <Link to='#' className="d-flex align-items-center nav-link font-weight-500" onClick={toggleAccount}>
                        <span className="mr-2"><FontAwesomeIcon icon={faWallet} /></span>
                        <span className="menu-title">Account</span>
                        {!isAccount && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isAccount && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    <Collapse isOpen={isAccount} className="collapse-css-transition">
                        <Nav vertical>
                            <NavItem className={`${urlPath.pathname ==='/my-remittance' && 'active'}`}>
                                <Link to='/my-remittance' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><FontAwesomeIcon icon={faWallet} /></span>
                                    <span className="menu-title">Remittance</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/remittance-paid' && 'active'}`}>
                                <Link to='/remittance-paid' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><FontAwesomeIcon icon={faWallet} /></span>
                                    <span className="menu-title">Remittance Paid</span>
                                </Link>
                            </NavItem>
                             <NavItem className={`${urlPath.pathname ==='/freight' && 'active'}`}>
                                <Link to='/freight' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><FontAwesomeIcon icon={faMoneyBillAlt} /></span>
                                    <span className="menu-title">Freight</span>
                                </Link>
                            </NavItem>
                            {/* <NavItem className={`${urlPath.pathname ==='/invoices' && 'active'}`}>
                                <Link to='/invoices' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><FontAwesomeIcon icon={faWpforms} /></span>
                                    <span className="menu-title">Invoice</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/debit-note' && 'active'}`}>
                                <Link to='/debit-note' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><List /></span>
                                <span className="menu-title">Debit Note</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/credit-note' && 'active'}`}>
                                <Link to='/credit-note' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><List /></span>
                                <span className="menu-title">Credit Receipt</span>
                                </Link>
                            </NavItem> */}
                           </Nav>
                    </Collapse>
                </NavItem>
                <NavItem className={'d-none'}>
                    <Link to='/my-pickuppoint' className="d-flex align-items-center nav-link font-weight-500" onClick={() => props.closeSideBar()}>
                    <span className="mr-2"><FontAwesomeIcon icon={faCube} /></span>
                    <span className="menu-title">Warehouses</span>
                    </Link>
                </NavItem>
                {/* <NavItem className={`${urlPath.pathname ==='/my-brands' && 'active'}`}>
                    <Link to='/my-brands' className="d-flex align-items-center nav-link font-weight-500" onClick={() => props.closeSideBar()}>
                    <span className="mr-2"><Tag /></span>
                    <span className="menu-title">Stores</span>
                    </Link>
                </NavItem> */}
                {/* <NavItem className={`${urlPath.pathname ==='/Serviceability' && 'active'}`}>
                    <Link to='/Serviceability' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                        <span className="mr-2"><FontAwesomeIcon icon={faLocationArrow} /></span>
                        <span className="menu-title">Serviceability</span>
                    </Link>
                </NavItem>
                <NavItem className={`${urlPath.pathname ==='/channel-integration' && 'active'}`}>
                    <Link to='/channel-integration' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                        <span className="mr-2"><FontAwesomeIcon icon={ faLink} /></span>
                        <span className="menu-title">Channel Setup</span>
                    </Link>
                </NavItem>
                <NavItem className={`${urlPath.pathname ==='/price-calculator' && 'active'}`}>
                    <Link to='/price-calculator' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                        <span className="mr-2"><FontAwesomeIcon icon={faCalculator} /></span>
                        <span className="menu-title">Price Calculaor</span>
                    </Link>
                </NavItem> */}
                <NavItem className={isTool && "collapse-con"}>
                    <Link to='#' className="d-none d-flex align-items-center nav-link font-weight-500" onClick={toggleTool}>
                        <span className="mr-2"><FontAwesomeIcon icon={faUsersCog}/> </span>
                        <span className="menu-title">Utility</span>
                        {!isTool && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isTool && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    
                    <Collapse isOpen={isTool} className="d-none collapse-css-transition">
                        <Nav vertical>
                           
                            {/* <NavItem className={`${urlPath.pathname ==='/tracking' && 'active'}`}>
                                <Link to='/tracking' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><FontAwesomeIcon icon={faCode} /></span>
                                    <span className="menu-title">Tracking Code</span>
                                </Link>
                            </NavItem> */}
                           
                           
                            {/* <NavItem className={`${urlPath.pathname ==='/stock' && 'active'}`}>
                                <Link to='/stock' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><Layers /></span>
                                    <span className="menu-title">Stock</span>
                                </Link>
                            </NavItem>                            */}
                        </Nav>
                    </Collapse>
                </NavItem>
               </Nav>
            </div>
        </div>
  );
}

export default MerchantSideBar;