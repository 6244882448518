import React,{useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,CardTitle,Form, FormGroup, Label, Button, Input, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import {Eye, EyeOff, Mail, Lock } from 'react-feather';
import loginBg from "../../assets/img/login-bg.svg";
import { useForm } from 'react-hook-form';
import { LoginAdmin } from '../../actions';
import { connect, useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import { encryptStorageLogin } from '../../EncStorage';
import { toast } from 'react-toastify';

const AdminLogin = (props) => {
    const [passwordType, setPasswordType] = useState(true);
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
useEffect(() =>{
    // if(props.user !== undefined && props.user !== null && Object.entries(props.user).length > 0){
    //     history.push("/");
    //   }else{
    //     history.push("/login/admin");
    //   }
}, [])

    const { register:loginInput, handleSubmit:loginSubmit, formState: { errors }} = useForm();
    const onAdminLogin = (data) => {
        setLoader(true);
        data.email = data.email.trim();
        data.password = data.password.trim();
        
        dispatch(LoginAdmin(data)).then(data => {
            if(data.error !== true ){
                setLoader(false);
                // localStorage.setItem("user", JSON.stringify(data));
                encryptStorageLogin.setItem('userenc', JSON.stringify(data.data));
                history.push("/");
            }else{
                setLoader(false);
                toast.error("Invalid Credentials",{position:'top-right'});
            }
          }).catch(function (response) {
          setLoader(false)
          toast.error(response.msg,{position:'top-right'})
        });
    };
    
    return (
        <div className="app-content m-0 p-0 content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
          <Row>
           <Col sm={12}>            
            <div className="p-0 LoginPage content-wrapper">
                <Row>
                    <Link href="#" className="brand-logo text-center py-3 py-lg-0 d-none" ><img src={`../logo${process.env.REACT_APP_ADMIN_ID}.png`} alt="logo" style={{maxWidth:'200px'}} /></Link>
                    
                    <Col sm={12} lg={4} className=" px-2 p-lg-5 m-auto">
                        <div className='text-center'>
                            <Link href="#" className="brand-logo2 text-center py-3 py-lg-0 m-auto" ><img src={`../../logo${process.env.REACT_APP_ADMIN_ID}.png`} alt="logo" style={{maxWidth:'80px'}} /></Link>
                            <CardTitle tag="h4" className="text-center card-title pt-3 font-weight-bold card-title">Welcome to {process.env.REACT_APP_NAME} Admin</CardTitle>
                            <CardTitle tag="h6" className="text-center mb-3 card-title">Please sign-in to your account and start the {process.env.REACT_APP_NAME}</CardTitle>
                        </div>
                        <Card  className="border-0 px-xl-3 mx-auto bg-white shadow">
                            {/* <Link href="#" className="brand-logo2 text-center py-3 py-lg-0" ><img src={`../logo${process.env.REACT_APP_ADMIN_ID}.png`} alt="logo" style={{maxWidth:'200px'}} /></Link> */}
                            <CardBody className="align-items-center">
                              <Form onSubmit={loginSubmit(onAdminLogin)}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <InputGroup className='customInputGrp'>
                                     <span className='customPrefix'><Mail size={16} /></span>
                                     <input {...loginInput("email",{ required: 'Please Enter Email'})} className="form-control" placeholder="Enter Email" id="email" />
                                    </InputGroup>
                                    {errors.email && <span className="text-danger d-block mb-2">{errors.email.message}</span>}
                                </FormGroup> 
                                <FormGroup>
                                    <div className="d-flex justify-content-between">
                                        <Label for="password">Password</Label>
                                    </div>
                                    <InputGroup className='customInputGrp'>
                                        <span className='customPrefix'><Lock size={16} /></span> 
                                        <input {...loginInput("password",{ required: 'Please Enter Password'})} type={passwordType? "password" : "text"} placeholder="Enter Password" className="form-control" id="password" />
                                        {/* <InputGroupAddon addonType="append" onClick={() => setPasswordType(!passwordType)}>
                                            <InputGroupText className="py-0"> {passwordType ? <Eye /> : <EyeOff />}</InputGroupText>
                                        </InputGroupAddon> */}
                                    </InputGroup>  
                                    {errors.password && <span className="text-danger d-block mb-2">{errors.password.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <FormGroup check>
                                        <Label check><Input type="checkbox" />Remember Me </Label>
                                    </FormGroup>
                                </FormGroup>                                
                                <FormGroup>              
                                    <Button className="btn-sm ctm-btn w-100 py-2 rounded-pill" color="primary"> Sign in</Button>                                     
                                </FormGroup>
                                <p className="text-center">
                                    <span>New on our platform?</span>
                                    <Link to="/register" className="ml-25">Create an account</Link>
                                </p>
                              </Form>
                            </CardBody>
                        </Card>                          
                    </Col>                    
                </Row>
              
            </div>
            </Col>         
          </Row>
        </div>
    );
}
function mapStateToProps (state) {
    return{
      state:state.DataReducer
    }
  }
export default connect(mapStateToProps)(AdminLogin);