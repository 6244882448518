import React, {useEffect, useState} from 'react';
import { Row, Col, Card, CardBody,Badge, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCubes, faCube, faThLarge } from '@fortawesome/free-solid-svg-icons';
// import axios from 'axios';
// import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import { nFormatter,errorHandling } from '../../../../utlis';
import {Link} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';
import { AlertTriangle } from 'react-feather';
import { ndrCardOverView } from '../../../../actions';


const NDRCards = (props) => {

    const CardData = [
        { icon: faCube, text: "Today Failed", value: checkData(props.cardsOverView)&& checkUndeNullBlank(props.cardsOverView.today_ndr)?props.cardsOverView.today_ndr:0, link:`/ndr`, color: "info" },
        { icon: faCubes, text: "Total Failed", value: checkData(props.cardsOverView)&& checkUndeNullBlank(props.cardsOverView.total_ndr)?props.cardsOverView.total_ndr:0, link:`/old-ndr`, color: "secondary" },
        { icon: faCube, text: "Today  Pending", value: checkData(props.cardsOverView)&& checkUndeNullBlank(props.cardsOverView.today_action_pending)?props.cardsOverView.today_action_pending:0, link:`#`, color: "warning" },
        { icon: faThLarge, text: "Total  Pending", value: checkData(props.cardsOverView)&& checkUndeNullBlank(props.cardsOverView.total_action_pending)?props.cardsOverView.total_action_pending:0, link:`#`, color: "success" },
     ];      
      
    return (
      <Row>
        {/* {props.loader && <div className="formLoader"><Spinner /></div>} */}
        <Col xs={12} sm={12} md={12} lg={12} className="">          
          <Card className="overview-card border-0 py-3">
            <CardBody className="py-0">   
              <Row className="card-overview-list w-100 justify-content-center">
              {CardData.map((cardData)=>
                <Col xs={6} sm={4} md={3}>                  
                  <div className='media'>
                    
                    <div className='media-body my-auto position-relative'>
                      <h4 className="card-val">
                       <Link to={cardData.link}  class="text-dark font-bold h4">{nFormatter(cardData.value, 1)}</Link>
                      </h4>
                      <p className="text-muted text-nowrap font-weight-500 title-card">{cardData.text}</p>
                    </div>
                    <div className='media-aside mr-2 align-self-start'>
                      <div className="card-icon">
                        <Badge color={cardData.color} pill className="d-none p-0 d-flex justify-content-center align-items-center mx-auto">
                          {cardData.text === "Total Active" ? <AlertTriangle /> : 
                          <FontAwesomeIcon icon={cardData.icon} />}
                        </Badge>
                      </div>
                    </div>
                  </div>
                </Col>
              )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
}

function mapDispatchToProps (state) {
  return{
    state:state.DataReducer
  }
}

export default connect(mapDispatchToProps)(NDRCards);