import React, { useState, useEffect } from 'react';
import { Row, Col, Button, CardTitle, FormGroup, Form, Label, Spinner } from 'reactstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2';
import UrlImageDownloader from "react-url-image-downloader";
import { extension, errorHandling } from '../../utlis';
import { baseString } from '../../config';
import { toast } from 'react-toastify';


const BankDetails = (props) => {

    const [bankDetails, setBankDetails] = useState();
    const [loader, setLoader] = useState(false);
    const [checkCopy, setCheckcopy] = useState('');

    const { register: addMerchant_bankDetails, handleSubmit: merchantDetailsSubmit, formState: { errors }, reset } = useForm();

    useEffect(() => {
        viewBankDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset])

    const viewBankDetails = () => {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_BANKDETAILS}/view`,
            data: { "merchant_id": props.merchantId },
            headers: { "token": window.btoa(baseString) }
        })
            .then(function (response) {
                if (response.data.length > 0) {
                    let myResponse = response.data[0];
                    myResponse.account_type = String(myResponse.account_type)
                    reset(myResponse);
                    setBankDetails(myResponse)
                }
            })
            .catch(function (err) {
                errorHandling(err, "reload");
            });
    }

    const handleFileInput = (e) => {
        setCheckcopy(e.target.files[0])
    }
    const onAddbankDetails = (data) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("merchant_id", props.merchantId);
        formData.append("account_type", data.account_type);
        formData.append("bank_name", data.bank_name);
        formData.append("account_number", data.account_number);
        formData.append("ifsc_code", data.ifsc_code);
        formData.append("branch", data.branch);
        formData.append("cancel_cheque_copy", checkCopy !== '' ? checkCopy : bankDetails !== undefined && bankDetails.cancel_cheque_copy !== undefined && bankDetails.cancel_cheque_copy !== "" ? bankDetails.cancel_cheque_copy : 'NA');

        axios({
            method: "post",
            url: process.env.REACT_APP_MERCHANT_BANKDETAILS,
            data: formData,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false);
            if (res.data.error === false) {
                Swal.fire({
                    title: 'Success',
                    text: res.data.message,
                    icon: 'success',
                })
                viewBankDetails();
            }
        })
            .catch((err) => {
                setLoader(false);
                errorHandling(err, "");
            });
    };


    return (
        <>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Form onSubmit={merchantDetailsSubmit(onAddbankDetails)}>
                <div className="sub-page-header">
                    <CardTitle tag="h4" className="sub-page-title">Bank Details</CardTitle>
                </div>
                <Row>
                    <Col Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                            <Label>Account Type</Label>
                            <div>
                                <span className="form-check2 mr-2">
                                    <input {...addMerchant_bankDetails("account_type", { required: 'Please Select Account Type' })} type="radio" value="Saving" id="saving" />
                                    <Label className="mx-2" for="saving">Saving</Label>
                                </span>
                                <span className="form-check2">
                                    <input {...addMerchant_bankDetails("account_type", { required: 'Please Select Account Type' })} type="radio" value="Current" id="current" />
                                    <Label className="mx-2" for="current">Current</Label>
                                </span>
                            </div>
                            {errors.account_type && <span className="text-danger d-block error">{errors.account_type.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                            <Label for="bankName">Enter Bank Name<span className="text-danger">*</span></Label>
                            <input {...addMerchant_bankDetails("bank_name", {
                                required: 'Please Enter Bank Name',
                                pattern: { value: /^[a-z ,.'-]+$/i, message: 'Please Enter Valid Bank Name' }
                            })} className="form-control-sm form-control" placeholder="Bank Name" id="bankName" />
                            {errors.bank_name && <span className="text-danger d-block error">{errors.bank_name.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                            <Label for="accountNumber">Enter Account Number<span className="text-danger">*</span></Label>
                            <input {...addMerchant_bankDetails("account_number", {
                                required: 'Please Enter Account Number',
                                pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Account Number' }
                            })} type="text" className="form-control-sm form-control" placeholder="Account Number" id="accountNumber" />
                            {errors.account_number && <span className="text-danger d-block error">{errors.account_number.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                            <Label for="ifscCode">Enter IFSC Code<span className="text-danger">*</span></Label>
                            <input {...addMerchant_bankDetails("ifsc_code", { required: 'Please Enter IFSC Code' })} className="form-control-sm form-control" placeholder="IFSC Code" id="ifscCode" />
                            {errors.ifsc_code && <span className="text-danger d-block error">{errors.ifsc_code.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                            <Label for="branchName">Enter Branch Name<span className="text-danger">*</span></Label>
                            <input {...addMerchant_bankDetails("branch", { required: 'Please Enter Branch Name' })} className="form-control-sm form-control" placeholder="Branch Name" id="branchName" />
                            {errors.branch && <span className="text-danger d-block error">{errors.branch.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                            <Label for="chequeCopy">Upload Cheque Book Copy<span className="text-danger">*</span></Label>
                            <input {...addMerchant_bankDetails("cheque_copy", { required: bankDetails !== undefined && bankDetails.cancel_cheque_copy !== undefined && bankDetails.cancel_cheque_copy !== "" ? false : true && 'Please Add Cheque Book Copy' })} type="file" onChange={handleFileInput} className="form-control-sm form-control" id="chequeCopy" />
                            {bankDetails !== undefined && bankDetails.cancel_cheque_copy !== undefined && bankDetails.cancel_cheque_copy !== null && bankDetails.cancel_cheque_copy !== "" &&
                                <div className={`edit-brand-logo download-img-con ${extension(bankDetails.cancel_cheque_copy) === "pdf" && 'pdf-con'} mt-2`}>
                                    {extension(bankDetails.cancel_cheque_copy) === "pdf" && <span className="pdf-file">{bankDetails.cancel_cheque_copy}</span>}
                                    <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_MERCHANT + bankDetails.cancel_cheque_copy} />
                                </div>}
                            {errors.cheque_copy && <span className="text-danger d-block error">{errors.cheque_copy.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} className="mt-2 text-right">
                        <Button className="ctm-btn btn-sm" color="primary"> Save</Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default BankDetails;
