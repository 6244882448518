import React from 'react';
import { Row, Col, CardTitle, Table} from 'reactstrap';
import {zone_category_id} from '../../../config';
import { checkData } from '../../../ObjectExist';
import { toast } from 'react-toastify';

const ZoneCode = (props) => {
    return (
        <div>
            <div className="sub-page-header">
                <CardTitle tag="h4" className="sub-page-title">Zone Code</CardTitle>
            </div>
            <Row>
                <Col sm={12}>
                <Table responsive className="text-center custom-table">
                    <thead>
                        <tr>
                            {checkData(zone_category_id) && 
                            Object.entries(zone_category_id).map(([key,value]) => (
                                <th>{value}</th>
                            ))}
                        </tr>
                   </thead>
                    <tbody>
                        <tr>
                            {checkData(zone_category_id) && 
                            Object.entries(zone_category_id).map(([key, value]) => (
                                <td>{key}</td>
                            ))}
                        </tr>
                    </tbody>
                </Table>
                </Col>                  
            </Row>
        </div>
    );
}

export default ZoneCode;