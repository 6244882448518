import React, { useState, useEffect } from 'react';
import { Row, Col, Button, CardTitle, FormGroup, Form, Label, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2'
import { StateList } from '../manageMasters/StateList';
import { errorHandling } from '../../utlis';
import { baseString } from '../../config';
import { toast } from 'react-toastify';


const BillingDetails = (props) => {
    const [loader, setLoader] = useState(false);
    const { register: addMerchant_address, handleSubmit: merchantAddressSubmit, formState: { errors: errorMerchantAdrs }, reset: merchantAdrsReset } = useForm();



    useEffect(() => {
        viewBillingDetails();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const viewBillingDetails = () => {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_ADDRESSADD}/view`,
            data: { "merchant_id": props.merchantId },
            headers: { "token": window.btoa(baseString) }
        })
            .then(function (response) {
                let myResponse = response.data[0];
                merchantAdrsReset(myResponse);
            })
            .catch(function (err) {
                errorHandling(err, "reload");
            });
    }

    const onAddMerchantAddress = (data) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("merchant_id", props.merchantId);
        formData.append("address", data.address);
        formData.append("pincode", data.pincode);
        formData.append("city", data.city);
        formData.append("state_id", data.state_id);
        formData.append("contact_number", data.contact_number);
        formData.append("email", data.email);

        axios({
            method: "post",
            url: process.env.REACT_APP_MERCHANT_ADDRESSADD,
            data: formData,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false);
            if (res.data.error === false) {
                Swal.fire({
                    title: 'Success',
                    text: res.data.msg,
                    icon: 'success',
                })
            }
        })
            .catch((err) => {
                setLoader(false);
                errorHandling(err, "");
            });
    };


    return (
        <>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Form onSubmit={merchantAddressSubmit(onAddMerchantAddress)}>
                <div className="sub-page-header">
                    <CardTitle tag="h4" className="sub-page-title">Billing Detail</CardTitle>
                </div>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="bill_adrs">Billing Address<span className="text-danger">*</span></Label>
                            <input {...addMerchant_address("address", { required: 'Please Enter Address' })} className="form-control-sm form-control" placeholder="Address" id="address" />
                            {errorMerchantAdrs.address && <span className="text-danger d-block error">{errorMerchantAdrs.address.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="city">City<span className="text-danger">*</span></Label>
                            <input {...addMerchant_address("city", {
                                required: 'Please Enter City',
                                pattern: { value: /^[a-z ,.'-]+$/i, message: 'Please Enter Valid City' }
                            })} className="form-control-sm form-control" placeholder="City" id="city" />
                            {errorMerchantAdrs.city && <span className="text-danger d-block error">{errorMerchantAdrs.city.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="state">Select State<span className="text-danger">*</span></Label>
                            <div className="position-relative">
                                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                <select {...addMerchant_address("state_id", { required: 'Please Select State' })} className="form-control-sm form-control" id="state">
                                    <option value="">Select State</option>
                                    {StateList.map((item, index) => (
                                        <option value={item.state_id}>{item.state_name}</option>
                                    ))
                                    }
                                </select>
                                {errorMerchantAdrs.state_id && <span className="text-danger d-block error">{errorMerchantAdrs.state_id.message}</span>}
                            </div>
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="pincode">Pincode<span className="text-danger">*</span></Label>
                            <input {...addMerchant_address("pincode", {
                                required: 'Please Enter Pincode',
                                minLength: { value: 6, message: "at least 6 digits" },
                                maxLength: { value: 6, message: "maximum 6 digits" },
                                pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Pincode' }
                            })} className="form-control-sm form-control" placeholder="Pincode" id="pincode" />
                            {errorMerchantAdrs.pincode && <span className="text-danger d-block error">{errorMerchantAdrs.pincode.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="mobile_no">Mobile Number<span className="text-danger">*</span></Label>
                            <input {...addMerchant_address("contact_number", {
                                required: 'Please Enter Mobile Number',
                                minLength: { value: 10, message: "Please Enter 10 Digit Mobile Number" },
                                maxLength: { value: 10, message: "Please Enter 10 Digit Mobile Number" },
                                pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Mobile Number' }
                            })} className="form-control-sm form-control" placeholder="Mobile Number" id="mobile_no" />
                            {errorMerchantAdrs.contact_number && <span className="text-danger d-block error">{errorMerchantAdrs.contact_number.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="email">Email<span className="text-danger">*</span></Label>
                            <input {...addMerchant_address("email", {
                                required: "Please Enter Email",
                                pattern: { value: /\S+@\S+\.\S+/, message: 'Please Enter Valid Email' }
                            })} className="form-control-sm form-control" placeholder="Email" id="email" />
                            {errorMerchantAdrs.email && <span className="text-danger d-block error">{errorMerchantAdrs.email.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} className="mt-2 text-right">
                        <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                    </Col>
                </Row>
            </Form>

        </>
    );
}

export default BillingDetails;
