import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import { Button } from 'reactstrap';
import { errorHandling } from "../utlis";
import {checkUndeNullBlank } from '../ObjectExist';
import { baseString } from '../config';
import { toast } from 'react-toastify';


const Status = (props) => {    
    const StatusUpdate = () => {
        axios({
                method: "post",
                url: `${props.statusUrl}/status`,
                data: props.data,
                headers: { "token": window.btoa(baseString) }
            })
            .then((res) => {
                if (res.data.error === false) {
                    props.reListing()

                    if(checkUndeNullBlank(props.channelInteMsg)){
                        
                        toast.success(res.data.msg.replace("Store", props.channelInteMsg),{position:'top-right'})
                    }else{
                        toast.success(res.data.msg,{position:'top-right'})

                    }
                }
            })
            .catch((err) => {errorHandling(err,"change status")});
    };

    return ( 
        <Button outline color = { `${props.valStatus===1 ? "success": "danger"}` }
        className = "ctm-btn btn-sm btn-active"
        onClick = { StatusUpdate } > { props.valStatus === 1 ? 'Enable' : 'Disabled' } </Button>
    );
}

export default Status;