import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, UncontrolledTooltip, InputGroup, InputGroupAddon, InputGroupText, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Spinner, Badge, Label, Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink } from 'reactstrap';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faChevronDown, faArrowUp,faTruck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { delivery_type_id, order_ndrs, ndr_actions, download_delay, baseString } from '../../config';
import { connect } from 'react-redux';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { ChevronLeft, ChevronRight, Copy } from 'react-feather';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { changeIntoDateYmd, copyToClipBoard, moreText, changeIntoDate, errorHandling, remExtraSpace } from '../../utlis';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { DatePicker, DateRangePicker } from 'rsuite';
import TrackOrder from "../Orders/TrackOrder";
import classnames from 'classnames';
import { Pie } from 'react-chartjs-2';

const LiveNdr = (props) => {
  const [searchValue, setSearchValue] = useState(' ');
  const [liveNdrList, setLiveNdrList] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1)
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({});
  const [loader, setLoader] = useState(false);
  const [modalTakeAction, setModalTakeAction] = useState(false);
  const [modalUploadNDR, setModalUploadNDR] = useState(false);
  const [filterDate, setFilterDate] = useState();
  const [excelFile, setExcelFile] = useState();
  const [ndrId, setNdrId] = useState('');
  const [orderTracking, setOrderTracking] = useState({});
  const [statusRecheckOrderId, setStatusRecheckOrderId] = useState([]);
  const [tempClass, setTempClass] = useState(null);
  const [merchants, setMerchants] = useState([]);
  const [sideAnim, setSideAnim] = useState(false);
  const [lastOrderStatus, setlastOrderStatus] = useState();
  const [activeTab, setActiveTab] = useState('0');
  const dropFilterCount = 20;
  const { allowedRange } = DateRangePicker;
  const history = useHistory();
  const closeTracker = (data) => {
    setSideAnim(!sideAnim)
    setOrderTracking({});
    setTempClass(null);
  }
  const orderTrack = (orderId, lastStatus) => {
    setLoader(true);
    setlastOrderStatus(lastStatus)
    setStatusRecheckOrderId(orderId)
    let data = { "order_id": orderId }
    // axios.post(`${process.env.REACT_APP_TRACK_ORDER}`,data)
    axios({
      method: "post",
      url: process.env.REACT_APP_TRACK_ORDER,
      data: data,
      headers: { "token": window.btoa(baseString) }
    })
      .then((res) => {
        setLoader(false);
        if (res.data !== undefined && res.data.fatal !== true) {
          if (parseInt(res.data.errno) > 0) {
            if (res.data.sqlMessage !== undefined) {
                          toast.error(res.data.sqlMessage,{position:'top-right'})
            }
          } else {
            if (res.fatal !== true && res.error !== true) {
              setOrderTracking(res.data.data)
            }
            else {
              Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
            }
          }
        }
      }).catch((err) => {
        errorHandling(err, "")
        setLoader(false);
      });
  }
  const toggleTakeAction = (id) => {
    setModalTakeAction(true);
    setNdrId(id)
  }
  const toggleTakeActionClose = (id) => {
    setModalTakeAction(false);
    setNdrId('')
  }
  const toggleUploadNDR = () => {
    setModalUploadNDR(!modalUploadNDR);
    resetuploadfile();
    if (!modalUploadNDR) { setExcelFile(); }
  }

  const onDateChange = (e) => {
    setFilterDate(e.text);
  };

  const toggleActionTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
    AllNdrList(tab);
  }

  const [ndrStatus, setNdrStatus] = useState([]);
  const NdrStatusMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "NDR Status"
  }
  const opt_ndrStatus = [];
  for (const property in order_ndrs) {
    let tempObj = { label: `${order_ndrs[property]}`, value: property };
    opt_ndrStatus.push(tempObj);
  }
  var opt_merchants = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {}
      if (merchantName && merchantId) {
        tempObj = { label: merchantName, value: merchantId }
        opt_merchants.push(tempObj)
      }
      return true
    });
  }
  const merchantsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }
  const [allCourier, setAllCourier] = useState([]);
  const allCourierMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "All Carriers"
  }
  var opt_allCourier = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)) {
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function (key) {
      let tempObj = { label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }
      return opt_allCourier.push(tempObj)
    });
  }

  const AllNdrList = (action_id) => {
    setMerchants([]);
    setAllCourier([]);
    setNdrStatus([]);
    setFilterInput({});
    setFilterDate();
    reset();

    let data = { "is_live": 1 }

    data.action = action_id !== undefined && action_id;

    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setLoadertable(true)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_NDR}?page=${page}&per_page=${selectperpage}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false)
      if (res.data.error === false) {
        setLiveNdrList(res.data);
      } else {
        Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
      }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err, "reload");
    });
  }
  useEffect(() => {
    AllNdrList(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoadertable(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(liveNdrList.data) && checkUndeNullBlank(liveNdrList.totalPage)) {
      // &&checkUndeNullBlank(liveNdrList.totalPage).length>page.length
      if (liveNdrList.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(liveNdrList.data).length)
        setPage(page + 1)
        setLoadertable(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(liveNdrList.data)
      if (currentCount > Object.entries(liveNdrList.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { register: filter, handleSubmit: filterliveNdrListSubmit, watch: filterWatch, reset } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onFilterliveNdrList = (data, pagePerpage) => {
    pagePerpage.page = 1
    if ((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(data.delivery_type_id) || checkData(ndrStatus) || checkData(allCourier) || checkData(merchants) || checkUndeNullBlank(data.attemped_count)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      toast.info('Select Your Filtering Preference');
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    let byData = {}
    let tmpNdrStatus = [];
    let courierId = [];
    let merchantsId = [];
    if (checkData(ndrStatus)) {
      ndrStatus.map((item) => (
        tmpNdrStatus.push(item.value)
      ));
    }
    if (checkData(allCourier)) {
      allCourier.map((item) => (
        courierId.push(item.value)
      ));
    }
    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }

    if (checkUndeNullBlank(courierId)) {
      byData.courier_id = courierId
    }
    setLoader(true)
    if (data === undefined) {
      data = filterInput
    }
    let mId = checkUndeNullBlank(merchantsId) ? merchantsId : '';

    byData = { "action": activeTab, "is_live": 1, "search_field": checkUndeNullBlank(data.search_field) ? data.search_field : '', "search_value": checkUndeNullBlank(data.search_value) ? remExtraSpace(data.search_value) : '', "ndr_status": checkUndeNullBlank(tmpNdrStatus) ? tmpNdrStatus : '', "delivery_type_id": checkUndeNullBlank(data.delivery_type_id) ? data.delivery_type_id : '', "merchant_ids": mId, "courier_id": checkUndeNullBlank(courierId) ? courierId : '', "attemped_count": checkUndeNullBlank(data.attemped_count) ? data.attemped_count : '' }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_NDR}?page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setLiveNdrList(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  };
  const exportNdr = () => {
    let data = filterInput
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    data.action = parseInt(activeTab)
    data.is_export = 1
    data.is_live = 1
    setLoader(true);
    // axios.post(process.env.REACT_APP_ALL_NDR, data)
    axios({
      method: "post",
      url: process.env.REACT_APP_ALL_NDR,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
        if (parseInt(res.data.errno) > 0) {
          setLoader(false)
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          if (res.data.is_export === 1) {
            setTimeout(function () {
              setLoader(false);
              history.push("/my-downloads");
            }, download_delay);
          }
        }
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err, "");
    });

  }
  const { register: takeaction, handleSubmit: handleTakeAction, formState: { errors }, reset: resetAction } = useForm();
  const submitTakeAction = (data) => {
    data.ndr_id = ndrId
    data.reattempt_date = changeIntoDateYmd(filterDate)
    // axios.post(process.env.REACT_APP_SINGLE_NDR_UPDATE, data)
    axios({
      method: "post",
      url: process.env.REACT_APP_SINGLE_NDR_UPDATE,
      data: data,
      headers: { "token": window.btoa(baseString) }
    })
      .then((res) => {
        setLoader(false)
        if (res.data.error === false) {
          Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success' }).then(okay => {
            if (okay) {
              setNdrId('');
              if (checkData(filterInput)) {
                onCommonFilter();
              } else {
                AllNdrList();
              }
              setModalTakeAction(false);
              resetAction();
            }
          });
        }
      }).catch((err) => {
        setLoader(false)
        errorHandling(err, "");
      });
  }
  const { register: uploadndr, handleSubmit: handleUploadNDR, formState: { errors: errors2 }, reset: resetuploadfile } = useForm();
  const submitUploadNDR = (e) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("file", excelFile);
    if (props.user[0].user_type === "Super") {
      formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');
    } else {
      formData.append("merchant_id", checkData(props.user) ? props.user[0].id : '');
      formData.append("admin_id", process.env.REACT_APP_ADMIN_ID);
    }
    // axios
    // .post(`${process.env.REACT_APP_MULTIPLE_NDR_UPLOAD}`, formData)
    axios({
      method: "post",
      url: process.env.REACT_APP_MULTIPLE_NDR_UPLOAD,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      let errorFile = '';
      if (res.data.errorfile !== undefined) {
        errorFile = checkUndeNullBlank(res.data.errorfile) ? process.env.REACT_APP_BASE_URL + res.data.errorfile : '';
      }
      setLoader(false);
      setExcelFile();
      setModalUploadNDR(false);
      if (res.data.error === false) {
        Swal.fire({
          title: 'Success', text: res.data.msg, icon: 'success', allowOutsideClick: false, allowEscapeKey: false,
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>` : ''}</tbody></Table>`,
        }).then(okay => {
          if (okay) {
            AllNdrList();
          }
        });
      } else {
        Swal.fire({
          title: 'Error', text: res.data.msg, icon: 'error', allowOutsideClick: false, allowEscapeKey: false,
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
        }).then(okay => {
          if (okay) {
            AllNdrList();
          }
        });
      }
    })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      });
  }

  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0]);
  }

  const resetForm = () => {
    AllNdrList(activeTab);
  }

  let date = new Date();
  let startDate = date.setDate(date.getDate() + 2);
  let endDate = date.setDate(date.getDate() + 6);


  let actionPendingRows = checkData(liveNdrList) ? checkUndeNullBlank(liveNdrList.TotalRows) ? liveNdrList.TotalRows : 0 : 0;
  let actionTakenRows = 0;

  const data = {
    labels: ['Active', 'Closed'],
    datasets: [
      {
        label: 'Action (counts)',
        data: [actionPendingRows, actionTakenRows],
        backgroundColor: [
          '#fd7e14',
          'rgb(57,218,138)',
        ],
        hoverOffset: 0
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
      },
      title: {
        display: false,
        text: ''
      },
      legend: {
        display: false,
        position: "right",
        labels: { usePointStyle: true }
      },
    },
  };

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      {checkData(orderTracking) && <div className="overlay"></div>}

      <Row>
        <Col sm={12}>
          <div className="p-0 my-3 ndr livendr content-wrapper border-0">

            <CardHeader className="page-header d-flex justify-content-between align-items-start flex-wrap">
              {/* <CardTitle className="page-title">Manage Delivery Failed Shipments 
              </CardTitle> */}
              <div className="card-header-right d-flex flex-wrap align-items-center ml-auto position-relative">
                <div className="ndrPie-main d-none">
                  <div className="d-flex align-items-center ">
                    <div className="ndrPiecon" style={{ maxWidth: "70px", width: "100%" }}>
                      <Pie data={data} options={options} />
                    </div>
                    <div className="ndrpieLabels">
                      <Badge color='warning' className='mb-2'><span className="d-block mb-1">Action Pending </span><span className="d-block">{actionPendingRows}</span></Badge><br />
                      <Badge color='success'><span className="d-block mb-1">Action Taken</span><span>{ }</span></Badge>
                    </div>
                  </div>
                </div>
                <div className="btn-text btn-light" onClick={exportNdr}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Export Data</span>
                </div>
                <div className="btn-text btn-light" onClick={toggleUploadNDR}><span className="icon mx-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Upload Instructions</span>
                </div>
              </div>
            </CardHeader>
            <Card className='shadow border-0 mb-4 p-4'>
              <Row className="mx-0 position-relative">
                <Col sm={12}>
                  <ul className="channels-tabs">
                    <li
                      className={`channels-tab ${activeTab === '0' ? 'active' : ''}`}
                      onClick={() => { toggleActionTab('0'); }}>
                      Pending
                    </li>
                    <li
                      className={`channels-tab ${activeTab === '1' ? 'active' : ''}`}
                      onClick={() => { toggleActionTab('1'); }}>
                      Intiated
                    </li>
                    <li
                      className={`channels-tab ${activeTab === '2' ? 'active' : ''}`}
                      onClick={() => { toggleActionTab('2'); }}>
                      All
                    </li>  
                  </ul>
                  {/* <Nav tabs className="mb-4 border-bottom text-center flex-nowrap">
                    <NavItem>
                      <NavLink className={`warning-ctm ${classnames({ active: activeTab === '0' })}`} onClick={() => { toggleActionTab('0'); }}>
                        <div>
                          <span className="mr-2"></span>
                          <span className="text-nowrap">Action Awaited  asdfaf adsfa asdf</span>
                        </div>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={`success-ctm ${classnames({ active: activeTab === '1' })}`} onClick={() => { toggleActionTab('1'); }}>
                        <div>
                          <span className="mr-2"></span>
                          <span className="text-nowrap">Action Fulfilled czxzcx</span>
                        </div>
                      </NavLink>
                    </NavItem>
                  </Nav> */}
                </Col>
                {/* <Col sm={2} className="ndrPie-main">
                    <div className="d-flex align-items-center ">
                      <div className="ndrPiecon" style={{maxWidth: "70px",width:"100%"}}>
                          <Pie data={data} options={options} />
                      </div>
                      <div className="ndrpieLabels">
                          <Badge color='warning' className='mb-2'><span className="">Action Pending : </span><span className="">{actionPendingRows}</span></Badge><br/>
                          <Badge color='success'><span className="d-block mb-1">Action Taken</span><span>{}</span></Badge>
                      </div>
                    </div>
                </Col> */}
              </Row>
              <Form onSubmit={filterliveNdrListSubmit(onFilterliveNdrList)} className="cardFilter">
                <Row className="mx-0 mb-3 justify-content-start">
                  <Col sm={6} md={5} lg={5} xl={4}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control-sm form-control" onChange={(e) => { if (e.target.selectedOptions[0].text !== "Search By") { setSearchValue("Enter " + e.target.selectedOptions[0].text) } else { setSearchValue('') } }}>
                              <option value="awb_number">AWB Number</option>
                              <option value="customer_name">Consignee Name</option>
                              <option value="customer_mobile">Consignee Phone</option>
                              <option value="order_no">Order No</option>
                              <option value="product_name">Product Name</option>
                            </select>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input {...filter("search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue} />
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <MultiSelect
                          options={opt_ndrStatus}
                          value={ndrStatus}
                          onChange={setNdrStatus}
                          labelledBy="NDR Status"
                          overrideStrings={NdrStatusMultiSelectSettings}
                          className={checkData(ndrStatus) && "active"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  {/* <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select {...filter("attemped_count")} className={`form-control-sm form-control ${checkData(filterWatch("attemped_count")) ? 'valid' : ''}`}>
                        <option value="">Select Attempled Count</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                      </select>
                    </FormGroup>
                  </Col> */}
                  {props.user[0].user_type === "Super" &&
                    <Col sm={6} md={3} lg={3} xl={2}>
                      <FormGroup>
                        <MultiSelect
                          options={opt_merchants}
                          value={merchants}
                          onChange={(e) => { setMerchants(e); }}
                          labelledBy="Select Merchant"
                          overrideStrings={merchantsMultiSelectSettings}
                          className={checkData(merchants) && "active"}
                        />
                      </FormGroup>
                    </Col>}
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <MultiSelect
                          options={opt_allCourier}
                          value={allCourier}
                          onChange={setAllCourier}
                          labelledBy="All Couriers"
                          overrideStrings={allCourierMultiSelectSettings}
                          className={checkData(allCourier) && "active"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select {...filter("delivery_type_id")} className={`form-control-sm form-control ${checkData(filterWatch("delivery_type_id")) ? 'valid' : ''}`}>
                        <option value="">Payment Type</option>
                        {Object.entries(delivery_type_id).map(([key, value]) => (
                          <option value={key}>{value}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" onClick={resetForm} outline>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(liveNdrList) && checkData(liveNdrList.data) && checkUndeNullBlank(liveNdrList.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(liveNdrList.data).length)} of {liveNdrList.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      {props.user[0].user_type === "Super" &&
                        <th className="text-nowrap">Merchant Name</th>
                      }
                  
                      <th className="text-nowrap">Order Details</th>
                      <th className="text-nowrap">Carrier</th>
                      <th className="text-nowrap">Product Details</th>
                      <th className="text-nowrap">COD/Prepaid</th>
                      <th className="text-nowrap">Consignee Details</th>
                      <th className="text-nowrap">Failed Reason</th>
                      <th className="text-nowrap">Your Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(liveNdrList.data) ? liveNdrList.data.map((item, index) => (
                      <tr key={index} className={`${tempClass === item.id && "showTop"}`}>

 
                      
                       {props.user[0].user_type === "Super" && <td>{item.merchantName}</td>}
             
                    <td className="align-middle">
                      <div className="text-nowrap">
                        <FontAwesomeIcon icon={faTruck} /><br />
                        {item.order_no}
                        <br></br>
                        Date-{changeIntoDate(item.order_date)}

                    </div>
                    </td>
                    
                    <td className="align-middle">
                      <div className="list-group-item border-0 p-0 d-inline-flex">
                        <div className="list-content text-left">
                          <div className="">
                            <p className="mb-0">{(item.CourierName)}</p>
                          </div>
                          <div className="d-flex"><span className="mb-1 d-block text-left text-nowrap"  style={{ cursor: 'pointer' }}>AWB - {item.awb_no}</span>
                        </div>
                         
                        </div>
                      </div>
                    </td>
                    {/* <td className="align-middle text-left col-2">
                      

                    </td> */}
                    <td className="align-middle">
                      <div id={`addressTooltip${item.id}`}>{moreText(item.product_name)}</div>
                        <UncontrolledTooltip placement="top" target={`addressTooltip${item.id}`}>
                          {item.product_name}
                        </UncontrolledTooltip>
                        {/* {item.customer_name}<br /> {item.customer_mobile} */}
                    </td>
                    <td className="align-middle">
                      {delivery_type_id[item.delivery_type_id]}<br />{item.product_cod_value}
                      
                    </td>
                    
                    <td className="align-middle text-nowrap">
                      

                      <p className="mb-1">
                        <small className='font-weight-bold'>To</small><br />
                        {item.customer_name}<br /> {item.customer_mobile}
                      </p>
                    </td>
                    
                    {props.user[0].user_type === "Super" ?
                      <td className="align-middle">
                       
                        {order_ndrs[item.ndr_status_id]} <br /> <span className="text-nowrap">NDR - {changeIntoDate(item.created_at)}</span>
                       
                        <>
                          
                        {order_ndrs[item.ndr_status_id]} <br /> <span className="text-nowrap">NDR - {changeIntoDate(item.created_at)}</span>
                        </>
                        
                      </td> :
                      <td>
                         {order_ndrs[item.ndr_status_id]} <br /> <span className="text-nowrap">NDR - {changeIntoDate(item.created_at)}</span>
                      </td>
                      }
                      <td>
                      {item.merchant_remarks} <br></br>
                     Action On- {changeIntoDate(item.merchant_action_reattempt_date)}
                       </td>

                        {/* {props.user[0].user_type === "Super" &&
                          <td>{item.merchantName}</td>
                        }
                        <td className="align-middle">{item.customer_name}<br /> {item.customer_mobile}<br /> {item.product_cod_value + ' (' + delivery_type_id[item.delivery_type_id] + ')'}</td>
                        { <td className="align-middle">{item.awb_no}<br /> {item.product_cod_value}</td> }
                        <td className="align-middle text-left col-2">
                          <div className="list-group-item border-0 p-0 d-inline-flex">
                            <div className="list-icon">
                              <div className="avatar">
                                {item.logo !== null && item.logo !== undefined ?
                                  <img className="img-fluid" src={process.env.REACT_APP_IMAGE_COURIER + item.logo} alt="logo" width="60" />
                                  : <span className="no-img d-flex align-items-center justify-content-center rounded-circle border font-weight-500">M</span>}
                              </div>
                            </div>
                            <div className="list-content text-left">
                              <div className="">
                                <p className="mb-0">{(item.CourierName)}</p>
                              </div>
                              <div className="d-flex">
                                <span className="mb-1 d-block text-primary text-left text-nowrap" onClick={() => { setSideAnim(true); orderTrack(item.order_id, item.order_status_id); setStatusRecheckOrderId([item.id]); setTempClass(item.id) }} style={{ cursor: 'pointer' }}>AWB - {item.awb_no}</span>
                                <span onClick={() => copied(item.awb_no)} className="ml-1 copy-btn" id={`copy${item.id}`}>
                                  <Copy />
                                  <UncontrolledTooltip placement="bottom" target={`copy${item.id}`}>
                                    Copy
                                  </UncontrolledTooltip>
                                </span>
                              </div>
                              <div className="text-nowrap">Order no. -<Link target="_blank" to={`/order-detail/${item.order_id}`}>{item.order_no}</Link></div>
                            </div>
                          </div>
                        </td>
                        <td className="align-middle">{order_ndrs[item.ndr_status_id]} <br /> <span className="text-nowrap">NDR - {changeIntoDate(item.created_at)}</span></td>
                        <td className="align-middle">
                          
                          <span className='d-block'>Total Attempt : {item.total_attempt}</span>
                        </td>
                        <td className="align-middle">
                          {item.merchant_remarks}
                        </td>
                        <td className="align-middle">
                          <div id={`MoreProduct${item.id}`}>{moreText(item.product_name)}</div>
                          <UncontrolledTooltip placement="top" target={`MoreProduct${item.id}`}>
                            {item.product_name}
                          </UncontrolledTooltip>
                          {checkUndeNullBlank(item.order_date) &&
                            <>
                              <br /><span className="text-nowrap">Order - {changeIntoDate(item.order_date)}</span>
                            </>
                          }
                        </td> */}
                      </tr>
                    )) :
                      loadertable ? <tr><td colSpan="5" className="text-center"><LoaderDefault /></td></tr>
                        : <tr><td colSpan="5" className="text-center"><NodataFound /></td></tr>}
                  </tbody>
                </Table>
                {checkData(liveNdrList) && checkData(liveNdrList.data) && checkUndeNullBlank(liveNdrList.TotalRows) ?
                  <>
                    {liveNdrList.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>

            </Card>

            <Modal isOpen={modalTakeAction} toggle={toggleTakeAction} size="md" scrollable={true}>
              <ModalHeader toggle={toggleTakeActionClose}>Take Action</ModalHeader>
              <ModalBody>
                <Form onSubmit={handleTakeAction(submitTakeAction)}>
                  <Row className="mx-0 justify-content-center downloadModal">
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="action_id">Select Action<span className="text-danger">*</span></Label>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <select {...takeaction("ndr_action_id", { required: 'Please Select Action' })} id="action_id" className="form-control-sm form-control">
                            <option value="">Select Action</option>
                            {Object.entries(ndr_actions).map(([key, value]) => (
                              <option value={key}>{value}</option>
                            ))}
                          </select>
                        </div>
                        {errors.ndr_action_id && <span className="text-danger d-block error">{errors.ndr_action_id.message}</span>}
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="reattempt_date">Reattempt Date<span className="text-danger">*</span></Label>
                        {/* <DatePicker value={filterDate} onChange={date => setFilterDate(date)} disabledDate={beforeToday()} format="dd-MM-yyyy" change={onDateChange}  /> */}
                        <DatePicker value={filterDate} onChange={date => setFilterDate(date)} disabledDate={allowedRange(startDate, endDate)} format="dd-MM-yyyy" change={onDateChange} />
                      </FormGroup>
                    </Col>
                    <Col sm={12}>
                      <FormGroup>
                        <Label for="reattempt_date">Remarks</Label>
                        <textarea  {...takeaction("ndr_remarks", { required: false })} rows="4" id="remarks" placeholder="Input Remarks" className="form-control-sm form-control" />
                        {errors.ndr_remarks && <span className="text-danger d-block error">{errors.ndr_remarks.message}</span>}
                      </FormGroup>
                    </Col>
                    <Col sm={12} className="text-right">
                      <Button className="btn ctm-btn btn-sm" color="primary">Submit</Button>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
            <Modal isOpen={modalUploadNDR} toggle={toggleUploadNDR} scrollable={true} >
              <ModalHeader toggle={toggleUploadNDR}>Upload Your NDR Instructions</ModalHeader>
              <ModalBody>
                <Form onSubmit={handleUploadNDR(submitUploadNDR)}>
                  <Row className="m-0 pb-1">
                  
                    <Col sm={12} className="m-auto pt-1 p-0">
                      <FormGroup>
                        <input {...uploadndr("excelUpload", {
                          required: 'Please Upload XLSX File',
                          validate: {
                            lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                            acceptedFormats: files =>
                              ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                files[0]?.type
                              ) || 'Only .xslx file allowed',
                          },
                        })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                        <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                        {errors2.excelUpload && <span className="text-danger d-block error">{errors2.excelUpload.message}</span>}
                      </FormGroup>
                    </Col>
                    <Col sm={12} className="text-center">
                      <Button className="btn ctm-btn btn-sm" color="primary">Upload</Button>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>

          </div>
        </Col>

      </Row>
      {checkData(orderTracking) && <TrackOrder orderTracking={orderTracking} toggleTrack={sideAnim ? "show" : "hide"} closeTracker={(e) => { closeTracker(e) }} position="fixed" lastStatus={lastOrderStatus} statusRecheckOrderId={statusRecheckOrderId} user={props.user} />}
    </div>
  );
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}

export default connect(mapDispatchToProps)(LiveNdr);