import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Table, Spinner } from 'reactstrap';
import { ArrowLeft, Download } from 'react-feather';
import { useHistory, useParams } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
import axios from 'axios';
import { errorHandling, changeIntoDate, changeIntoDateYmd } from '../../utlis';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { ToWords } from 'to-words';
import { baseString, download_delay } from '../../config';
import moment from 'moment';
import { toast } from 'react-toastify';

const InvoiceDetail = (props) => {
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [loader, setLoader] = useState(false);

  const { invoiceId } = useParams();
  const toWords = new ToWords();
  useEffect(() => {
    viewInvoiceDetail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId])
  const viewInvoiceDetail = () => {
    setLoader(true);
    let data = {}
    if (props.user[0].user_type === "Super") {
      data = { "invoice_id": invoiceId, "admin_id": 1 }
    } else {
      data = { "invoice_id": invoiceId, "merchant_id": props.user[0].id }
    }
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_INVOICE_DETAIL,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false)
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            toast.error(res.data.sqlMessage,{position:'top-right'})

          }
        } else {
          setInvoiceDetail(res.data.data);
        }
      }
    })
      .catch((err) => {
        setLoader(false)
        errorHandling(err, "reload");
      });
  }


  let history = useHistory();
  const goToPreviousPath = () => {
    history.goBack()
  }
  const exportInvoicePDF = () => {
    let data = { "invoice_id": invoiceId }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setLoader(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_INVOICE_DOWNLOAD_PDF,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
        if (parseInt(res.data.errno) > 0) {
          setLoader(false);
          if (res.data.sqlMessage !== undefined) {
            toast.error(res.data.sqlMessage,{position:'top-right'})

          }
        } else {
          if (res.data.error === false) {
            setTimeout(function () {
              setLoader(false);
              history.push("/my-downloads");
            }, download_delay);
          }
        }
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err, "");
    });
  }
  const exportInvoiceExcel = () => {
    let date = checkData(invoiceDetail) && checkData(invoiceDetail.invoice_data) ? changeIntoDateYmd(invoiceDetail.invoice_data[0].invoice_date) : moment()
    let firstDay = moment(date).startOf('month').format("YYYY-MM-DD");
    let lastDay = moment(date).endOf('month').format("YYYY-MM-DD");
    let merchanId = checkData(invoiceDetail) && checkData(invoiceDetail.invoice_data) ? invoiceDetail.invoice_data[0].merchant_id : ''
    let data = { "invoice_id": invoiceId, "start_date": firstDay, "end_date": lastDay }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
      data.merchant_ids = [merchanId]
    } else {
      data.merchant_id = props.user[0].id
    }
    setLoader(true);
    data.is_export = 1
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_FREIGHT_LIST,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
        if (parseInt(res.data.errno) > 0) {
          setLoader(false);
          if (res.data.sqlMessage !== undefined) {
               toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          if (res.data.error === false) {
            setTimeout(function () {
              setLoader(false);
              history.push("/my-downloads");
            }, download_delay);
          }
        }
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err, "");
    });
  }

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 invoiceDetail shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                <CardTitle className="mb-0 page-title">Invoice Details</CardTitle>
                <div className="card-header-right d-flex align-items-center ml-auto">
                  <div onClick={exportInvoicePDF} className="btn-text btn-light"><span className="icon"><Download /></span><span>Export PDF</span></div>
                  <div onClick={exportInvoiceExcel} className="btn-text btn-light"><span className="icon"><Download /></span><span>Export Freight Excel</span></div>
                  <div onClick={goToPreviousPath} className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back to Invoices</span></div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                {checkData(invoiceDetail.invoice_data) ?
                  <div className='invoice-table-con'>
                    <Table className='invoice-table custom-table mb-0 border-bottom-0' bordered>
                      <thead>
                        <tr>
                          <th>
                            <div className="logo-wrapper">
                              <img src={Logo} alt="logo" style={{ maxWidth: '150px' }} />
                            </div>
                          </th>
                          <th>
                            <h3 className="text-center">Tax Invoice</h3>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td rowSpan={3} className="col-4">
                            <div>
                              <div className="ivc_box">
                                {/* <span className="ivc_ttl d-block"></span> */}
                                <span className="ivc_ttl d-block">{checkData(invoiceDetail.company) && invoiceDetail.company.name}</span>
                                {/* <span className="ivc-text">K-81 B, Basement, Kalkaji New Delhi-110019</span> */}
                                <span className="ivc-text">{checkData(invoiceDetail.company) && invoiceDetail.company.address}</span>
                              </div>
                              <div className="ivc_box">
                                <span className="ivc_ttl mr-1">GSTIN/UIN : </span>
                                {/* <span className="ivc-text"></span> */}
                                <span className="ivc-text">{checkData(invoiceDetail.company) && invoiceDetail.company.GSTIN}</span>
                              </div>
                              <div className="ivc_box">
                                <span className="ivc_ttl mr-1">State Name : </span>
                                {/* <span className="ivc-text">,</span>*/}
                                <span className="ivc-text">{checkData(invoiceDetail.company) && invoiceDetail.company.state},</span>
                                <span className="ivc_ttl mr-1">CODE : </span>
                                {/* <span className="ivc-text"></span> */}
                                <span className="ivc-text">{checkData(invoiceDetail.company) && invoiceDetail.company.code}</span>
                              </div>
                              <div className="ivc_box">
                                <span className="ivc_ttl mr-1">PAN No. : </span>
                                {/* <span className="ivc-text"></span> */}
                                <span className="ivc-text">{checkData(invoiceDetail.company) && invoiceDetail.company.pan}</span>
                              </div>
                              <div className="ivc_box">
                                <span className="ivc_ttl mr-1">CIN : </span>
                                {/* <span className="ivc-text"></span>  */}
                                <span className="ivc-text">{checkData(invoiceDetail.company) && invoiceDetail.company.CIN}</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-block">Invoice No.</span>
                              <span className="ivc-text">{invoiceDetail.invoice_data[0].invoice_no}</span>
                            </div>
                          </td>
                          <td>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-block">Invoice Date</span>
                              <span className="ivc-text">{changeIntoDate(invoiceDetail.invoice_data[0].invoice_date)}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-block">Reverse Charge</span>
                              <span className="ivc-text">N</span>
                            </div>
                          </td>
                          <td>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-block">Due Date </span>
                              <span className="ivc-text">{changeIntoDate(invoiceDetail.invoice_data[0].due_date)}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-block">Mode of Payment</span>
                              <span className="ivc-text">{checkData(invoiceDetail.invoice) && invoiceDetail.invoice.mode}</span>
                            </div>
                          </td>
                          <td>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-block">Payment Terms</span>
                              <span className="ivc-text">{checkData(invoiceDetail.invoice) && invoiceDetail.invoice.payment_terms}</span>
                            </div>
                          </td>
                        </tr>
                        <tr className='bg-light'>
                          <th><div>Details of Receiver (Billed to)</div></th>
                          <th colSpan={2}><div>Details of Consignee (Shipped to)</div></th>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ minHeight: "100px" }}>
                              <span className="ivc_ttl d-block">{invoiceDetail.invoice_data[0].company_name}</span>
                              <span className="ivc-text">{invoiceDetail.invoice_data[0].contact_number}</span><br />
                              <span className="ivc-text">{invoiceDetail.invoice_data[0].address}</span><br />
                              <span className="ivc-text">{invoiceDetail.invoice_data[0].city}</span>,
                              <span className="ivc-text">{checkUndeNullBlank(invoiceDetail.state_name) && invoiceDetail.state_name}</span>,
                              <span className="ivc-text">{invoiceDetail.invoice_data[0].pincode}</span><br />
                              <span className="ivc_ttl mr-1">GSTIN/UIN : </span> <span className="ivc-text">{checkData(invoiceDetail.invoice_data) && invoiceDetail.invoice_data[0].gst_number}</span><br />
                              <span className="ivc_ttl mr-1">PAN No. : </span> <span className="ivc-text">{checkData(invoiceDetail.invoice_data) && invoiceDetail.invoice_data[0].pan_card_no}</span>
                            </div>

                          </td>
                          <td colSpan={2}>
                            <div style={{ minHeight: "100px" }}>
                              <span className="ivc_ttl d-block">{invoiceDetail.invoice_data[0].company_name}</span>
                              <span className="ivc-text">{invoiceDetail.invoice_data[0].contact_number}</span><br />
                              <span className="ivc-text">{invoiceDetail.invoice_data[0].address}</span><br />
                              <span className="ivc-text">{invoiceDetail.invoice_data[0].city}</span>,
                              <span className="ivc-text">{checkUndeNullBlank(invoiceDetail.state_name) && invoiceDetail.state_name}</span>,
                              <span className="ivc-text">{invoiceDetail.invoice_data[0].pincode}</span><br />
                              <span className="ivc_ttl mr-1">GSTIN/UIN : </span> <span className="ivc-text">{checkData(invoiceDetail.invoice_data) && invoiceDetail.invoice_data[0].gst_number}</span><br />
                              <span className="ivc_ttl mr-1">PAN No. : </span> <span className="ivc-text">{checkData(invoiceDetail.invoice_data) && invoiceDetail.invoice_data[0].pan_card_no}</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Table className='invoice-table invoice-table-min custom-table align-top mb-0' bordered>
                      <thead>
                        <tr className='bg-light'>
                          <th className='col-1 text-center'>S No.</th>
                          <th className='col-4 text-center'>Particulars</th>
                          <th className='col-1 text-center'>HSN/SAC</th>
                          <th className='col-1 text-center'>GST Rate</th>
                          <th className='col-2 text-center'></th>
                          <th className='col-3 text-center'>Amount (₹)</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr>
                          <td rowSpan={2} className="text-center">
                            <div>1</div>
                          </td>
                          <td rowSpan={2} className="text-center">
                            <div>Forward and RTO Freight</div>
                          </td>
                          <td rowSpan={2} className="text-center">{checkData(invoiceDetail.invoice) && invoiceDetail.invoice.HSN}</td>
                          <td rowSpan={2} className="text-center">
                            <div>{checkData(invoiceDetail.invoice) && invoiceDetail.invoice.gst_rate}</div>
                          </td>
                          <td className='h-400'></td>
                          <td className="text-center">{checkUndeNullBlank(invoiceDetail.invoice_data) && invoiceDetail.invoice_data[0].sub_total}</td>
                        </tr>
                        <tr>
                          {/* <th className='align-bottom'></th> */}
                          <th className='align-bottom'>
                            <span className="ivc_ttl d-block">Assessable Value</span>
                            {checkUndeNullBlank(invoiceDetail.invoice_data) && invoiceDetail.invoice_data[0].state_id !== 86 ?
                              <span className="ivc_ttl d-block">IGST(18%)</span>
                              :
                              <>
                                <span className="ivc_ttl d-block">CGST(9%)</span>
                                <span className="ivc_ttl d-block">SGST(9%)</span>
                              </>}
                          </th>
                          <th className='align-bottom text-center'>
                            <span className="ivc_ttl d-block">{checkUndeNullBlank(invoiceDetail.invoice_data) && invoiceDetail.invoice_data[0].sub_total}</span>
                            {checkUndeNullBlank(invoiceDetail.invoice_data) && invoiceDetail.invoice_data[0].state_id !== 86 ?
                              <span className="ivc_ttl d-block">{checkUndeNullBlank(invoiceDetail.invoice_data) && invoiceDetail.invoice_data[0].total_gst.toFixed(2)}</span>
                              :
                              <>
                                <span className="ivc_ttl d-block">{checkUndeNullBlank(invoiceDetail.invoice_data) && (invoiceDetail.invoice_data[0].total_gst / 2).toFixed(2)}</span>
                                <span className="ivc_ttl d-block">{checkUndeNullBlank(invoiceDetail.invoice_data) && (invoiceDetail.invoice_data[0].total_gst / 2).toFixed(2)}</span>
                              </>}
                          </th>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr className='bg-light'>
                          <th colSpan={4} className="text-center">Total Invoice Value (INR)</th>
                          <th className="text-center"></th>
                          <th className="text-center">{checkUndeNullBlank(invoiceDetail.invoice_data) && invoiceDetail.invoice_data[0].net_amt.toFixed(2)}</th>
                        </tr>
                        <tr>
                          <td colSpan={4}>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-block">Amount Chargeable (in words) </span>
                              <span className="ivc-text">{checkUndeNullBlank(invoiceDetail.invoice_data) && toWords.convert(invoiceDetail.invoice_data[0].net_amt, { currency: true, ignoreZeroCurrency: true })} /-</span>
                            </div>
                          </td>
                          <td></td>
                          <td className="text-right">E. & E.O</td>
                        </tr>
                      </tfoot>
                    </Table>
                    <Table className='invoice-table custom-table align-top mt-5' bordered>
                      <tbody>
                        {/* <tr className='bg-light'>
                    <th className='col-4 text-center' rowSpan={2}>HSN/SAC</th>
                    <th className='col-2 text-center' rowSpan={2}>Assessable Value</th>
                    <th colSpan={3} className="text-center">Rate @ {checkData(invoiceDetail.invoice) && invoiceDetail.invoice.gst_rate}</th>
                  </tr>  
                  <tr>
                    <th>CGST </th>  
                    <th>SGST</th>  
                    <th>IGST</th>  
                  </tr>      
                  <tr>
                    <td>{checkData(invoiceDetail.invoice) && invoiceDetail.invoice.HSN}</td>  
                    <td>{checkUndeNullBlank(invoiceDetail.invoice_data) && invoiceDetail.invoice_data[0].sub_total}</td>  
                    <td>{checkUndeNullBlank(invoiceDetail.invoice_data) && invoiceDetail.invoice_data[0].state_id === 86 ? (invoiceDetail.invoice_data[0].total_gst/2).toFixed(2) : 0}</td>  
                    <td>{checkUndeNullBlank(invoiceDetail.invoice_data) && invoiceDetail.invoice_data[0].state_id === 86 ? (invoiceDetail.invoice_data[0].total_gst/2).toFixed(2) : 0}</td>  
                    <td>{checkUndeNullBlank(invoiceDetail.invoice_data) && invoiceDetail.invoice_data[0].state_id !== 86 ? invoiceDetail.invoice_data[0].total_gst.toFixed(2) : 0}</td>  
                  </tr>      
                  <tr>
                    <th className='text-right'>Total</th>  
                    <th>{checkUndeNullBlank(invoiceDetail.invoice_data) && invoiceDetail.invoice_data[0].net_amt.toFixed(2)}</th>  
                    <td colSpan={3}></td>   
                  </tr>      
                  <tr>
                    <td colSpan={5}>
                      <div className="ivc_box">
                        <span className="ivc_ttl">Tax Amount (in words) : </span>
                        <span className="ivc-text">{checkUndeNullBlank(invoiceDetail.invoice_data) && toWords.convert(invoiceDetail.invoice_data[0].total_gst, { currency: true, ignoreZeroCurrency: true })} /-</span>                          
                      </div>  
                    </td>     
                  </tr>       */}
                        <tr className='bg-light'>
                          <td>
                            <div className="ivc_box">
                              <span className="ivc_ttl">Declaration : </span>
                            </div>
                          </td>
                          <td>
                            <div className="ivc_box" colSpan='2'>
                              <span className="ivc_ttl">Company Bank Details : </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={2} className='col-6'>
                            <div className="ivc_box">
                              {/* <span className="ivc_ttl d-block">Declaration : </span> */}
                              <div>
                                <span className="ivc-text d-block">1. We declare that this invoice shows the actual price of service described and that all particulars are true and correct.</span>
                                <span className="ivc-text d-block">2. Any discrepancy found in the invoice must be raised within 7 working days of receipt of this Invoice and subject to realisation of balance undisputed amount .</span>
                                <span className="ivc-text d-block">3. This is computer generated invoice and does not require any stamp or signature</span>
                              </div>
                            </div>
                          </td>
                          <td colSpan={2} className='col-6'>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-inline-block" style={{ minWidth: '120px' }}>Bank Name : </span>
                              {/* <span className="ivc-text">Induslnd Bank</span>*/}
                              <span className="ivc-text">{checkData(invoiceDetail.bank_details) && invoiceDetail.bank_details.name}</span>
                            </div>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-inline-block" style={{ minWidth: '120px' }}>A/c No. : </span>
                              {/* <span className="ivc-text">201001656627</span>*/}
                              <span className="ivc-text">{checkData(invoiceDetail.bank_details) && invoiceDetail.bank_details.account_no}</span>
                            </div>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-inline-block" style={{ minWidth: '120px' }}>Branch & IFSC : </span>
                              {/* <span className="ivc-text">Mohan Co-Operate</span>  */}
                              <span className="ivc-text">{checkData(invoiceDetail.bank_details) && invoiceDetail.bank_details.branch}</span>
                            </div>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-inline-block" style={{ minWidth: '120px' }}>Code : </span>
                              {/* <span className="ivc-text">INDB0000735</span>*/}
                              <span className="ivc-text">{checkData(invoiceDetail.bank_details) && invoiceDetail.bank_details.Code}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-right' colSpan={3}>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-block"></span>
                              <span className='signature d-block' style={{ minHeight: "40px" }}></span>
                              <span className="ivc-text">Authorised Signatory</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={5} className="text-center">
                            <span className="ivc-text">**This is computer generated invoice**</span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  : loader ? <div className="w-100 text-center"><Spinner /></div> : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

export default InvoiceDetail;