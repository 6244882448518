import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, Collapse, CardTitle, FormGroup, Form, CardHeader, Label, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { UploadCloud, Download } from 'react-feather';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { errorHandling } from '../../utlis';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { baseString } from '../../config';
import { toast } from 'react-toastify';


const orderData = [
  { id: 1, name: "Merchant Name", value: "merchant.company_name", isChecked: false },
  { id: 2, name: "Merchant Store Name", value: "merchant.brand_name", isChecked: false },
  { id: 3, name: "Merchant Warehouse", value: "merchant_pickup_points.contact_name", isChecked: false },
  { id: 4, name: "Upload Date", value: "orders.created_at", isChecked: false },
  { id: 5, name: "Booking Date", value: "orders.booking_date", isChecked: false },
  { id: 6, name: "AWB Number", value: "orders.awb_no", isChecked: false },
  { id: 7, name: "Order Number", value: "orders.order_no", isChecked: false },
  { id: 8, name: "Reference Number", value: "orders.system_order_no", isChecked: false },
  { id: 9, name: "Consignee Name", value: "orders.customer_name", isChecked: false },
  { id: 10, name: "Origin Pincode", value: "orders.pickup_pincode", isChecked: false },
  { id: 11, name: "Origin City", value: "merchant_pickup_points.pickup_city_name", isChecked: false },
  { id: 12, name: "Origin State", value: "merchant_pickup_points.pickup_state_id", isChecked: false },
  { id: 13, name: "Destination Pincode", value: "orders.destination_pincode", isChecked: false },
  { id: 14, name: "Destination City", value: "order_address.customer_city", isChecked: false },
  { id: 15, name: "Destination State", value: "order_address.customer_state", isChecked: false },
  { id: 16, name: "Service", value: "orders.service_id", isChecked: false },
  { id: 17, name: "Order Type", value: "orders.delivery_type_id", isChecked: false },
  { id: 18, name: "Carrier Name", value: "courier.full_name", isChecked: false },
  { id: 19, name: "Shipment Status", value: "orders.order_status_id", isChecked: false },
  // { id: 20, name: "Shipment Status", value: "orders.order_status", isChecked: false },
  { id: 21, name: "Carrier Remarks", value: "orders.api_msg", isChecked: false },
  { id: 22, name: "Last Event Date", value: "orders.last_event_date", isChecked: false },
  { id: 23, name: "First Attempt Date", value: "orders.first_attempt_date", isChecked: false },
  { id: 24, name: "Ageing", value: "orders.age", isChecked: false },
  { id: 25, name: "No. Of Attempt", value: "orders.total_attempt", isChecked: false },
  { id: 26, name: "First NDR", value: "order_ndr.first_ndr_status_id", isChecked: false },
  { id: 27, name: "First NDR Date", value: "order_ndr.first_courier_event_date", isChecked: false },
  { id: 28, name: "Second NDR", value: "order_ndr.second_ndr_status_id", isChecked: false },
  { id: 29, name: "Second NDR Date", value: "order_ndr.second_courier_event_date", isChecked: false },
  { id: 30, name: "Third NDR", value: "order_ndr.third_ndr_status_id", isChecked: false },
  { id: 31, name: "Third NDR Date", value: "order_ndr.third_courier_event_date", isChecked: false },
  { id: 32, name: "Product Quantity", value: "orders.product_qty", isChecked: false },
  { id: 33, name: "Invoice Value", value: "orders.product_invoice_value", isChecked: false },
  { id: 34, name: "COD Value", value: "orders.product_cod_value", isChecked: false },
  { id: 35, name: "Merchant Weight In Gms", value: "orders.product_weight_in_kgs", isChecked: false },
  { id: 36, name: "Merchant Volumertic Weight", value: "orders.product_vol_weight_in_kgs", isChecked: false },
  { id: 37, name: "Length", value: "orders.length_in_cms", isChecked: false },
  { id: 38, name: "Breadth", value: "orders.breadth_in_cms", isChecked: false },
  { id: 39, name: "Height", value: "orders.height_in_cms", isChecked: false },
  { id: 40, name: "Charged Weight", value: "merchant_freight.charged_weight", isChecked: false },
  { id: 41, name: "Product Name", value: "orders.product_name", isChecked: false },
  { id: 42, name: "Consignee Phone", value: "orders.customer_mobile", isChecked: false },
  { id: 43, name: "Billing Zone", value: "orders.zone_category_id", isChecked: false },
  { id: 44, name: "Lost Status", value: "orders.is_lost", isChecked: false },
  { id: 45, name: "Total Freight", value: "merchant_freight.total_amt", isChecked: false },
  { id: 46, name: "Remittance Status", value: "merchant_remittance.bank_ref_no", isChecked: false },
  { id: 47, name: "Last Update", value: "orders.updated_at", isChecked: true },
];

const UtilityTool = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenFile, setIsOpenFile] = useState(true);
  const [isOpenPaste, setIsOpenPaste] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState([]);
  const [isGetFieldCheck, setIsGetFieldCheck] = useState(true);
  const [excelFile, setExcelFile] = useState();
  const [loader, setLoader] = useState(false);

  const [checked, setChecked] = useState(orderData.map((index, key) => (
    key === false
  )));
  const [inputData, setInputData] = useState('');
  const [inputDataLength, setInputDataLength] = useState(0);
  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0])
  }
  const { register: uploadOrderdata, handleSubmit: orderUploadSubmit, formState: { errors: errors3 }, reset: orderReset } = useForm();

  const toggleFile = () => {
    setIsOpenFile(true);
    setIsOpenPaste(false);
  };
  const togglePaste = () => {
    setExcelFile();
    setIsOpenPaste(true);
    setIsOpenFile(false);
  };
  useEffect(() => {
    setDataList(orderData);
    let allChecked = true;
    for (const utilityCheckbox in checked) {
      if (checked[utilityCheckbox] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [checked])



  const selectAll = (value) => {
    setCheckedAll(value);
    if (value) {
      var opt_order_id = [];
      checkData(dataList) && Object.keys(dataList).map(function (key) {
        let tempObj = dataList[key].value
        opt_order_id.push(tempObj)
        return setCheckboxValue(opt_order_id)
      });
      // ));
    } else {
      setCheckboxValue([]);
    }
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const utilityCheckbox in newState) {
        newState[utilityCheckbox] = value;
      }
      return newState;
    });
  };


  const toggleCheck = (utilityCheckbox, event, Id) => {
    var opt_order_id = checkboxValue;
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[utilityCheckbox] = !prevState[utilityCheckbox];
      return newState;
    });
    if (event.target.checked) {
      opt_order_id.push(Id)
      setCheckboxValue(opt_order_id);
      setIsGetFieldCheck(checkData(checkboxValue));
    } else {
      function arrayRemove(arr, value) {
        return arr.filter(function (ele) {
          return ele !== value;
        });
      }
      var result = arrayRemove(checkboxValue, Id);
      setCheckboxValue(result)
      setIsGetFieldCheck(checkData(checkboxValue));
    };
  };

  const listNewLine = (e) => {
    var lastData = e.target.value.slice(-1);
    var Tlist = e.target.value.split(/,+/);
    if (lastData !== ",") {
      setInputDataLength(Tlist.length);
    }
    var list = e.target.value.replace(/(\r\n|\r|\n){2,}/g, '$1');
    setInputData(list);
    if (lastData === ",") {
      list = list.split(/,+/);
      setInputData(list);
    }
    if (e.target.value.length === 0) {
      setInputDataLength(0);
    }
    if (e.key === "Enter") {
      if (lastData !== ",") {
        let remLine = e.target.value.trim();
        let remComa = remLine.split(/,+/);
        setInputData(remComa.toString() + ",");
      }
    }
  }

  // const listNewLine = (e)=> {  
  //   var lastData = e.target.value.slice(-1);
  //   // var newLine = "";
  //   // var list = e.target.value.replace(/\n|\r/g, "");
  //   var list = e.target.value.replace(/(\r\n|\r|\n){2,}/g, '$1');
  //   setInputData(list);
  //   if(lastData === ","){
  //     list = list.split(/,+/);
  //     // list.pop();
  //     setInputDataLength(list.length-1);
  //     list.forEach((val,index)=>{
  //       // newLine +=val+",\n";
  //       // setInputData(newLine);
  //     })
  //     setInputData(list);
  //   }
  //   if(e.target.value.length === 0){
  //     setInputDataLength(0);
  //   }
  //   // if (e.which == 13) {
  //   // }
  // }

  const onUtilityDataSubmit = (data) => {
    setIsGetFieldCheck(checkData(checkboxValue));
    let searchData = checkUndeNullBlank(data.search_data) ? data.search_data.replace(/[\r\n]/gm, '') : '';

    if (checkData(checkboxValue) || data.data_type === "freight") {
      setLoader(true);
      let url = ''
      let formData = ''
      let dataget = ''
      if (isOpenPaste) {
        url = process.env.REACT_APP_GET_ORDER_PASTE_DATA
        dataget = { "search_data": checkUndeNullBlank(data.search_data) ? searchData : "", "search_field": data.search_field, "data_type": data.data_type, "get_fields": checkData(checkboxValue) ? checkboxValue : '', "admin_id": checkData(props.user) ? props.user[0].id : '' }
      } else {
        formData = new FormData();
        formData.append("file", excelFile);
        formData.append("search_field", data.search_field);
        formData.append("data_type", data.data_type);
        formData.append("get_fields", checkboxValue);
        formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');
        url = process.env.REACT_APP_GET_ORDER_FILE_DATA
      }
      // axios
      //   .post(`${url}`, formData !== '' ? formData : dataget)
      axios({
        method: "post",
        url: `${url}`,
        data: formData !== '' ? formData : dataget,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        setLoader(false);
        let errorFile = '';
        if (res.data.errorfile !== undefined) {
          errorFile = checkUndeNullBlank(res.data.errorfile) ? process.env.REACT_APP_BASE_URL + res.data.errorfile : '';
        }

        if (res.data.error === false) {
          setExcelFile(); orderReset(); selectAll(false);
          // if(isOpenPaste){
          Swal.fire({ title: 'Success', icon: 'success', text: res.data.msg })
          //     }else{
          //     Swal.fire({
          //       title: 'Success',
          //       html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`:''}</tbody></Table>`,
          //       text: res.data.msg,
          //       icon: 'success',
          //       allowOutsideClick: false,
          //       allowEscapeKey: false
          //     }).then(okay => {
          //       if (okay) {
          //         // bulkOrderListing();
          //     }
          //   });
          // }
        } else {
          if (isOpenPaste) {
            Swal.fire({ title: 'Error', icon: 'error', text: res.data.msg })
          } else {
            Swal.fire({
              title: 'Error',
              text: res.data.msg,
              html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
              icon: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(okay => {
              if (okay) {
                // bulkOrderListing();
              }
            });
          }
        }
      })
        .catch((err) => {
          setLoader(false);
          errorHandling(err, "");
        });
    }
  };
  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 utilityTool shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                <CardTitle className="page-title">Manage Utility Tool</CardTitle>
              </CardHeader>
              <CardBody className="pt-0">
                <Form onSubmit={orderUploadSubmit(onUtilityDataSubmit)}>
                  <Row className="mx-0 justify-content-center">
                    <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <select type="select" {...uploadOrderdata("search_field", { required: 'Please Select Field Name' })} className="form-control form-control-sm">
                          <option value="">Select Field Name</option>
                          <option value="awb_no">AWB Number</option>
                          <option value="order_no">Order Number</option>
                          {isOpen &&
                            <option value="system_order_no">Reference Number (10 Digits)</option>}
                        </select>
                        {errors3.search_field && <span className="text-danger d-block error">{errors3.search_field.message}</span>}
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={5} lg={4} xl={4}>
                      <FormGroup className='mb-0'>
                        <div className="d-flex flex-wrap align-items-center">
                          <span className="form-check2 mr-2">
                            <input {...uploadOrderdata("data_type")} type="radio" value="orders" id="order-data" onClick={() => setIsOpen(true)} checked={isOpen} />
                            <Label className="mx-2 my-0" for="order-data">Order Data</Label>
                          </span>
                          <span className="form-check2">
                            <input {...uploadOrderdata("data_type")} type="radio" value="freight" id="freight-data" onClick={() => { setIsOpen(false); selectAll(false); setIsGetFieldCheck(true) }} />
                            <Label className="mx-2 my-0" for="freight-data">Freight Data </Label>
                          </span>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Collapse isOpen={isOpen} className="px-2">
                    <hr />
                    <Row className="mx-0">
                      <Col sm={12}>
                        <FormGroup>
                          <div className="form-check2 d-flex flex-wrap align-items-center justify-content-center mb-4">
                            <input {...uploadOrderdata("allselect")} type="checkbox" id="select_all" checked={checkedAll} onChange={(event) => selectAll(event.target.checked)} value="checkedall" />
                            <Label className="mx-2 my-0" for="select_all" >Select All</Label>
                          </div>
                        </FormGroup>
                      </Col>
                      {dataList.map((data, key) =>
                        <Col xsm={6} sm={6} md={4} xl={3}>
                          <FormGroup>
                            <div className="form-check2 d-flex flex-wrap align-items-center">
                              <input checked={checked[key]} {...uploadOrderdata('check' + key)} type="checkbox" id={data.id} onChange={(event) => toggleCheck(key, event, data.value)} />
                              <Label className="mx-2 my-0" for={data.id} >{data.name}</Label>
                            </div>
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <FormGroup className="mb-0">
                      {isGetFieldCheck === false && <span className="text-danger d-block error">Please Select Any One or More</span>}
                    </FormGroup>
                  </Collapse>
                  <hr />
                  <Row className="mx-0 mb-3">
                    <Col xsm={12}>
                      <FormGroup>
                        <div className="d-flex flex-wrap justify-content-center align-items-center">
                          <span className="form-check2 mr-2">
                            <input {...uploadOrderdata("fileData")} type="radio" value="upload" id="upload_file" onClick={toggleFile} checked={isOpenFile} />
                            <Label className="mx-2 my-0" for="upload_file">Upload File</Label>
                          </span>
                          <span className="form-check2">
                            <input {...uploadOrderdata("fileData")} type="radio" value="paste" id="paste_data" onClick={togglePaste} />
                            <Label className="mx-2 my-0" for="paste_data">Paste Data</Label>
                          </span>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Collapse isOpen={isOpenFile} className="px-2">
                    {isOpenFile &&
                      <Row className="mx-0 justify-content-center">
                        <Col sm={6} xl={3}>
                          <FormGroup className="d-flex flex-column align-items-center p-3 border">
                            <span className="icon mb-3 text-primary px-2 py-1 border border-primary rounded" outline><Download /></span>
                            <span className="btn-md bg-primary fsc-3 px-3 py-1 rounded text-center" color="primary"><Link target="_blank" download to="/format/utility_data.xlsx" className="text-white">Download Data Format </Link></span>
                          </FormGroup>
                        </Col>
                        <Col sm={6} xl={3}>
                          <div className="d-flex flex-column align-items-center border p-3">
                            <span className="icon mb-3 text-success px-2 py-1 border border-success rounded" outline><UploadCloud /></span>
                            <FormGroup className='overflow-hidden mb-0'>
                              <input {...uploadOrderdata("excelUpload", {
                                required: 'Please Upload XLSX File',
                                validate: {
                                  lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                  acceptedFormats: files =>
                                    ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                      files[0]?.type
                                    ) || 'Only .xslx file allowed',
                                },
                              })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                              <Label className="custom-file-label text-nowrap mb-0" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                              {errors3.excelUpload && <span className="text-danger d-block error">{errors3.excelUpload.message}</span>}
                            </FormGroup>
                          </div>
                        </Col>

                        <Col sm={6} xl={3}>
                          <FormGroup className="d-flex flex-column align-items-center p-3 border">
                            <span className="icon mb-3 text-dark px-2 py-1 border border-dark rounded" outline><Download /></span>
                            <Button className="btn-md ctm-btn" color="dark">Get Data</Button>
                          </FormGroup>
                        </Col>
                      </Row>}
                  </Collapse>

                  <Collapse isOpen={isOpenPaste} className="px-2">
                    {isOpenPaste &&
                      <Row className="mx-0">
                        <Col sm={12}>
                          {/* <FormGroup>
                            <textarea {...uploadOrderdata("search_data",{ required: 'Please Enter AWB No.',
                            pattern: {value:/^[0-9,]*$/,message:'Please Enter Valid AWB No.'} })} type="textarea" rows={10} onChange={(e) => {listNewLine(e)}} onKeyPress={(e)=>{listNewLine(e)}} value={inputData} id="exampleText" placeholder="Enter Your AWB Number Comma Seperated" className='form-control' />
                            {errors3.search_data && <span className="text-danger d-block error">{errors3.search_data.message}</span>}
                        </FormGroup> */}
                          <FormGroup>
                            <textarea {...uploadOrderdata("search_data", { required: 'Please Enter AWB No.' })} type="textarea" rows={10} onChange={(e) => { listNewLine(e) }} onKeyPress={(e) => { listNewLine(e) }} value={inputData} id="exampleText" placeholder="Enter Your AWB Number Comma Seperated" className='form-control' />
                            {errors3.search_data && <span className="text-danger d-block error">{errors3.search_data.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <div><span className='fsc-3'>Please Enter Comma Seperated Value</span></div>
                          <span>Length : {inputDataLength}</span>
                        </Col>
                        <Col className="btn-container text-right">
                          <Button className="btn ctm-btn btn-sm" color="primary">Get Data</Button>
                        </Col>
                      </Row>}
                  </Collapse>
                </Form>
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

export default UtilityTool;