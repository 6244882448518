import React, {useEffect, useState} from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle,Spinner, FormGroup,Form, CardHeader, Table,Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import {ChevronLeft,ChevronRight} from 'react-feather';
import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import LoaderDefault from '../LoaderDefault';
import { changeIntoDate, remExtraSpace, errorHandling } from '../../utlis';
import { AllState } from '../../actions';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import Swal from 'sweetalert2';
import NodataFound from '../NodataFound';
import { baseString } from '../../config';

const State = (props) => {
  const [state, setState] = useState({})
  const [page, setPage] = useState(1)
  const [selectperpage,setSelectperpage] = useState(20);
  const [loader, setLoader] = useState(false);
  const [loaderTable, setLoadertable] = useState(false)
  const dispatch = useDispatch();
  const [filterInput, setFilterInput] = useState('');
  const [currentCount,setCurrentCount] = useState(1);
  const dropFilterCount = 20

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
        setLoader(true)
        setPage(1)
        setCurrentCount(1)
        let pagePerpage = {"page":1, "per_page":event.target.value};
        onpaginationFilter(filterInput,pagePerpage)
}
const stateListing = () =>{
  setLoadertable(true)
  setFilterInput('')
  setPage(1)
  setCurrentCount(1)
  let mypost={};
  let myget={"page":1,"per_page":selectperpage};

  dispatch(AllState(mypost,myget)).then(data => {
    setLoadertable(false);
    setState(data)
  }).catch((err) =>{
    setLoadertable(false);
    errorHandling(err,"reload");
  });
}
useEffect(() => {
  if(checkData(props.state) && checkData(props.state.myAllState)){
    setState(props.state.myAllState);
  }else{
  stateListing();
}
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
},[selectperpage,page,dispatch])
const { register, handleSubmit } = useForm();
function nextPageData() {
  if(checkData(state.data)&&checkUndeNullBlank(state.totalPage)){
    // &&checkUndeNullBlank(state.totalPage).length>page.length
      if(state.totalPage > page){
        setCurrentCount(currentCount+Object.entries(state.data).length)
    setPage(page+1)
    setLoader(true)
      let pagePerpage = {"page":page+1, "per_page":selectperpage};
      onpaginationFilter(filterInput,pagePerpage);
    }
  }
}
function previousPageData() {
  if(page >1){
  setPage(page-1)
  checkData(state.data)
  if(currentCount>Object.entries(state.data).length){
    setCurrentCount(currentCount-selectperpage)
  }else{
    setCurrentCount(1)
  }
  let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
    onpaginationFilter(filterInput,pagePerpage);
}
}
const onpaginationFilter = (data,pagePerpage) => {
  onCommonFilter(data,pagePerpage)
};
  const onSubmit = (data, pagePerpage) => {
    pagePerpage.page=1
    if(checkUndeNullBlank(data.state)){
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data,pagePerpage)
    }else{
      Swal.fire({title: 'info',text: 'Select Your Filtering Preference',icon: 'info',})
    }
  };
  const onCommonFilter = (data,pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
      fetch(`${process.env.REACT_APP_GET_STATE}?state=${checkUndeNullBlank(data.state)?remExtraSpace(data.state):''}&page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,{
        method:'POST',
        headers:{
          "Content-Type":'application/json',
          "Accept":'application/json',
          "token": window.btoa(baseString)
        },
      }).then((resp)=>{
        resp.json().then((result)=>{
          if(result.fatal !== true){
            setLoader(false);
            setState(result);
          }
        });
      }).catch((err) => {
        setLoader(false);
        errorHandling(err,"");
      });
  };
  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 manageState shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex justify-content-between">
              <CardTitle className="mb-0 page-title">Manage state</CardTitle>
            </CardHeader>
              <Form onSubmit={handleSubmit(onSubmit)} className="cardFilter">
                  <Row className="mx-0 pt-1 justify-content-start justify-content-sm-center">
                    <Col sm={6} md={4} lg={3} xl={3}>
                        <FormGroup>
                          <input {...register("state")}  className="form-control-sm form-control" placeholder="Enter State" />
                        </FormGroup>
                    </Col>
                    <Col sm={3} className="btn-container">
                      <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline onClick={stateListing}>Reset</Button>
                    </Col>
                  </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(state) && checkData(state.data) && checkUndeNullBlank(state.TotalRows) ?
                  <span className="small">Showing {currentCount} to {(currentCount-1)+(Object.entries(state.data).length)} of  {state.TotalRows}</span>: ''}  
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th className="text-nowrap">State Id</th>
                      <th>State</th>
                      <th>Created</th>
                    </tr>
                   </thead>
                  <tbody>
                  {checkData(state.data) ? state.data.map((item, index) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.state}</td>
                      <td className="text-nowrap">{(item.created_at !== undefined && item.created_at !== null?changeIntoDate(item.created_at):'NA')}</td>
                    </tr>
                    ))
                    :loaderTable ? <td colSpan="4"><LoaderDefault /></td>:
                    <td colSpan="4"><NodataFound /></td> }
                   </tbody>
                </Table>
                {checkData(state) && checkData(state.data) && checkUndeNullBlank(state.TotalRows) ?
                <>
                {state.TotalRows > dropFilterCount ?
               <Row className="m-0 table-footer">
               <Col xs="6">
                <Pagination aria-label="Page navigation example">
                    <PaginationItem className="prev">
                      <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink> 
                    </PaginationItem>
                    <PaginationItem className="next ml-3">
                      <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                    </PaginationItem>
                </Pagination>
                </Col>
                <Col xs="6">
                <Form>
                  <FormGroup>
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                          <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          </select>
                  </FormGroup>
                </Form>
                </Col>
               </Row>:''}
               </>
               :''}
              </CardBody>
            </Card>
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}

function mapDispatchToProps (state) {
  return{
    state:state.DataReducer
  }
}

export default connect(mapDispatchToProps)(State);