import React from 'react';
import {Card,CardBody, CardText, CardSubtitle,Button, CardTitle} from 'reactstrap';
import {Link} from 'react-router-dom'
import { toast } from 'react-toastify';

const ThankYouMsg = (props) => {
  
    return (
        <div className="app-content m-0 p-0 content overflow-hidden">
            <div className="thankyou-msg d-flex justify-content-center align-items-center">
                <Card className="thankyou-msg-box text-center border-0 mx-auto">
                    <CardBody>
                        <div className="img-con">
                            <img src="./images/thankyou-img.png" className="img-fluid" alt="thank"/>
                        </div>
                        <CardTitle tag="h4" className="my-4">Thank you!</CardTitle>
                        <CardText>
                            Thank you for Registering with                         
                        </CardText>
                        <CardSubtitle>Please login and complete your KYC to our panel</CardSubtitle>
                        <div className="mt-4"><Link to="/login"><Button className="ctm-btn btn-sm" color="primary">Login</Button></Link></div>
                    </CardBody>
                </Card>   
            </div>        
        </div>
    )
}

export default ThankYouMsg
 