
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Spinner } from 'reactstrap';

import MyCouriers from './Setting/MyCouriers';
const RateCard = (props) => {
    const [loader, setLoader] = useState(false);
    return (
        <div className="app-content content overflow-hidden"> 
            <Card className="shadow border-0 mb-4 p-4">
                <MyCouriers user={props.user} setLoader={setLoader} />
            </Card>
        </div>
    )
};
export default RateCard;