import React, { useState, useEffect } from 'react';
import { Row, Col, Button, CardTitle, FormGroup, Form, Label, Collapse, Spinner } from 'reactstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2';
import { errorHandling } from '../../utlis';
import { baseString } from '../../config';
import { toast } from 'react-toastify';


const SmsNotification = (props) => {
  const [loader, setLoader] = useState(false);
  const { register: addSMSNotification, handleSubmit: merchantSMSSubmit, formState: { errors: errorSMS }, setValue } = useForm();
  const [isSMSEnableAWB, setIsSMSEnableAWB] = useState(false);
  const [isSMSEnableDelFailed, setIsSMSEnableDelFailed] = useState(false);
  const [isSMSEnableCnlOdr, setIsSMSEnableCnlOdr] = useState(false);


  useEffect(() => {
    viewSMSNoti();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const viewSMSNoti = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_SETTING}/view`,
      data: { "setting_id": 4, "merchant_id": props.merchantId, },
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        setLoader(false);
        if (response.data.length > 0) {
          let myresponse = response.data[0];
          myresponse.setting_value_4 = myresponse.setting_value;
          myresponse.price_4 = myresponse.price;
          // smsNotiReset(myresponse);
          setValue("setting_value_4", myresponse.setting_value)
          setValue("price_4", myresponse.price)

          if (myresponse.setting_value === '1') {
            setIsSMSEnableAWB(true);
          }
        }
      })
      .catch(function (err) {
        setLoader(false);
        errorHandling(err, "")
      });

    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_SETTING}/view`,
      data: { "setting_id": 6, "merchant_id": props.merchantId, },
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        setLoader(false);
        if (response.data.length > 0) {
          let myresponse = response.data[0];
          myresponse.setting_value_6 = myresponse.setting_value;
          myresponse.price_6 = myresponse.price;
          // smsNotiReset(myresponse);
          setValue("setting_value_6", myresponse.setting_value)
          setValue("price_6", myresponse.price)

          if (myresponse.setting_value === '1') {
            setIsSMSEnableCnlOdr(true);
          }
        }
      })
      .catch(function (err) {
        setLoader(false);
        errorHandling(err, "")
      });
  }
  const onSMSNotification = (data) => {
    forSmsNotification();

    function forSmsNotification() {
      setLoader(true);
      let formData = new FormData();
      // formData.append("merchant_id", props.merchantId);
      formData.append("setting_value", data.setting_value_4);
      formData.append("price", data.price_4);
      formData.append("setting_id", 4);
      axios({
        method: "post",
        url: process.env.REACT_APP_MERCHANT_SETTING,
        data: formData,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        setLoader(false);
        if (res.data.error === false) {
          Swal.fire({title: 'Success',text: res.data.msg,icon: 'success',})
        }
      })
        .catch((err) => {
          setLoader(false);
          errorHandling(err, "")
        });
    }

  };

  return (
    <>
      {loader && <div className="formLoader"><Spinner /></div>}
      <Form onSubmit={merchantSMSSubmit(onSMSNotification)} >
        <div className="sub-page-header" >
          <CardTitle tag="h4" className="sub-page-title" > SMS Notification </CardTitle>
        </div>
        <Row>
          <Col sm={12}>
            <FormGroup>
              <Label>SMS Send on AWB number Punch<span className="text-danger">*</span></Label>
              <div className="d-flex flex-wrap">
                <span className="form-check2 mr-4">
                  <input {...addSMSNotification("setting_value_4", { required: 'Please Choose field' })} type="radio" value="1" id="enableAWB" onClick={() => { setIsSMSEnableAWB(true) }} />
                  <Label for="enableAWB">Enable</Label>
                </span>
                <span className="form-check2 mr-4">
                  <input {...addSMSNotification("setting_value_4", { required: 'Please Choose field' })} type="radio" value="0" id="disableAWB" onClick={() => { setIsSMSEnableAWB(false) }} defaultChecked />
                  <Label for="disableAWB">Disable</Label>
                </span>
                <Collapse isOpen={isSMSEnableAWB}>
                  {isSMSEnableAWB &&
                    <span>
                      <input {...addSMSNotification("price_4", {
                        required: 'Please Enter Price',
                        pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Price' }
                      })} className="form-control" placeholder="Price" />
                      {errorSMS.price_4 && <span className="text-danger d-block error">{errorSMS.price_4.message}</span>}
                    </span>}
                </Collapse>
              </div>
              {errorSMS.setting_value_4 && <span className="text-danger d-block error">{errorSMS.setting_value_4.message}</span>}
            </FormGroup>
          </Col>
          <Col sm={12}>
            <FormGroup>
              <Label>SMS Send on Delivery Failed Status<span className="text-danger">*</span></Label>
              <div className="d-flex flex-wrap">
                <span className="form-check2 mr-4">
                  <input {...addSMSNotification("setting_value_6", { required: 'Please Choose field' })} type="radio" value="1" id="enableCnlOdr" onClick={() => { setIsSMSEnableDelFailed(true) }} />
                  <Label for="enableCnlOdr">Enable</Label>
                </span>
                <span className="form-check2 mr-4">
                  <input {...addSMSNotification("setting_value_6", { required: 'Please Choose field' })} type="radio" value="0" id="disableCnlOdr" onClick={() => { setIsSMSEnableDelFailed(false) }} defaultChecked />
                  <Label for="disableCnlOdr">Disable</Label>
                </span>
                <Collapse isOpen={isSMSEnableCnlOdr}>
                  {isSMSEnableCnlOdr &&
                    <span>
                      <input {...addSMSNotification("price_6", {
                        required: 'Please Enter Price',
                        pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Price' }
                      })} className="form-control" placeholder="Price" />
                      {errorSMS.price_6 && <span className="text-danger d-block error">{errorSMS.price_6.message}</span>}
                    </span>}
                </Collapse>
              </div>
              {errorSMS.setting_value_6 && <span className="text-danger d-block error">{errorSMS.setting_value_6.message}</span>}
            </FormGroup>
          </Col>
          <Col sm={12}>
            <FormGroup>
              <Label>Channel Order Verification SMS<span className="text-danger">*</span></Label>
              <div className="d-flex flex-wrap">
                <span className="form-check2 mr-4">
                  <input {...addSMSNotification("setting_value_7", { required: 'Please Choose field' })} type="radio" value="1" id="enableCnlOdr" onClick={() => { setIsSMSEnableCnlOdr(true) }} />
                  <Label for="enableCnlOdr">Enable</Label>
                </span>
                <span className="form-check2 mr-4">
                  <input {...addSMSNotification("setting_value_7", { required: 'Please Choose field' })} type="radio" value="0" id="disableCnlOdr" onClick={() => { setIsSMSEnableCnlOdr(false) }} defaultChecked />
                  <Label for="disableCnlOdr">Disable</Label>
                </span>
                <Collapse isOpen={isSMSEnableCnlOdr}>
                  {isSMSEnableCnlOdr &&
                    <span>
                      <input {...addSMSNotification("price_7", {
                        required: 'Please Enter Price',
                        pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Price' }
                      })} className="form-control" placeholder="Price" />
                      {errorSMS.price_7 && <span className="text-danger d-block error">{errorSMS.price_7.message}</span>}
                    </span>}
                </Collapse>
              </div>
              {errorSMS.setting_value_7 && <span className="text-danger d-block error">{errorSMS.setting_value_7.message}</span>}
            </FormGroup>
          </Col>
          <Col sm={12} className="mt-2 text-right">
            <Button className="ctm-btn btn-sm" color="primary" > Save changes </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default SmsNotification;
