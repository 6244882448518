import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Form, CardHeader, Table, UncontrolledTooltip, InputGroup, InputGroupAddon, InputGroupText, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label, Spinner } from 'reactstrap';
import { MultiSelect } from "react-multi-select-component";
import { DateRangePicker } from 'rsuite';
// import 'rsuite/dist/styles/rsuite-default.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faChevronDown, faChevronRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { changeIntoDateTime, copyToClipBoard, errorHandling, remExtraSpace } from '../../utlis';
import { Copy } from 'react-feather';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { delivery_type_id, lost_resion, download_delay, baseString } from '../../config';
import moment from 'moment';
import TrackOrder from './TrackOrder';

const UpdateLost = (props) => {
  const [updateLostList, setUpdateLostList] = useState({});
  const [merchants, setMerchants] = useState([]);
  const [LostStatus, setLostStatus] = useState([]);
  const [allCourier, setAllCourier] = useState([]);
  const [searchValue, setSearchValue] = useState(' ');
  const [excelmodal, setExcelmodal] = useState(false);
  const [excelFile, setExcelFile] = useState();
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1)
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment().format('YYYY-MM-DD'), });
  const [loader, setLoader] = useState(false);
  const [filterDate, setFilterDate] = useState([new Date(), new Date()]);
  const [sideAnim, setSideAnim] = useState(false);
  const [statusRecheckOrderId, setStatusRecheckOrderId] = useState([]);
  const [tempClass, setTempClass] = useState(null);
  const [orderTracking, setOrderTracking] = useState({});
  const [lastOrderStatus, setlastOrderStatus] = useState();
  const dropFilterCount = 20;
  const history = useHistory();

  var opt_merchants = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {}
      if (merchantName && merchantId) {
        tempObj = { label: merchantName, value: merchantId }
        opt_merchants.push(tempObj)
      }
      return true
    });
  }
  const merchantsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }

  const opt_lostStatus = [
    { label: "Lost Initianted", value: 1 },
    { label: "Lost Approved", value: 5 },
  ];
  const lostStatusMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Lost Status"
  }

  const allCourierMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Carriers"
  }
  var opt_allCourier = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)) {
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function (key) {
      let tempObj = { label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }
      return opt_allCourier.push(tempObj)
    });
  }

  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    if (!excelmodal) { setExcelFile(""); }
  }
  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0]);
  }

  useEffect(() => {
    updateLostListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { register: filter, handleSubmit: filterUpdateLostSubmit, watch: filterWatch, reset } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };

  const onFilterUpdateLost = (data, pagePerpage) => {
    pagePerpage.page = 1
    if ((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(allCourier) || checkData(merchants) || checkData(LostStatus) || checkUndeNullBlank(data.delivery_type_id) || checkData(filterDate)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      toast.info('Select Your Filtering Preference');
    }
  };

  const { register: uploadUpdateLost, handleSubmit: uploadLostUploadSubmit, formState: { errors } } = useForm();
  const onUpdateLostSubmit = (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("file", excelFile);
    formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');

    axios({
      method: "post",
      url: `${process.env.REACT_APP_LOST_SHIPMENT_UPLOAD}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      let errorFile = '';
      if (res.data.errorfile !== undefined) {
        errorFile = checkUndeNullBlank(res.data.errorfile) ? process.env.REACT_APP_BASE_URL + res.data.errorfile : '';
      }
      setExcelFile();
      setExcelmodal(false);
      if (res.data.error === false) {
        Swal.fire({
          title: 'Success',
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>` : ''}</tbody></Table>`,
          text: res.data.msg,
          icon: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(okay => {
          if (okay) {
            updateLostListing();
          }
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: res.data.msg,
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Added</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(okay => {
          if (okay) {
            updateLostListing();
          }
        });
      }
    })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "")
      });
  }

  const updateLostListing = () => {
    setFilterInput({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment().format('YYYY-MM-DD'), });
    reset();
    setMerchants([]);
    setAllCourier([]);
    setLostStatus([]);
    setFilterDate([new Date(), new Date()]);
    let data = { "start_date": moment().format('YYYY-MM-DD'), "end_date": moment().format('YYYY-MM-DD') }

    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setLoadertable(true)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_LOST_SHIPMENT}?page=${page}&per_page=${selectperpage}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false)
      if (res.data.error === false) {
        setUpdateLostList(res.data);
      } else {
        Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
      }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err, "reload");
    });
  }

  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(updateLostList.data)
      if (currentCount > Object.entries(updateLostList.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }

  function nextPageData() {
    if (checkData(updateLostList.data) && checkUndeNullBlank(updateLostList.totalPage)) {
      if (updateLostList.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(updateLostList.data).length)
        setPage(page + 1)
        setLoadertable(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoadertable(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }

  const onCommonFilter = (data, pagePerpage) => {
    let byData = {}
    let merchantsId = [];
    let courierId = [];
    let lostStatusId = [];

    if (checkData(allCourier)) {
      allCourier.map((item) => (
        courierId.push(item.value)
      ));
    }

    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }

    if (checkData(LostStatus)) {
      LostStatus.map((item) => (
        lostStatusId.push(item.value)
      ));
    }

    setLoader(true)

    if (data === undefined) {
      data = filterInput
    }

    let mId = checkUndeNullBlank(merchantsId) ? merchantsId : '';
    let cId = checkUndeNullBlank(courierId) ? courierId : '';
    let lsId = checkUndeNullBlank(lostStatusId) ? lostStatusId : '';
    let sDate = checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '';
    let eDate = checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '';
    byData = { "search_field": checkUndeNullBlank(data.search_field) ? data.search_field : '', "search_value": checkUndeNullBlank(data.search_value) ? remExtraSpace(data.search_value) : '', "delivery_type_id": checkUndeNullBlank(data.delivery_type_id) ? data.delivery_type_id : '', "courier_id": cId, "merchant_ids": mId, "lost_status": lsId, "start_date": sDate, "end_date": eDate }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_LOST_SHIPMENT}?page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setUpdateLostList(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  };
  const exportLost = () => {
    if (checkData(filterInput)) {
      let data = filterInput
      if (props.user[0].user_type === "Super") {
        data.admin_id = props.user[0].id
      } else {
        data.merchant_id = props.user[0].id
      }
      data.is_export = 1
      setLoader(true);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_LOST_SHIPMENT}`,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
          if (parseInt(res.data.errno) > 0) {
            setLoader(false)
            if (res.data.sqlMessage !== undefined) {
                          toast.error(res.data.sqlMessage,{position:'top-right'})
            }
          } else {
            if (res.data.is_export === 1) {
              setTimeout(function () {
                setLoader(false);
                history.push("/my-downloads");
              }, download_delay);
            }
          }
        }
      }).catch((err) => {
        setLoader(false)
        errorHandling(err, "")
      });
    } else {
      Swal.fire({ icon: 'error', title: 'Error', text: 'Select Your Filtering Preference' })
    }
  }

  // copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }
  const closeTracker = (data) => {
    setSideAnim(!sideAnim)
    setOrderTracking({});
    setTempClass(null);
  }
  const orderTrack = (orderId, lastStatus) => {
    setlastOrderStatus(lastStatus)
    setStatusRecheckOrderId(orderId)
    let data = { "order_id": orderId }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_TRACK_ORDER}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setOrderTracking(res.data.data)
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      Swal.fire({ icon: 'error', text: err, title: 'Error' });
      setLoader(false);
    });
  }

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      {checkData(orderTracking) && <div className="overlay"></div>}
      <Row>
        
        <Col sm={12}>
          <Card className="p-0 my-3 updateLost shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="page-title">Manage Lost Shipments </CardTitle>
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  <div className="btn-text btn-light mx-2" onClick={excelUpload}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Update Lost Shipments</span></div>
                  <div className="btn-text btn-light" onClick={exportLost}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Export Lost Shipments</span></div>
                </div>
                <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                  <ModalHeader toggle={excelUpload}>Upload Update Lost</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={uploadLostUploadSubmit(onUpdateLostSubmit)}>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Update Lost via XLSX</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please Export below given template</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                      <Row className="m-0 pb-1">
                        <Col sm={12} className="p-0">
                          <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/update_lost_shipment.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download
                          </Link>
                            <span className="ml-2">Upload Update Lost Format File</span></p>
                        </Col>
                        <Col sm={12} className="m-auto pt-1 p-0">
                          <FormGroup>
                            <input {...uploadUpdateLost("excelUpload", {
                              required: 'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                  ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                    files[0]?.type
                                  ) || 'Only .xslx file allowed',
                              },
                            })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                            <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                            {errors.excelUpload && <span className="text-danger d-block error">{errors.excelUpload.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="btn ctm-btn btn-sm" color="primary">Upload</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </CardHeader>
              <Form onSubmit={filterUpdateLostSubmit(onFilterUpdateLost)} className="cardFilter">
                <Row className="mx-0 pt-1">
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker value={filterDate} onChange={date => { setFilterDate(date) }} placeholder="Choose Added Date Range" format="dd-MM-yyyy" showOneCalendar={props.oneCalendar} />
                    </FormGroup>
                  </Col>
                  {props.user[0].user_type === "Super" &&
                    <Col sm={6} md={4} lg={3} xl={2}>
                      <FormGroup>
                        <MultiSelect
                          options={opt_merchants}
                          value={merchants}
                          onChange={setMerchants}
                          labelledBy="Select Merchant"
                          overrideStrings={merchantsMultiSelectSettings}
                          className={checkData(merchants) && "active"}
                        />
                      </FormGroup>
                    </Col>}
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <MultiSelect
                        options={opt_lostStatus}
                        value={LostStatus}
                        onChange={setLostStatus}
                        labelledBy="Select Lost Status"
                        overrideStrings={lostStatusMultiSelectSettings}
                        className={checkData(LostStatus) && "active"}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <MultiSelect
                        options={opt_allCourier}
                        value={allCourier}
                        onChange={setAllCourier}
                        labelledBy="All Couriers"
                        overrideStrings={allCourierMultiSelectSettings}
                        className={checkData(allCourier) && "active"}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select {...filter("delivery_type_id")} className={`form-control-sm form-control ${checkData(filterWatch("delivery_type_id")) ? 'valid' : ''}`}>
                        <option value="">Select Payment Mode</option>
                        {Object.entries(delivery_type_id).map(([key, value]) => (
                          <option value={key}>{value}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={5} lg={4} xl={3}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control-sm form-control" onChange={(e) => { if (e.target.selectedOptions[0].text !== "Search By") { setSearchValue("Enter " + e.target.selectedOptions[0].text) } else { setSearchValue('') } }}>
                              <option value="Search By">Search By</option>
                              <option value="order_no">Order No.</option>
                              <option value="awb_number">AWB No.</option>
                              <option>{process.env.REACT_APP_NAME} Order No.</option>
                            </select>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input {...filter("search_value")} placeholder={searchValue} className="form-control-sm form-control" />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" onClick={updateLostListing} outline>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(updateLostList) && checkData(updateLostList.data) && checkUndeNullBlank(updateLostList.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(updateLostList.data).length)} of {updateLostList.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Merchant</th>
                      <th className="text-nowrap">Courier</th>
                      <th className="text-nowrap">Awb No.</th>
                      <th className="text-nowrap">Order No.</th>
                      {/* <th className="text-nowrap">Current Order Status</th> */}
                      <th className="text-nowrap">Reason</th>
                      <th className="text-nowrap">Credit/Debit Note</th>
                      <th className="text-nowrap">CN./DN. Date</th>
                      <th className="text-nowrap">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(updateLostList.data) ? updateLostList.data.map((item, index) => (
                      <tr key={index} className={`${tempClass === item.id && "showTop"}`}>
                        <td>{item.merchantName}</td>
                        <td>{item.courierName}</td>
                        <td>
                          <div className="text-nowrap"><span className="text-primary cursor-pointer" onClick={() => { setSideAnim(true); orderTrack(item.id, item.order_status_id); setStatusRecheckOrderId([item.id]); setTempClass(item.id) }}>{item.awb_no}</span>
                            <span onClick={() => copied(item.awb_no)} className="ml-25 copy-btn" id={`copy${item.id}`}>
                              <Copy />
                              <UncontrolledTooltip placement="bottom" target={`copy${item.id}`}>
                                Copy
                              </UncontrolledTooltip>
                            </span>
                          </div>
                        </td>
                        <td><div className="text-nowrap"><Link to={`/order-detail/${item.order_id}`}>{item.order_no}</Link>
                          <span onClick={() => copied(item.order_no)} className="ml-1 copy-btn" id={`copyOdr${item.id}`}>
                            <Copy />
                            <UncontrolledTooltip placement="bottom" target={`copyOdr${item.id}`}>
                              Copy
                            </UncontrolledTooltip>
                          </span>
                        </div></td>
                        {/* <td>Shipment Lost</td> */}
                        <td>{lost_resion[item.reason_id]}</td>
                        <td>{item.cn_no || 'NA'}</td>
                        <td>{changeIntoDateTime(item.cn_trans_date)}</td>
                        <td className="text-nowrap">{changeIntoDateTime(item.created_at)}</td>
                      </tr>
                    )) :
                      loadertable ? <tr><td colSpan="10" className="text-center"><LoaderDefault /></td></tr>
                        : <tr><td colSpan="10" className="text-center"><NodataFound /></td></tr>}
                  </tbody>
                </Table>
                {checkData(orderTracking) && <TrackOrder orderTracking={orderTracking} toggleTrack={sideAnim ? "show" : "hide"} closeTracker={(e) => { closeTracker(e) }} position="fixed" lastStatus={lastOrderStatus} statusRecheckOrderId={statusRecheckOrderId} user={props.user} />}
                {checkData(updateLostList) && checkData(updateLostList.data) && checkUndeNullBlank(updateLostList.TotalRows) ?
                  <>
                    {updateLostList.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}

export default connect(mapDispatchToProps)(UpdateLost);