import React from 'react';
import { Row, Col, Label, Form, FormGroup, Button, CardTitle} from 'reactstrap';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';

const General = (props) => {

    const { register, handleSubmit, formState: { errors }} = useForm();
    const onSubmit = data => {};

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="sub-page-header">
                <CardTitle tag="h4" className="sub-page-title">General</CardTitle>
            </div>
            <Row>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="company_name">Company Name<span className="text-danger">*</span></Label>
                        <input {...register("company_name", { required: 'Please Enter Company Name'})} type="text" className="form-control" placeholder="Company Name" id="company_name" />
                        {errors.company_name && <span className="text-danger d-block error">{errors.company_name.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="brand_name">Store Name<span className="text-danger">*</span></Label>
                        <input {...register("brand_name", { required: 'Please Enter Store Name'})} type="text" className="form-control" placeholder="Store Name" id="brand_name" />
                        {errors.brand_name && <span className="text-danger d-block error">{errors.brand_name.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="api_key">Live API Key<span className="text-danger">*</span></Label>
                        <input {...register("api_key", { required: 'Please Enter Live API Key'})} type="text" className="form-control" placeholder="Live API Key" id="api_key" />
                        {errors.api_key && <span className="text-danger d-block error">{errors.api_key.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="email">Email<span className="text-danger">*</span></Label>
                        <input {...register("email", { required: 'Please Enter Email',
                        pattern: {value:/\S+@\S+\.\S+/,message:'Please Enter Valid Email'} })} type="text" className="form-control" placeholder="Email" id="email" />
                        {errors.email && <span className="text-danger d-block error">{errors.email.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="max_liability_shipment">Max Liability Per Shipment<span className="text-danger">*</span></Label>
                        <input {...register("max_liability_shipment", { required: 'Please Enter Max Liability Per Shipment'})} type="text" className="form-control" placeholder="" id="max_liability_shipment" />
                        {errors.max_liability_shipment && <span className="text-danger d-block error">{errors.max_liability_shipment.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label>Billing<span className="text-danger">*</span></Label>
                        <div>
                            <span className="form-check2 d-inline-block mr-4">
                            <input {...register("billing", { required: true })} name="billingType" type="radio" value="1" className="ml-0 position-relative" id="prepaid" defaultChecked/>
                            <Label className="mx-2" for="prepaid">Prepaid</Label>
                            </span>
                            <span className="form-check2 d-inline-block">
                            <input {...register("billing", { required: true })} name="billingType" type="radio" value="0" className="ml-0 position-relative" id="postpaid" />
                            <Label className="mx-2" for="postpaid">Postpaid</Label>
                            </span>
                        </div>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="credit_limit">Credit Limit<span className="text-danger">*</span></Label>
                        <input {...register("credit_limit", { required: 'Please Enter Credit Limit',
                        pattern: {value:/^[+-]?\d+(\.\d+)?$/,message:'Please Enter Valid Credit Limit'} })} type="text" className="form-control" placeholder="Credit Limit" id="credit_limit" />
                        {errors.credit_limit && <span className="text-danger d-block error">{errors.credit_limit.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={12} className="mt-2 text-right">                    
                    <Button className="ctm-btn btn-sm" color="primary"> Save changes</Button>
                </Col>            
            </Row>
        </Form>
    );
}

export default General;