import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, CardTitle, Form, Spinner, Button, CardSubtitle, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Copy } from 'react-feather';
import { copyToClipBoard, errorHandling } from '../../../utlis';
import { toast } from 'react-toastify';
import axios from 'axios';
import { checkData, checkUndeNullBlank } from '../../../ObjectExist';
import NodataFound from '../../NodataFound';
import { connect } from 'react-redux';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { baseString } from '../../../config';


const TrackingCode = (props) => {
    const [trackingSnippet, setTrackingSnippet] = useState('');
    const [loader, setLoader] = useState(false);
    const [mergeAccount, setMergeAccount] = useState(false);
    const [brandList, setBrandList] = useState({});

    useEffect(() => {
        if (checkData(props.state) && checkData(props.state.viewMergeAccount) && checkData(props.state.viewMergeAccount.data)) {
            setMergeAccount(props.state.viewMergeAccount.data);
        }
        setLoader(true)
        const data = {}
        data.handler = 'tracking_code'
        data.admin_id = 1
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_SNIPPET_VIEW}`,
            data: data,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false)
            if (res.data.error === false) {
                let myresponse = res.data.data[0]
                if (checkData(myresponse)) {
                    let myStr = myresponse.setting_value
                    // myStr = myStr.replace('YQ_MID:"0"', `YQ_MID:${opt_id.length > 0 ? JSON.stringify(opt_id) : props.user[0].id}`)
                    setTrackingSnippet(myStr.replace('YQ_MID:"0"', `YQ_MID:${opt_id.length > 0 ? JSON.stringify(opt_id) : props.user[0].id}`));
                }
            }
        })
            .catch((err) => {
                setLoader(false)
                errorHandling(err, "reload");
            });

        brandPointListing();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.state]);

    var opt_id = [];
    if (checkData(mergeAccount)) {
        Object.keys(mergeAccount).map(function (key) {
            let merchantId = checkUndeNullBlank(mergeAccount[key].id) && mergeAccount[key].id
            let tempId = ''
            if (merchantId) {
                tempId = merchantId
                opt_id.push(tempId)
            }
            return true
        });
    }

    const brandPointListing = () => {
        setLoader(true);
        let data = {}
        if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
            data = { "merchant_id": props.user[0].id }
        }
        axios({
            method: "post",
            url: process.env.REACT_APP_MERCHANT_BRANDS,
            data: data,
            headers: { "token": window.btoa(baseString) }
        })
            .then(function (response) {
                setLoader(false);
                if (response.data.fatal !== true) {
                    setBrandList(response.data)
                }
            }).catch(function (response) {
                setLoader(false);
                errorHandling(response, "reload")
            });
    }

    // copy text
    const copied = (text) => {
        let decision = copyToClipBoard(text);
        decision.then(function (result) {
            if (result === true) {
                toast("Copied!", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }

    const { register: trackUrl, handleSubmit: trackingUrlSubmit, formState: { errors }, setValue, getValues } = useForm();
    const getBrand = (brandId) => {
        if (brandId !== '') {
            setValue("tracking_url", `${process.env.REACT_APP_EXTERNAL_TRACKING_URL}/${brandId.replace(/\s/g, "")}`)
        } else {
            setValue("tracking_url", '')
        }
    }
    const onTrackingUrlSubmit = () => { }

    return (
        <>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Form>
                {props.header === "no" ? "" :
                    <div className="sub-page-header">
                        <CardTitle tag="h4" className="sub-page-title">Tracking Code</CardTitle>
                    </div>}
                <Row>
                    {checkUndeNullBlank(trackingSnippet) &&
                        <Col sm={12}>
                            <div className="d-flex align-items-center mb-2">
                                <CardSubtitle tag="h6" className="mr-auto">Snippet Code</CardSubtitle>
                                <Button onClick={() => copied(trackingSnippet)} className="ml-auto float-right btn-text btn-light">
                                    <span className="mr-2">Copy Code</span><Copy style={{ width: "14px" }} />
                                </Button>
                            </div>
                        </Col>}
                    <Col sm={12}>
                        {checkUndeNullBlank(trackingSnippet) ?
                            <>
                                <FormGroup>
                                    <textarea type="textarea" value={trackingSnippet} rows={15} className="form-control-sm form-control" />
                                </FormGroup>
                                <div><span>Create an HTML File and Paste This Code</span></div>
                            </> : loader ? '' : <NodataFound />}
                    </Col>
                    {checkUndeNullBlank(trackingSnippet) &&
                        <Col sm={12} className="mt-2 text-right">
                            <Button className="btn-sm ctm-btn" color="primary"><Link target="_blank" download to="/format/provid_etrack_shopify.pdf" className="text-white"> How To Integrate</Link></Button>
                        </Col>}
                </Row>

            </Form>
            {checkUndeNullBlank(trackingSnippet) &&
                <Form onSubmit={trackingUrlSubmit(onTrackingUrlSubmit)} className="">
                    <Row>
                        <Col sm={12}><CardSubtitle tag="h6" className="mr-auto my-3">Create Tracking URL</CardSubtitle></Col>
                        <Col sm={6} md={4}>
                            <FormGroup>
                                <Label for="brand">Choose Store</Label>
                                <div className="position-relative">
                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                    <select {...trackUrl("brand", { required: 'Please Select Store' })} className="form-control-sm form-control" id="brand" onChange={(e) => getBrand(e.target.value)}>
                                        <option value="">Choose Store</option>
                                        {checkData(brandList.data) && brandList.data.map((item, index) => (
                                            <option value={item.brand_name}>{item.brand_name}</option>
                                        ))}
                                    </select>
                                </div>
                                {errors.brand && <span className="text-danger d-block error">{errors.brand.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={6} md={4}>
                            <FormGroup>
                                <div className="d-flex justify-content-between mb-1 align-items-center">
                                    <Label for="tracking_url" className="mb-0">Tracking URL</Label>
                                    <span onClick={() => copied(getValues("tracking_url"))} className="ml-auto float-right btn-text btn-light">
                                        <span className="mr-2">Copy URL</span><Copy style={{ width: "14px" }} />
                                    </span>
                                </div>
                                <input type="text" {...trackUrl("tracking_url", { required: 'Please Select Store' })} className="form-control-sm form-control" value="" id="tracking_url" readonly="true" />
                                {errors.tracking_url && <span className="text-danger d-block error">{errors.tracking_url.message}</span>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>}
        </>
    );
}

function mapStateToProps(state) {
    return {
        state: state.DataReducer
    }
}
export default connect(mapStateToProps)(TrackingCode);