import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faAddressCard, faTruck, faUniversity, faExclamationCircle, faHashtag, faStar, faHandshake, faSettings } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import classnames from 'classnames';
import { User, Lock, Bell, Map, Settings } from 'react-feather';
import Profile from './Profile';
import BankDetail from './BankDetail';
import ChangePassword from './ChangePassword';
import KYCDetail from './KYCDetail';
import BillingDetail from './BillingDetail';
import EmailNotification from './EmailNotification';
import LabelSetting from './LabelSetting';
import CourierSetting from './CourierSetting';
import MyCouriers from './MyCouriers'; 
import FilterTags from './FilterTags';
import CustomerRating from './CustomerRating';
import { checkData } from '../../../ObjectExist';
import { useParams } from 'react-router-dom'
import KycDetails from '../KycDetails';
import { faStaylinked } from '@fortawesome/free-brands-svg-icons';
import ApiIntegration from '../../ApiIntegration/ApiIntegration';
import AgrementDetail from './AgrementDetail';


const AccountSetting = (props) => {
    console.log('props', props)
    const [loader, setLoader] = useState(false);
    const [activeTab, setActiveTab] = useState('8');
    const [bankDetails, setBankDetails] = useState();
    const [kycDetails, setKycDetails] = useState({});
    const [billingDetails, setBillingDetails] = useState({});

    const { settingsTab } = useParams();
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    useEffect(() => {
        setActiveTab(settingsTab);
    }, [settingsTab])

    return (
        <div className="app-content content overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}
            <Row>
                <Col sm={12}>
                <Card className="shadow border-0 mb-4 p-4">
                    <div className="p-0 my-3 MerchantSetting content-wrapper">
                        {/* <div className="content-header d-flex justify-content-between mb-4">
                            <div className="customTitle"><Settings size={20} className="titleIcon" /><span>Setting</span></div>
                            {props.user[0].document_status === 0 ?
                                <div className="account-activation"><span>Account status :- </span><span className={`text-danger`}>Pending Account Activation</span> </div>
                                : ''}
                        </div> */}
                        <Row>
                            <ul className="channels-tabs">
                                <li
                                    className={`channels-tab ${activeTab === '1' ? 'active' : ''}`}
                                    onClick={() => { toggle('1'); }}>
                                    Profile
                                </li>
                                <li
                                    className={`channels-tab ${activeTab === '8' ? 'active' : ''}`}
                                    onClick={() => { toggle('8'); }}>
                                   Change Password
                                </li>
                            </ul>
                            <Nav vertical tabs pills className="mb-2 border-0 bg-white shadow p-3 d-none">
                                <NavItem className='d-none'>
                                    <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                                        <div className="d-flex align-items-center">
                                            <span className="mr-2"><User /></span>
                                            <span>Profile</span>
                                        </div>
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2"><FontAwesomeIcon icon={faUniversity}/></span>
                                        <span>Bank Detail</span>
                                        {checkData(bankDetails) ?
                                        <span className={`ml-auto ${activeTab === '2' ? 'text-light' : 'text-success'}`}><Lock /></span>
                                        :
                                        <span className={`ml-auto ${activeTab === '2' ? 'text-light' : 'text-danger'}`}><FontAwesomeIcon icon={faExclamationCircle} /></span>
                                        }
                                    </div>
                                </NavLink>
                            </NavItem> */}
                                {/* <NavItem>
                                <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2"><FontAwesomeIcon icon={faAddressCard} /></span>
                                        <span>KYC Detail</span>
                                        {checkData(kycDetails) ?
                                        <span className={`ml-auto ${activeTab === '3' ? 'text-light' : 'text-success'}`}><Lock /></span>
                                        :
                                        <span className={`ml-auto ${activeTab === '3' ? 'text-light' : 'text-danger'}`}><FontAwesomeIcon icon={faExclamationCircle} /></span>
                                        }
                                    </div>
                                </NavLink>
                            </NavItem> */}
                                {/* <NavItem>
                                <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2"><FontAwesomeIcon icon={faFileAlt} /></span>
                                        <span>Billing Detail</span>
                                        {checkData(billingDetails) ?
                                        <span className={`ml-auto ${activeTab === '4' ? 'text-light' : 'text-success'}`}><Lock /></span>
                                        :
                                        <span className={`ml-auto ${activeTab === '4' ? 'text-light' : 'text-danger'}`}><FontAwesomeIcon icon={faExclamationCircle} /></span>
                                        }
                                    </div>
                                </NavLink>
                            </NavItem> */}
                                {/* <NavItem>
                                <NavLink className={classnames({ active: activeTab === '5' })} onClick={() => { toggle('5'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2"><Bell /></span>
                                        <span>Email Notification</span>
                                    </div>
                                </NavLink>
                            </NavItem> */}
                                <NavItem className='d-none'>
                                    <NavLink className={classnames({ active: activeTab === '6' })} onClick={() => { toggle('6'); }}>
                                        <div className="d-flex align-items-center">
                                            <span className="mr-2"><Map /></span>
                                            <span>Label Setting</span>
                                        </div>
                                    </NavLink>
                                </NavItem>
                                <NavItem className='d-none'>
                                    <NavLink className={classnames({ active: activeTab === '9' })} onClick={() => { toggle('9'); }}>
                                        <div className="d-flex align-items-center">
                                            <span className="mr-2"><FontAwesomeIcon icon={faTruck} /></span>
                                            <span>My Carriers</span>
                                        </div>
                                    </NavLink>
                                </NavItem>
                                <NavItem className='d-none'>
                                    <NavLink className={classnames({ active: activeTab === '7' })} onClick={() => { toggle('7'); }}>
                                        <div className="d-flex align-items-center">
                                            <span className="mr-2"><Bell /></span>
                                            <span>Carrier Sort</span>
                                        </div>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '8' })} onClick={() => { toggle('8'); }}>
                                        <div className="d-flex align-items-center">
                                            <span className="mr-2"><Lock /></span>
                                            <span>Update Password</span>
                                        </div>
                                    </NavLink>
                                </NavItem>
                                <NavItem className='d-none'>
                                    <NavLink className={classnames({ active: activeTab === '10' })} onClick={() => { toggle('10'); }}>
                                        <div className="d-flex align-items-center">
                                            <span className="mr-2 d-flex"><FontAwesomeIcon icon={faHashtag} /></span>
                                            <span>Tags Settings For Order</span>
                                        </div>
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                <NavLink className={classnames({ active: activeTab === '11' })} onClick={() => { toggle('11'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2 d-flex"><FontAwesomeIcon icon={faStar} /></span>
                                        <span>Customer Rating</span>
                                    </div>                                  
                                </NavLink>
                            </NavItem> */}
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '12' })} onClick={() => { toggle('12'); }}>
                                        <div className="d-flex align-items-center">
                                            <span className="mr-2 d-flex"><FontAwesomeIcon icon={faStaylinked} /></span>
                                            <span>API KEY</span>
                                        </div>
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                <NavLink className={classnames({ active: activeTab === '13' })} onClick={() => { toggle('13'); }}>
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2 d-flex"><FontAwesomeIcon icon={faHandshake} /></span>
                                        <span>Agrement Details</span>
                                    </div>                                  
                                </NavLink>
                            </NavItem> */}
                            </Nav>

                            <Col md={12}>
                                {props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" &&
                                     
                                    <CardBody className='p-0'>
                                        <TabContent activeTab={activeTab} className="pb-0">
                                            <TabPane tabId="1">
                                                {activeTab === '1' &&
                                                    <Profile user={props.user} setLoader={setLoader} />
                                                }
                                            </TabPane>
                                            <TabPane tabId="2">
                                                {/* {activeTab === '2' && */}
                                                <BankDetail user={props.user} setBankDetails={setBankDetails} setLoader={setLoader} />
                                                {/* } */}
                                            </TabPane>
                                            <TabPane tabId="3">
                                                {/* {activeTab === '3' && */}
                                                {/* <KYCDetail user={props.user} setKycDetails={setKycDetails} setLoader={setLoader} /> */}
                                                <KycDetails merchantId={props.user[0].id} setKycDetails={setKycDetails} setLoader={setLoader} />
                                                {/* } */}
                                            </TabPane>
                                            <TabPane tabId="4">
                                                {/* {activeTab === '4' && */}
                                                <BillingDetail user={props.user} setBillingDetails={setBillingDetails} setLoader={setLoader} />
                                                {/* } */}
                                            </TabPane>
                                            <TabPane tabId="5">
                                                {activeTab === '5' &&
                                                    <EmailNotification user={props.user} setLoader={setLoader} />
                                                }
                                            </TabPane>
                                            <TabPane tabId="6">
                                                {activeTab === '6' &&
                                                    <LabelSetting user={props.user} setLoader={setLoader} />
                                                }
                                            </TabPane>
                                            <TabPane tabId="7">
                                                {activeTab === '7' &&
                                                    <CourierSetting user={props.user} setLoader={setLoader} />
                                                }
                                            </TabPane>
                                            <TabPane tabId="8">
                                                {activeTab === '8' &&
                                                    <ChangePassword user={props.user} setLoader={setLoader} />
                                                }
                                            </TabPane>
                                            <TabPane tabId="9">
                                                {activeTab === '9' &&
                                                    <MyCouriers user={props.user} setLoader={setLoader} />
                                                }
                                            </TabPane>
                                            <TabPane tabId="10">
                                                <FilterTags user={props.user} setLoader={setLoader} />
                                            </TabPane>
                                            <TabPane tabId="11">
                                                <CustomerRating user={props.user} setLoader={setLoader} />
                                            </TabPane>
                                            <TabPane tabId="12">
                                                <ApiIntegration user={props.user} setLoader={setLoader} />
                                            </TabPane>
                                            <TabPane tabId="13">
                                                <AgrementDetail user={props.user} setLoader={setLoader} />
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                  
                                }
                            </Col>
                        </Row>
                        {console.log('props.user', props.user)}
                        <MyCouriers user={props.user} />
                        

                    </div>
                </Card>
                </Col>
            </Row>
        </div>
    );
}

export default AccountSetting;