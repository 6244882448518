import React, {useEffect, useState} from 'react';
import { Row, Col, Card, CardBody,Badge, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHolding,faTruckRampBox,
  faTruckFast, faCircleXmark, faTriangleExclamation, faArrowRightArrowLeft, 
  faTruck, faArrowAltCircleLeft, faTruckMoving, faExclamationCircle, faCross, faTimesCircle, 
  faBars, faMoneyBillAlt, faCubes, faCube, faThLarge, faTh, faCodeBranch, faHistory } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import { changeIntoDatedmY, firstDayOftheMonth, errorHandling, nFormatter } from '../../../../utlis';
import {Link} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux'
import { dashCardOverView } from '../../../../actions';
import { AlertTriangle } from 'react-feather';
import Confetti from 'react-dom-confetti';
import LoaderDefault from '../../../LoaderDefault';
import { baseString } from '../../../../config';


const Cards = (props) => {
    const [cardsOverView, setCardsOverView] = useState({});
    let [processCount,setProcessCount] = useState(0);
    const [loader, setLoader] = useState(false);
    const [startConfetti,setStartConfetti] = useState(false);
    const dispatch = useDispatch();
    const listingSummary = () => {
        setLoader(true);
        let mypost = {"merchant_id":props.user[0].id}
        let myget = {}
        dispatch(dashCardOverView(mypost,myget)).then((response) => {
            if(response.error === false){
              setCardsOverView(response.data);
            }
            setLoader(false)
          }).catch(function (response) {
            setLoader(false);
            errorHandling(response,"reload");
          });
    }

    //orders count listing
    const orderCountListing = () => {
      let data = {}
      if(props.user[0].user_type === "Super"){
        data.admin_id=props.user[0].id
      }else{
        data.merchant_id=props.user[0].id
      }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_ORDER_COUNT}`,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
          if(res.data.error === false){
            let myResponse = res.data.data
            if(checkData(myResponse)){
              myResponse.map((item) => {
                let processingCount = (checkUndeNullBlank(item.order_status_id) && (parseInt(item.order_status_id) === 25)) ? item.total : 0
                if(parseInt(processingCount)>0){
                  setProcessCount(processingCount);
                }
                return true
              }); 
            }
          }else{
            Swal.fire({icon:'error',text:res.data.msg,title:'Error'});
          }
      }).catch((err) => {
        errorHandling(err,"reload");
      })
    }

    window.addEventListener('load',function(){
      setStartConfetti(true);
    })

    useEffect(() => {
      if(checkData(props.state) && checkData(props.state.viewAllCardOver)&& checkData(props.state.viewAllCardOver.data)){
        setCardsOverView(props.state.viewAllCardOver.data);
      }else{
        listingSummary();
      }
      orderCountListing();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const cardData = [
        { icon: '', text: "Total Shipment", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.total_shipments) ? cardsOverView.total_shipments : '0', link:`#`, color: "info" },
        // { icon: faMoneyBillAlt, text: "Today's Failed", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.today_failed_shipments) ? cardsOverView.today_failed_shipments : '0', link:`/orders?start_date=${changeIntoDatedmY(new Date())}&end_date=${changeIntoDatedmY(new Date())}`, color: "warning" },
        { icon: '', text: "Total COD", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.total_COD_shipments) ? cardsOverView.total_COD_shipments : '0', link:"#", color: "primary" },
        { icon: '', text: "Total Freight", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.total_Freight_shipments) ?cardsOverView.total_Freight_shipments : '0', link:`#`, color: "danger" },
        { icon: '', text: "Avg. Freight", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.avgFreight) ? cardsOverView.avgFreight : '0', link:"#", color: "success" },
        // { icon: faCreditCard, text: "Active Couriers", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.total_active_couriers) ? cardsOverView.total_active_couriers : '0', link:"/settings/9", color: "danger" },
        // { icon: faCodeBranch, text: "Channel Orders", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.today_channel_order) ? cardsOverView.today_channel_order : '0', link:`/channel-orders?start_date=${changeIntoDatedmY(new Date())}&end_date=${changeIntoDatedmY(new Date())}`, color: "info" },
        // { icon: faCodeBranch, text: "Today's Channel Orders", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.today_channel_order) ? cardsOverView.today_channel_order : '0', link:`/channel-orders?o=all`, color: "info" },
      ];

      let channelOdrVal = checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.today_channel_order) ? cardsOverView.today_channel_order : 0;
      
      const config = {
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 2000,
        dragFriction: 0.09,
        duration: 20000,
        stagger: 10,
        width: "10px",
        height: "10px",
        perspective: "1000px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
      };
      
    return (
      <Row>
        {/* {loader && <div className="formLoader"><Spinner /></div>} */}
        {channelOdrVal >= 1000 && <div className={`confetti-con ${startConfetti ? 'd-block' : 'd-none'}`}><Confetti active={startConfetti && channelOdrVal >= 1000 ? true : false } config={ config } className="confetti" /></div>}
        <Col xs={12} sm={12} md={12} lg={12} className="">          
          <Card className="overview-card border-0 py-3">
          {loader ? <LoaderDefault /> :
           
              <CardBody className="py-0 ">
                <Row className="card-overview-list w-100">
                {/* <Col sm={12} className="text-right">
                  <span className='' onClick={listingSummary} title="Refresh"> Refresh <i class="fa fa-refresh" aria-hidden="true"></i></span>          
                </Col> */}
              </Row>
                <Row className="card-overview-list w-100"> 
                {cardData.map((cardData)=>
                  <Col xs={6} sm={4} md={3} className="">                  
                    <div className={`media ${cardData.color}` }> 
                      <div className='media-body my-auto position-relative'>
                        <h4 className={`card-val ${cardData.text === "Today's Channel Orders" && startConfetti && channelOdrVal >= 1000 && 'confetti-animate-text'}`}>
                        <Link to={cardData.link} class="text-dark font-bold h4">{
                          cardData.text === "Today's Channel Orders" ?
                          cardData.value : nFormatter(cardData.value, 1)
                        }</Link>
                        {cardData.text === "Today's Shipments" && processCount > 0 && 
                        <div className="processing-triangle-icon"><Link to="/orders?o=processing"><AlertTriangle /></Link></div>}
                        </h4>
                        <p className="text-muted text-nowrap font-weight-500 title-card">{cardData.text}</p>
                        {cardData.text === "Today's Shipments" && processCount > 0 &&  
                        <p className="text-warning text-nowrap font-weight-500 title-card act-pending-area m-0 d-none">
                          <span className="process-loader d-inline-flex align-items-center justify-content-center"><Spinner size="sm" /></span><Link to="/orders?o=processing" className="text-warning text-decoration-underline">Action Active ({processCount})</Link></p>}
                      </div>
                      {/* <div className='media-aside mr-2 align-self-start'>
                        <div className="card-icon"><Badge color={cardData.color} pill className="p-0 d-flex justify-content-center align-items-center mx-auto">
                          {<FontAwesomeIcon icon={cardData.icon} />}
                          </Badge></div>
                      </div> */}
                    </div>
                  </Col>
                )}
                </Row>

                <Row>
        {/* {loader && <div className="formLoader"><Spinner /></div>} */}

        <Col xs={12} sm={12} md={12} lg={12} className="">
            <h3 className='NewHeading'>Shipments</h3>
        </Col>          
        
        
        <Col xs={12} sm={12} md={12} lg={12} className="">          
          <div className='smallCard'>
            <div className='smallCardIconWrap'>
                <FontAwesomeIcon icon={faBars} className='smallCardIcon' style= {{ color: '#8079ad', backgroundColor: '#e5e2f8' }} />
            </div>
            <div className='smallCardTitleWrap'>
                <p className='smallCardTitleTitle'>Total Delivered</p>
                <h3 className='smallCardTitleCount'>{ checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.total_Del_shipments) ? cardsOverView.total_Del_shipments : '0' }</h3>
            </div>
          </div>
          <div className=' smallCard'>
            <div className='smallCardIconWrap'>
                <FontAwesomeIcon icon={faBars}  className='smallCardIcon' style={{color: "#e1b778", backgroundColor: "#f9f0e1" }} />
            </div>
            <div className='smallCardTitleWrap'>
                <p className='smallCardTitleTitle'>Total Returned</p>
                <h3 className='smallCardTitleCount'>{ checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.total_rto_shipments) ? cardsOverView.total_rto_shipments : '0' }</h3>
            </div>
          </div>
          {/* <div className=' smallCard'>
            <div className='smallCardIconWrap'>
                <FontAwesomeIcon icon={faHandHolding}  className='smallCardIcon' style={{color: "#62b8cf", backgroundColor: "#cef3fd" }} />
            </div>
            <div className='smallCardTitleWrap'>
                <p className='smallCardTitleTitle'>Picked</p>
                <h3 className='smallCardTitleCount'>10</h3>
            </div>
          </div> */}
          <div className=' smallCard'>
            <div className='smallCardIconWrap'>
                <FontAwesomeIcon icon={faTruck} className='smallCardIcon' style={{color: "#e1b778", backgroundColor: "#f9f0e1" }} />
            </div>
            <div className='smallCardTitleWrap'>
                <p className='smallCardTitleTitle'>Total Pickup Awaited</p>
                <h3 className='smallCardTitleCount'>{ checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.total_PickupAwaited_shipments) ? cardsOverView.total_PickupAwaited_shipments : '0' }</h3>
            </div>
          </div>
          <div className=' smallCard'>
            <div className='smallCardIconWrap'>
                <FontAwesomeIcon icon={faArrowAltCircleLeft} className='smallCardIcon' style={{color: "#62b8cf", backgroundColor: "#cef3fd" }} />
            </div>
            <div className='smallCardTitleWrap'>
                <p className='smallCardTitleTitle'>Total Intransit</p>
                <h3 className='smallCardTitleCount'>{ checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.total_InTransit_shipments) ? cardsOverView.total_InTransit_shipments : '0' }</h3>
            </div>
          </div>
          <div className=' smallCard'>
            <div className='smallCardIconWrap'>
                <FontAwesomeIcon icon={faTruckMoving}  className='smallCardIcon' style= {{ color: '#8079ad', backgroundColor: '#e5e2f8' }} />
            </div>
            <div className='smallCardTitleWrap'>
                <p className='smallCardTitleTitle'>Total OFD</p>
                <h3 className='smallCardTitleCount'>{ checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.total_OFD_shipments) ? cardsOverView.total_OFD_shipments : '0' }</h3>
            </div>
          </div>
          {/* <div className=' smallCard'>
            <div className='smallCardIconWrap'>
                <FontAwesomeIcon icon={faTimesCircle}  className='smallCardIcon' style={{color: "#e1b778", backgroundColor: "#f9f0e1" }} />
            </div>
            <div className='smallCardTitleWrap'>
                <p className='smallCardTitleTitle'>Total Cancelled</p>
                <h3 className='smallCardTitleCount'>10</h3>
            </div>
          </div> */}
          <div className=' smallCard'>
            <div className='smallCardIconWrap'>
                <FontAwesomeIcon icon={faExclamationCircle}  className='smallCardIcon' style={{color: "#e94c89", backgroundColor: "#fbe1eb" }} />
            </div>
            <div className='smallCardTitleWrap'>
                <p className='smallCardTitleTitle'>Total Failed</p>
                <h3 className='smallCardTitleCount'>{ checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.total_Failed_shipments) ? cardsOverView.total_Failed_shipments : '0' }</h3>
            </div>
          </div>
        </Col>
      </Row>

              </CardBody>}
              
          </Card>
        </Col>
      </Row>
    );
}

function mapDispatchToProps (state) {
  return{
    state:state.DataReducer
  }
}

export default connect(mapDispatchToProps)(Cards);