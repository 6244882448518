import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, FormGroup, Form, CardHeader, Label, Button, Collapse, Spinner, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { ArrowLeft } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCheck, faUser, faTruck, faBox, faLocationArrow, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { baseString, states } from '../../config';
import { randomNumberByDate, errorHandling } from '../../utlis';
import LoaderDefault from '../LoaderDefault';
import { useHistory } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { StateList } from '../manageMasters/StateList';
import { toast } from 'react-toastify';

const CreateOrder = (props) => {

    const [isCod, setIsCod] = useState(false);
    const [kyc, setKyc] = useState("");
    const [kycNoValid, setKycNoValid] = useState(false);
    const [isWalkinCustomer, setIsWalkinCustomer] = useState(false);
    const [loader, setLoader] = useState(false);
    const [courierwithPrice, setCourierwithPrice] = useState({});
    const [pickupLocation, setPickupLocation] = useState({});
    const [pickupKey, setPickupKey] = useState('');
    const [zoneAndPrice, setZoneAndPrice] = useState({});
    const [quantity, setQuantity] = useState('');
    const [unitprice, setUnitprice] = useState('');
    const [noData, setNoData] = useState(false);
    const [merchant_id_new, setMerchant_id_new] = useState('')
    const [activeTab, setActiveTab] = useState('consignee'); // Initially, the first tab is active

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
         if(tabName === "pickup"){
            if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
                pickupPointHandler()
            } else {
                if (props.merchantId !== "" && props.merchantId !== undefined && props.merchantId != null) {
                    console.log('useEffect else')
                    const debounceTimeout = setTimeout(() => {
                        // Perform the action you want to debounce here
                        //console.log('Performing action:', props.merchantId);
                        pickupPointHandler()
                    }, 500); // Set the debounce delay here (e.g., 500 milliseconds)

                    return () => {
                        // Cleanup the timeout if the component unmounts before the debounce time elapses
                        clearTimeout(debounceTimeout);
                    };
                }
            }
        }


    };

    const GetCityState = (e) => {
        if (e.target.value.length === 6) {
            let mydata = {}

            if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
                mydata = { "merchant_id": props.user[0].id, "search_pincode": e.target.value }
                // walkin_mobile
            } else {
                mydata = { "merchant_id": merchant_id_new, "search_pincode": e.target.value }
            }

           
            axios({
                method: "post",
                url: `${process.env.REACT_APP_DEST_PINCODE_DETAIL}`,
                data: mydata,
                headers: { "token": window.btoa(baseString) }
            })
                .then((res) => {
                    if (res.data !== undefined && res.data.fatal !== true) {
                        if (res.data.error === false && checkData(res.data.data)) {
                            let myResponse = {}
                            myResponse = { "customer_city": checkUndeNullBlank(res.data.data[0].name) ? res.data.data[0].name : "",  "customer_state": checkUndeNullBlank(res.data.data[0].state) ? res.data.data[0].state : ""}
                            // reset(myResponse)
                            if (checkData(myResponse)) {
                                [
                                    { name: 'customer_city', value: checkUndeNullBlank(myResponse.customer_city) ? myResponse.customer_city : '' },
                                    { name: 'customer_state', value: checkUndeNullBlank(myResponse.customer_state) ? myResponse.customer_state : '' },
                                ].forEach(({ name, value }) => setValue(name, value))
                            }
                        }
                    }
                }).catch((err) => {
                    errorHandling(err, "")
                });
        }



    };



    // const kycIdWatch = useRef(null);
    let inVoiceValue = quantity * unitprice
    const scrollTo = () => {
        scroller.scrollTo('scroll-to-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }
    const handlePickuplocation = (e) => {
        let index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index];
        var pickupIndex = optionElement.getAttribute('data-id');
        setPickupKey(pickupIndex)
    }
    const history = useHistory();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const walkinDetail = (e) => {
        if (e.target.value.length === 10) {
            let mydata = {}
            if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
                mydata = { "merchant_id": props.user[0].id, "search_mobile": e.target.value }
                // walkin_mobile
            } else {
                mydata = { "merchant_id": merchant_id_new, "search_mobile": e.target.value }
            }
            axios({
                method: "post",
                url: `${process.env.REACT_APP_WALKIN_CUST_DETAIL}`,
                data: mydata,
                headers: { "token": window.btoa(baseString) }
            })
                .then((res) => {
                    if (res.data !== undefined && res.data.fatal !== true) {
                        if (res.data.error === false && checkData(res.data.data)) {
                            let myResponse = {}
                            myResponse = { "walkin_mobile": checkUndeNullBlank(res.data.data[0].mobile) ? res.data.data[0].mobile : "", "walkin_full_name": checkUndeNullBlank(res.data.data[0].name) ? res.data.data[0].name : "", "walkin_email": checkUndeNullBlank(res.data.data[0].email) ? res.data.data[0].email : "", "walkin_kyc": checkUndeNullBlank(res.data.data[0].kyc_type_id) ? res.data.data[0].kyc_type_id : "", "walkin_kyc_no": checkUndeNullBlank(res.data.data[0].kyc_no) ? res.data.data[0].kyc_no : "" }
                            // reset(myResponse)
                            if (checkData(myResponse)) {
                                [
                                    { name: 'walkin_full_name', value: myResponse.walkin_full_name },
                                    { name: 'walkin_email', value: myResponse.walkin_email },
                                    { name: 'walkin_kyc', value: myResponse.walkin_kyc },
                                    { name: 'walkin_kyc_no', value: myResponse.walkin_kyc_no }
                                ].forEach(({ name, value }) => setValue(name, value))
                            }
                        }
                    }
                }).catch((err) => {
                    errorHandling(err, "")
                });
        }
    }


    const destinationDetails = (e) => {
        if (e.target.value.length === 10) {
            let mydata = {}
            if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
                mydata = { "merchant_id": props.user[0].id, "search_mobile": e.target.value }
                // walkin_mobile
            } else {
                //admin
                mydata = { "merchant_id": props.merchantId, "search_mobile": e.target.value }
            }
            axios({
                method: "post",
                url: `${process.env.REACT_APP_DEST_CUST_DETAIL}`,
                data: mydata,
                headers: { "token": window.btoa(baseString) }
            })
                .then((res) => {
                    if (res.data !== undefined && res.data.fatal !== true) {
                        if (res.data.error === false && checkData(res.data.data)) {
                            let myResponse = {}
                            myResponse = { "customer_name": checkUndeNullBlank(res.data.data[0].customer_name) ? res.data.data[0].customer_name : "", "customer_mobile": e.target.value, "customer_address_1": checkUndeNullBlank(res.data.data[0].customer_address_1) ? res.data.data[0].customer_address_1 : "", "customer_address_2": checkUndeNullBlank(res.data.data[0].customer_address_2) ? res.data.data[0].customer_address_2 : "", "destination_pincode": checkUndeNullBlank(res.data.data[0].destination_pincode) ? res.data.data[0].destination_pincode : "", "customer_city": checkUndeNullBlank(res.data.data[0].customer_city) ? res.data.data[0].customer_city : "", "customer_state": checkUndeNullBlank(res.data.data[0].customer_state) ? res.data.data[0].customer_state : "", "customer_email": checkUndeNullBlank(res.data.data[0].customer_email) ? res.data.data[0].customer_email : "" }
                            // reset(myResponse)
                            if (checkData(myResponse)) {
                                [
                                    { name: 'customer_name', value: checkUndeNullBlank(myResponse.customer_name) ? myResponse.customer_name : '' },
                                    { name: 'customer_address_1', value: checkUndeNullBlank(myResponse.customer_address_1) ? myResponse.customer_address_1 : '' },
                                    { name: 'customer_address_2', value: checkUndeNullBlank(myResponse.customer_address_2) ? myResponse.customer_address_2 : '' },
                                    { name: 'destination_pincode', value: checkUndeNullBlank(myResponse.destination_pincode) ? myResponse.destination_pincode : '' },
                                    { name: 'customer_city', value: checkUndeNullBlank(myResponse.customer_city) ? myResponse.customer_city : '' },
                                    { name: 'customer_state', value: checkUndeNullBlank(myResponse.customer_state) ? myResponse.customer_state : '' },
                                    { name: 'customer_emai', value: checkUndeNullBlank(myResponse.customer_emai) ? myResponse.customer_emai : '' }
                                ].forEach(({ name, value }) => setValue(name, value))
                            }
                        }
                    }
                }).catch((err) => {
                    errorHandling(err, "")
                });
        }
    }
    const pickupPointHandler = () => {
        let mydata = {}
        if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
            mydata = { "merchant_id": props.user[0].id }
        } else {
            if (props.merchantId !== "" && props.merchantId !== undefined && props.merchantId != null) {
                console.log('pickupPointHandler else')
                mydata = { "merchant_id": props.merchantId }
            }
        }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_ACTIVE_PICKUPPOINT}`,
            data: mydata,
            headers: { "token": window.btoa(baseString) }
        })
            .then((res) => {
                if (res.data !== undefined && res.data.fatal !== true) {
                    if (parseInt(res.data.errno) > 0) {
                        if (res.data.sqlMessage !== undefined) {
                                        toast.error(res.data.sqlMessage,{position:'top-right'})
                        }
                    } else {
                        if (res.data.error === false) {
                            setPickupLocation(res.data.data);
                        }
                    }
                }
            }).catch((err) => {
                errorHandling(err, "")
            });
    }
    useEffect(() => {
        if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
            pickupPointHandler()
        } else {
            if (merchant_id_new != "" && merchant_id_new != undefined && merchant_id_new != null) {
               // console.log('useEffect else')
                const debounceTimeout = setTimeout(() => {
                    // Perform the action you want to debounce here
                    //console.log('Performing action:', merchant_id_new);
                    pickupPointHandler()
                }, 500); // Set the debounce delay here (e.g., 500 milliseconds)

                return () => {
                    // Cleanup the timeout if the component unmounts before the debounce time elapses
                    clearTimeout(debounceTimeout);
                };
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchant_id_new])

    const handleInputChange = (event) => {
        setMerchant_id_new(event.target.value);
    };


    const inputChange = (e) => {
        [
            { name: 'courier_id', value: '' }
        ].forEach(({ name, value }) => setValue(name, value));
        // if (parseInt(e.target.value) === 3) {
        //     [
        //         { name: 'cod_value', value: '' }
        //     ].forEach(({ name, value }) => setValue(name, value))
        // }
        setZoneAndPrice({});
        setCourierwithPrice({});
    }
    const inputChangeMI = (event) => {
        // setMerchant_id_new(e.target.value)
        handleInputChange(event)
    }
    const inputChangeDtype = (e) => {
        [
            { name: 'courier_id', value: '' }
        ].forEach(({ name, value }) => setValue(name, value));
        if (parseInt(e.target.value) === 3) {
            [
                { name: 'cod_value', value: '' }
            ].forEach(({ name, value }) => setValue(name, value))
        }
        setZoneAndPrice({});
        setCourierwithPrice({});
    }
    const onSubmitPrice = (data) => {
        // setInvoiceValue(data.product_qty*data.product_unit_price)
        [
            { name: 'courier_id', value: '' }
        ].forEach(({ name, value }) => setValue(name, value));
        setZoneAndPrice({});
        setCourierwithPrice({});
        setLoader(true);
        let formData = new FormData();
        

        if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
            formData.append("merchant_id", props.user[0].id);
            formData.append("admin_id", process.env.REACT_APP_ADMIN_ID);
        } else if(merchant_id_new!==undefined && merchant_id_new > 0) {
            formData.append("merchant_id", merchant_id_new);
            formData.append("admin_id", process.env.REACT_APP_ADMIN_ID);
        }else if(props.merchantId!==undefined && props.merchantId > 0){
            formData.append("merchant_id", props.merchantId);
            formData.append("admin_id", process.env.REACT_APP_ADMIN_ID);
            
        }
        formData.append("delivery_type_id", data.delivery_type_id);
        formData.append("cod_value", checkUndeNullBlank(data.cod_value) && parseInt(data.delivery_type_id) === 1 ? data.cod_value : '');
        formData.append("source_pincode", pickupLocation[pickupKey].pickup_pincode);
        formData.append("destination_pincode", data.destination_pincode);
        formData.append("weight_in_kgs", data.weight_in_kgs);
        formData.append("length_in_cms", data.length_in_cms);
        formData.append("breadth_in_cms", data.breadth_in_cms);
        formData.append("height_in_cms", data.height_in_cms);
        formData.append("service_id", data.service_id);
        formData.append("eway_bill_no", data.eway_bill_no);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_PRICE_CALCULATOR}`,
            data: formData,
            headers: { "token": window.btoa(baseString) }
        })
            .then(function (response) {
                setLoader(false);
                if (response.data.error === false) {
                    //console.log("response.data.data",JSON.stringify(response.data.data));
                     setCourierwithPrice(response.data.data)
                    setNoData(false);
                    if (response.data.data.length < 1) {
                        setNoData(true);
                    }
                }
                if (response.data.error === true) {
                    // Swal.fire({
                    //     title: 'Error',
                    //     text: response.data.msg,
                    //     icon: 'error',
                    // });
                    toast.error(response.data.msg,{position:'top-right'});
                }
            })
            .catch(function (response) {
                setLoader(false);
                errorHandling(response, "")
            });
    }
    const onSubmitOrder = (data) => {
        if (data.service_id === "6") {
            data.movement_type_id = '2'
        } else {
            data.movement_type_id = '1'
        }
        let wallet_balance=1;
        if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
            data.merchant_id = props.user[0].id
            data.admin_id = process.env.REACT_APP_ADMIN_ID;
            wallet_balance=props.user[0].wallet_balance;
        } else if (merchant_id_new!==undefined && merchant_id_new > 0) {
            data.merchant_id = merchant_id_new;
        }else if(props.merchantId!==undefined && props.merchantId > 0){
            data.merchant_id = props.merchantId;
        }

            let inValue = data.product_qty * data.product_unit_price
        setLoader(true);
        if (checkData(data.courier_id)) {
                //let diffrence_balance = (parseInt(zoneAndPrice.total_price) > 0 && parseInt(props.user[0].wallet_balance) > 0) ? (parseInt(zoneAndPrice.total_price) - parseInt(props.user[0].wallet_balance)) : 0;
                // if (checkData(props.user) && props.user[0].wallet_balance >= zoneAndPrice.total_price) {
                data.invoice_value = inValue
                data.merchant_wallet_balance = parseFloat(wallet_balance);
                data.customer_type = 2;
                data.movement_type_id = 1;
                data.all_charges = zoneAndPrice.all_prices
                data.freight = zoneAndPrice.total_price
                data.zone_category_id = zoneAndPrice.zone_id
                data.pickup_pincode = pickupLocation[pickupKey].pickup_pincode !== undefined ? pickupLocation[pickupKey].pickup_pincode : '';
                axios({
                    method: "post",
                    url: `${process.env.REACT_APP_CREATE_SINGEL_ORDER}`,
                    data: data,
                    headers: { "token": window.btoa(baseString) }
                })
                    .then(function (response) {
                        setLoader(false);
                        if (response.data.error === false) {
                            //Swal.fire({ title: 'Success', text: response.data.msg, icon: 'success', })
                            toast.success(response.data.msg,{position:'top-right'});
                            if (response.data.status === 'assigned') {
                                history.push(`/order-detail/${response.data.order_id}`);
                            } if (response.data.status === 'failed') {
                                history.push(`/orders?o=failed`);
                            } if (response.data.status === 'processing') {
                                history.push(`/orders?o=processing}`);
                            }
                        }
                        if (response.data.error === true) {
                            toast.error(response.data.msg,{position:'top-right'});
                         //   Swal.fire({ title: 'Error', text: response.data.msg, icon: 'error', allowOutsideClick: false, allowEscapeKey: false });
                        }
                    })
                    .catch(function (response) {
                        setLoader(false);
                        errorHandling(response, "")
                    });
                // }
                // else{
                //     setLoader(false);
                //     Swal.fire({title:'Error', text:`Please Recharge Your Wallet With Amount ${diffrence_balance.toFixed(2)}. Your Current Balance is  ${props.user[0].wallet_balance} `, icon:'error'})
                // }
            
        } else {
            setLoader(false);
            //Swal.fire({ title: 'Error', text: `Please Choose Carrier`, icon: 'error' });
            toast.error('Please choose carrier',{position:'top-right'});
        }
    }

    const onErrorUpdate = (errors, e) => console.log('Error', errors);

    const getKycId = (e) => {
        setKyc(e.target.selectedOptions[0].text);
        if (kyc !== "Select Kyc" || kyc !== "") {
            setKycNoValid(true);
        }
    }

    return (
        <div>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Row>
                <Col sm={12}>
                    <div className="p-4 mb-3">
                        <Row>
                            <Col sm={4}>
                                <div className='border-right'>
                                    <ul className="orders-tabs">
                                        <li className={`orders-tab ${activeTab === 'consignee' ? 'active' : ''}`}
                                            onClick={() => handleTabClick('consignee')}>
                                            <FontAwesomeIcon icon={faUser} className="stepIcon" />
                                            <div className="stepText">
                                                <span>Step 1</span>
                                                <p>Consignee Details</p>
                                            </div>
                                            <FontAwesomeIcon icon={faArrowRight} className="rightArrowIcon" />
                                        </li>
                                        <li className={`orders-tab ${activeTab === 'shipment' ? 'active' : ''}`}
                                            onClick={() => handleTabClick('shipment')}>
                                            <FontAwesomeIcon icon={faTruck} className="stepIcon" />
                                            <div className="stepText">
                                                <span>Step 2</span>
                                                <p>Shipment Details</p>
                                            </div>
                                            <FontAwesomeIcon icon={faArrowRight} className="rightArrowIcon" />
                                        </li>
                                        <li className={`orders-tab ${activeTab === 'package' ? 'active' : ''}`}
                                            onClick={() => handleTabClick('package')}>
                                            <FontAwesomeIcon icon={faBox} className="stepIcon" />
                                            <div className="stepText">
                                                <span>Step 3</span>
                                                <p>Package Details</p>
                                            </div>
                                            <FontAwesomeIcon icon={faArrowRight} className="rightArrowIcon" />
                                        </li>
                                        <li className={`orders-tab ${activeTab === 'pickup' ? 'active' : ''}`}
                                            onClick={() => handleTabClick('pickup')}>
                                            <FontAwesomeIcon icon={faLocationArrow} className="stepIcon" />
                                            <div className="stepText">
                                                <span>Step 4</span>
                                                <p>Pickup Address</p>
                                            </div>
                                            <FontAwesomeIcon icon={faArrowRight} className="rightArrowIcon" />
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col sm={8}>
                                {activeTab === 'consignee' && <>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="customer_name">Consignee Name<span className="text-danger">*</span></Label>
                                                <input {...register("customer_name", {
                                                    required: 'Please Enter Consignee Name',
                                                    pattern: { value: /^[a-z ,.'-]+$/i, message: 'Please Enter Valid Consignee Name' }
                                                })} type="text" className="form-control-sm form-control" placeholder="Full Name" onChange={(e) => inputChange(e)} />
                                                {errors.customer_name && <span className="text-danger d-block error">{errors.customer_name.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="customer_mobile">Consignee Mobile<span className="text-danger">*</span></Label>
                                                <input {...register("customer_mobile", {
                                                    required: 'Please Enter Mobile',
                                                    minLength: { value: 10, message: "Please Enter 10 Digit Mobile" },
                                                    maxLength: { value: 10, message: "Please Enter 10 Digit Mobile" },
                                                    pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Mobile' }
                                                })} className="form-control-sm form-control" placeholder="Consignee Mobile" id="customer_mobile" onChange={(e) => { inputChange(e); destinationDetails(e); }} />
                                                {errors.customer_mobile && <span className="text-danger d-block error">{errors.customer_mobile.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="address1">Consignee Address 1<span className="text-danger">*</span></Label>
                                                <input {...register("customer_address_1", { required: 'Please Enter Consignee Address 1.' })} className="form-control-sm form-control" placeholder="Enter Consignee Address 1" onChange={(e) => inputChange(e)} />
                                                {errors.customer_address_1 && <span className="text-danger d-block error">{errors.customer_address_1.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="address2">Consignee Address 2 <small></small></Label>
                                                <input {...register("customer_address_2")} className="form-control-sm form-control" placeholder="Enter Address 2" onChange={(e) => inputChange(e)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="destination_pincode">Consignee Pincode<span className="text-danger">*</span></Label>
                                                <input {...register("destination_pincode", {
                                                    required: 'Please Enter Pincode.',
                                                    minLength: { value: 6, message: "at least 6 digits" },
                                                    maxLength: { value: 6, message: "maximum 6 digits" },
                                                    pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Pincode' }
                                                })} className="form-control-sm form-control" placeholder="Enter Pincode" onChange={(e) => { inputChange(e); GetCityState(e); }} />
                                                {errors.destination_pincode && <span className="text-danger d-block error">{errors.destination_pincode.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="city">Consignee City<span className="text-danger">*</span></Label>
                                                <input {...register("customer_city", {
                                                    required: 'Please Enter City.',
                                                    pattern: { value: /^[a-z ,.'-]+$/i, message: 'Please Enter Valid City' }
                                                })} className="form-control-sm form-control" placeholder="Enter City" onChange={(e) => inputChange(e)} />
                                                {errors.customer_city && <span className="text-danger d-block error">{errors.customer_city.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="state">Select Consignee State<span className="text-danger">*</span></Label>
                                                <div className="position-relative">
                                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                                    <select {...register("customer_state", { required: 'Please Select State' })} className="form-control-sm form-control" onChange={(e) => inputChange(e)}>
                                                        <option value="">Select State</option>
                                                        {StateList.map((item, index) => (
                                                            <option value={item.state_name}>{item.state_name}</option>
                                                        ))
                                                        }
                                                    </select>
                                                    {errors.customer_state && <span className="text-danger d-block error">{errors.customer_state.message}</span>}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="email">Consignee Email</Label>
                                                <input {...register("customer_email", {
                                                    required: false,
                                                    pattern: { value: /\S+@\S+\.\S+/, message: 'Please Enter Valid Email' }
                                                })} className="form-control-sm form-control" placeholder="Enter Email" onChange={(e) => inputChange(e)} />
                                                {errors.customer_email && <span className="text-danger d-block error">{errors.customer_email.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="text-right">
                                              {/* <a href='#' className='prevButtonOrder'>Prev</a>       */}
                                              <a href='#' className='nextButtonOrder' onClick={() => handleTabClick('shipment')}>Next</a>      
                                        </Col>
                                    </Row>
                                </>}
                                {activeTab === 'shipment' && <>
                                    <Row className="align-items-baseline">
                                    <Col md={6}>
                                            <FormGroup>
                                                <Label>Service</Label>
                                                <div className="d-flex flex-wrap">
                                                    <span className=" mr-2">
                                                        <input {...register("service_id", { required: true })} type="radio" value="1" id="express" onChange={(e) => inputChange(e)} defaultChecked />
                                                        <Label className="mx-2" for="express">Air</Label>
                                                    </span>
                                                    {/* <span className="">
                                                        <input {...register("service_id", { required: true })} type="radio" value="6" id="reverse" onChange={(e) => inputChange(e)} />
                                                        <Label className="mx-2" for="revers e">Reverse</Label>
                                                    </span> */}
                                                    <span className="">
                                                        <input {...register("service_id", { required: true })} type="radio" value="7" id="surface" onChange={(e) => inputChange(e)} />
                                                        <Label className="mx-2" for="surface">Surface</Label>
                                                    </span>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>Choose Payment Type</Label>
                                                <div className="d-flex">
                                                    <span className=" mr-2">
                                                        <input {...register("delivery_type_id", { required: true })} type="radio" value="3" id="prepaid" defaultChecked onClick={() => setIsCod(false)} onChange={(e) => inputChangeDtype(e)} />
                                                        <Label className="mx-2" for="prepaid">Prepaid</Label>
                                                    </span>
                                                    <span className=" mr-2">
                                                        <input {...register("delivery_type_id", { required: true })} type="radio" value="1" id="cod" onClick={() => setIsCod(true)} onChange={(e) => inputChangeDtype(e)} />
                                                        <Label className="mx-2" for="cod">COD</Label>
                                                    </span>
                                                    <Collapse isOpen={isCod}>
                                                        {isCod &&
                                                            <input {...register("cod_value", { required: 'Please Enter COD Amount' })} className="form-control-sm form-control" placeholder="Enter COD Amount" id="cod_amount" style={{ width: "170px" }} onChange={(e) => inputChange(e)} />
                                                        }
                                                    </Collapse>
                                                </div>
                                                {errors.cod_value && <span className="text-danger d-block error">{errors.cod_value.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        
                                        <Col md={8}>
                                            <FormGroup>
                                                <Label for="product_name">Product Name<span className="text-danger">*</span></Label>
                                                <input {...register("product_name", { required: 'Please Enter Product Name' })} className="form-control-sm form-control" placeholder="Enter Product Name" id="product_name" onChange={(e) => inputChange(e)} />
                                                {errors.product_name && <span className="text-danger d-block error">{errors.product_name.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="quantity">Qty.<span className="text-danger">*</span></Label>
                                                <input {...register("product_qty", {
                                                    required: 'Please Enter Quantity',
                                                    min: { value: 1, message: "Please enter a value greater than or equal to 1." },
                                                    pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Quantity' }
                                                })} className="form-control-sm form-control" placeholder="Enter Product QTY" id="quantity" onChange={(e) => { inputChange(e); setQuantity(e.target.value); }} />
                                                {errors.product_qty && <span className="text-danger d-block error">{errors.product_qty.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="product_unit_price">Product Value<span className="text-danger">*</span></Label>
                                                <input {...register("product_unit_price", {
                                                    required: 'Please Enter Product Value',
                                                    min: { value: 1, message: "Value greater than 1." },
                                                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Valid Product Value' }
                                                })} className="form-control-sm form-control" placeholder="" id="product_unit_price" onChange={(e) => { inputChange(e); setUnitprice(e.target.value); }} />
                                                {errors.product_unit_price && <span className="text-danger d-block error">{errors.product_unit_price.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="eway_bill_no">E-way Bill No.</Label>
                                                <input {...register("eway_bill_no", {
                                                })} className="form-control-sm form-control" placeholder="" id="eway_bill_no" />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="order_no">Order Number</Label>
                                                <input {...register("order_no", { required: 'Please Enter Order Number' })} className="form-control-sm form-control"  id="order_no" onChange={(e) => inputChange(e)} />
                                                {errors.order_no && <span className="text-danger d-block error">{errors.order_no.message}</span>}
                                            </FormGroup>
                                        </Col>
                                         
                                            <Col sm={12} className="d-flex">
                                                <FormGroup className="font-weight-500 ml-auto">
                                                    <span>Shipment Value :</span>
                                                    <span>{inVoiceValue}</span>
                                                </FormGroup>
                                            </Col>
                                        
                                        <Col md={12} className="text-right">
                                              <a href='#' className='prevButtonOrder' onClick={() => handleTabClick('consignee')}>Prev</a>      
                                              <a href='#' className='nextButtonOrder' onClick={() => handleTabClick('package')}>Next</a>      
                                        </Col>
                                    </Row>


                                </>}
                                {activeTab === 'package' && <>
                                    <Row className="align-items-baseline">
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="weight_in_kgs">Package Weight In KGS.<span className="text-danger">*</span> </Label>
                                                <input {...register("weight_in_kgs", {
                                                    required: 'Please Enter Weight',
                                                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Weight' }
                                                })} className="form-control-sm form-control" id="weight_in_kgs" onChange={(e) => inputChange(e)} />
                                                {errors.weight_in_kgs && <span className="text-danger d-block error">{errors.weight_in_kgs.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="length_in_cms">Dimension (Length In CMS)<span className="text-danger">*</span></Label>
                                                <input {...register("length_in_cms", {
                                                    required: 'Please Enter Length',
                                                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Length' }
                                                })} className="form-control-sm form-control" id="length_in_cms" onChange={(e) => inputChange(e)} />
                                                {errors.length_in_cms && <span className="text-danger d-block error">{errors.length_in_cms.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="breadth_in_cms">Dimension (Width In CMS)<span className="text-danger">*</span></Label>
                                                <input {...register("breadth_in_cms", {
                                                    required: 'Please Enter Breadth ',
                                                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Breadth ' }
                                                })} className="form-control-sm form-control" id="breadth_in_cms" onChange={(e) => inputChange(e)} />
                                                {errors.breadth_in_cms && <span className="text-danger d-block error">{errors.breadth_in_cms.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="height_in_cms">Dimension (Height In CMS)<span className="text-danger">*</span></Label>
                                                <input {...register("height_in_cms", {
                                                    required: 'Please Enter Height',
                                                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Height ' }
                                                })} className="form-control-sm form-control" id="height_in_cms" onChange={(e) => inputChange(e)} />
                                                {errors.height_in_cms && <span className="text-danger d-block error">{errors.height_in_cms.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="text-right">
                                              <a href='#' className='prevButtonOrder' onClick={() => handleTabClick('shipment')}>Prev</a>      
                                              <a href='#' className='nextButtonOrder' onClick={() => handleTabClick('pickup')}>Next</a>      
                                        </Col>
                                    </Row>
                                </>}
                                {activeTab === 'pickup' && <>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="pickup_detail">Choose Pickup Location<span className="text-danger">*</span></Label>
                                                <div className="position-relative">
                                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                                    <select {...register("pickup_point_id", { required: 'Please Select Pickup Location' })} className="form-control-sm form-control" id="pickup_point_id" onChange={(e) => { inputChange(e); handlePickuplocation(e); }}>
                                                        <option value="">Select Pickup Location</option>
                                                        {pickupLocation !== undefined && pickupLocation !== null && Object.entries(pickupLocation).length > 0 ? pickupLocation.map((item, index) => (
                                                            <option data-id={index} value={item.id}>{item.contact_name}</option>
                                                        ))
                                                            : ''}
                                                    </select>
                                                </div>
                                                {errors.pickup_point_id && <span className="text-danger d-block error">{errors.pickup_point_id.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                {checkData(pickupLocation) && checkData(pickupKey) ?
                                                    <div className='pickupAddress'>
                                                        <p className="mb-0">{checkData(pickupLocation[pickupKey].contact_name) && pickupLocation[pickupKey].contact_name},<br /><span className="mr-3">{checkData(pickupLocation[pickupKey].pickup_address_1) && pickupLocation[pickupKey].pickup_address_1}</span>{checkData(pickupLocation[pickupKey].pickup_city_name) && pickupLocation[pickupKey].pickup_city_name} <br /> {states[pickupLocation[pickupKey].pickup_state_id]} , {pickupLocation[pickupKey].pickup_pincode}</p>
                                                        <FontAwesomeIcon icon={faLocationArrow} className="bgIcon" />
                                                    </div> : 'Please Choose Pickup Location First'
                                                }
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="text-right">
                                              <a href='#' className='prevButtonOrder' onClick={() => handleTabClick('package')}>Prev</a>
                                              <Button className="btn ctm-btn btn-sm" color="primary" onClick={handleSubmit(onSubmitPrice, onErrorUpdate)}>Available Carriers</Button>   
                                        </Col>
                                    </Row>
                                </>}
                            </Col>
                        </Row>
                        <div className="courier-list" name="scroll-to-element">
                                <Row>
                                    {checkData(courierwithPrice) && courierwithPrice.map((item, index) => (
                                        <Col md={6} lg={6} xl={4}>
                                            <div className="courier-box mb-4">
                                                <div>
                                                    <input type="radio" disabled={(checkData(item.price) && checkUndeNullBlank(item.price.total) && item.price.total >= 0 ? false : true)} {...register("courier_id")} value={item.courier_id} id={item.courier_id} onClick={() => setZoneAndPrice({ "zone_id": item.zone_category_id, "total_price": checkData(item.price) && checkUndeNullBlank(item.price.total) && item.price.total, "all_prices": item.price, "courier_id": item.courier_id })} />
                                                    <label className="form-check-label d-flex align-items-center border p-3 rounded" for={item.courier_id}>
                                                        <div className="icon-con">
                                                            <span className="icon d-flex align-items-center justify-content-center mr-2"><FontAwesomeIcon icon={faCheck} /></span>
                                                        </div>
                                                        <Card className="mb-0">
                                                            <CardBody className="d-flex align-items-center card-body p-1">
                                                            {/* {props.user[0].user_type != "Merchant" ?
                                                                <Row className='mb-3'>
                                                                    <Col md={4}>
                                                                        <CardHeader className="px-0"><CardTitle className='mb-0'>Merchant id</CardTitle></CardHeader>
                                                                        <input {...register("merchant_id")} id="merchant_id" className="form-control-sm form-control" placeholder="Merchant id" onChange={(e) => inputChangeMI(e)} />
                                                                    </Col>
                                                                </Row> : null} */}
                                                                {/* <div className='courier-img'>
                                                                    <img src={process.env.REACT_APP_IMAGE_COURIER + item.courier_img} alt="courier img" className="courier_img img-fluid" width="90" />
                                                                </div> */}
                                                                <div className="mr-3">
                                                                    <CardTitle className='mb-0' tag="h6">{item.courier_name}</CardTitle>
                                                                    {/* <div>
                                                                        <span>Charged Weight :</span>
                                                                        <span>{item.charged_weight_in_kgs || 'NA'}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>TAT :</span>
                                                                        <span>{item.tat_in_days || 'NA'}</span>
                                                                    </div> */}
                                                                </div>
                                                                <div className="courier_price">
                                                                    <CardTitle className='mb-0 font-weight-bold' tag="h6">₹{(checkData(item.price) && checkUndeNullBlank(item.price.total)) ? item.price.total.toFixed(2) : 'NA'}</CardTitle>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </label>
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                        //   :loader && <Col md={12} className='d-flex justify-content-center'><LoaderDefault /></Col>
                                    }
                                </Row>
                                <Row>
                                    {loader ? <Col md={12} className='d-flex justify-content-center'><LoaderDefault /></Col> : noData &&
                                        <Col md={12} className='nodataImage py-3 text-center'>
                                            <img src="images/noCourier.png" className="img-fluid h-auto" alt="Courier No Found" />
                                            <h6 className="text-muted pt-2">There is no courier serviceable for this Pincode / Service.</h6>
                                        </Col>
                                    }
                                </Row>
                                {checkData(courierwithPrice) &&
                                    <>
                                        <Row>
                                            <Col sm={12} className="text-right">
                                                <Button className="btn ctm-btn btn-sm" color="primary" onClick={handleSubmit(onSubmitOrder, onErrorUpdate)}>Book Order</Button>
                                            </Col>
                                        </Row>
                                        <Badge color='dark' pill className='d-none w-100 my-2 text-right'>All Price are inclusive tax</Badge>
                                    </>
                                }
                            </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default CreateOrder;