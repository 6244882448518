import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, UncontrolledTooltip, CardTitle, Spinner, InputGroup, InputGroupAddon, InputGroupText, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { DateRangePicker } from 'rsuite';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { copyToClipBoard, changeIntoDateTime, changeIntoDateYmd, firstDayOftheMonth, errorHandling, remExtraSpace } from '../../utlis';
import { Copy } from 'react-feather';
import { toast, ToastContainer } from 'react-toastify';
import { useForm } from "react-hook-form";
import NodataFound from '../NodataFound';
import LoaderDefault from '../LoaderDefault';
import axios from 'axios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { connect, useDispatch } from 'react-redux'
import { MerchantRemittanceList } from '../../actions';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { baseString, download_delay } from '../../config';
import moment from 'moment';

const MerchantRemittance = (props) => {
  const [loadertable, setLoadertable] = useState(false);
  const [merchantRemittance, setMerchantRemittance] = useState({});
  const [filterDate, setFilterDate] = useState([firstDayOftheMonth(), new Date()]);
  const [transDate, setTransDate] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [allCourier, setAllCourier] = useState([]);
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), });
  const [searchValue, setSearchValue] = useState(' ');
  const [loader, setLoader] = useState(false);


  const dispatch = useDispatch();
  const dropFilterCount = 20;

  const history = useHistory();


  const merchantsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }
  var opt_merchants = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {}
      if (merchantName && merchantId) {
        tempObj = { label: merchantName, value: merchantId }
        opt_merchants.push(tempObj)
      }
      return true
    });
  }

  const allCourierMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "All Couriers"
  }
  var opt_allCourier = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)) {
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function (key) {
      let tempObj = { label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }
      return opt_allCourier.push(tempObj)
    });
  }

  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  const onTransChange = (e) => {
    setTransDate(e.text);
  };
  let dateRangeRef = dateRange => dateRangeRef = dateRange;


  useEffect(() => {
    if (checkData(props.state) && checkData(props.state.MerchantRemittance)) {
      setMerchantRemittance(props.state.MerchantRemittance);
    } else {
      remittanceListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // remittance Listing 
  const remittanceListing = () => {
    setTransDate([]);
    setFilterDate([firstDayOftheMonth(), new Date()]);
    let data = { "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), "end_date": moment().format('YYYY-MM-DD') }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }

    reset(); setMerchants([]); setAllCourier([]); setSearchValue(' ');
    setLoadertable(true);
    setFilterInput({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), })
    setCurrentCount(1)
    setPage(1)
    let mypost = data
    let myget = { "page": page, "per_page": selectperpage }
    dispatch(MerchantRemittanceList(mypost, myget)).then((response) => {
      // setMerchant(response);
      setMerchantRemittance(response);
      setLoadertable(false)
    }).catch(function (response) {
      setLoadertable(false);
      errorHandling(response, "reload")
    });
  }


  //export data
  const exportRemmitance = () => {
    if (checkData(filterInput)) {
      let data = filterInput
      if (props.user[0].user_type === "Super") {
        data.admin_id = props.user[0].id
      } else {
        data.merchant_id = props.user[0].id
      }
      data.is_export = 1
      setLoader(true);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_MERCHANT_REMITTANCE_LIST}`,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
          if (parseInt(res.data.errno) > 0) {
            setLoader(false);
            if (res.data.sqlMessage !== undefined) {
                          toast.error(res.data.sqlMessage,{position:'top-right'})
            }
          } else {
            if (res.data.is_export === 1) {
              setTimeout(function () {
                setLoader(false);
                history.push("/my-downloads");
              }, download_delay);
            }
          }
        }
      }).catch((err) => {
        setLoader(false)
        errorHandling(err, "")
      });
    } else {
      Swal.fire({ icon: 'error', title: 'Error', text: 'Select Your Filtering Preference' })
    }
  }

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(merchantRemittance.data) && checkUndeNullBlank(merchantRemittance.totalPage)) {
      if (merchantRemittance.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(merchantRemittance.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(merchantRemittance.data)
      if (currentCount > Object.entries(merchantRemittance.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { register: filter, handleSubmit: filtermerchRemitSubmit, watch: filterWatch, reset } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onFilterMerchRemit = (data, pagePerpage) => {
    pagePerpage.page = 1
    if ((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(merchants) || checkData(allCourier) || checkUndeNullBlank(data.pay_status) || checkData(filterDate) || checkData(transDate)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      toast.info('Select Your Filtering Preference');
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    setLoader(true)
    let byData = {}
    let courierId = [];
    let merchantsId = [];
    // let tmpallCourier = [];
    // allCourier.map((item) => (
    //   tmpallCourier.push(item.value)      
    // ));

    if (checkData(allCourier)) {
      allCourier.map((item) => (
        courierId.push(item.value)
      ));
    }
    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }

    byData = { "search_field": checkUndeNullBlank(data.search_field) ? data.search_field : '', "search_value": checkUndeNullBlank(data.search_value) ? remExtraSpace(data.search_value) : '', "pay_status": checkUndeNullBlank(data.pay_status) ? data.pay_status : '', "courier_id": checkUndeNullBlank(courierId) ? courierId : '', "merchant_ids": checkUndeNullBlank(merchantsId) ? merchantsId : '', "start_date": checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '', "end_date": checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '', "trans_start_date": checkData(transDate) ? changeIntoDateYmd(transDate[0]) : '', "trans_end_date": checkData(transDate) ? changeIntoDateYmd(transDate[1]) : '' }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = 1
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_REMITTANCE_LIST}?page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setMerchantRemittance(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "")
      setLoader(false);
    });
  };


  // copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 merchantRemittance shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="page-title">
                  {props.user[0].user_type === "Super" ? "Merchant Remittance" : "Manage Remittance"}
                </CardTitle>
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  <div className="btn-text btn-light mx-2" onClick={exportRemmitance}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Export Remittance</span></div>
                </div>
              </CardHeader>
              <Form onSubmit={filtermerchRemitSubmit(onFilterMerchRemit)} className="cardFilter">
                <Row className="mx-0 justify-content-start justify-content-lg-center mb-3">
                  <Col sm={6} md={5} lg={5} xl={3}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control-sm form-control" onChange={(e) => { if (e.target.selectedOptions[0].text !== "Search By") { setSearchValue("Enter " + e.target.selectedOptions[0].text) } else { setSearchValue('') } }}>
                              <option value="">Search By</option>
                              <option value="awb_number">AWB Number</option>
                              <option value="customer_name">Consignee Name</option>
                              <option value="customer_mobile">Consignee Phone</option>
                              {/* <option value="order_no">Order No</option> */}
                              <option value="product_name">Product Name</option>
                            </select>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input {...filter("search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue} />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  {props.user[0].user_type === "Super" &&
                    <Col sm={6} md={3} lg={3} xl={2}>
                      <FormGroup>
                        <MultiSelect
                          options={opt_merchants}
                          value={merchants}
                          onChange={setMerchants}
                          labelledBy="Select Merchant"
                          overrideStrings={merchantsMultiSelectSettings}
                          className={checkData(merchants) && "active"}
                        />
                      </FormGroup>
                    </Col>}
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <MultiSelect
                          options={opt_allCourier}
                          value={allCourier}
                          onChange={(e) => setAllCourier(e)}
                          labelledBy="All Couriers"
                          overrideStrings={allCourierMultiSelectSettings}
                          className={checkData(allCourier) && "active"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <select {...filter("pay_status")} className={`form-control-sm form-control ${checkData(filterWatch("pay_status")) ? 'valid' : ''}`}>
                          <option value="">Payment Status</option>
                          <option value="1">Paid</option>
                          <option value="0">Unpaid</option>
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Upload Date" placement="bottomEnd" format="dd-MM-yyyy" showOneCalendar={props.oneCalendar} ref={dateRangeRef} change={onDateChange} />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker value={transDate} onChange={date => setTransDate(date)} placeholder="Choose Transaction Date" placement="bottomStart" format="dd-MM-yyyy" showOneCalendar={props.oneCalendar} change={onTransChange} />
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={remittanceListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(merchantRemittance) && checkData(merchantRemittance.data) && checkUndeNullBlank(merchantRemittance.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(merchantRemittance.data).length)} of {merchantRemittance.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      {props.user[0].user_type === "Super" &&
                        <th className="text-nowrap">Merchant Name</th>
                      }
                      <th className="text-nowrap">Trans. Details</th>
                      <th className="text-nowrap">Amount</th>
                      <th className="text-nowrap">Delivery Date</th>
                      <th className="text-nowrap">Carrier Details</th>
                      <th className="text-nowrap">Order Added</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(merchantRemittance.data) ? merchantRemittance.data.map((item, index) => (
                      <tr key={index}>
                        {props.user[0].user_type === "Super" &&
                          <td>{item.MerchantName}</td>
                        }
                        <td>
                          <div className="list-content">
                            <div className="">
                              <p className="mb-0"> <span className="font-weight-bold"> Txn Id :</span> {item.bank_ref_no || 'NA'}</p>
                              <p className="mb-0"> <span className="font-weight-bold"> Txn Amt :</span>  {item.trans_amt || 'NA'}</p>
                              <p className="mb-0"> <span className="font-weight-bold">Txn Date :</span> {changeIntoDateTime(item.trans_date)}</p>
                            </div>
                          </div>
                        </td>
                        <td>{item.cod_value}</td>
                        <td>{changeIntoDateYmd(item.order_delivered_date)}</td>
                        <td>
                          <div className="list-group-item border-0 p-0 d-inline-flex align-items-center">
                            <div className="list-icon">
                              <div className="avatar">
                                {item.logo !== null && item.logo !== undefined ?
                                  <img className="img-fluid" src={process.env.REACT_APP_IMAGE_COURIER + item.logo} alt="logo" width="60" />
                                  : <span className="no-img d-flex align-items-center justify-content-center rounded-circle border font-weight-500">L</span>}
                              </div>
                            </div>
                            <div className="list-content text-left">
                              <div className="">
                                <p className="mb-0">{item.CourierName}</p>
                                <p className="mb-0 text-nowrap">
                                  <span>AWB - {item.awb_no ? item.awb_no : "NA"}</span>
                                  <span onClick={() => copied(item.awb_no ? item.awb_no : "NA")} className="ml-25 copy-btn" id={`copy${item.id}`}>
                                    <Copy />
                                    <UncontrolledTooltip placement="bottom" target={`copy${item.id}`}>
                                      Copy
                                    </UncontrolledTooltip>
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-nowrap">{changeIntoDateTime(item.created_at)}</td>
                      </tr>
                    )) : loadertable ? <tr><td colSpan="5"><LoaderDefault /></td></tr> :
                      <tr><td colSpan="5"><NodataFound /></td></tr>}
                  </tbody>
                </Table>
                <ToastContainer style={{ width: "200px" }} />
                {checkData(merchantRemittance) && checkData(merchantRemittance.data) && checkUndeNullBlank(merchantRemittance.TotalRows) ?
                  <>
                    {merchantRemittance.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}
function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapDispatchToProps)(MerchantRemittance);