import React, { useState, useEffect } from 'react';
import { Row, Col, Button, CardTitle, FormGroup, Form, Label, Spinner } from 'reactstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2';
import { errorHandling } from '../../utlis';
import { baseString } from '../../config';
import { toast } from 'react-toastify';


const CustomerRating = (props) => {

    const [loader, setLoader] = useState(false);

    const { register: addRating, handleSubmit: merchantRatingSubmit, formState: { errors: errorRating }, reset: ratingReset } = useForm();

    useEffect(() => {
        viewMerchantRating();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const viewMerchantRating = () => {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_SETTING}/view`,
            data: { "setting_id": 5, "merchant_id": props.merchantId, },
            headers: { "token": window.btoa(baseString) }
        })
            .then(function (response) {
                setLoader(false);
                if (response.data.length > 0) {
                    let myresponse = response.data[0];
                    ratingReset(myresponse);
                }
            })
            .catch(function (err) {
                setLoader(false);
                errorHandling(err, "reload");
            });
    }
    const onMerchantRating = (data) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("merchant_id", props.merchantId);
        formData.append("setting_value", data.setting_value);
        formData.append("setting_id", 5);
        
        axios({
            method: "post",
            url: process.env.REACT_APP_MERCHANT_SETTING,
            data: formData,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false);
            if (res.data.error === false) {
                Swal.fire({
                    title: 'Success',
                    text: res.data.msg,
                    icon: 'success',
                })
            }
        })
            .catch((err) => {
                setLoader(false);
                errorHandling(err, "");
            });
    };

    return (
        <>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Form onSubmit={merchantRatingSubmit(onMerchantRating)} >
                <div className="sub-page-header" >
                    <CardTitle tag="h4" className="sub-page-title"> Customer Rating </CardTitle>
                </div>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label>Rating<span className="text-danger">*</span></Label>
                            <div>
                                <span className="form-check2 mr-4">
                                    <input {...addRating("setting_value", { required: 'Please Choose field' })} type="radio" value="1" id="enableR" />
                                    <Label className="mx-2" for="enableR">Enable</Label>
                                </span>
                                <span className="form-check2">
                                    <input {...addRating("setting_value", { required: 'Please Choose field' })} type="radio" value="0" id="disableR" defaultChecked />
                                    <Label className="mx-2" for="disableR">Disable</Label>
                                </span>
                            </div>
                            {errorRating.setting_value && <span className="text-danger d-block error">{errorRating.setting_value.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} className="mt-2 text-right">
                        <Button className="ctm-btn btn-sm" color="primary" > Save changes </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default CustomerRating;
