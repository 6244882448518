import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Label, CardTitle, Badge, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Spinner, UncontrolledTooltip, Modal, ModalHeader, ModalBody, } from 'reactstrap';
import { MultiSelect } from "react-multi-select-component";
import { DateRangePicker } from 'rsuite';
// import 'rsuite/dist/styles/rsuite-default.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import { baseString, delivery_type_id } from '../../config';
import axios from 'axios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { firstDayOftheMonth, copyToClipBoard, changeIntoDateTime, errorHandling, characterCheck, moreText } from '../../utlis';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Copy } from 'react-feather';
import { toast } from 'react-toastify';
import EditBulkOrders from './EditBulkOrders';
import moment from 'moment';

const ProcessingOrder = (props) => {
  const [deliveryType, setDeliveryType] = useState([]);
  const [filterDate, setFilterDate] = useState([firstDayOftheMonth(), new Date()]);
  const [allprocessingOrder, setAllprocessingOrder] = useState({});
  const [tableLoader, setTableLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(1000);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState('');
  const [searchValue, setSearchValue] = useState(' ');
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkboxOrderId, setCheckboxOrderId] = useState([]);
  const [checked, setChecked] = useState(false);
  const [overallchecked, setOverallChecked] = useState(false);
  const [editmodal, setEditmodal] = useState(false);
  const [merchantId, setMerchantId] = useState('');
  const [orderDetail, setOrderDetail] = useState({});
  const [cancelmodal, setCancelmodal] = useState(false);

  const dropFilterCount = 20
  const [loader, setLoader] = useState(false);
  // cancel shipment
  const cancelmodalToggle = () => {
    if (checkboxOrderId.length > 0) {
      setCancelmodal(!cancelmodal);
      clearInterval(interval);
    } else {
                              toast.error("Select Order",{position:'top-right'})

    }
  }
  const cancelmodalToggleClose = () => {
    setCancelmodal(false);
  }
  const selectAll = (value) => {
    setOverallChecked(false);

    let abc = checkData(allprocessingOrder.data) && allprocessingOrder.data.map((key) => (
      key === false
    ));
    if (value) {
      var opt_order_id = [];
      checkData(allprocessingOrder.data) && Object.keys(allprocessingOrder.data).map(function (key) {
        let tempObj = getMinutes(allprocessingOrder.data[key].updated_at) > 6 && allprocessingOrder.data[key].id
        tempObj && opt_order_id.push(tempObj);

        return setCheckboxOrderId(opt_order_id)
      });
      // ));
    } else {
      setCheckboxOrderId([])
    }

    setChecked(abc);
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const utilityCheckbox in newState) {
        newState[utilityCheckbox] = value;
      }
      return newState;
    });
  };
  const toggleEditClose = () => setEditmodal(false);
  const toggleEdit = (productId, merchantId) => {
    setLoader(true);
    setMerchantId(merchantId)
    let data = {}
    data = { "order_id": productId, "merchant_id": merchantId, "is_edit": 1 }
    setEditmodal(!editmodal);
    // axios.post(process.env.REACT_APP_ORDER_DETAILS, data)
    axios({
      method: "post",
      url: process.env.REACT_APP_ORDER_DETAILS,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          setOrderDetail(res.data);
        }
      }
    }).catch((err) => {
      setLoader(false);
      errorHandling(err, "")
    })
  }
  const toggleCheck = (utilityCheckbox, event, Id) => {
    setOverallChecked(false);

    var opt_order_id = checkboxOrderId;

    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[utilityCheckbox] = !prevState[utilityCheckbox];
      return newState;
    });
    if (event.target.checked) {
      opt_order_id.push(Id)
      setCheckboxOrderId(opt_order_id)
    } else {
      function arrayRemove(arr, value) {
        return arr.filter(function (ele) {
          return ele !== value;
        });
      }
      var result = arrayRemove(checkboxOrderId, Id);
      setCheckboxOrderId(result)
    };
  }
  // let dateRangeRef =  dateRange => dateRangeRef = dateRange;
  const processingOrderListing = () => {
    setOverallChecked(false);
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    setTableLoader(true);
    setFilterDate([firstDayOftheMonth(), new Date()]);
    setFilterInput('')
    // setCurrentCount(1)
    // setPage(1)
    let data = { "order_status_id": 25, processing_bucket: 1, "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), "end_date": moment().format('YYYY-MM-DD') }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }

    setDeliveryType([]); setSearchValue(' '); reset();

    // axios.post(`${process.env.REACT_APP_ALL_ORDER}?page=${page}&per_page=${selectperpage}`, data)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_ORDER}?page=${page}&per_page=${selectperpage}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setTableLoader(false)
      if (res.data.error === false) {

        if (res.data.data.length === 0) {
          clearInterval(interval);
          props.orderCountListing();
        }
        setAllprocessingOrder(res.data);
      }
      else {
        Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
      }
    }).catch((err) => {
      setTableLoader(false)
      errorHandling(err, "reload");
    })
  }
  var interval = '';
  useEffect(() => {
    processingOrderListing();

    interval = setInterval(() => {
      processingOrderListing();
    }, 7000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  let getMinutes = function (date) {
    var endTime = new Date();
    var startTime = new Date(date);
    var timeStart = new Date(startTime.getTime() + startTime.getTimezoneOffset() * 60000);

    var difference = endTime.getTime() - timeStart.getTime(); // This will give difference in milliseconds
    var resultInMinutes = Math.round(difference / 60000);
    return resultInMinutes;
  }

  const DeliveryTypeMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Payment Type..."
  }
  const opt_deliveryType = [];
  for (const property in delivery_type_id) {
    let tempObj = { label: `${delivery_type_id[property]}`, value: property };
    opt_deliveryType.push(tempObj);
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(allprocessingOrder.data) && checkUndeNullBlank(allprocessingOrder.totalPage)) {
      // &&checkUndeNullBlank(allprocessingOrder.totalPage).length>page.length
      if (allprocessingOrder.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(allprocessingOrder.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(allprocessingOrder.data)
      if (currentCount > Object.entries(allprocessingOrder.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  // filter Bulk Order
  const { register: filter, handleSubmit: filterOrderSubmit, reset } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onFilterOrder = (data, pagePerpage) => {
    pagePerpage.page = 1
    if ((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(deliveryType) || checkData(filterDate)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      toast.info('Select Your Filtering Preference');
    }

  };
  const onCommonFilter = (data, pagePerpage) => {
    setOverallChecked(false);
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    setFilterInput(data);
    setLoader(true)
    let byData = {}
    let tmpDeliveryType = []

    deliveryType.map((item) => (
      tmpDeliveryType.push(item.value)
    ));

    byData = { "order_status_id": 25, "search_field": checkUndeNullBlank(data.search_field) ? data.search_field : '', "search_value": checkUndeNullBlank(data.search_value) ? data.search_value : '', "delivery_type_id": checkData(tmpDeliveryType) ? tmpDeliveryType : '', "start_date": checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '', "end_date": checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '' }
    if (props.user[0].user_type === "Super") {
      byData.admin_id = 1
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_ORDER}?page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        setAllprocessingOrder(res.data);
      }
      else {
        Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  };

  // copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }
  const autoShip = (message, oid, mid, sid) => {
    if (message === 'Uploaded') {
      placeBulkOrder(oid, mid, sid, message);
    }
    return
  }
  const placeBulkOrder = (oid, mid, sid, msg) => {
    let orderIds = checkUndeNullBlank(oid) ? [oid] : checkboxOrderId.length > 0 ? checkboxOrderId : '';
    let orderStatusId = (checkUndeNullBlank(sid)) ? sid : 25
    let data = { "order_ids": orderIds, "order_status_id": orderStatusId }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
      data.merchant_wallet_balance = props.user[0].wallet_balance
    }
    if (checkUndeNullBlank(oid) || checkboxOrderId.length > 0) {
      setLoader(true);
      // axios.post(process.env.REACT_APP_PLACE_BULK_ORDER, data)
      axios({
        method: "post",
        url: process.env.REACT_APP_PLACE_BULK_ORDER,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        setLoader(false);
        if (res.data.error !== true && res.data.fatal !== true) {
          // if(msg === 'Uploaded'){
          //   processingOrderListing();
          // }else 
          Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success', allowOutsideClick: false, allowEscapeKey: false }).then(okay => {
            if (okay) {
              setOverallChecked(false);
              setCheckboxOrderId([]);
              setCheckedAll(false);
              setChecked(false);
              processingOrderListing();
            }
          });
        } else {
          Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
        }
      }).catch((err) => {
        setLoader(false);
        errorHandling(err, "")
      })
    } else {
                              toast.error("Select Order",{position:'top-right'})

    }
  }

  const markasFailed = (oid, mid) => {
     let orderIds =  [oid];
 
    if (checkUndeNullBlank(oid) && checkUndeNullBlank(mid) && orderIds.length > 0) {

      setLoader(true);

      // axios.post(process.env.REACT_APP_PLACE_BULK_ORDER, data)
      let data = {}
      data.order_ids = orderIds;
      data.merchant_id = mid;
      data.is_force_update=1;
     
      axios({
        method: "post",
        url: `${process.env.REACT_APP_FAILED_ORDER_STATUS}`,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        setLoader(false);

        if (res.data.error === false) {
          Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success', customClass: 'swal_scs_err_popup', });
        } else {
          Swal.fire({ title: 'Error', text: res.msg, icon: 'error', customClass: 'swal_scs_err_popup', })
        }
      }).catch((err) => {
        errorHandling(err, "");
        setLoader(false);

      });


    } else {
                              toast.error("Select Order",{position:'top-right'})

    }
  }

  const { register: cancelOrder, handleSubmit: cancelOrderSubmit, formState: { errors: errors4 }, reset: resetCancelled } = useForm();
  const onCancelOrderSubmit = data => {
    setLoader(true);
    data.order_ids = checkboxOrderId
    // axios.post(process.env.REACT_APP_CANCEL_ORDER, data)
    axios({
      method: "post",
      url: process.env.REACT_APP_CANCEL_ORDER,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false)
      if (res.data.error === false) {
        Swal.fire({ icon: 'success', title: 'Success', text: res.data.msg })
        setCancelmodal(false);
        resetCancelled();
        setCheckboxOrderId([]);
        processingOrderListing();
        // onCommonFilter();
        setCheckedAll(false);
        setChecked(false);
      } else {
        Swal.fire({ icon: 'error', title: 'Error', text: res.data.msg })
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err, "")
    });
  }
  return (
    // <div className="app-content content overflow-hidden">
    <div>
      {loader && <div className="formLoader"><Spinner /></div>}
      {checkData(orderDetail) &&
        <Modal isOpen={editmodal} toggle={editmodal} scrollable={true} size="xl">
          <ModalHeader toggle={toggleEditClose}>Edit Order</ModalHeader>
          <ModalBody>
            <EditBulkOrders orderDetails={orderDetail} setEditmodal={setEditmodal} merchantId={merchantId} setOrderDetail={setOrderDetail} user={props.user} processingOrderListing={processingOrderListing} />
          </ModalBody>
        </Modal>
      }
      <Row>
        <Col sm={12}>
          <div className="p-0 processingOrder content-wrapper border-0">
            <div className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap d-none">
                
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto position-relative">
                  {checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === "Merchant" && checkUndeNullBlank(props.user[0].ship_status) && parseInt(props.user[0].ship_status) === 1 && checkData(allprocessingOrder) && checkData(allprocessingOrder.data) &&
                    <>
                      {checkboxOrderId.length > 0 &&
                        <div className="btn btn-light btn-orange font-weight-bold mx-2 text-white shake" onClick={() => placeBulkOrder()}><span className="icon mr-2"><FontAwesomeIcon icon={faCartPlus} /></span><span>RESHIP</span></div>}
                      <div className="btn-text btn-light mx-2" onClick={cancelmodalToggle}><span className="icon mr-2"><FontAwesomeIcon icon={faTimes} /></span><span>Cancel Shipment</span></div>
                    </>
                  }
                </div>
              </CardHeader>
              <Modal isOpen={cancelmodal} toggle={cancelmodal} scrollable={true} >
                <ModalHeader toggle={cancelmodalToggleClose}>Cancel Shipment</ModalHeader>
                <ModalBody>
                  <Form onSubmit={cancelOrderSubmit(onCancelOrderSubmit)}>
                    <Row className="m-0 pb-1">
                      <Col sm={12}>
                        <FormGroup>
                          <Label for="remaks">Remarks</Label>
                          <textarea {...cancelOrder("remarks", { required: 'Please Enter Remaks' })} rows="4" className="form-control-sm form-control" placeholder="Remarks" id="remarks" />
                          {errors4.remarks && <span className="text-danger d-block error">{errors4.remarks.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col sm={12} className="text-right">
                        <Button className="btn ctm-btn btn-sm" color="primary">Submit</Button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
              <Form onSubmit={filterOrderSubmit(onFilterOrder)} className="cardFilter">
              <CardTitle className="page-title">Manage Processing Orders</CardTitle>
                <Row className="mx-0 justify-content-start justify-content-lg-center">
                  <Col sm={6} md={5} lg={5} xl={4}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control-sm form-control" onChange={(e) => { if (e.target.selectedOptions[0].text !== "Search By") { setSearchValue("Enter " + e.target.selectedOptions[0].text) } else { setSearchValue('') } }}>
                              <option value="customer_name">Consignee Name</option>
                              <option value="customer_name">Consignee Pincode</option>
                              <option value="customer_mobile">Consignee Phone</option>
                              <option value="order_no">Order No</option>
                              <option value="product_name">Product Name</option>
                            </select>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input {...filter("search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue} />
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <MultiSelect
                          options={opt_deliveryType}
                          value={deliveryType}
                          onChange={(e) => setDeliveryType(e)}
                          labelledBy="Payment Type"
                          overrideStrings={DeliveryTypeMultiSelectSettings}
                          className={checkData(deliveryType) && "active"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} format="dd-MM-yyyy" showOneCalendar={props.oneCalendar} placement="bottomEnd" />
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={processingOrderListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(allprocessingOrder) && checkData(allprocessingOrder.data) && checkUndeNullBlank(allprocessingOrder.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(allprocessingOrder.data).length)} of {allprocessingOrder.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      {checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === "Merchant" &&
                        <th><div className="form-check2"><input name="cust-type" type="checkbox" id="select_all_processing" checked={checkedAll} onChange={(event) => selectAll(event.target.checked)} value="checkedall" /><label className="form-check-label" for="select_all_processing"></label></div></th>
                      }
                      {props.user[0].user_type === "Super" &&
                        <th className="text-nowrap">Merchant Name</th>}
                      <th className="text-nowrap">Order Number</th>
                      <th className="text-nowrap">Consignee Pincode</th>
                      <th className="text-nowrap">Added</th>
                      <th className="text-nowrap">Updated</th>
                      <th className="text-nowrap">Response</th>
                      <th className="text-nowrap">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(allprocessingOrder.data) ? allprocessingOrder.data.map((item, index) => {
                      {/* item.api_msg ==='Uploaded' &&checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === "Merchant" && autoShip(item.api_msg,item.id,item.merchant_id,item.order_status_id); */ }
                      let errorText = characterCheck(item.customer_name + item.customer_address_1 + item.customer_address_2 + item.customer_city + item.customer_state)
                      return (
                        <tr>
                          {checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === "Merchant" &&
                            <td>
                              {(getMinutes(item.updated_at) > 6 || item.api_msg === 'Uploaded') &&
                                <div className="form-check2"><input checked={checked !== undefined && checked[index]} name={'check' + index} type="checkbox" id={item.id} onChange={(event) => toggleCheck(index, event, item.id)} disabled={overallchecked} /><label className="form-check-label" for={item.id}></label></div>
                              }
                            </td>
                          }
                          {props.user[0].user_type === "Super" &&
                            <td>{item.merchantName}</td>}
                          <td><div><Link target="_blank" to={`/order-detail/${item.id}`}>{item.order_no}</Link>
                            <span onClick={() => copied(item.order_no)} className="ml-1 copy-btn" id={`copyOdr${item.id}`}>
                              <Copy />
                              <UncontrolledTooltip placement="bottom" target={`copyOdr${item.id}`}>
                                Copy
                              </UncontrolledTooltip>
                            </span>
                          </div></td>
                          <td>{item.destination_pincode}</td>
                          <td>{changeIntoDateTime(item.created_at)}</td>
                          <td>{changeIntoDateTime(item.updated_at)}</td>
                          <td><span id={`productTooltip${item.id}`}>{moreText(item.api_msg)}</span><span>
                            <UncontrolledTooltip placement="top" target={`productTooltip${item.id}`}>
                              {item.api_msg}
                            </UncontrolledTooltip>
                          </span> <br /> <Badge color='warning' pill className='mt-2 mr-1 badge-70-text' >{moreText(characterCheck(item.customer_name + item.customer_address_1 + item.customer_address_2 + item.customer_city + item.customer_state))}</Badge> </td>
                          <td>
                            {checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === "Merchant" ?
                              <>
                                {getMinutes(item.updated_at) > 6 || item.api_msg === 'Uploaded' || checkUndeNullBlank(errorText) ?
                                  <>
                                    <span><Button className="mx-2 btn-sm btn-outline-orange font-weight-bold" outline onClick={() => placeBulkOrder(item.id, item.merchant_id, item.order_status_id)}>RESHIP</Button></span>
                                    <span><Button className="mx-2 btn-sm" color="primary" outline onClick={() => toggleEdit(item.id, item.merchant_id)}>Edit</Button></span>
                                    <span><Button className="mx-2 btn-sm" color="danger" outline onClick={() => markasFailed(item.id, item.merchant_id)}>Mark as Fail</Button></span>
                                  </>
                                  :
                                  <><span class="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span>
                                    <div className="d-flex align-items-center text-warning-light"><span>Processing</span><div className="dot-flashing d-inline-block ml-4"></div></div></>
                                }
                              </>
                              :
                              <>
                                <span class="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span>
                                <div className="d-flex align-items-center text-warning-light"><span>Processing</span><div className="dot-flashing d-inline-block ml-4"></div></div>
                                <span><Button className="mx-2 btn-sm" color="primary" outline onClick={() => toggleEdit(item.id, item.merchant_id)}>Edit</Button></span>
                                <span><Button className="mx-2 btn-sm" color="danger" outline onClick={() => markasFailed(item.id, item.merchant_id)}>Mark as Fail</Button></span>
                              </>
                            }
                          </td>
                        </tr>
                      )
                    }) :
                      tableLoader ? <tr><td colSpan="6" className="text-center"><LoaderDefault /></td></tr>
                        : <tr><td colSpan="6" className="text-center"><NodataFound /></td></tr>
                    }

                  </tbody>
                </Table>

                {checkData(allprocessingOrder) && checkData(allprocessingOrder.data) && checkUndeNullBlank(allprocessingOrder.TotalRows) ?
                  <>
                    {allprocessingOrder.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer d-none">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                {/* <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option> */}
                                <option value="1000">1000</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </div>
          </div>
        </Col>

      </Row>
    </div>
  );
}

export default ProcessingOrder;