import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, CardTitle, FormGroup, Form, Nav, NavItem, NavLink, TabContent, TabPane, Label, Spinner, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import classnames from 'classnames';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { StateList } from '../manageMasters/StateList';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
import UrlImageDownloader from "react-url-image-downloader";
import { extension, errorHandling } from '../../utlis';
import { baseString } from '../../config';

const CourierDetails = (props) => {

  const [activeTab, setActiveTab] = useState('1');
  const [bankDetails, setBankDetails] = useState({});
  const [regutaloryDetails, setRegutaloryDetails] = useState({});
  const [loader, setLoader] = useState(false);

  const { register: addCourier_address, handleSubmit: courierAddressSubmit, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const { register: regulatory, handleSubmit: regulatorySubmit, formState: { errors: errors3 }, reset: reset3 } = useForm();
  const { register: spocDetails, handleSubmit: spocSubmit, formState: { errors: errors4 }, reset: reset4 } = useForm();
  const [checkCopy, setCheckcopy] = useState('');
  const [gstCopy, setGstCopy] = useState('');
  const [panCopy, setPanCopy] = useState('');
  const { register: addCourier_bankDetails, handleSubmit: bankDetailsSubmit, formState: { errors }, reset } = useForm();

  const { courierId } = useParams();

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_COURIER_BANKDETAILS}/view`,
      data: { "courier_id": courierId },
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        if (response.data.length > 0) {
          let myResponse = response.data[0];
          reset(myResponse);
          setBankDetails(myResponse);
        }
      })
      .catch(function (err) {
        errorHandling(err, "reload");
      });
    axios({
      method: "post",
      url: `${process.env.REACT_APP_COURIER_ADDRESSADD}/view`,
      data: { "courier_id": courierId },
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        let myResponse = response.data[0];
        reset2(myResponse);
      })
      .catch(function (err) {
        errorHandling(err, "reload");
      });
    axios({
      method: "post",
      url: `${process.env.REACT_APP_COURIER_REGULATRYDETAILS}/view`,
      data: { "courier_id": courierId },
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        let myResponse = response.data[0];
        reset3(myResponse);
        setRegutaloryDetails(myResponse);
      })
      .catch(function (err) {
        errorHandling(err, "reload");
      });
    axios({
      method: "post",
      url: `${process.env.REACT_APP_COURIER_SPOCDETAILS}/view`,
      data: { "courier_id": courierId },
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        let myResponse = response.data[0];
        reset4(myResponse);
      })
      .catch(function (err) {
        errorHandling(err, "reload");
      });
  }, [reset, reset2, reset3, reset4, courierId])

  const handleFileInput = (e) => {
    setCheckcopy(e.target.files[0])
  }
  const handleFileInputpan = (e) => {
    setPanCopy(e.target.files[0])
  }
  const handleFileInputgst = (e) => {
    setGstCopy(e.target.files[0])
  }
  const onAddbankDetails = (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("courier_id", courierId);
    formData.append("account_type", data.account_type);
    formData.append("bank_name", data.bank_name);
    formData.append("account_number", data.account_number);
    formData.append("ifsc_code", data.ifsc_code);
    formData.append("branch", data.branch);
    formData.append("cancel_cheque_copy", checkCopy !== '' ? checkCopy : bankDetails !== undefined && bankDetails.cancel_cheque_copy !== undefined && bankDetails.cancel_cheque_copy !== "" ? bankDetails.cancel_cheque_copy : 'NA');

    axios({
      method: "post",
      url: `${process.env.REACT_APP_COURIER_BANKDETAILS}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        Swal.fire({ title: 'Success', text: res.data.message, icon: 'success', })
      }
    })
      .catch((err) => {
        setLoader(false);
        Swal.fire({ title: 'Error', text: err, icon: 'error' });
      });
  };
  const onAddcourierAddress = (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("courier_id", courierId);
    formData.append("address", data.address);
    formData.append("pincode", data.pincode);
    formData.append("city", data.city);
    formData.append("state_id", data.state_id);
    formData.append("contact_number", data.contact_number);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_COURIER_ADDRESSADD}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        Swal.fire({
          title: 'Success',
          text: res.data.message,
          icon: 'success',
        })
      }
    })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      });
  };
  const onRegulatory = (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("courier_id", courierId);
    formData.append("pan_card_no", data.pan_card_no);
    formData.append("pan_card", panCopy !== '' ? panCopy : regutaloryDetails !== undefined && regutaloryDetails.pan_card !== undefined && regutaloryDetails.pan_card !== "" ? regutaloryDetails.pan_card : 'NA');
    formData.append("gst_number", data.gst_number);
    formData.append("gst_file", gstCopy !== '' ? gstCopy : regutaloryDetails !== undefined && regutaloryDetails.gst_file !== undefined && regutaloryDetails.gst_file !== "" ? regutaloryDetails.gst_file : 'NA');

    axios({
      method: "post",
      url: `${process.env.REACT_APP_COURIER_REGULATRYDETAILS}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data.error === false) {
        setLoader(false);
        Swal.fire({
          title: 'Success',
          text: res.data.message,
          icon: 'success',
        })
      }
    })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      });
  };
  const onSpocSetails = (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("courier_id", courierId);
    formData.append("remittance_email", data.remittance_email);
    formData.append("pendency_email", data.pendency_email);
    
    axios({
      method: "post",
      url: `${process.env.REACT_APP_COURIER_SPOCDETAILS}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        Swal.fire({ title: 'Success', text: res.data.message, icon: 'success', })
      }
    })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      })
  };

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }


  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <div className="p-0 my-3 courierDetails content-wrapper">
            <div className="content-header pl-3 mb-4 d-flex justify-content-between flex-wrap">
              <div className="page-title">Update Carrier Details</div>
              <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                <Link to='/courier' className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back to Carrier List</span></Link>
              </div>
            </div>
            <Row>
              <Col md={3}>
                <Nav vertical tabs pills className="mb-2 border-0">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => { toggle('1'); }}
                    >
                      Address Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { toggle('2'); }}
                    >
                      Bank Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => { toggle('3'); }}
                    >
                      Regulatory Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '4' })}
                      onClick={() => { toggle('4'); }}
                    >
                      Spoc Details
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md={9}>
                <Card className="shadow border-0">
                  <CardBody className="p-4">
                    <TabContent activeTab={activeTab} className="pb-0">
                      <TabPane tabId="1">
                        <Form onSubmit={courierAddressSubmit(onAddcourierAddress)}>
                          <div className="sub-page-header">
                            <CardTitle tag="h4" className="sub-page-title">Address Details</CardTitle>
                          </div>
                          <Row>
                            <Col sm={12} md={6} xl={4} className="mb-2">
                              <FormGroup>
                                <Label for="address">Enter Address<span className="text-danger">*</span></Label>
                                <input {...addCourier_address("address", { required: 'Please Enter Address' })} className="form-control-sm form-control" placeholder="Address" id="address" />
                                {errors2.address && <span className="text-danger d-block error">{errors2.address.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} xl={4} className="mb-2">
                              <FormGroup>
                                <Label for="city">Enter City<span className="text-danger">*</span></Label>
                                <input {...addCourier_address("city", {
                                  required: 'Please Enter City',
                                  pattern: { value: /^[a-z ,.'-]+$/i, message: 'Please Enter Valid City' }
                                })} className="form-control-sm form-control" placeholder="City" id="city" />
                                {errors2.city && <span className="text-danger d-block error">{errors2.city.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} xl={4} className="mb-2">
                              <FormGroup>
                                <Label for="state">Select State<span className="text-danger">*</span></Label>
                                <div className="position-relative">
                                  <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                  <select {...addCourier_address("state_id", { required: 'Please Select State' })} className="form-control-sm form-control" id="state">
                                    <option value="">Select State</option>
                                    {StateList.map((item, index) => (
                                      <option value={item.state_id}>{item.state_name}</option>
                                    ))
                                    }
                                  </select>
                                  {errors2.state_id && <span className="text-danger d-block error">{errors2.state_id.message}</span>}
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} xl={4} className="mb-2">
                              <FormGroup>
                                <Label for="pincode">Enter Pincode<span className="text-danger">*</span></Label>
                                <input {...addCourier_address("pincode", {
                                  required: 'Please Enter Pincode',
                                  minLength: { value: 6, message: "at least 6 digits" },
                                  maxLength: { value: 6, message: "maximum 6 digits" },
                                  pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Pincode' }
                                })} className="form-control-sm form-control" placeholder="Pincode" id="pincode" />
                                {errors2.pincode && <span className="text-danger d-block error">{errors2.pincode.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} xl={4} className="mb-2">
                              <FormGroup>
                                <Label for="contact">Enter Contact Number<span className="text-danger">*</span></Label>
                                <input {...addCourier_address("contact_number", {
                                  required: 'Please Enter Contact Number',
                                  minLength: { value: 10, message: "Please Enter 10 Digit Contact Number" },
                                  maxLength: { value: 10, message: "Please Enter 10 Digit Contact Number" },
                                  pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Contact Number' }
                                })} className="form-control-sm form-control" placeholder="Contact Number" id="contact" />
                                {errors2.contact_number && <span className="text-danger d-block error">{errors2.contact_number.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} className="text-right">
                              <Button className="ctm-btn btn-sm save" color="primary">Save</Button>
                            </Col>
                          </Row>
                        </Form>
                      </TabPane>

                      <TabPane tabId="2">
                        <Form onSubmit={bankDetailsSubmit(onAddbankDetails)}>
                          <div className="sub-page-header">
                            <CardTitle tag="h4" className="sub-page-title">Bank Details</CardTitle>
                          </div>
                          <Row>
                            <Col Col sm={12} md={6} xl={4} className="mb-2">
                              <FormGroup>
                                <Label>Account Type<span className="text-danger">*</span></Label>
                                <div>
                                  <span className="form-check2 mr-2">
                                    <input {...addCourier_bankDetails("account_type", { required: 'Please Select Account Type' })} type="radio" value="Saving" id="saving" />
                                    <Label className="mx-2" for="saving">Saving</Label>
                                  </span>
                                  <span className="form-check2">
                                    <input {...addCourier_bankDetails("account_type", { required: 'Please Select Account Type' })} type="radio" value="Current" id="current" />
                                    <Label className="mx-2" for="current">Current</Label>
                                  </span>
                                </div>
                                {errors.account_type && <span className="text-danger d-block error">{errors.account_type.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} xl={4} className="mb-2">
                              <FormGroup>
                                <Label for="bankName">Enter Bank Name<span className="text-danger">*</span></Label>
                                <input {...addCourier_bankDetails("bank_name", { required: 'Please Enter Bank Name' })} className="form-control-sm form-control" placeholder="Bank Name" id="bankName" />
                                {errors.bank_name && <span className="text-danger d-block error">{errors.bank_name.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} xl={4} className="mb-2">
                              <FormGroup>
                                <Label for="accountNumber">Enter Account Number<span className="text-danger">*</span></Label>
                                <input {...addCourier_bankDetails("account_number", {
                                  required: 'Please Enter Account Number',
                                  pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Account Number' }
                                })} type="text" className="form-control-sm form-control" placeholder="Account Number" id="accountNumber" />
                                {errors.account_number && <span className="text-danger d-block error">{errors.account_number.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} xl={4} className="mb-2">
                              <FormGroup>
                                <Label for="IFSC_Code">Enter IFSC Code<span className="text-danger">*</span></Label>
                                <input {...addCourier_bankDetails("ifsc_code", { required: 'Please Enter IFSC Code' })} className="form-control-sm form-control" placeholder="IFSC Code" id="IFSC_Code" />
                                {errors.ifsc_code && <span className="text-danger d-block error">{errors.ifsc_code.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} xl={4} className="mb-2">
                              <FormGroup>
                                <Label for="branchName">Enter Branch Name<span className="text-danger">*</span></Label>
                                <input {...addCourier_bankDetails("branch", { required: 'Please Enter Branch Name' })} className="form-control-sm form-control" placeholder="Branch" id="branchName" />
                                {errors.branch && <span className="text-danger d-block error">{errors.branch.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} xl={4} className="mb-2">
                              <FormGroup>
                                <Label for="uploadCheque">Upload Cheque Book Copy<span className="text-danger">*</span></Label>
                                <input {...addCourier_bankDetails("cheque_copy", { required: bankDetails !== undefined && bankDetails.cancel_cheque_copy !== undefined && bankDetails.cancel_cheque_copy !== "" ? false : true && 'Please Add Cheque Book Copy' })} type="file" onChange={handleFileInput} className="form-control-sm form-control" id="uploadCheque" />
                                {bankDetails !== undefined && bankDetails.cancel_cheque_copy !== undefined && bankDetails.cancel_cheque_copy !== null &&
                                  <div className={`edit-brand-logo download-img-con ${extension(bankDetails.cancel_cheque_copy) === "pdf" && 'pdf-con'} mt-2`}>
                                    {extension(bankDetails.cancel_cheque_copy) === "pdf" && <span className="pdf-file">{bankDetails.cancel_cheque_copy}</span>}
                                    <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_COURIER + bankDetails.cancel_cheque_copy} />
                                  </div>}
                                {errors.cheque_copy && <span className="text-danger d-block error">{errors.cheque_copy.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} className="text-right">
                              <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                            </Col>
                          </Row>
                        </Form>
                      </TabPane>

                      <TabPane tabId="3">
                        <Form onSubmit={regulatorySubmit(onRegulatory)}>
                          <div className="sub-page-header">
                            <CardTitle tag="h4" className="sub-page-title">Regulatory Details</CardTitle>
                          </div>
                          <Row>
                            <Col sm={12} md={6} xl={6} className="mb-2">
                              <FormGroup>
                                <Label for="panNumber">Enter Pan Card Number<span className="text-danger">*</span></Label>
                                <input {...regulatory("pan_card_no", { required: 'Please Enter Pan Card Number' })} className="form-control-sm form-control" placeholder="PAN Number" id="panNumber" />
                                {errors3.pan_card_no && <span className="text-danger d-block error">{errors3.pan_card_no.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} xl={6} className="mb-2">
                              <FormGroup>
                                <Label for="uploadPan">Upload Pan Card<span className="text-danger">*</span></Label>
                                <input {...regulatory("pan_cards", { required: regutaloryDetails !== undefined && regutaloryDetails.pan_card !== undefined && regutaloryDetails.pan_card !== "" ? false : true && 'Please Add Pan Card Copy' })} type="file" onChange={handleFileInputpan} className="form-control-sm form-control" id="uploadPan" />
                                {regutaloryDetails !== undefined && regutaloryDetails.pan_card !== undefined && regutaloryDetails.pan_card !== null &&
                                  <div className={`edit-brand-logo download-img-con ${extension(regutaloryDetails.pan_card) === "pdf" && 'pdf-con'} mt-2`}>
                                    {extension(regutaloryDetails.pan_card) === "pdf" && <span className="pdf-file">{regutaloryDetails.pan_card}</span>}
                                    <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_COURIER + regutaloryDetails.pan_card} />
                                  </div>}
                                {errors3.pan_cards && <span className="text-danger d-block error">{errors3.pan_cards.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} xl={6} className="mb-2">
                              <FormGroup>
                                <Label for="gstNumber">Enter GST Number<span className="text-danger">*</span></Label>
                                <input {...regulatory("gst_number", { required: 'Please Enter GST Number' })} className="form-control-sm form-control" placeholder="GSTIN Number" id="gstNumber" />
                                {errors3.gst_number && <span className="text-danger d-block error">{errors3.gst_number.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} xl={6} className="mb-2">
                              <FormGroup>
                                <Label for="uploadGst">Upload GST Copy<span className="text-danger">*</span></Label>
                                <input {...regulatory("gst_files", { required: regutaloryDetails !== undefined && regutaloryDetails.gst_file !== undefined && regutaloryDetails.gst_file !== "" ? false : true && 'Please Add GST Copy' })} type="file" onChange={handleFileInputgst} className="form-control-sm form-control" id="uploadGst" />
                                {regutaloryDetails !== undefined && regutaloryDetails.gst_file !== undefined && regutaloryDetails.gst_file !== null && regutaloryDetails.gst_file !== "" &&
                                  <div className={`edit-brand-logo download-img-con ${extension(regutaloryDetails.gst_file) === "pdf" && 'pdf-con'} mt-2`}>
                                    {extension(regutaloryDetails.gst_file) === "pdf" && <span className="pdf-file">{regutaloryDetails.gst_file}</span>}
                                    <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_COURIER + regutaloryDetails.gst_file} />
                                  </div>}
                                {errors3.gst_files && <span className="text-danger d-block error">{errors3.gst_files.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} className="text-right">
                              <Button className="ctm-btn btn-sm save" color="primary">Save</Button>
                            </Col>
                          </Row>
                        </Form>
                      </TabPane>

                      <TabPane tabId="4">
                        <Form onSubmit={spocSubmit(onSpocSetails)}>
                          <div className="sub-page-header">
                            <CardTitle tag="h4" className="sub-page-title">Spoc Details</CardTitle>
                          </div>
                          <Row>
                            <Col sm={12} md={6} xl={6} className="mb-2">
                              <FormGroup>
                                <Label for="remittanceEmail">Enter Remittance Email <small>(Enter Coma Separated)</small><span className="text-danger">*</span></Label>
                                <input {...spocDetails("remittance_email", { required: 'Please Enter Remittance Email' })} className="form-control-sm form-control" placeholder="Enter Remittance Email" id="remittanceEmail" />
                                {errors4.remittance_email && <span className="text-danger d-block error">{errors4.remittance_email.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} xl={6} className="mb-2">
                              <FormGroup>
                                <Label for="pendencyEmail">Enter Pendency Email <small>(Enter Coma Separated)</small><span className="text-danger">*</span></Label>
                                <input {...spocDetails("pendency_email", { required: 'Please Enter Pendency Email' })} className="form-control-sm form-control" placeholder="Enter Pendency Email" id="pendencyEmail" />
                                {errors4.pendency_email && <span className="text-danger d-block error">{errors4.pendency_email.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={12} className="text-right">
                              <Button className="ctm-btn btn-sm save" color="primary">Save</Button>
                            </Col>
                          </Row>
                        </Form>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {/* <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 courierDetails shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
              <CardTitle className="mb-0 page-title">Update Courier Details</CardTitle>
              <Link to='/courier' className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back to Courier List</span></Link>
            </CardHeader>
                <Nav tabs className="mt-2">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => { toggle('1'); }}
                    >
                      Address Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { toggle('2'); }}
                    >
                      Bank Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => { toggle('3'); }}
                    >
                      Regulatory Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '4' })}
                      onClick={() => { toggle('4'); }}
                    >
                      Spoc Details
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                  <Form onSubmit={courierAddressSubmit(onAddcourierAddress)}>
                  <Row className="mx-0 pt-3">
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                          <Label for="address">Enter Address<span className="text-danger">*</span></Label>
                          <input {...addCourier_address("address", { required: 'Please Enter Address' })} className="form-control-sm form-control" placeholder="Address" id="address" />
                          {errors2.address && <span className="text-danger d-block error">{errors2.address.message}</span>}
                        </FormGroup>                      
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                          <Label for="city">Enter City<span className="text-danger">*</span></Label>
                          <input {...addCourier_address("city", { required: 'Please Enter City',
                          pattern: {value:/^[a-z ,.'-]+$/i,message:'Please Enter Valid City'}})}  className="form-control-sm form-control" placeholder="City" id="city" />
                          {errors2.city && <span className="text-danger d-block error">{errors2.city.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                      <FormGroup>
                        <Label for="state">Select State<span className="text-danger">*</span></Label>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...addCourier_address("state_id",{ required: 'Please Select State'})}  className="form-control-sm form-control" id="state">
                                <option value="">Select State</option>
                                {StateList.map((item, index) => (
                                  <option value={item.state_id}>{item.state_name}</option>
                                ))
                            }
                            </select>
                            {errors2.state_id && <span className="text-danger d-block error">{errors2.state_id.message}</span>}
                        </div>
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                          <Label for="pincode">Enter Pincode<span className="text-danger">*</span></Label>
                          <input {...addCourier_address("pincode", { required: 'Please Enter Pincode',
                          minLength: { value: 6, message: "at least 6 digits"},
                          maxLength: { value: 6, message: "maximum 6 digits"},
                          pattern: {value:/^[+-]?\d+(\d+)?$/,message:'Please Enter Valid Pincode'}})}  className="form-control-sm form-control" placeholder="Pincode" id="pincode"/>
                          {errors2.pincode && <span className="text-danger d-block error">{errors2.pincode.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                          <Label for="contact">Enter Contact Number<span className="text-danger">*</span></Label>
                          <input {...addCourier_address("contact_number", { required: 'Please Enter Contact Number' })}  className="form-control-sm form-control" placeholder="Contact Number" id="contact" />
                          {errors2.contact_number && <span className="text-danger d-block error">{errors2.contact_number.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} className="text-right">
                      <Button className="btn btn-sm save" color="primary">Save</Button>
                    </Col>
                  </Row>
                  </Form>
               
                  </TabPane>
                  <TabPane tabId="2">
                  <Form onSubmit={bankDetailsSubmit(onAddbankDetails)}>
                  <Row className="mx-0 pt-3"> 
                    <Col Col sm={12} md={6} xl={4} className="mb-2"> 
                          <FormGroup>
                            <Label>Account Type<span className="text-danger">*</span></Label>
                            <div>
                              <span className="mr-2">
                                <input {...addCourier_bankDetails("account_type", { required: 'Please Select Account Type' })} type="radio" value="Saving" id="saving" />
                                <Label className="mx-2" for="saving">Saving</Label>
                              </span>
                              <span>
                                <input {...addCourier_bankDetails("account_type", { required: 'Please Select Account Type' })} type="radio" value="Current" id="current" />
                                <Label className="mx-2" for="current">Current</Label>
                              </span>
                            </div>
                            {errors.account_type && <span className="text-danger d-block error">{errors.account_type.message}</span>}
                          </FormGroup>                  
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                          <Label for="bankName">Enter Bank Name<span className="text-danger">*</span></Label>
                          <input {...addCourier_bankDetails("bank_name",{ required: 'Please Enter Bank Name'})} className="form-control-sm form-control" placeholder="Bank Name" id="bankName" />
                          {errors.bank_name && <span className="text-danger d-block error">{errors.bank_name.message}</span>}
                        </FormGroup>                      
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                          <Label for="accountNumber">Enter Account Number<span className="text-danger">*</span></Label>
                          <input {...addCourier_bankDetails("account_number",{ required: 'Please Enter Account Number',
                          pattern: {value:/^[+-]?\d+(\d+)?$/,message:'Please Enter Valid Account Number'}})} type="text" className="form-control-sm form-control" placeholder="Account Number" id="accountNumber" />
                          {errors.account_number && <span className="text-danger d-block error">{errors.account_number.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                          <Label for="IFSC_Code">Enter IFSC Code<span className="text-danger">*</span></Label>
                          <input {...addCourier_bankDetails("ifsc_code",{ required: 'Please Enter IFSC Code'})} className="form-control-sm form-control" placeholder="IFSC Code" id="IFSC_Code"/>
                          {errors.ifsc_code && <span className="text-danger d-block error">{errors.ifsc_code.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">
                        <FormGroup>
                          <Label for="branchName">Enter Branch Name<span className="text-danger">*</span></Label>
                          <input {...addCourier_bankDetails("branch", { required: 'Please Enter Branch Name'} )} className="form-control-sm form-control" placeholder="Branch" id="branchName" />
                          {errors.branch && <span className="text-danger d-block error">{errors.branch.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={4} className="mb-2">                       
                        <FormGroup>
                          <Label for="uploadCheque">Upload Cheque Book Copy<span className="text-danger">*</span></Label>
                          <input {...addCourier_bankDetails("cheque_copy", { required: bankDetails !== undefined && bankDetails.cancel_cheque_copy !== undefined && bankDetails.cancel_cheque_copy !== "" ? false: true && 'Please Add Cheque Book Copy'})} type="file" onChange={handleFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadCheque" />
                          {bankDetails !== undefined && bankDetails.cancel_cheque_copy !== undefined && bankDetails.cancel_cheque_copy !== null && <div className="edit-brand-logo mt-2"><img src={process.env.REACT_APP_IMAGE_COURIER+bankDetails.cancel_cheque_copy} alt="logo" style={{width:"120px"}} /></div>}
                          {errors.cheque_copy && <span className="text-danger d-block error">{errors.cheque_copy.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} className="text-right">
                      <Button className="btn btn-sm" color="primary">Save</Button>
                    </Col>
                  </Row>
                  </Form>
                  </TabPane>
                  <TabPane tabId="3">
                  <Form onSubmit={regulatorySubmit(onRegulatory)}>
                  <Row className="mx-0 pt-3">
                    <Col sm={12} md={6} xl={6} className="mb-2">
                        <FormGroup>
                          <Label for="panNumber">Enter Pan Card Number<span className="text-danger">*</span></Label>
                          <input {...regulatory("pan_card_no",{ required: 'Please Enter Pan Card Number'})} className="form-control-sm form-control" placeholder="PAN Number" id="panNumber" />
                          {errors3.pan_card_no && <span className="text-danger d-block error">{errors3.pan_card_no.message}</span>}
                        </FormGroup>                      
                    </Col>
                    <Col sm={12} md={6} xl={6} className="mb-2">
                        <FormGroup>
                          <Label for="uploadPan">Upload Pan Card<span className="text-danger">*</span></Label>
                          <input {...regulatory("pan_cards", { required: regutaloryDetails !== undefined && regutaloryDetails.pan_card !== undefined && regutaloryDetails.pan_card !== "" ? false: true && 'Please Add Pan Card Copy'})} type="file" onChange={handleFileInputpan} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadPan" />
                          {regutaloryDetails !== undefined && regutaloryDetails.pan_card !== undefined && regutaloryDetails.pan_card !== null &&<div className="edit-brand-logo mt-2"><img src={process.env.REACT_APP_IMAGE_COURIER+regutaloryDetails.pan_card} alt="logo" style={{width:"120px"}} /></div>}
                          {errors3.pan_cards && <span className="text-danger d-block error">{errors3.pan_cards.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={6} className="mb-2">
                        <FormGroup>
                          <Label for="gstNumber">Enter GST Number<span className="text-danger">*</span></Label>
                          <input {...regulatory("gst_number", { required: 'Please Enter GST Number'})}  className="form-control-sm form-control" placeholder="GSTIN Number" id="gstNumber" />
                          {errors3.gst_number && <span className="text-danger d-block error">{errors3.gst_number.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={6} className="mb-2">
                        <FormGroup>
                          <Label for="uploadGst">Upload GST Copy<span className="text-danger">*</span></Label>
                          <input {...regulatory("gst_files", { required: regutaloryDetails !== undefined && regutaloryDetails.gst_file !== undefined && regutaloryDetails.gst_file !== "" ? false: true && 'Please Add GST Copy'})} type="file" onChange={handleFileInputgst} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadGst" />
                          {regutaloryDetails !== undefined && regutaloryDetails.gst_file !== undefined && <div className="edit-brand-logo mt-2"><img src={process.env.REACT_APP_IMAGE_COURIER+regutaloryDetails.gst_file} alt="logo" style={{width:"120px"}} /></div>}
                          {errors3.gst_files && <span className="text-danger d-block error">{errors3.gst_files.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} className="text-right">
                      <Button className="btn  btn-sm save" color="primary">Save</Button>
                    </Col>
                  </Row>
                  </Form>
               
                  </TabPane>
                  <TabPane tabId="4">
                  <Form onSubmit={spocSubmit(onSpocSetails)}>
                  <Row className="mx-0 pt-3">
                    <Col sm={12} md={6} xl={6} className="mb-2">
                        <FormGroup>
                          <Label for="remittanceEmail">Enter Remittance Email <small>(Enter Coma Separated)</small><span className="text-danger">*</span></Label>
                          <input {...spocDetails("remittance_email",{ required: 'Please Enter Remittance Email'})} className="form-control-sm form-control" placeholder="Enter Remittance Email" id="remittanceEmail"/>
                          {errors4.remittance_email && <span className="text-danger d-block error">{errors4.remittance_email.message}</span>}
                        </FormGroup>                      
                    </Col>
                    <Col sm={12} md={6} xl={6} className="mb-2">
                        <FormGroup>
                          <Label for="pendencyEmail">Enter Pendency Email <small>(Enter Coma Separated)</small><span className="text-danger">*</span></Label>
                          <input {...spocDetails("pendency_email",{ required: 'Please Enter Pendency Email'})} className="form-control-sm form-control" placeholder="Enter Pendency Email" id="pendencyEmail"/>
                          {errors4.pendency_email && <span className="text-danger d-block error">{errors4.pendency_email.message}</span>}
                        </FormGroup>                      
                    </Col>
                    <Col sm={12} className="text-right">
                      <Button className="btn  btn-sm save" color="primary">Save</Button>
                    </Col>
                  </Row>
                  </Form>
               
                  </TabPane>
                </TabContent>
          </Card>
        </Card>
      </Col>
      
      </Row> */}
    </div>
  );
}

export default CourierDetails;