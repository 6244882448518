import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Label, Form, FormGroup, Modal, ModalHeader, ModalBody, UncontrolledTooltip, Spinner } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faArrowDown } from '@fortawesome/free-solid-svg-icons';
// import { DateRangePicker } from 'rsuite';
import LoaderDefault from '../LoaderDefault';
import Swal from 'sweetalert2';
import axios from 'axios';
import NodataFound from '../NodataFound';
import { baseString, download_delay, states } from '../../config';
import { changeIntoDateTime, changeIntoDateYmd, getPickupDate, firstDayOftheMonth, errorHandling, changeIntoDatedmY } from '../../utlis';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { useForm } from 'react-hook-form';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { toast } from 'react-toastify';

const PickupPending = (props) => {
  const [PickupPending, setPickupPending] = useState({})
  const [filterDate, setFilterDate] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState('');
  const [inputAWBNo, setInputAWBNo] = useState('');
  const [modalGenerate, setModalGenerate] = useState(false);
  const [freshData, setFreshData] = useState(0);
  const toggleDownload = () => {
    setModalDownload(!modalDownload)
    setInputAWBNo('');
  };
  const freshToggle = (e) => {
    setPickupPending({});
    setFreshData(e.target.value);
    pickupPendingList(e.target.value);
  }
  const [modalDownload, setModalDownload] = useState(false);
  const toggleGenerate = () => {
    setModalGenerate(!modalGenerate);
    setInputAWBNo('');
  }


  const dropFilterCount = 20

  // const onDateChange = (e) => {
  //   setFilterDate(e.text);
  // };
  // let dateRangeRef =  dateRange => dateRangeRef = dateRange;
  const history = useHistory();

  const pickupPendingList = (fresh) => {
    setLoadertable(true);
    setFilterInput('')
    setCurrentCount(1)
    setPage(1);
    setFilterDate([]);
    let data = { "order_status_id": [2, 4], "start_date": changeIntoDateYmd(firstDayOftheMonth()), "end_date": changeIntoDateYmd(new Date()) }
    if (fresh !== undefined) {
      data.is_fresh = parseInt(fresh);
    } else {
      data.is_fresh = parseInt(freshData);
    }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PICKUP_PENDING}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          setPickupPending(res.data);
        }
      }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err, "reload")
    })
  }
  useEffect(() => {
    pickupPendingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(PickupPending.data) && checkUndeNullBlank(PickupPending.totalPage)) {
      // &&checkUndeNullBlank(PickupPending.totalPage).length>page.length
      if (PickupPending.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(PickupPending.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(PickupPending.data)
      if (currentCount > Object.entries(PickupPending.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  // filter Bulk Order
  const { handleSubmit: filterPickupPending } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onFilterPickupPending = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (filterDate.length > 0) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      toast.info('Select Your Filtering Preference');
    }

  };
  const onCommonFilter = (data, pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
    let byData = {}

    byData = { "start_date": checkData(filterDate) ? changeIntoDateYmd(filterDate[0]) : '', "end_date": checkData(filterDate) ? changeIntoDateYmd(filterDate[1]) : '' }
    if (props.user[0].user_type === "Super") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PICKUP_PENDING}?page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setPickupPending(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "")
      setLoader(false);
    });
  };

  const { register: bulkLabel, handleSubmit: bulkLabelDownload, formState: { errors: errormanlabel } } = useForm();
  const { register: general, handleSubmit: generateManifest, formState: { errors: errormanfest } } = useForm();
  const submitGenerate = (data) => {
    var lastData = data.awb_nos.slice(-1);

    data.awb_nos = data.awb_nos.split(',')
    // labelDownloadCommon(data);
    if (lastData === ",") {
      data.awb_nos.pop();
    }
    if (data.awb_nos.length <= 500) {
      manifestDownloadCommon(data);
    } else {
      Swal.fire({ icon: 'error', text: "Maximum 500 Docket Numbers Allowed", title: 'Error' });
    }
  }
  const singleManifestDownload = (data) => {
    manifestDownloadCommon(data);
  }

  const manifestDownloadCommon = (data) => {
    setLoader(true);
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    data.is_fresh = parseInt(freshData);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_DOWNLOAD_MANIFEST}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data.is_generate === 1) {
        setLoader(false);
        props.downloadNotification('Generating Manifest...', '/my-downloads');
        // setTimeout(function(){ 
        //   setLoader(false);
        //   history.push("/my-downloads"); 
        // },download_delay);
      } else {
        setLoader(false);
        Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
      }
    }).catch((err) => {
      errorHandling(err, "")
      setLoader(false);
    });
  }
  const singleLableDownload = (data) => {
    labelDownloadCommon(data);
  }
  const labelDownload = (data) => {
    // setLoader(true);
    console.log("data1",JSON.stringify(data));

    var lastData = data.awb_nos.slice(-1);

    data.awb_nos = data.awb_nos.split(',')
    // labelDownloadCommon(data);
    if (lastData === ",") {
      data.awb_nos.pop();
    }
    if (data.awb_nos.length <= 200) {
      labelDownloadCommon(data);
    } else {
      Swal.fire({ icon: 'error', text: "Maximum 200 Docket Numbers Allowed", title: 'Error' });
    }

  }
  const labelDownloadCommon = (data) => {
    setLoader(true);
    let is_pod=(data.is_pod!==undefined)?data.is_pod:"";

    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
      data.logo = props.user[0].logo
    }
    data.is_pod = is_pod;
    let url=`${process.env.REACT_APP_DOWNLOAD_ADDRESS_LABEL}`;
    if(is_pod === true || is_pod === false){
      url=`${process.env.REACT_APP_DOWNLOAD_POD}`;
    }
    
    axios({
      method: "post",
      url: url,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data.is_generate === 1) {
       // setLoader(false);
       // props.downloadNotification('Generating Address Labels...', '/my-downloads');
        setTimeout(function(){ 
            setLoader(false);
            history.push("/my-downloads"); 
        },5000);
      }
      else {
        setLoader(false);
        Swal.fire({ title: 'Error', text: res.msg, icon: 'error', });
      }
    }).catch((err) => {
      errorHandling(err, "")
      setLoader(false);
    });
  }
  const tokenGenerate = (pid, cid) => {
    setLoader(true);
    let data = { "pickup_point_id": pid, "courier_id": cid };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BLUEDART_PICKUP}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        Swal.fire({ title: 'Success', text: `Token No. = ${res.data.TOKEN_NO}`, icon: 'success', allowOutsideClick: false, allowEscapeKey: false });
      }
      else {
        Swal.fire({ title: 'Error', text: res.data.msg, icon: 'error', });
      }
    }).catch((err) => {
      errorHandling(err, "")
      setLoader(false);
    });
  }
  const seperateAWBno = (e) => {
    var text = e.target.value;
    var lastData = text.slice(-1);
    var inputData = text;
    var newText = "";
    var listData = "";
    listData = text.replace(/\n|\r/g, "");
    listData = inputData.split(/,+/);
    setInputAWBNo(text);
    if (lastData === ",") {
      newText = listData.toString();
      setInputAWBNo(newText);
    }

    if (listData.length > 500) {
      Swal.fire({ icon: 'error', text: "Maximum 500 Docket Numbers Allowed", title: 'Error' });
    }
  }



  return (
    // <div className="app-content content overflow-hidden">
    <div>
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <div className="p-0 PickupPending content-wrapper border-0">
            <CardHeader className="page-header d-flex flex-wrap justify-content-between d-none">
              <CardTitle className="page-title">Export Address Labels/Manifests</CardTitle>
              <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                <div className="btn-text btn-light mx-2" onClick={toggleDownload}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Export Bulk Label</span></div>
                <div className="btn-text btn-light mx-2" onClick={toggleGenerate}><span className="icon mr-2"></span><span>Generate Custom Manifest</span></div>
              </div>
            </CardHeader>
            <Col sm={12}>
              <FormGroup className='d-none'>
                <div className="d-flex flex-wrap align-items-center justify-content-sm-center">
                  <span className="form-check2 mr-2">
                    <input name='is_fresh' type="radio" value="0" id="all" onChange={freshToggle} defaultChecked />
                    <Label className="mx-2 my-0" for="all">All</Label>
                  </span>
                  <span className="form-check2">
                    <input name='is_fresh' type="radio" value="1" id="fresh" onChange={freshToggle} />
                    <Label className="mx-2 my-0" for="fresh">Label Not Downloaded</Label>
                  </span>
                </div>
              </FormGroup>
            </Col>
            <Form onSubmit={filterPickupPending(onFilterPickupPending)}>
              <Row className="mx-0 justify-content-center">
                <Col sm={3} className="btn-container">
                  {/* <Button className="btn btn-sm mr-2 mb-2 mb-sm-0" color="primary">Search</Button>
                    <Button type="reset" className="btn btn-sm mr-2 mb-2 mb-sm-0" color="primary" outline onClick={pickupPendingList}>Reset</Button> */}
                    {props.user[0].user_type === "Super" &&
                   <Button className="btn btn-sm mr-2 mb-2 mb-sm-0" color="secondary" onClick={toggleDownload}>Download Bulk POD</Button> }
                  {/*  <Button className="btn btn-sm mr-2 mb-2 mb-sm-0" color="dark" onClick={toggleGenerate}>Generate Custome Manifest</Button> */}
                </Col>
              </Row>
            </Form>
            <Modal isOpen={modalDownload} toggle={toggleDownload} size="lg" scrollable={true}>
              <ModalHeader toggle={toggleDownload}>BULK POD DOWNLOAD</ModalHeader>
              <ModalBody>
                <Form onSubmit={bulkLabelDownload(labelDownload)}>
                  <Row className="mx-0 justify-content-center downloadModal">
                    <Col sm={12}>
                      <FormGroup>
                        <Label for="exampleText">Please Enter Comma Seperated Docket Number</Label>
                        <textarea {...bulkLabel("awb_nos", { required: 'Please Enter Comma Seperated Docket Number' })} value={inputAWBNo} onChange={e => seperateAWBno(e)} rows="4" className="form-control-sm form-control" placeholder="Please Enter Comma Seperated AWB Number" id="labels" />
                        {/* <Input type="textarea" name="text" id="exampleText" rows="15" /> */}
                        {/* <span className="small" > 500 Docket Numbers (approx) or 5000 Characters Allowed</span> */}
                        {errormanlabel.awb_nos && <span className="text-danger d-block error">{errormanlabel.awb_nos.message}</span>}
                        <span className="small" > Maximum 200 Docket Numbers Allowed</span>
                        <input {...bulkLabel("is_pod")} type="checkbox" id="is_pod" value="1" />

                      </FormGroup>
                    </Col>
                    {/* <Col sm={12} className="text-right">
                      <FormGroup>
                        <div className="form-check2 d-flex flex-wrap align-items-center justify-content-center mb-4">
                          <input {...bulkLabel("is_amazon")} type="checkbox" id="for_amazon" value="1" />
                          <Label className="mx-2 my-0" for="for_amazon" >Click For Amazon Courier</Label>
                        </div>
                      </FormGroup>
                    </Col> */}
                    <Col sm={12} className="text-right">
                      <Button className="btn ctm-btn btn-sm" color="primary">Generate POD's</Button>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
            <Modal isOpen={modalGenerate} toggle={toggleGenerate} size="lg" scrollable={true}>
              <ModalHeader toggle={toggleGenerate}>Manual Manifest Generation Module</ModalHeader>
              <ModalBody>
                <Form onSubmit={generateManifest(submitGenerate)}>
                  <Row className="mx-0 justify-content-center downloadModal">
                    <Col sm={12}>
                      <FormGroup>
                        <Label for="exampleText">Please Enter Comma Seperated AWB Number</Label>
                        <textarea {...general("awb_nos", { required: 'Please Enter Comma Seperated AWB Number' })} onChange={e => seperateAWBno(e)} value={inputAWBNo} rows="4" className="form-control-sm form-control" placeholder="Please Enter Comma Seperated AWB Number" id="labels" />
                        {/* <Input type="textarea" name="text" id="exampleText" rows="15" /> */}
                        {/* <span className="small" > 500 Docket Numbers (approx) or 5000 Characters Allowed</span> */}
                        {errormanfest.awb_nos && <span className="text-danger d-block error">{errormanfest.awb_nos.message}</span>}
                        <span className="small" > Maximum 500 Docket Numbers Allowed</span>
                      </FormGroup>
                    </Col>
                    <Col sm={12} className="text-right">
                      <Button className="btn ctm-btn btn-sm" color="primary">Generate/Download Manifest</Button>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
            <CardBody className="p-0">
              <div className="showing-count">
                {checkData(PickupPending) && checkData(PickupPending.data) && checkUndeNullBlank(PickupPending.TotalRows) ?
                  <span className="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(PickupPending.data).length)} of {PickupPending.TotalRows}</span> : ''}
              </div>
              <Row className='p-3'>
                {checkData(PickupPending.data)  ? PickupPending.data.map((item, index) => (
                  <Col md={4}>
                    <Card className="shadow border-0 mb-4 p-4">
                      {/* <span>{item.MerchantPickupPointName}</span> */}
                      <div className="position-relative font-weight-bold">
                        <div className="text-truncate" id={`addressTooltip${index}`}>{item.pickup_address_1}</div>
                        <UncontrolledTooltip placement="top" target={`addressTooltip${index}`}>
                          {item.pickup_address_1} {item.pickup_city_name} {item.pickup_pincode} {states[item.pickup_state_id]}
                        </UncontrolledTooltip>
                      </div>

                      <div>{item.CourierName}</div>
                      {item.courier_id === 3 && <div><span className="cursor-pointer text-primary fsc-4" onClick={() => tokenGenerate(item.pickup_point_id, item.courier_id)}>Generate Token</span></div>}

                      {/* <Link to="/all-shipments">{item.pickup_address_1}</Link> */}
                      <div className="manifestsProdCount"><Link className="text-white" to={`/shipments?start_date=${changeIntoDatedmY(item.created_at)}&end_date=${changeIntoDatedmY(item.created_at)}&cid=${item.courier_id}&mid=${item.merchant_id}&status=4`}>{item.totalOrders}</Link> </div>

                      <div>Added Date {changeIntoDateTime(item.created_at)}</div>
                      <div className="text-primary">Next Pickup- {getPickupDate(item.created_at)}</div>

                      <Button className="ctm-btn btn-sm mt-3" color="primary" onClick={() => singleManifestDownload({ "pickup_point_id": item.pickup_point_id, "courier_id": item.courier_id, "order_date": changeIntoDateYmd(item.created_at) })}>Download Manifest</Button>
                      <Button className="ctm-btn btn-sm mt-3" color="primary" onClick={() => singleLableDownload({ "pickup_point_id": item.pickup_point_id, "courier_id": item.courier_id, "order_date": changeIntoDateYmd(item.created_at) })}>Download Address Labels</Button>
                    </Card>
                  </Col>
                )) :
                  loadertable ? <LoaderDefault />
                    : <NodataFound />
                }
              </Row>

              <Table responsive className="text-center custom-table d-none">
                <thead>
                  <tr>
                    <th className="text-nowrap">Warehouse</th>
                    <th className="text-nowrap">Courier</th>
                    <th className="text-nowrap">Total Orders</th>
                    <th className="text-nowrap">Address Labels/Manifest</th>
                    <th className="text-nowrap">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {checkData(PickupPending.data) && PickupPending.data[0].courier_id > 0 ? PickupPending.data.map((item, index) => (
                    <tr key={index}>
                      <td className="col-2 text-nowrap text-left">
                        <span>{item.MerchantPickupPointName}</span>
                        <div className="position-relative">
                          <div className="text-truncate" id={`addressTooltip${index}`}>{item.pickup_address_1}</div>
                          <UncontrolledTooltip placement="top" target={`addressTooltip${index}`}>
                            {item.pickup_address_1} {item.pickup_city_name} {item.pickup_pincode} {states[item.pickup_state_id]}
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td className="text-nowrap">
                        <div>{item.CourierName}</div>
                        {item.courier_id === 3 && <div><span className="cursor-pointer text-primary fsc-4" onClick={() => tokenGenerate(item.pickup_point_id, item.courier_id)}>Generate Token</span></div>}
                      </td>
                      <td className="text-nowrap">
                        {/* <Link to="/all-shipments">{item.pickup_address_1}</Link> */}
                        <div><Link to={`/shipments?start_date=${changeIntoDatedmY(item.created_at)}&end_date=${changeIntoDatedmY(item.created_at)}&cid=${item.courier_id}&mid=${item.merchant_id}&status=4`}>{item.totalOrders}</Link> </div>
                      </td>
                      <td className="text-nowrap">
                        <div><span style={{ 'cursor': 'pointer' }} onClick={() => singleManifestDownload({ "pickup_point_id": item.pickup_point_id, "courier_id": item.courier_id, "order_date": changeIntoDateYmd(item.created_at) })}>Generate Manifest</span></div>
                        <div><span style={{ 'cursor': 'pointer' }} onClick={() => singleLableDownload({ "pickup_point_id": item.pickup_point_id, "courier_id": item.courier_id, "order_date": changeIntoDateYmd(item.created_at) })}>Download Address Labels</span></div>
                      </td>
                      <td className="text-nowrap">
                        <div>Added Date {changeIntoDateTime(item.created_at)}</div>
                        <div className="text-primary">Pickup Scheduled for- {getPickupDate(item.created_at)}</div>
                      </td>
                    </tr>
                  )) :
                    loadertable ? <tr>
                      <td colSpan="5"><LoaderDefault /></td>
                    </tr>
                      : <tr><td colSpan="5" className="text-center"><NodataFound /></td></tr>
                  }
                </tbody>
              </Table>
              {checkData(PickupPending) && checkData(PickupPending.data) && checkUndeNullBlank(PickupPending.TotalRows) ?
                <>
                  {PickupPending.TotalRows > dropFilterCount ?
                    <Row className="m-0 table-footer">
                      <Col xs="6">
                        <Pagination aria-label="Page navigation example align-items-center">
                          <PaginationItem className="prev">
                            <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                          </PaginationItem>
                          <PaginationItem className="next ml-3">
                            <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                          </PaginationItem>
                        </Pagination>
                      </Col>
                      <Col xs="6">
                        <Form>
                          <FormGroup>
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                              <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </FormGroup>
                        </Form>
                      </Col>
                    </Row> : ''}
                </>
                : ''}
            </CardBody>
          </div>
        </Col>

      </Row>
    </div>
  );
}

export default PickupPending;