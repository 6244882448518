import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { MultiSelect, } from "react-multi-select-component";
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { useForm } from 'react-hook-form';
import { changeIntoDateTime, errorHandling, remExtraSpace } from '../../utlis';
import { ArrowLeft, ChevronLeft, ChevronRight } from 'react-feather';
import { connect } from 'react-redux';
import { baseString, sms_log_status } from '../../config';
import { Doughnut } from 'react-chartjs-2';

const SmgLog = (props) => {
  const [smsLog, setSmsLog] = useState({});
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState('');
  const [verifiedCount, setVerifiedCount] = useState('');
  const [notVerifiedCount, setNotVerifiedCount] = useState('');
  const [merchants, setMerchants] = useState([]);

  const dropFilterCount = 20
  let history = useHistory();
  const goToPreviousPath = () => {
    if (checkUndeNullBlank(history.location.key)) {
      history.goBack();
    } else {
      history.push('/channel-orders');
    }
  }
  var opt_merchants = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {}
      if (merchantName && merchantId) {
        tempObj = { label: merchantName, value: merchantId }
        opt_merchants.push(tempObj)
      }
      return true
    });
  }
  const merchantsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }
  const smsLogListing = () => {
    setMerchants([]);
    let data = {}
    reset();
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }

    setLoader(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_ORDER_SMS_LOG}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false)
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})

          }
        } else {
          setSmsLog(res.data);
          let myResponse = res.data.TotalDataArr
          if (checkData(myResponse)) {
            myResponse.map((item) => {
              let verifiedCount = (checkUndeNullBlank(item.status) && (parseInt(item.status) === 1)) ? parseInt(item.total) : 0
              let notverifiedCount = (checkUndeNullBlank(item.status) && (parseInt(item.status) === 0)) ? parseInt(item.total) : 0
              if (parseInt(verifiedCount) > 0) {
                setVerifiedCount(verifiedCount);
              }
              if (parseInt(notverifiedCount) > 0) {
                setNotVerifiedCount(notverifiedCount);
              }
              return (
                myResponse
              )
            });
          }
        }
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err, "reload");
    });
  }

  useEffect(() => {
    smsLogListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(smsLog.data) && checkUndeNullBlank(smsLog.totalPage)) {
      // &&checkUndeNullBlank(smsLog.totalPage).length>page.length
      if (smsLog.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(smsLog.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(smsLog.data)
      if (currentCount > Object.entries(smsLog.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  // filter sms log
  const { register: filter, handleSubmit: smsLogSubmit, reset } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onFilterSmgLog = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.customer_mobile) || checkUndeNullBlank(data.status_id) || checkData(merchants)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      toast.info('Select Your Filtering Preference');
    }

  };
  const onCommonFilter = (data, pagePerpage) => {
    setLoader(true)
    let merchantsId = [];

    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }
    let mId = checkUndeNullBlank(merchantsId) ? merchantsId : '';

    let byData = { "status_id": checkUndeNullBlank(data.status_id) ? data.status_id : '', "customer_mobile": checkUndeNullBlank(data.customer_mobile) ? remExtraSpace(data.customer_mobile) : '', "merchant_ids": mId }


    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = 1
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_ORDER_SMS_LOG}?page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
                        toast.error(res.data.sqlMessage,{position:'top-right'})
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setSmsLog(res.data);
            let myResponse = res.data.TotalDataArr
            if (checkData(myResponse)) {
              myResponse.map((item) => {
                let verifiedCount = (checkUndeNullBlank(item.status) && (parseInt(item.status) === 1)) ? parseInt(item.total) : 0
                let notverifiedCount = (checkUndeNullBlank(item.status) && (parseInt(item.status) === 0)) ? parseInt(item.total) : 0
                if (parseInt(verifiedCount) > 0) {
                  setVerifiedCount(verifiedCount);
                }
                if (parseInt(notverifiedCount) > 0) {
                  setNotVerifiedCount(notverifiedCount);
                }
                return (
                  myResponse
                )
              });
            }
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  };

  const data = {
    labels: ['Verified', 'Not Verified'],
    datasets: [
      {
        label: 'SMS Log',
        data: [verifiedCount, notVerifiedCount],
        backgroundColor: [
          'rgb(47,129,83)',
          'rgb(205,69,70)',
        ],
        hoverOffset: 0
      },

    ],

  };

  let totatlVerified = verifiedCount + notVerifiedCount;
  let verifiedCountPercent = (verifiedCount / totatlVerified) * 100;
  let notVerifiedCountPercent = (notVerifiedCount / totatlVerified) * 100;

  const options = {
    plugins: {
      title: {
        display: false,
        text: ''
      },
      legend: {
        display: false,
        font: {
          size: 8
        },
        position: "top",
        labels: {
          usePointStyle: true, font: {
            size: 8
          }
        }
      },
      datalabels: {
        display: true,
        color: "white",
      },
      tooltips: {
        backgroundColor: "#5a6e7f",
      },
    }
  };

  return (
    <div className="app-content content overflow-hidden">
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 rtoShipment shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                <CardTitle className="page-title">SMS Log</CardTitle>
                <div onClick={goToPreviousPath} className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back</span></div>
              </CardHeader>
              <Form onSubmit={smsLogSubmit(onFilterSmgLog)} className="cardFilter">
                <Row className="mx-0 justify-content-start mb-3">
                  <Col sm={6} lg={2}>
                    <FormGroup>
                      <input {...filter("customer_mobile")} type="text" className="form-control-sm form-control" placeholder="Enter Mobile Number" />
                    </FormGroup>
                  </Col>
                  {props.user[0].user_type === "Super" &&
                    <Col sm={6} lg={2}>
                      <FormGroup>
                        <MultiSelect
                          options={opt_merchants}
                          value={merchants}
                          onChange={setMerchants}
                          labelledBy="Select Merchant"
                          overrideStrings={merchantsMultiSelectSettings}
                        />
                      </FormGroup>
                    </Col>}
                  <Col sm={6} lg={2}>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select {...filter("status_id")} className={`form-control-sm form-control`}>
                        <option value="">Select Status</option>
                        <option value="0">Not Verified</option>
                        <option value="1">Verified</option>
                      </select>
                    </FormGroup>
                  </Col>

                  <Col sm={6} lg={2} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={smsLogListing}>Reset</Button>
                  </Col>
                  <Col sm={6} lg={2} className="position-relative">
                    <div className='dogoutsms d-flex align-items-center' >
                      <Doughnut data={data} options={options} />
                      <div className='dogoutlabel'>
                        <Badge color='success w-100'>Verified : {`${verifiedCount} / ${(verifiedCountPercent).toFixed(2)}%`}</Badge><br />
                        <Badge color='danger w-100'>Not Verified : {`${notVerifiedCount} / ${(notVerifiedCountPercent).toFixed(2)}%`}</Badge>
                        <Badge color='primary w-100'>Total : {verifiedCount + notVerifiedCount}</Badge>
                      </div>
                    </div>

                  </Col>
                  <Col sm={3} className="w-auto position-relative">

                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(smsLog) && checkData(smsLog.data) && checkUndeNullBlank(smsLog.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(smsLog.data).length)} of {smsLog.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      {props.user[0].user_type === "Super" &&
                        <th className="text-nowrap">Merchant Name</th>
                      }
                      <th className="text-nowrap">Mobile</th>
                      <th className="text-nowrap">Status</th>
                      <th className="text-nowrap">Date</th>
                      <th className="text-nowrap">Verify</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(smsLog.data) ? smsLog.data.map((item, index) => {
                      return (
                        <tr>
                          {props.user[0].user_type === "Super" &&
                            <td>{item.merchantName}</td>
                          }
                          <td>{item.mobile}</td>
                          <td><Badge color={`${item.status === 1 ? 'success' : 'danger'}`}>{sms_log_status[item.status]}</Badge></td>
                          <td>{(checkUndeNullBlank(item.created_at) ? changeIntoDateTime(item.created_at) : 'NA')}</td>
                          <td>{(checkUndeNullBlank(item.updated_at) ? changeIntoDateTime(item.updated_at) : 'NA')}</td>
                        </tr>
                      )
                    }) :
                      loader ? <tr><td colSpan="7" className="text-center"><LoaderDefault /></td></tr>
                        : <tr><td colSpan="7" className="text-center"><NodataFound /></td></tr>}
                  </tbody>
                </Table>

                {checkData(smsLog) && checkData(smsLog.data) && checkUndeNullBlank(smsLog.TotalRows) ?
                  <>
                    {smsLog.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}

export default connect(mapDispatchToProps)(SmgLog);